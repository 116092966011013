import CountryCodes from '../constants/countryCodes';
import ErrorMessages from '../constants/errors';

function transformErrors(errors, formContext) {
  const country = formContext.countryCode === 'AU' ? 'Australian' : 'New Zealand';

  return errors.map((error) => {
    if (error.name === 'required') {
      error.message = ErrorMessages.REQUIRED;
    }

    if (error.params.format === 'ez-mail') {
      error.message = 'Needs to be a valid email';
    } else if (error.params.format === 'required-phone-mask' || error.params.format === 'optional-phone-mask') {
      if (!CountryCodes.REGULAR_REGION_CODES.includes(formContext.countryCode)) {
        error.message = `Needs to be a valid number`;
      } else {
        error.message = `Needs to be a valid ${country} number`;
      }
    } else if (error.params.format === 'mobile-mask') {
      if (!CountryCodes.REGULAR_REGION_CODES.includes(formContext.countryCode)) {
        error.message = `Needs to be a valid mobile number`;
      } else {
        error.message = `Needs to be a valid ${country} mobile number`;
      }
    }
    if (error.params.format === 'bsb') {
      error.message = ErrorMessages.BSB_MAX_LENGTH;
    }
    if (error.params.format === 'mobile-and-phone-mask' || error.params.format === 'usaphone') {
      error.message = 'Needs to be a valid phone or mobile number';
    }
    return error;
  });
}

export default transformErrors;
