import { useContext, useEffect, useState } from 'react';
import StripeElements from '../StripeElements';
import { CreditCard, BuildingBank } from 'tabler-icons-react';
import BankTransferForm from './BankTransfer/eddr/BankTransferForm';
import ApiService from '../../services/apiService';
import MainStore from '../../stores/MainStore';
import { FormFields } from './BankTransfer/eddr/FormValidation';

type OrganisationInfo = {
  creditApplicationId: string;
  description: string;
  orgId: string;
  authToken: string;
};

function PaymentAuthorisationWrapper(props: { value: any; onChange: any; schema: any }) {
  const mainStore = useContext(MainStore);
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [isLoading, setIsLoading] = useState(false);
  const [fees, setFees] = useState({});
  const { bankTransfer = false, creditCard = false } = props.schema;
  const [bankTransferAuthorityCreated, setBankTransferAuthorityCreated] = useState(props.value ? true : false);

  const getOrganisationInfo = (): OrganisationInfo => {
    return {
      creditApplicationId: mainStore.creditApplicationId || '',
      orgId: mainStore.orgid || '',
      authToken: mainStore.authToken,
      description:
        mainStore.creditApplicationDomain.registeredCompanyName !== ''
          ? mainStore.creditApplicationDomain.registeredCompanyName
          : '---',
    };
  };

  const createOrphanBankTransferAgreement = (
    formFields: FormFields,
    transactionInfo: OrganisationInfo,
    showPayToModal: () => void
  ) => {
    if (isLoading || bankTransferAuthorityCreated) return;

    setIsLoading(true);
    const data: FormData = new FormData();

    data.append('accountName', formFields.accountName);
    data.append('bsb', formFields.bsb);
    data.append('accountNumber', formFields.accountNumber);
    data.append('paymentMethodsType', 'EFT');
    data.append('uniqueReference', transactionInfo?.creditApplicationId || '');
    data.append('description', transactionInfo?.description);
    data.append('email', formFields.email);

    ApiService.createOrphanBankTransferAgreement(data, transactionInfo?.orgId, transactionInfo?.authToken)
      .then((response) => {
        mainStore.updatePayToAgreement({});
        if (response.data?.paymentType === 'PAYTO') {
          showPayToModal();
          mainStore.updatePayToAgreement({
            uid: response.data?.uid,
            status: 'CREATED',
          });
        }
        setBankTransferAuthorityCreated(true);
      })
      .catch((error) => {
        console.error('Error on ZEPTO agreement creation: ', error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    ApiService.getFeesByOrgId(mainStore.orgid, mainStore.authToken)
      .then((response) => {
        setFees(response.data);
      })
      .catch((error) => {
        console.error(`Error getting fees for the org: ${mainStore.orgid} -> `, error);
      });
  }, [mainStore.authToken, mainStore.orgid]);

  useEffect(() => {
    // @ts-ignore
    if ((bankTransfer && !creditCard) || mainStore.bankTransferInfo?.bsb) {
      setPaymentMethod('bank_transfer');
    }
    // @ts-ignore
  }, [bankTransfer, creditCard, mainStore.bankTransferInfo?.bsb]);

  return (
    <div>
      <div className="flex flex-wrap">
        <div className="flex w-1/3 sm:w-full mt-6 gap-5">
          {bankTransfer && creditCard ? (
            <>
              <div
                className={`bg-white border-gray-400 flex flex-col items-center p-4 rounded w-36 cursor-pointer hover:bg-blue-50 ${
                  paymentMethod === 'card' ? 'border-blue-500 text-blue-500 border-2 font-semibold' : 'border'
                }`}
                onClick={() => setPaymentMethod('card')}
              >
                <CreditCard size={32} strokeWidth={2} color={paymentMethod !== 'card' ? 'black' : '#3B82F6'} />
                Card
              </div>
              <div
                className={`bg-white border-gray-400 flex flex-col items-center p-4 rounded w-36 cursor-pointer hover:bg-blue-50 ${
                  paymentMethod !== 'card' ? 'border-blue-500 text-blue-500 border-2 font-semibold' : 'border'
                }`}
                onClick={() => setPaymentMethod('bank_transfer')}
              >
                <BuildingBank size={32} strokeWidth={2} color={paymentMethod === 'card' ? 'black' : '#3B82F6'} />
                Bank Transfer
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {paymentMethod === 'card' ? (
        <StripeElements {...props} />
      ) : (
        <BankTransferForm
          feeInformation={fees}
          onSubmit={(formFields: FormFields, showPayToModal: () => void) =>
            createOrphanBankTransferAgreement(formFields, getOrganisationInfo(), showPayToModal)
          }
          bankTransferAuthorityCreated={bankTransferAuthorityCreated}
          setBankTransferAuthorityCreated={setBankTransferAuthorityCreated}
          isLoading={isLoading}
          {...props}
        />
      )}
    </div>
  );
}

export default PaymentAuthorisationWrapper;
