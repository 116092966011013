import React from 'react';
import ezyLogo from './images/logo-ezy.svg';
import Step from './Step';

const MainTemplate = ({ children, wizards = [], currentStep, brand, aggred, isConfirmed }) => {
  return (
    <div className="min-h-screen bg-gray-100">
      <div
        className="flex flex-wrap p-3 shadow"
        style={{ backgroundColor: brand.color.background, color: brand.color.text, height: '82px' }}
      >
        {brand.logoUrl && brand.logoUrl.length > 0 && (
          <div className="xs:hidden">
            <img src={brand.logoUrl} style={{ height: '60px' }} height="60" width="auto" alt={brand.companyName} />
          </div>
        )}
        <div className="flex-1 px-4">
          <p
            className="text-2xl mt-2 xs:mt-4 sm:mt-4 xs:text-lg sm:text-lg overflow-ellipsis overflow-hidden"
            style={{ color: brand.color.text }}
          >
            {brand.companyName}
          </p>
        </div>
        <div className="xs:max-w-12 sm:max-w-16 overflow-ellipsis overflow-hidden">
          <span className="block text-xs xs:mt-0 sm:mt-0 mt-1">Contact us</span>
          <p className="block text-lg xs:text-xs sm:text-sm overflow-ellipsis overflow-hidden">{brand.contact}</p>
        </div>
      </div>
      <div
        className="overflow-hidden justify-center m-auto"
        style={{ maxWidth: '1400px', height: 'calc(100vh - 82px - 3rem)' }}
      >
        {wizards.length > 0 && (
          <div className="h-auto mt-4">
            <div className="py-6">
              <div className="flex">
                {wizards.map((wizard, i) => (
                  <Step
                    wizard={wizard}
                    key={`wx-${i}`}
                    id={i}
                    index={i}
                    currentStep={currentStep}
                    isConfirmed={isConfirmed}
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        <div
          id="main-container"
          className="flex flex-col bg-white m-4 flex-1 shadow overflow-auto"
          style={{ height: `calc(100vh - 82px - 3rem - ${aggred ? '11rem' : '2rem'})` }}
        >
          {children}
        </div>
      </div>
      <footer className="min-h-12 footer bg-gray-700 text-gray-200">
        <ul className={brand.instanceType === 'SMOKEBALL' ? 'hidden' : 'footer-list'}>
          <li className="footer__powered-by">
            <div className="footer__powered-by__div text-sm mr-6 ml-6 p-2">
              <p>Powered By</p>
              <a href="https://www.ezycollect.com.au/" target="_blank" rel="noopener noreferrer">
                <img className="footer__powered-by__logo ml-2 mr-2" src={ezyLogo} alt="ezyCollect" />
              </a>
            </div>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default MainTemplate;
