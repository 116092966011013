import configs from './config';

const baseURL = configs.api_base_url;

const caUrls = {
  brand: `${baseURL}/organization/subdomain/`,
  techSettings: `${baseURL}/tech-settings/`,
  creditApplication: `${baseURL}/creditApplication`,
  paymentApplication: `${baseURL}/paymentApplication`,
  createOrphanSetupIntent: `${baseURL}/setup/create`,
  createOrphanBankTransferAgreement: `${baseURL}/bank-transfer/create`,
  getAgreementStatus: `${baseURL}/agreements`,
  getFeesByOrgId: `${baseURL}/payment-method`,
};

export default caUrls;
