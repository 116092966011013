import AuthUtils from '../Utils/AuthUtils';
import axios from 'axios';
import configs from '../constants/config';

const abnLookupURL = configs.abn_lookup_api_url;

class ABNService {
  getABNInfo(abn, countryCode) {
    return axios.get(`${abnLookupURL}${encodeURI(abn)}?countryCode=${countryCode}`, {
      auth: AuthUtils.getAbnAuth(),
    });
  }
}

export default new ABNService();
