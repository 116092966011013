import React, { useContext, useState } from 'react';
import { observer} from 'mobx-react-lite';
import MainStore from '../../stores/MainStore';
import { CustomerType } from '../../domains/TypeEnum';
import properties from '../../constants/properties';

const brooks_hire_options = [
    { value: CustomerType.residential, label: "Residential" },
    { value: CustomerType.business, label: "Business" },
];

const oma_options = [
    { value: CustomerType.individual, label: "Individual" },
    { value: CustomerType.company, label: "Company" },
];

const reliable_hire_options = [
    { value: CustomerType.individual, label: "Individual" },
    { value: CustomerType.business, label: "Business" },
];

const selectOptions = (organizationId:number) => {
    if(properties.OMA_GROUP.includes(organizationId) ) {
        return oma_options;
    }

    if(properties.BROOKS_HIRE_ORGS.includes(organizationId) ) {
        return brooks_hire_options;
    }

    if(properties.RELIABLE_HIRE_ORGS.includes(organizationId) ) {
        return reliable_hire_options;
    }
};

const CashApplicationSelector = observer((props:Record<string, any>) => {

    const defaultLabel = props.label || "Customer Type *"
    const defaultErrorLabel = props.errorLabel || "Please select customer type"
    const mainStore = useContext(MainStore);
    const { organizationId }:any = mainStore.creditApplicationDomain;
    let options:any = selectOptions(organizationId);
    const [error, setError] = useState(false);

    const onBlur = (e:any) => {
        if(e.target.value === '') {
            setError(true);
        } else {
            setError(false);
        }
    }

    const onChange = (e:any) => {
        if(e.target.value === '') {
            setError(true);
            mainStore.setCustomerTypeError(true);
        } else {
            setError(false);
            mainStore.setCustomerTypeError(false);
        }

        if(e.target.value !== CustomerType.residential) {
            mainStore.cleanCompanyInfoForResidential();
        }
        mainStore.updateCustomApplication({customerType: e.target.value});
    }

    return <div className="flex flex-wrap">
        <div className="w-1/4 sm:w-1/2 xs:w-full" style={{marginBottom: '20px'}}>
            <label className="block mx-4">
                <span className={`${error ? 'text-red-700' : 'text-gray-500'} block font-medium text-base`}>{error ? defaultErrorLabel : defaultLabel}</span>
                <select autoFocus onBlur={(e:any) => onBlur(e)} onChange={(e:any) => onChange(e) }
                    className="form-control rounded-md border-gray-300 width:auto shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mt-1"
                    id="select_customer_type"
                    style={{ WebkitAppearance: 'none', appearance: 'none' }}
                    value={mainStore.customApplication.customerType}
                >
                    <option value=""></option>
                    { options.map( (e:Record<string, any>, i:number) => <option key={i} value={e.value}>{e.label}</option>) }
                </select>
            </label>
        </div>
    </div>
});

export default CashApplicationSelector;