const BUSINESS_TYPE_OPTIONS = [
    { label: "Private Corporation", value: "Private Corporation" },
    { label: "Public Corporation", value: "Public Corporation" },
    { label: "Sole Proprietorship", value: "Sole Proprietorship" },
    { label: "Partnerships", value: "Partnerships" },
    { label: "Single Member Limited Liability Company (LLC)", value: "Single Member Limited Liability Company (LLC)" },
    { label: "Multi Member Limited Liability Company (LLC)", value: "Multi Member Limited Liability Company (LLC)" },
    { label: "Incorporated Non-profit", value: "Incorporated Non-profit" },
    { label: "Unincorporated Non-profit", value: "Unincorporated Non-profit" },
    { label: "Associations/Estates & Trusts", value: "Associations/Estates & Trusts" },
    { label: "Government (Federal/State/Local)", value: "Government (Federal/State/Local)" },
    { label: "Medical/Legal", value: "Medical/Legal" },
    { label: "Other", value: "Other" },
];

export default BUSINESS_TYPE_OPTIONS;