import React, {useContext, useEffect, useMemo, useState} from "react";
import MainStore from "../stores/MainStore";
import {observer} from "mobx-react-lite";
import FormField from "./FormField";

const BusinessTypeCustom = observer(({value, hasError, errorMessage}) => {
  const mainStore = useContext(MainStore);
  const checkList = useMemo(() => ["Restaurant", "Manufacturer", "Takeaway", "Distributor", "Retail Store"], []);
  const [list, setList] = useState([]);
  const [other, setOther] = useState(false);
  const [otherText, setOtherText] = useState("");

  useEffect(() => {
    const businessType = value.split(",");
    let updatedList = businessType
      .map(item => item.trim())
      .map(item => checkList.includes(item) ? item : "")
      .filter(item => item !== "");

    let updatedOther = businessType
      .map(item => item.trim())
      .map(item => !checkList.includes(item) ? item : "")
      .filter(item => item !== "");

    setList(updatedList)
    if(updatedOther !== null && updatedOther.length > 0) {
      setOther(true);
      setOtherText(updatedOther);
    }
  }, [checkList, value]);

  const handleCheck = (event) => {
    let updatedList = [...list];
    if (event.target.checked) {
      updatedList = [...list, event.target.value];
    } else {
      updatedList.splice(list.indexOf(event.target.value), 1);
    }

    setList(updatedList);
    let allItems = checkedItems(updatedList)
    allItems = otherText.length > 0 ? allItems + ", " + otherText : allItems

    mainStore.creditApplicationDomain.updateBusinessTypeState(allItems)
  };

  const switchOther = () => {
    const updateOther = !other;
    setOther(updateOther);

    if(!updateOther) {
      handleOther("")
    }
  }

  const handleOtherInput = (event) => handleOther(event.target.value)

  const handleOther = (value) => {
    setOtherText(value);

    const text = value.length > 0 ? checkedItems(list) + ", " + value : checkedItems(list);
    mainStore.creditApplicationDomain.updateBusinessTypeState(text);
  }

  const checkedItems = (updatedList) => {
    return updatedList.length
      ? updatedList.reduce((total, item) => {
        return total + ", " + item;
      })
      : "";
  }

  return (
    <div className="ml-4">
      <span className={`${hasError ? 'text-red-700' : 'text-gray-500'} font-medium`}>
        Business Type *
      </span>
      <div className="block mt-2">
        {checkList ? checkList.map((item, index) => (
          <div key={index} className={index % 2 === 0 ? "inline-block mr-4 mt-2" : "inline-block mr-4 mt-2"}>
            <input
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="checkbox"
              value={item}
              onChange={handleCheck}
              checked={list.includes(item)}
            />
            <label className="form-check-label inline-block text-gray-800 select-none"
                   htmlFor="inlineCheckbox2">
              {item}
            </label>
          </div>
        )) : ""}
      </div>

      <div className="block mt-2">
        <div className="form-check inline-block mr-4">
          <input
            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            type="checkbox"
            onChange={switchOther}
            checked={other}
          />
          <label className="form-check-label inline-block text-gray-800" htmlFor="inlineCheckbox1">
            Other:
          </label>
        </div>
        <div className={other ? "form-check inline-block w-40 visible" : "form-check inline-block w-40 invisible"}>
          <FormField
            value={otherText}
            placeholder="Other"
            name="other"
            onChange={handleOtherInput}
            type="text"
          />
        </div>
      </div>
      {hasError && <span className="text-red-700 text-sm font-medium">{errorMessage}</span>}
    </div>
  );
});

export default BusinessTypeCustom;