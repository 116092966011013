import React from 'react';

const SvgBase = ({ cssClass = 'w-4 mr-2', viewBox = '0 0 24 24', children }) => {
  return (
    <svg className={`fill-current ${cssClass}`} xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
      {children}
    </svg>
  );
};

export default SvgBase;
