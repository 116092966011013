
const css = {
  base: {
    color: '#32325d',
    fontFamily: '"geomanistregular", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: '20px',
    fontSize: '14px',
    padding: '6px 12px',
    '::placeholder': {
      color: '#a0a0a0',
    },
  },
  invalid: {
    fontFamily: '"geomanistregular", "Helvetica Neue", Helvetica, Arial, sans-serif',
    color: '#df1b41',
    iconColor: '#df1b41',
  },
};

export default css;
