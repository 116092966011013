import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { getDynamicPrecision } from '../Utils/NumberFormatter';
import properties from '../constants/properties';
import MainStore from '../stores/MainStore';
import FormField from './FormField';
import FormTitle from './FormTitle';
import Tooltip from './Tooltip';

const FeeStructureForm = observer(() => {
  const mainStore = useContext(MainStore);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    mainStore.fetchFeeStructureData();
  }, [mainStore]);

  const onChange = (parentKey, fieldKey, values) => {
    const fieldValue = parseFloat(values.value) >= 0 ? parseFloat(values.value) : '';

    mainStore.updateFeeStructure(parentKey, fieldKey, fieldValue);
  };

  const hasUSSurcharge = (fieldName) => {
    if (!mainStore.feeStructureDomain.isLoaded) return false;

    const surchargeFieldName = fieldName === 'transactionFee' ? 'surchargeValue' : 'surchargeRate';
    const isSurcharged = mainStore.feeStructureDomain[fieldName][surchargeFieldName] > 0.0;
    const isUnlocked = mainStore.feeStructureDomain[fieldName].lock === false;

    return isOnboardingUS && (isSurcharged || isUnlocked);
  };

  const isUSAndHasSurcharge = () => {
    if (!isOnboardingUS) return false;

    const hasSomeSurcharge = [
      mainStore.feeStructureDomain.transactionFee?.surchargeValue,
      mainStore.feeStructureDomain.amexFees?.surchargeRate,
      mainStore.feeStructureDomain.mastercardFees?.surchargeRate,
      mainStore.feeStructureDomain.visaFees?.surchargeRate,
      mainStore.feeStructureDomain.internationalFees?.surchargeRate,
    ].some((fee) => fee > 0.0);

    return hasSomeSurcharge;
  };

  const showUSSurchargeMessage = (fieldName) => {
    if (hasUSSurcharge(fieldName)) {
      return (
        <span className="w-full block mx-4 text-sm mt-1" style={{ color: '#083549' }}>
          *Surcharge fees are not applicable for Debit Card transactions
        </span>
      );
    }
  };

  const fields = {
    transactionFee: {
      surchargeValue: {
        name: 'surchargeValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('transactionFee', 'surchargeValue'),
      },
      netValue: {
        name: 'netValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('transactionFee', 'netValue'),
      },
      feeValue: {
        name: 'feeValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('transactionFee', 'feeValue'),
      },
    },
    fee3ds: {
      feeValue: {
        name: 'feeValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('fee3ds', 'feeValue'),
      },
    },
    failedFee: {
      feeValue: {
        name: 'feeValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('failedFee', 'feeValue'),
      },
    },
    disputeFee: {
      feeValue: {
        name: 'feeValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('disputeFee', 'feeValue'),
      },
    },
    internationalFees: {
      surchargeRate: {
        name: 'surchargeRate',
        validate: mainStore.hasErrorInFeeStructureFormAttr('internationalFees', 'surchargeRate'),
      },
      netRate: {
        name: 'netRate',
        validate: mainStore.hasErrorInFeeStructureFormAttr('internationalFees', 'netRate'),
      },
      feeRate: {
        name: 'feeRate',
        validate: mainStore.hasErrorInFeeStructureFormAttr('internationalFees', 'feeRate'),
      },
    },
    visaFees: {
      surchargeRate: {
        name: 'surchargeRate',
        validate: mainStore.hasErrorInFeeStructureFormAttr('visaFees', 'surchargeRate'),
      },
      netRate: {
        name: 'netRate',
        validate: mainStore.hasErrorInFeeStructureFormAttr('visaFees', 'netRate'),
      },
      feeRate: {
        name: 'feeRate',
        validate: mainStore.hasErrorInFeeStructureFormAttr('visaFees', 'feeRate'),
      },
    },
    mastercardFees: {
      surchargeRate: {
        name: 'surchargeRate',
        validate: mainStore.hasErrorInFeeStructureFormAttr('mastercardFees', 'surchargeRate'),
      },
      netRate: {
        name: 'netRate',
        validate: mainStore.hasErrorInFeeStructureFormAttr('mastercardFees', 'netRate'),
      },
      feeRate: {
        name: 'feeRate',
        validate: mainStore.hasErrorInFeeStructureFormAttr('mastercardFees', 'feeRate'),
      },
    },
    amexFees: {
      surchargeRate: {
        name: 'surchargeRate',
        validate: mainStore.hasErrorInFeeStructureFormAttr('amexFees', 'surchargeRate'),
      },
      netRate: {
        name: 'netRate',
        validate: mainStore.hasErrorInFeeStructureFormAttr('amexFees', 'netRate'),
      },
      feeRate: {
        name: 'feeRate',
        validate: mainStore.hasErrorInFeeStructureFormAttr('amexFees', 'feeRate'),
      },
    },
    eftTransactionFee: {
      surchargeValue: {
        name: 'surchargeValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('eftTransactionFee', 'surchargeValue'),
      },
      netValue: {
        name: 'netValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('eftTransactionFee', 'netValue'),
      },
      feeValue: {
        name: 'feeValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('eftTransactionFee', 'feeValue'),
      },
    },
    realTimeTransactionFee: {
      surchargeValue: {
        name: 'surchargeValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('realTimeTransactionFee', 'surchargeValue'),
      },
      netValue: {
        name: 'netValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('realTimeTransactionFee', 'netValue'),
      },
      feeValue: {
        name: 'feeValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('realTimeTransactionFee', 'feeValue'),
      },
    },
    eftVariableFee: {
      surchargeValue: {
        name: 'surchargeValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('eftVariableFee', 'surchargeValue'),
      },
      netValue: {
        name: 'netValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('eftVariableFee', 'netValue'),
      },
      feeValue: {
        name: 'feeValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('eftVariableFee', 'feeValue'),
      },
      thresholdValue: {
        name: 'thresholdValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('eftVariableFee', 'thresholdValue'),
      },
    },
    realTimeVariableFee: {
      surchargeValue: {
        name: 'surchargeValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('realTimeVariableFee', 'surchargeValue'),
      },
      netValue: {
        name: 'netValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('realTimeVariableFee', 'netValue'),
      },
      feeValue: {
        name: 'feeValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('realTimeVariableFee', 'feeValue'),
      },
      thresholdValue: {
        name: 'thresholdValue',
        validate: mainStore.hasErrorInFeeStructureFormAttr('realTimeVariableFee', 'thresholdValue'),
      },
    },
    eftFailedFee: {
      feeValue: {
        name: 'feeValue',
      },
    },
    eftDisputeFee: {
      feeValue: {
        name: 'feeValue',
      },
    },
    eftRefundFee: {
      feeValue: {
        name: 'feeValue',
      },
    },
    agreedToConfigTerms: {
      name: 'agreedToConfigTerms',
      validate: mainStore.hasErrorInFeeStructureFormAttr('agreedToConfigTerms', 'agreedToConfigTerms'),
    },
  };

  const shouldRenderPaymentMethodSection = (paymentMethod) => {
    return mainStore.feeStructureDomain.paymentMethodType?.includes(paymentMethod);
  };

  const isOnboardingUS = properties.US_ONBOARDING_ORGS.includes(mainStore.creditApplicationDomain.organizationId);
  const isOnboardingNZ = properties.NZ_ONBOARDING_ORGS.includes(mainStore.creditApplicationDomain.organizationId);

  return (
    mainStore.feeStructureDomain.isLoaded && (
      <div className="flex-1 overflow-auto">
        {shouldRenderPaymentMethodSection('CREDIT_CARD') ? (
          <>
            <div className="pt-10 pl-10 pr-10">
              <FormTitle
                title={
                  <div className="flex items-center">
                    <span className="mr-1">Card - Fees Structure</span>
                    <Tooltip title={'FormTitle'}>
                      Absorb - Fee covered by your business
                      <br />
                      Surcharge - Fee passed on to your customers
                    </Tooltip>
                  </div>
                }
                margin="mb-8"
              />
            </div>
            <div className="px-10" data-has-error={mainStore.feeStructureErrors.hasError}>
              <div className="form-group field field-object">
                {isUSAndHasSurcharge() ? (
                  <span
                    className="w-full block mx-4 text-sm mt-1"
                    style={{ color: '#083549', fontStyle: 'italic', textAlign: 'center', marginTop: '-1em' }}
                  >
                    Surcharging on Debit Card Transactions will not be allowed and this will default to absorption of
                    fees by your business
                  </span>
                ) : (
                  <></>
                )}
                <fieldset className="bg-gray-70 p-4">
                  <div className="flex flex-wrap">
                    <legend className="float-left mt-0 mb-4 w-full uppercase text-indigo-700 font-semibold">
                      Transaction Fee{isOnboardingNZ ? ' (NZD)' : ' ($)'}
                    </legend>
                    <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                      <FormField
                        label="Fee"
                        value={mainStore.feeStructureDomain.transactionFee?.feeValue?.toString() || ''}
                        placeholder="Fee"
                        onChange={(values) => onChange('transactionFee', fields.transactionFee.feeValue.name, values)}
                        name={fields.transactionFee.feeValue.name}
                        hasError={fields.transactionFee.feeValue.validate.hasError}
                        errorMessage={fields.transactionFee.feeValue.validate.message}
                        mki-disabled
                        mki-required
                        type="number"
                        masked={true}
                        mki-thousandSeparator={true}
                        mki-prefixed="$"
                        mki-decimalScale={getDynamicPrecision(mainStore.feeStructureDomain.transactionFee?.feeValue)}
                        mki-fixedDecimalScale={true}
                      />
                    </div>
                    <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                      <FormField
                        label="Absorb"
                        value={mainStore.feeStructureDomain.transactionFee?.netValue?.toString() || ''}
                        placeholder="Absorb"
                        onChange={(values) => onChange('transactionFee', fields.transactionFee.netValue.name, values)}
                        name={fields.transactionFee.netValue.name}
                        hasError={fields.transactionFee.netValue.validate.hasError}
                        errorMessage={fields.transactionFee.netValue.validate.message}
                        mki-disabled={mainStore.feeStructureDomain.transactionFee?.lock || false}
                        step={0.1}
                        type="number"
                        masked={true}
                        mki-thousandSeparator={true}
                        mki-prefixed="$"
                        mki-decimalScale={3}
                        mki-onBlur={forceUpdate}
                      />
                    </div>
                    <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                      <FormField
                        label={`Surcharge${hasUSSurcharge('transactionFee') ? '*' : ''}`}
                        value={mainStore.feeStructureDomain.transactionFee?.surchargeValue?.toString() || ''}
                        placeholder="Surcharge"
                        onChange={(values) =>
                          onChange('transactionFee', fields.transactionFee.surchargeValue.name, values)
                        }
                        name={fields.transactionFee.surchargeValue.name}
                        hasError={fields.transactionFee.surchargeValue.validate.hasError}
                        errorMessage={fields.transactionFee.surchargeValue.validate.message}
                        mki-disabled={mainStore.feeStructureDomain.transactionFee?.lock || false}
                        step={0.1}
                        type="number"
                        masked={true}
                        mki-thousandSeparator={true}
                        mki-prefixed="$"
                        mki-decimalScale={3}
                        mki-onBlur={forceUpdate}
                      />
                      {showUSSurchargeMessage('transactionFee')}
                    </div>
                  </div>

                  {parseFloat(mainStore.feeStructureDomain.fee3ds?.feeValue?.toString()) > 0 && (
                    <div className="flex flex-wrap">
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group pt-4">
                        <FormField
                          label="3DS"
                          value={mainStore.feeStructureDomain.fee3ds?.feeValue?.toString() || ''}
                          placeholder="3DS"
                          onChange={(values) => onChange('fee3ds', fields.fee3ds.feeValue.name, values)}
                          name={fields.fee3ds.feeValue.name}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-prefixed="$"
                          mki-decimalScale={getDynamicPrecision(mainStore.feeStructureDomain.fee3ds?.feeValue)}
                          mki-disabled
                          mki-fixedDecimalScale={true}
                        />
                      </div>
                    </div>
                  )}

                  {mainStore.feeStructureDomain.visaFees && (
                    <div className="flex flex-wrap pt-10">
                      <legend className="float-left mt-0 mb-4 w-full uppercase text-indigo-700 font-semibold">
                        Visa (%)
                      </legend>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Fee"
                          value={mainStore.feeStructureDomain.visaFees?.feeRate?.toString() || ''}
                          placeholder="Fee"
                          onChange={(values) => onChange('visaFees', fields.visaFees.feeRate.name, values)}
                          name={fields.visaFees.feeRate.name}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed="%"
                          mki-decimalScale={getDynamicPrecision(mainStore.feeStructureDomain.visaFees?.feeRate)}
                          mki-disabled
                          mki-fixedDecimalScale={true}
                          hasError={fields.visaFees.feeRate.validate.hasError}
                          errorMessage={fields.visaFees.feeRate.validate.message}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Absorb"
                          value={mainStore.feeStructureDomain.visaFees?.netRate?.toString() || ''}
                          placeholder="Absorb"
                          onChange={(values) => onChange('visaFees', fields.visaFees.netRate.name, values)}
                          name={fields.visaFees.netRate.name}
                          mki-disabled={mainStore.feeStructureDomain.visaFees?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed="%"
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                          hasError={fields.visaFees.netRate.validate.hasError}
                          errorMessage={fields.visaFees.netRate.validate.message}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label={`Surcharge${hasUSSurcharge('visaFees') ? '*' : ''}`}
                          value={mainStore.feeStructureDomain.visaFees?.surchargeRate?.toString() || ''}
                          placeholder="Surcharge"
                          onChange={(values) => onChange('visaFees', fields.visaFees.surchargeRate.name, values)}
                          name={fields.visaFees.surchargeRate.name}
                          mki-disabled={mainStore.feeStructureDomain.visaFees?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed="%"
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                          hasError={fields.visaFees.surchargeRate.validate.hasError}
                          errorMessage={fields.visaFees.surchargeRate.validate.message}
                        />
                        {showUSSurchargeMessage('visaFees')}
                      </div>
                    </div>
                  )}

                  {mainStore.feeStructureDomain.mastercardFees && (
                    <div className="flex flex-wrap pt-10">
                      <legend className="float-left mt-0 mb-4 w-full uppercase text-indigo-700 font-semibold">
                        Mastercard (%)
                      </legend>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Fee"
                          value={mainStore.feeStructureDomain.mastercardFees?.feeRate?.toString() || ''}
                          placeholder="Fee"
                          onChange={(values) => onChange('mastercardFees', fields.mastercardFees.feeRate.name, values)}
                          name={fields.mastercardFees.feeRate.name}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed="%"
                          mki-decimalScale={getDynamicPrecision(mainStore.feeStructureDomain.mastercardFees?.feeRate)}
                          mki-disabled
                          mki-fixedDecimalScale={true}
                          hasError={fields.mastercardFees.feeRate.validate.hasError}
                          errorMessage={fields.mastercardFees.feeRate.validate.message}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Absorb"
                          value={mainStore.feeStructureDomain.mastercardFees?.netRate?.toString() || ''}
                          placeholder="Absorb"
                          onChange={(values) => onChange('mastercardFees', fields.mastercardFees.netRate.name, values)}
                          name={fields.mastercardFees.netRate.name}
                          mki-disabled={mainStore.feeStructureDomain.mastercardFees?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed="%"
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                          hasError={fields.mastercardFees.netRate.validate.hasError}
                          errorMessage={fields.mastercardFees.netRate.validate.message}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label={`Surcharge${hasUSSurcharge('mastercardFees') ? '*' : ''}`}
                          value={mainStore.feeStructureDomain.mastercardFees?.surchargeRate?.toString() || ''}
                          placeholder="Surcharge"
                          onChange={(values) =>
                            onChange('mastercardFees', fields.mastercardFees.surchargeRate.name, values)
                          }
                          name={fields.mastercardFees.surchargeRate.name}
                          mki-disabled={mainStore.feeStructureDomain.mastercardFees?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed="%"
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                          hasError={fields.mastercardFees.surchargeRate.validate.hasError}
                          errorMessage={fields.mastercardFees.surchargeRate.validate.message}
                        />
                        {showUSSurchargeMessage('mastercardFees')}
                      </div>
                    </div>
                  )}

                  {mainStore.feeStructureDomain.amexFees && (
                    <div className="flex flex-wrap pt-10">
                      <legend className="float-left mt-0 mb-4 w-full uppercase text-indigo-700 font-semibold">
                        American Express (%)
                      </legend>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Fee"
                          value={mainStore.feeStructureDomain.amexFees?.feeRate?.toString() || ''}
                          placeholder="Fee"
                          onChange={(values) => onChange('amexFees', fields.amexFees.feeRate.name, values)}
                          name={fields.amexFees.feeRate.name}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed="%"
                          mki-decimalScale={getDynamicPrecision(mainStore.feeStructureDomain.amexFees?.feeRate)}
                          mki-disabled
                          mki-fixedDecimalScale={true}
                          hasError={fields.amexFees.feeRate.validate.hasError}
                          errorMessage={fields.amexFees.feeRate.validate.message}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Absorb"
                          value={mainStore.feeStructureDomain.amexFees?.netRate?.toString() || ''}
                          placeholder="Absorb"
                          onChange={(values) => onChange('amexFees', fields.amexFees.netRate.name, values)}
                          name={fields.amexFees.netRate.name}
                          mki-disabled={mainStore.feeStructureDomain.amexFees?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed="%"
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                          hasError={fields.amexFees.netRate.validate.hasError}
                          errorMessage={fields.amexFees.netRate.validate.message}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label={`Surcharge${hasUSSurcharge('amexFees') ? '*' : ''}`}
                          value={mainStore.feeStructureDomain.amexFees?.surchargeRate?.toString() || ''}
                          placeholder="Surcharge"
                          onChange={(values) => onChange('amexFees', fields.amexFees.surchargeRate.name, values)}
                          name={fields.amexFees.surchargeRate.name}
                          mki-disabled={mainStore.feeStructureDomain.amexFees?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed="%"
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                          hasError={fields.amexFees.surchargeRate.validate.hasError}
                          errorMessage={fields.amexFees.surchargeRate.validate.message}
                        />
                        {showUSSurchargeMessage('amexFees')}
                      </div>
                    </div>
                  )}

                  {parseFloat(mainStore.feeStructureDomain.internationalFees?.feeRate?.toString()) > 0 && (
                    <div className="flex flex-wrap pt-10">
                      <legend className="float-left mt-0 mb-4 w-full uppercase text-indigo-700 font-semibold">
                        International Card (%)
                      </legend>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Fee"
                          value={mainStore.feeStructureDomain.internationalFees?.feeRate?.toString() || ''}
                          placeholder="Fee"
                          onChange={(values) =>
                            onChange('internationalFees', fields.internationalFees.feeRate.name, values)
                          }
                          name={fields.internationalFees.feeRate.name}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed="%"
                          mki-decimalScale={getDynamicPrecision(
                            mainStore.feeStructureDomain.internationalFees?.feeRate
                          )}
                          mki-disabled
                          mki-fixedDecimalScale={true}
                          hasError={fields.internationalFees.feeRate.validate.hasError}
                          errorMessage={fields.internationalFees.feeRate.validate.message}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Absorb"
                          value={mainStore.feeStructureDomain.internationalFees?.netRate?.toString() || ''}
                          placeholder="Absorb"
                          onChange={(values) =>
                            onChange('internationalFees', fields.internationalFees.netRate.name, values)
                          }
                          name={fields.internationalFees.netRate.name}
                          mki-disabled={mainStore.feeStructureDomain.internationalFees?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed="%"
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                          hasError={fields.internationalFees.netRate.validate.hasError}
                          errorMessage={fields.internationalFees.netRate.validate.message}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label={`Surcharge${hasUSSurcharge('internationalFees') ? '*' : ''}`}
                          value={mainStore.feeStructureDomain.internationalFees?.surchargeRate?.toString() || ''}
                          placeholder="Surcharge"
                          onChange={(values) =>
                            onChange('internationalFees', fields.internationalFees.surchargeRate.name, values)
                          }
                          name={fields.internationalFees.surchargeRate.name}
                          mki-disabled={mainStore.feeStructureDomain.internationalFees?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed="%"
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                          hasError={fields.internationalFees.surchargeRate.validate.hasError}
                          errorMessage={fields.internationalFees.surchargeRate.validate.message}
                        />
                        {showUSSurchargeMessage('internationalFees')}
                      </div>
                    </div>
                  )}
                </fieldset>

                <fieldset className="bg-gray-70 p-4 mt-4 mb-4">
                  <div className="flex flex-wrap">
                    <legend className="float-left mt-0 mb-4 w-full uppercase text-indigo-700 font-semibold">
                      <div className="flex items-center">
                        <span className="mr-1">Fixed Business Fees{isOnboardingNZ ? ' (NZD)' : ' ($)'}*</span>
                        <Tooltip title="FixedFees">
                          <p>
                            These fees will be charged on failed transactions
                            <br />
                            and when there is a dispute or chargeback
                          </p>
                        </Tooltip>
                      </div>
                    </legend>
                    <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                      <FormField
                        label="Failed Transaction Fee"
                        value={mainStore.feeStructureDomain.failedFee?.feeValue?.toString() || ''}
                        placeholder="Failed Transaction Fee"
                        onChange={(values) => onChange('failedFee', fields.failedFee.feeValue.name, values)}
                        name={fields.failedFee.feeValue.name}
                        type="number"
                        masked={true}
                        mki-thousandSeparator={true}
                        mki-prefixed="$"
                        mki-decimalScale={getDynamicPrecision(mainStore.feeStructureDomain.failedFee?.feeValue)}
                        mki-fixedDecimalScale={true}
                        mki-disabled
                      />
                    </div>
                    <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                      <FormField
                        label="Dispute / Chargeback Fee"
                        value={mainStore.feeStructureDomain.disputeFee?.feeValue?.toString() || ''}
                        placeholder="Dispute / Chargeback Fee"
                        onChange={(values) => onChange('disputeFee', fields.disputeFee.feeValue.name, values)}
                        name={fields.disputeFee.feeValue.name}
                        type="number"
                        masked={true}
                        mki-thousandSeparator={true}
                        mki-prefixed="$"
                        mki-decimalScale={getDynamicPrecision(mainStore.feeStructureDomain.disputeFee?.feeValue)}
                        mki-disabled
                        mki-fixedDecimalScale={true}
                      />
                    </div>
                    <span className="w-full block mx-4 text-sm mt-1" style={{ color: '#083549' }}>
                      *These fees are covered by your business
                    </span>
                  </div>
                </fieldset>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {shouldRenderPaymentMethodSection('EFT') ? (
          <>
            <div className="pt-10 pl-10 pr-10">
              <FormTitle
                title={
                  <div className="flex items-center">
                    <span className="mr-1">
                      {isOnboardingUS ? 'ACH - Fees Structure' : 'Bank Transfer - Fees Structure'}
                    </span>
                    <Tooltip title={'FormTitle'}>
                      Absorb - Fee covered by your business
                      <br />
                      Surcharge - Fee passed on to your customers
                    </Tooltip>
                  </div>
                }
                margin="mb-8"
              />
            </div>
            <div className="px-10" data-has-error={mainStore.feeStructureErrors.hasError}>
              <div className="form-group field field-object">
                <fieldset className="bg-gray-70 p-4 mb-8">
                  {isOnboardingUS ? (
                    <></>
                  ) : (
                    <div className="flex flex-wrap">
                      <legend className="float-left mt-0 mb-4 w-full uppercase text-indigo-700 font-semibold">
                        Transaction Fee{isOnboardingNZ ? ' (NZD)' : ' ($)'}
                      </legend>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Fee"
                          value={mainStore.feeStructureDomain.eftTransactionFee?.feeValue?.toString() || ''}
                          placeholder="Fee"
                          onChange={(values) =>
                            onChange('eftTransactionFee', fields.eftTransactionFee.feeValue.name, values)
                          }
                          name={fields.eftTransactionFee.feeValue.name}
                          hasError={fields.eftTransactionFee.feeValue.validate.hasError}
                          errorMessage={fields.eftTransactionFee.feeValue.validate.message}
                          mki-disabled
                          mki-required
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-prefixed="$"
                          mki-decimalScale={getDynamicPrecision(
                            mainStore.feeStructureDomain.eftTransactionFee?.feeValue
                          )}
                          mki-fixedDecimalScale={true}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Absorb"
                          value={mainStore.feeStructureDomain.eftTransactionFee?.netValue?.toString() || ''}
                          placeholder="Absorb"
                          onChange={(values) =>
                            onChange('eftTransactionFee', fields.eftTransactionFee.netValue.name, values)
                          }
                          name={fields.eftTransactionFee.netValue.name}
                          hasError={fields.eftTransactionFee.netValue.validate.hasError}
                          errorMessage={fields.eftTransactionFee.netValue.validate.message}
                          mki-disabled={mainStore.feeStructureDomain.eftTransactionFee?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-prefixed="$"
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Surcharge"
                          value={mainStore.feeStructureDomain.eftTransactionFee?.surchargeValue?.toString() || ''}
                          placeholder="Surcharge"
                          onChange={(values) =>
                            onChange('eftTransactionFee', fields.eftTransactionFee.surchargeValue.name, values)
                          }
                          name={fields.eftTransactionFee.surchargeValue.name}
                          hasError={fields.eftTransactionFee.surchargeValue.validate.hasError}
                          errorMessage={fields.eftTransactionFee.surchargeValue.validate.message}
                          mki-disabled={mainStore.feeStructureDomain.eftTransactionFee?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-prefixed="$"
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                        />
                      </div>
                    </div>
                  )}

                  {mainStore.feeStructureDomain.eftVariableFee && (
                    <div className="flex flex-wrap pt-10">
                      {isOnboardingUS ? (
                        <></>
                      ) : (
                        <legend className="float-left mt-0 mb-4 w-full uppercase text-indigo-700 font-semibold">
                          Additional Charge over $
                          {mainStore.feeStructureDomain.eftVariableFee?.thresholdValue?.toString()}
                        </legend>
                      )}
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Fee"
                          value={mainStore.feeStructureDomain.eftVariableFee?.feeValue?.toString() || ''}
                          placeholder="Fee"
                          onChange={(values) => onChange('eftVariableFee', fields.eftVariableFee.feeValue.name, values)}
                          name={fields.eftVariableFee.feeValue.name}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed={mainStore.feeStructureDomain.settlementDelay === 'T+2' ? null : '%'}
                          mki-prefixed={mainStore.feeStructureDomain.settlementDelay === 'T+2' ? '$' : null}
                          mki-decimalScale={getDynamicPrecision(mainStore.feeStructureDomain.eftVariableFee?.feeValue)}
                          mki-disabled
                          mki-fixedDecimalScale={true}
                          hasError={fields.eftVariableFee.feeValue.validate.hasError}
                          errorMessage={fields.eftVariableFee.feeValue.validate.message}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Absorb"
                          value={mainStore.feeStructureDomain.eftVariableFee?.netValue?.toString() || ''}
                          placeholder="Absorb"
                          onChange={(values) => onChange('eftVariableFee', fields.eftVariableFee.netValue.name, values)}
                          name={fields.eftVariableFee.netValue.name}
                          mki-disabled={mainStore.feeStructureDomain.eftVariableFee?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed={mainStore.feeStructureDomain.settlementDelay === 'T+2' ? null : '%'}
                          mki-prefixed={mainStore.feeStructureDomain.settlementDelay === 'T+2' ? '$' : null}
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                          hasError={fields.eftVariableFee.netValue.validate.hasError}
                          errorMessage={fields.eftVariableFee.netValue.validate.message}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Surcharge"
                          value={mainStore.feeStructureDomain.eftVariableFee?.surchargeValue?.toString() || ''}
                          placeholder="Surcharge"
                          onChange={(values) =>
                            onChange('eftVariableFee', fields.eftVariableFee.surchargeValue.name, values)
                          }
                          name={fields.eftVariableFee.surchargeValue.name}
                          mki-disabled={mainStore.feeStructureDomain.eftVariableFee?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed={mainStore.feeStructureDomain.settlementDelay === 'T+2' ? null : '%'}
                          mki-prefixed={mainStore.feeStructureDomain.settlementDelay === 'T+2' ? '$' : null}
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                          hasError={fields.eftVariableFee.surchargeValue.validate.hasError}
                          errorMessage={fields.eftVariableFee.surchargeValue.validate.message}
                        />
                      </div>
                      {isOnboardingUS && mainStore.feeStructureDomain.settlementDelay === 'T+4' ? (
                        <div className="w-1/3 xs:w-full xs:mt-6 form-group pt-4">
                          <FormField
                            label={`Capped at ${isOnboardingNZ ? ' (NZD)' : ' ($)'}`}
                            value={mainStore.feeStructureDomain.eftVariableFee?.thresholdValue?.toString() || ''}
                            placeholder={`Capped at ${isOnboardingNZ ? ' (NZD)' : ' ($)'}`}
                            name={fields.eftVariableFee?.thresholdValue.name}
                            type="number"
                            masked={true}
                            mki-thousandSeparator={true}
                            mki-prefixed="$"
                            mki-decimalScale={getDynamicPrecision(
                              mainStore.feeStructureDomain.eftVariableFee?.thresholdValue
                            )}
                            mki-disabled
                            mki-fixedDecimalScale={true}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}

                  <div className="flex flex-wrap pt-10">
                    <legend className="float-left mt-0 mb-4 w-full uppercase text-indigo-700 font-semibold">
                      <div className="flex items-center">
                        <span className="mr-1">Fixed Business Fees{isOnboardingNZ ? ' (NZD)' : ' ($)'}*</span>
                        <Tooltip title="FixedFees">
                          <p>
                            These fees will be charged on failed transactions
                            <br />
                            and when there is a dispute or chargeback
                          </p>
                        </Tooltip>
                      </div>
                    </legend>
                    <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                      <FormField
                        label={isOnboardingUS ? 'Failed ACH Transaction Fee' : 'Failed Transaction Fee'}
                        value={mainStore.feeStructureDomain.eftFailedFee?.feeValue?.toString() || ''}
                        placeholder="Failed Transaction Fee"
                        name={fields.eftFailedFee.feeValue.name}
                        type="number"
                        masked={true}
                        mki-thousandSeparator={true}
                        mki-prefixed="$"
                        mki-decimalScale={getDynamicPrecision(mainStore.feeStructureDomain.eftFailedFee?.feeValue)}
                        mki-fixedDecimalScale={true}
                        mki-disabled
                      />
                    </div>
                    <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                      <FormField
                        label="Dispute / Chargeback Fee"
                        value={mainStore.feeStructureDomain.eftDisputeFee?.feeValue?.toString() || ''}
                        placeholder="Dispute / Chargeback Fee"
                        name={fields.eftDisputeFee.feeValue.name}
                        type="number"
                        masked={true}
                        mki-thousandSeparator={true}
                        mki-prefixed="$"
                        mki-decimalScale={getDynamicPrecision(mainStore.feeStructureDomain.eftDisputeFee?.feeValue)}
                        mki-disabled
                        mki-fixedDecimalScale={true}
                      />
                    </div>
                    {isOnboardingUS ? (
                      <></>
                    ) : (
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Refund Fee"
                          value={mainStore.feeStructureDomain.eftRefundFee?.feeValue?.toString() || ''}
                          placeholder="Refund Fee"
                          name={fields.eftRefundFee.feeValue.name}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-prefixed="$"
                          mki-decimalScale={getDynamicPrecision(mainStore.feeStructureDomain.eftRefundFee?.feeValue)}
                          mki-disabled
                          mki-fixedDecimalScale={true}
                        />
                      </div>
                    )}
                    <span className="w-full block mx-4 text-sm mt-1" style={{ color: '#083549' }}>
                      *These fees are covered by your business
                    </span>
                  </div>
                </fieldset>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {shouldRenderPaymentMethodSection('PAYTO') ? (
          <>
            <div className="pt-10 pl-10 pr-10">
              <FormTitle
                title={
                  <div className="flex items-center">
                    <span className="mr-1">PayTo - Fees Structure</span>
                    <Tooltip title={'FormTitle'}>
                      Absorb - Fee covered by your business
                      <br />
                      Surcharge - Fee passed on to your customers
                    </Tooltip>
                  </div>
                }
                margin="mb-8"
              />
            </div>
            <div className="px-10" data-has-error={mainStore.feeStructureErrors.hasError}>
              <div className="form-group field field-object">
                <fieldset className="bg-gray-70 p-4 mb-8">
                  {isOnboardingUS ? (
                    <></>
                  ) : (
                    <div className="flex flex-wrap">
                      <legend className="float-left mt-0 mb-4 w-full uppercase text-indigo-700 font-semibold">
                        Transaction Fee{isOnboardingNZ ? ' (NZD)' : ' ($)'}
                      </legend>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Fee"
                          value={mainStore.feeStructureDomain.realTimeTransactionFee?.feeValue?.toString() || ''}
                          placeholder="Fee"
                          onChange={(values) =>
                            onChange('realTimeTransactionFee', fields.realTimeTransactionFee.feeValue.name, values)
                          }
                          name={fields.realTimeTransactionFee.feeValue.name}
                          hasError={fields.realTimeTransactionFee.feeValue.validate.hasError}
                          errorMessage={fields.realTimeTransactionFee.feeValue.validate.message}
                          mki-disabled
                          mki-required
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-prefixed="$"
                          mki-decimalScale={getDynamicPrecision(
                            mainStore.feeStructureDomain.realTimeTransactionFee?.feeValue
                          )}
                          mki-fixedDecimalScale={true}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Absorb"
                          value={mainStore.feeStructureDomain.realTimeTransactionFee?.netValue?.toString() || ''}
                          placeholder="Absorb"
                          onChange={(values) =>
                            onChange('realTimeTransactionFee', fields.realTimeTransactionFee.netValue.name, values)
                          }
                          name={fields.realTimeTransactionFee.netValue.name}
                          hasError={fields.realTimeTransactionFee.netValue.validate.hasError}
                          errorMessage={fields.realTimeTransactionFee.netValue.validate.message}
                          mki-disabled={mainStore.feeStructureDomain.realTimeTransactionFee?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-prefixed="$"
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Surcharge"
                          value={mainStore.feeStructureDomain.realTimeTransactionFee?.surchargeValue?.toString() || ''}
                          placeholder="Surcharge"
                          onChange={(values) =>
                            onChange(
                              'realTimeTransactionFee',
                              fields.realTimeTransactionFee.surchargeValue.name,
                              values
                            )
                          }
                          name={fields.realTimeTransactionFee.surchargeValue.name}
                          hasError={fields.realTimeTransactionFee.surchargeValue.validate.hasError}
                          errorMessage={fields.realTimeTransactionFee.surchargeValue.validate.message}
                          mki-disabled={mainStore.feeStructureDomain.realTimeTransactionFee?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-prefixed="$"
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                        />
                      </div>
                    </div>
                  )}

                  {mainStore.feeStructureDomain.realTimeVariableFee && (
                    <div className="flex flex-wrap pt-10">
                      {isOnboardingUS ? (
                        <></>
                      ) : (
                        <legend className="float-left mt-0 mb-4 w-full uppercase text-indigo-700 font-semibold">
                          Additional Charge over $
                          {mainStore.feeStructureDomain.realTimeVariableFee?.thresholdValue?.toString()}
                        </legend>
                      )}
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Fee"
                          value={mainStore.feeStructureDomain.realTimeVariableFee?.feeValue?.toString() || ''}
                          placeholder="Fee"
                          onChange={(values) =>
                            onChange('realTimeVariableFee', fields.realTimeVariableFee.feeValue.name, values)
                          }
                          name={fields.realTimeVariableFee.feeValue.name}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed={mainStore.feeStructureDomain.settlementDelay === 'T+2' ? null : '%'}
                          mki-prefixed={mainStore.feeStructureDomain.settlementDelay === 'T+2' ? '$' : null}
                          mki-decimalScale={getDynamicPrecision(
                            mainStore.feeStructureDomain.realTimeVariableFee?.feeValue
                          )}
                          mki-disabled
                          mki-fixedDecimalScale={true}
                          hasError={fields.realTimeVariableFee.feeValue.validate.hasError}
                          errorMessage={fields.realTimeVariableFee.feeValue.validate.message}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Absorb"
                          value={mainStore.feeStructureDomain.realTimeVariableFee?.netValue?.toString() || ''}
                          placeholder="Absorb"
                          onChange={(values) =>
                            onChange('realTimeVariableFee', fields.realTimeVariableFee.netValue.name, values)
                          }
                          name={fields.realTimeVariableFee.netValue.name}
                          mki-disabled={mainStore.feeStructureDomain.realTimeVariableFee?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed={mainStore.feeStructureDomain.settlementDelay === 'T+2' ? null : '%'}
                          mki-prefixed={mainStore.feeStructureDomain.settlementDelay === 'T+2' ? '$' : null}
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                          hasError={fields.realTimeVariableFee.netValue.validate.hasError}
                          errorMessage={fields.realTimeVariableFee.netValue.validate.message}
                        />
                      </div>
                      <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                        <FormField
                          label="Surcharge"
                          value={mainStore.feeStructureDomain.realTimeVariableFee?.surchargeValue?.toString() || ''}
                          placeholder="Surcharge"
                          onChange={(values) =>
                            onChange('realTimeVariableFee', fields.realTimeVariableFee.surchargeValue.name, values)
                          }
                          name={fields.realTimeVariableFee.surchargeValue.name}
                          mki-disabled={mainStore.feeStructureDomain.realTimeVariableFee?.lock || false}
                          step={0.1}
                          type="number"
                          masked={true}
                          mki-thousandSeparator={true}
                          mki-sufixed={mainStore.feeStructureDomain.settlementDelay === 'T+2' ? null : '%'}
                          mki-prefixed={mainStore.feeStructureDomain.settlementDelay === 'T+2' ? '$' : null}
                          mki-decimalScale={3}
                          mki-onBlur={forceUpdate}
                          hasError={fields.realTimeVariableFee.surchargeValue.validate.hasError}
                          errorMessage={fields.realTimeVariableFee.surchargeValue.validate.message}
                        />
                      </div>
                      {isOnboardingUS && mainStore.feeStructureDomain.settlementDelay === 'T+4' ? (
                        <div className="w-1/3 xs:w-full xs:mt-6 form-group pt-4">
                          <FormField
                            label={`Capped at ${isOnboardingNZ ? ' (NZD)' : ' ($)'}`}
                            value={mainStore.feeStructureDomain.realTimeVariableFee?.thresholdValue?.toString() || ''}
                            placeholder={`Capped at ${isOnboardingNZ ? ' (NZD)' : ' ($)'}`}
                            name={fields.realTimeVariableFee?.thresholdValue.name}
                            type="number"
                            masked={true}
                            mki-thousandSeparator={true}
                            mki-prefixed="$"
                            mki-decimalScale={getDynamicPrecision(
                              mainStore.feeStructureDomain.realTimeVariableFee?.thresholdValue
                            )}
                            mki-disabled
                            mki-fixedDecimalScale={true}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="flex flex-wrap pt-10">
                        <legend className="float-left mt-0 mb-4 w-full uppercase text-indigo-700 font-semibold">
                          <div className="flex items-center">
                            <span className="mr-1">Fixed Business Fees{isOnboardingNZ ? ' (NZD)' : ' ($)'}*</span>
                            <Tooltip title="FixedFees">
                              <p>
                                These fees will be charged on failed transactions
                                <br />
                                and when there is a dispute or chargeback
                              </p>
                            </Tooltip>
                          </div>
                        </legend>
                        <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                          <FormField
                            label={isOnboardingUS ? 'Failed ACH Transaction Fee' : 'Failed Transaction Fee'}
                            value={mainStore.feeStructureDomain.eftFailedFee?.feeValue?.toString() || ''}
                            placeholder="Failed Transaction Fee"
                            name={fields.eftFailedFee.feeValue.name}
                            type="number"
                            masked={true}
                            mki-thousandSeparator={true}
                            mki-prefixed="$"
                            mki-decimalScale={getDynamicPrecision(mainStore.feeStructureDomain.eftFailedFee?.feeValue)}
                            mki-fixedDecimalScale={true}
                            mki-disabled
                          />
                        </div>
                        <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                          <FormField
                            label="Dispute / Chargeback Fee"
                            value={mainStore.feeStructureDomain.eftDisputeFee?.feeValue?.toString() || ''}
                            placeholder="Dispute / Chargeback Fee"
                            name={fields.eftDisputeFee.feeValue.name}
                            type="number"
                            masked={true}
                            mki-thousandSeparator={true}
                            mki-prefixed="$"
                            mki-decimalScale={getDynamicPrecision(mainStore.feeStructureDomain.eftDisputeFee?.feeValue)}
                            mki-disabled
                            mki-fixedDecimalScale={true}
                          />
                        </div>
                        <span className="w-full block mx-4 text-sm mt-1" style={{ color: '#083549' }}>
                          *These fees are covered by your business
                        </span>
                      </div>
                    </div>
                  )}
                </fieldset>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {mainStore.feeStructureDomain.clientType === 'EZYCOLLECT_STANDARD' ? (
          <div className="px-10">
            <fieldset className="bg-gray-70 p-4 mb-8">
              <span>To apply a surcharge, you're required to comply with the following:</span>
              <ul className="list-disc pl-10">
                {isOnboardingUS ? (
                  <>
                    <li>You're responsible for determining if surcharge is allowed in your state.</li>
                    <li>You must follow state and local laws.</li>
                    <li>Only credit card transactions are permitted.</li>
                    <li>The surcharge amount must not exceed your cost of acceptance for the credit card.</li>
                  </>
                ) : null}
                <li>
                  This is the default fee structure that applies to all your customers. However, you have the
                  flexibility to customize the fee structure for individual customers as needed
                </li>
              </ul>
              <div className="w-1/2 xs:w-full">
                <label className="block mx-4 mt-4">
                  <div className={'flex items-center'}>
                    <input
                      type="checkbox"
                      className="mr-1"
                      name="checkbox"
                      value="1"
                      onChange={(e) => mainStore.updateFeeStructure('agreedToConfigTerms', null, e.target.checked)}
                      checked={mainStore.feeStructureDomain.agreedToConfigTerms || false}
                    />
                    <span>I agree</span>
                  </div>
                  {fields.agreedToConfigTerms.validate.hasError && (
                    <span className="text-red-700 text-sm font-medium">
                      {fields.agreedToConfigTerms.validate.message}
                    </span>
                  )}
                </label>
              </div>
            </fieldset>
          </div>
        ) : null}
      </div>
    )
  );
});

export default FeeStructureForm;
