enum ApplicationType {
    creditApplication = "CREDIT_APPLICATION",
    cashApplication = "CASH_APPLICATION",
    cashCustomerApplication = "CASH_CUSTOMER_APPLICATION",
    creditCustomerApplication = "CREDIT_CUSTOMER_APPLICATION",
    prePaidAccount = "PRE_PAID_ACCOUNT",
    creditAccount = "CREDIT_ACCOUNT",
}

enum CustomerType {
    residential = "RESIDENTIAL",
    business = "BUSINESS",
    individual = "INDIVIDUAL",
    company = "COMPANY"
}

export {ApplicationType, CustomerType};