import React from 'react';
import CommonUtils from '../../../../Utils/CommonsUtils';

type BTFeeStructure = {
  connectorType: string;
  enable: boolean;
  surcharged: boolean;
  feeAfterThreshold: string;
  threshold: string;
  transactionLimit: string;
  type: string;
  payToEnable: boolean;
  flatFee: string;
  payToConfig?: BTFeeStructure;
};

type BankTransferFeeProps = {
  feeInformation: BTFeeStructure | null;
};

export const BankTransferFee = (props: BankTransferFeeProps) => {
  const { feeInformation = null } = props;
  const handleFeeRender = () => {
    const payTo = feeInformation?.payToEnable && feeInformation.payToConfig;
    const payToThreshold = feeInformation?.payToConfig?.threshold ? parseFloat(feeInformation?.payToConfig?.threshold) : 0.0;
    const payToSurcharged = feeInformation?.payToConfig?.flatFee ? parseFloat(feeInformation?.payToConfig?.flatFee) : 0.0;
    const hasPayToThreshold = payToThreshold > 0.0;

    let feeMessage = <></>;
    if (feeInformation) {
      if (
        feeInformation?.surcharged &&
        parseFloat(feeInformation?.threshold) > 0 &&
        parseFloat(feeInformation?.feeAfterThreshold) > 0
      ) {
        feeMessage = (
          <>
            A payment processing fee of <strong>{'$' + feeInformation?.flatFee}</strong> will apply
            {feeInformation?.enable &&
              parseFloat(feeInformation?.threshold) > 0 &&
              parseFloat(feeInformation?.feeAfterThreshold) > 0 && (
                <>
                  {' '}
                  and an additional <strong>{feeInformation?.feeAfterThreshold}%</strong> computed on the payment amount
                  for transactions exceeding <strong>${CommonUtils.formatMoney(feeInformation?.threshold)}</strong>
                </>
              )}
            {payTo && payToSurcharged > 0.0 && (
              <>
                <br />
                <br />
                <i>
                  If PayTo is available a transaction fee of $
                  {payToSurcharged} will be added to the payment amount.{' '}
                </i>
              </>
            )}
            {hasPayToThreshold && (
              <>
                <i>
                  Additional{' '}
                  {feeInformation.payToConfig?.feeAfterThreshold}
                  % computed on the payment amount for transactions exceeding $
                  {payToThreshold}.
                </i>
              </>
            )}
          </>
        );
      }
    }

    return feeMessage;
  };

  return (
    <div className="text-center my-5 link font-sans">
      <span className="fee-message">{handleFeeRender()}</span>
    </div>
  );
};
