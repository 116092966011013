import React from 'react';

const ExpiredLink = () => {
  return (
    <div className="flex flex-col h-full items-center justify-center">
      <p className="mb-11 text-lg" style={{ color: '#083549' }}>
        Oops... Looks like this form link is expired
      </p>
      <svg width="422" height="295" viewBox="0 0 422 295" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1473_231)">
          <path d="M422 279.344H0V280.63H422V279.344Z" fill="#3F3D56" />
          <path
            d="M303.616 135.583C299.709 112.162 279.346 94.3135 254.813 94.3135H154.487C129.964 94.3135 109.605 112.148 105.684 135.555C105.232 138.232 104.998 140.985 104.998 143.791V231.185C104.998 233.99 105.232 236.739 105.684 239.416C108.805 258.064 122.365 273.179 140.155 278.547C144.691 279.919 149.503 280.657 154.487 280.657H254.813C259.797 280.657 264.609 279.919 269.145 278.547C286.94 273.174 300.505 258.05 303.616 239.387C304.064 236.72 304.297 233.981 304.297 231.185V143.791C304.297 140.995 304.064 138.251 303.616 135.583ZM263.304 278.547C260.545 279.038 257.71 279.295 254.813 279.295H154.487C151.59 279.295 148.755 279.038 145.997 278.547C123.47 274.541 106.365 254.859 106.365 231.185V202.176C106.365 120.207 172.826 95.6806 254.813 95.6806C281.39 95.6806 302.935 117.221 302.935 143.791V231.185C238.419 239.816 285.83 274.541 263.304 278.547Z"
            fill="#3F3D56"
          />
          <path d="M224.613 160.262L228.473 185.345L285.083 177.627L279.615 148.321L224.613 160.262Z" fill="#FE6600" />
          <path d="M269.043 19.5146H245.637V43.7361H269.043V19.5146Z" fill="#2F2E41" />
          <path d="M247.773 45.1402L253.563 27.7754H264.848L265.441 45.1402H247.773Z" fill="#FFB6B6" />
          <path opacity="0.1" d="M247.773 45.1402L253.563 27.7754H264.848L265.441 45.1402H247.773Z" fill="black" />
          <path
            d="M253.884 199.815L253.241 266.702L252.598 271.847V279.233L262.247 280.208L262.89 265.415C262.89 265.415 273.183 236.474 270.61 213.964C268.037 191.454 269.323 197.886 269.323 197.886L253.884 199.815Z"
            fill="#FFB6B6"
          />
          <path
            d="M260.009 205.068L236.27 267.606L234.997 272.632L232.31 279.512L222.967 276.911L227.75 262.898C227.75 262.898 228.693 232.197 239.281 212.165C249.868 192.133 246.329 197.656 246.329 197.656L260.009 205.068Z"
            fill="#FFB6B6"
          />
          <path
            d="M247.773 43.21L230.404 50.9277L241.494 108.175L239.742 190.34L228.163 214.779C228.163 214.779 226.223 228.756 246.165 219.752C266.107 210.748 288.622 208.176 288.622 208.176L283.154 170.552L278.329 71.8297L278.973 49.963L264.82 40.959L247.773 43.21V43.21Z"
            fill="#2F2E41"
          />
          <path
            d="M238.099 215.673L218.824 183.415L240.85 108.176L230.403 50.9283L248.731 43.084C248.731 43.084 258.219 52.3211 258.219 59.94C258.219 67.559 238.099 215.673 238.099 215.673Z"
            fill="#E6E6E6"
          />
          <path
            d="M279.913 209.242L262.417 58.986C262.196 57.0887 262.52 55.1678 263.351 53.4478L268.358 43.084L287.978 48.6773L280.091 110.105L301.166 170.552L279.913 209.242H279.913Z"
            fill="#E6E6E6"
          />
          <path
            d="M264.176 278.921C264.176 278.921 251.954 271.847 250.667 275.705C249.381 279.564 248.737 282.78 248.737 282.78C248.737 282.78 245.521 295 255.17 295C264.82 295 264.176 289.442 264.176 289.442V278.921Z"
            fill="#2F2E41"
          />
          <path
            d="M221.639 275.011C221.639 275.011 235.598 272.866 235.393 276.929C235.187 280.991 234.616 284.22 234.616 284.22C234.616 284.22 233.166 296.773 224.178 293.263C215.189 289.754 217.811 284.811 217.811 284.811L221.639 275.011Z"
            fill="#2F2E41"
          />
          <path
            d="M259.957 37.2259C268.789 37.2259 275.949 30.0676 275.949 21.2375C275.949 12.4073 268.789 5.24902 259.957 5.24902C251.125 5.24902 243.965 12.4073 243.965 21.2375C243.965 30.0676 251.125 37.2259 259.957 37.2259Z"
            fill="#FFB6B6"
          />
          <path
            d="M272.734 6.98944C271.99 3.0474 267.942 0.381075 263.944 0.0467254C259.945 -0.287624 256.028 1.22191 252.427 2.9918C249.986 4.19157 247.583 5.54386 245.606 7.41135C243.628 9.27885 242.093 11.7184 241.738 14.4146C241.613 15.3685 241.629 16.3652 241.238 17.2445C240.751 18.343 239.703 19.089 239.071 20.1114C237.937 21.945 238.458 24.6125 240.198 25.8852C238.011 26.8172 235.764 27.7893 234.096 29.484C232.429 31.1786 231.466 33.795 232.381 35.9891C232.704 36.7654 233.239 37.4448 233.516 38.2391C234.041 39.7472 233.541 41.4606 232.61 42.7577C231.678 44.0548 230.362 45.0166 229.07 45.9556C232.391 46.6063 235.78 47.1133 239.151 46.8097C242.521 46.5061 245.903 45.3253 248.344 42.9819C250.784 40.6385 252.127 37.0236 251.251 33.7558C250.581 31.2525 248.705 29.0589 248.706 26.4674C248.707 24.4753 249.827 22.6782 250.909 21.0054C253.272 17.3519 255.634 13.6984 257.997 10.045C258.422 9.38779 258.87 8.70772 259.542 8.30575C260.685 7.62193 262.167 7.92265 263.371 8.49023C264.576 9.05781 265.676 9.87212 266.96 10.224C269.581 10.9423 272.622 9.29284 273.448 6.70438"
            fill="#2F2E41"
          />
          <path
            d="M263.287 39.5055C261.955 40.7957 260.034 41.2113 258.254 41.7341C256.474 42.2569 254.581 43.0791 253.78 44.752C252.995 46.3922 253.565 48.4744 254.865 49.746C256.166 51.0175 258.055 51.5532 259.874 51.528C261.693 51.5028 263.465 50.9698 265.176 50.351C269.853 48.6589 274.282 46.2854 278.281 43.3285C275.691 42.612 273.996 39.5879 274.738 37.0051C275.341 34.9041 277.186 33.4387 278.441 31.649C280.602 28.5674 280.962 24.5053 280.246 20.8103C279.531 17.1153 277.846 13.6901 276.179 10.3158C275.651 9.24909 275.112 8.16437 274.29 7.30428C273.467 6.4442 272.303 5.82802 271.12 5.95125C269.156 6.15574 267.901 8.31151 267.888 10.2858C267.875 12.2601 268.741 14.1157 269.398 15.9775C270.075 17.8958 270.545 19.9291 270.364 21.9551C270.212 23.6529 269.594 25.3067 268.596 26.6888C267.825 27.7561 266.843 28.6518 266.005 29.6671C265.357 30.4521 264.783 31.3413 264.613 32.3447C264.303 34.1706 265.374 36.0182 265.08 37.8468C264.77 39.7799 262.735 41.2598 260.8 40.9602"
            fill="#2F2E41"
          />
          <path
            d="M214.377 262.816C244.576 262.816 269.057 238.341 269.057 208.149C269.057 177.958 244.576 153.482 214.377 153.482C184.178 153.482 159.697 177.958 159.697 208.149C159.697 238.341 184.178 262.816 214.377 262.816Z"
            fill="#FE6600"
          />
          <path
            d="M247.139 203.762H240.342C239.284 197.532 236.021 191.89 231.148 187.865C219.932 178.604 203.331 180.186 194.068 191.399C194.036 191.435 194.006 191.472 193.977 191.509C193.236 192.468 193.414 193.846 194.374 194.586C195.333 195.326 196.711 195.148 197.451 194.189C201.475 189.315 207.566 186.213 214.376 186.213C224.803 186.214 233.79 193.549 235.879 203.762H229.885C229.628 203.762 229.38 203.852 229.183 204.016C228.717 204.404 228.654 205.095 229.042 205.561L237.669 215.915C237.712 215.966 237.759 216.012 237.809 216.055C238.275 216.443 238.966 216.38 239.354 215.915L247.982 205.561C248.146 205.364 248.236 205.116 248.236 204.859C248.236 204.254 247.745 203.762 247.139 203.762H247.139Z"
            fill="white"
          />
          <path
            d="M234.38 221.712C233.421 220.972 232.043 221.15 231.303 222.109C228.111 225.973 223.692 228.628 218.781 229.633C206.905 232.064 195.306 224.41 192.874 212.536H198.869C199.475 212.536 199.966 212.045 199.966 211.439C199.965 211.183 199.875 210.935 199.711 210.738L191.084 200.384C190.696 199.918 190.004 199.856 189.539 200.244C189.488 200.286 189.441 200.333 189.399 200.384L180.772 210.738C180.384 211.203 180.447 211.895 180.913 212.282C181.11 212.446 181.358 212.536 181.614 212.536H188.412C189.469 218.767 192.732 224.409 197.606 228.433C208.821 237.694 225.423 236.112 234.686 224.9C234.718 224.864 234.748 224.827 234.777 224.789C235.517 223.83 235.34 222.452 234.38 221.712Z"
            fill="white"
          />
          <path
            d="M229.645 152.549C228.982 157.286 226.001 160.785 222.985 160.363C219.969 159.941 218.062 155.759 218.725 151.02C218.964 149.123 219.654 147.311 220.737 145.736L223.796 125.698L233.171 127.334L229.16 146.914C229.769 148.727 229.935 150.659 229.645 152.549Z"
            fill="#FFB6B6"
          />
          <path
            d="M237.802 49.9629C237.802 49.9629 228.153 46.7472 226.866 58.3237C225.58 69.9003 224.293 84.0494 224.293 84.0494C224.293 84.0494 218.503 97.5553 221.076 104.63C223.65 111.704 228.153 106.772 223.65 112.775C219.147 118.779 219.774 138.531 219.774 138.531L234.264 137.802C234.264 137.802 242.305 84.6925 242.305 83.4062C242.305 82.1199 237.802 49.9629 237.802 49.9629V49.9629Z"
            fill="#E6E6E6"
          />
          <path
            d="M300.524 150.398C300.703 155.179 298.382 159.146 295.339 159.259C292.296 159.373 289.685 155.59 289.506 150.808C289.408 148.899 289.769 146.994 290.559 145.253L290.055 124.99L299.571 124.956L299.058 144.936C299.976 146.613 300.479 148.487 300.524 150.398V150.398Z"
            fill="#FFB6B6"
          />
          <path
            d="M285.668 49.7461C285.668 49.7461 295.374 46.7033 296.453 58.301C297.533 69.8986 298.566 91.143 298.566 91.143C298.566 91.143 304.114 104.75 301.414 111.778C298.715 118.805 294.301 113.793 298.696 119.876C303.091 125.959 301.514 143.114 301.514 143.114L287.039 142.126C287.039 142.126 280.545 91.4643 280.568 90.1782C280.591 88.8921 285.668 49.7461 285.668 49.7461V49.7461Z"
            fill="#E6E6E6"
          />
        </g>
        <defs>
          <clipPath id="clip0_1473_231">
            <rect width="422" height="295" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <p>If you need any information or help, please contact us!</p>
      <a href="mailto:customersuccess@ezycollect.com.au" style={{ color: '#189DD6' }}>
        customersuccess@ezycollect.com.au
      </a>
    </div>
  );
};

export default ExpiredLink;
