import styled from 'styled-components';
import React, { useContext, useState } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import CreditCardForm from './CreditCardForm';
import MainStore from '../../stores/MainStore';
import CreditCardFee from './CreditCardFee';

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0rem;
  padding: 0.5rem;
`;

const CreditCard = (props: any) => {
  const { value, onChange, options, feeInformation, loadSetupIntent } = props;
  const [creditCardInfo, setCreditCardInfo] = useState(value ? JSON.parse(value) : {});
  const stripe = useStripe();
  const elements = useElements();
  const mainStore = useContext(MainStore);

  const updateCreditCard = (obj: Record<string, any>) => {
    mainStore.updateCreditCard(obj);
    setCreditCardInfo(obj);
    onChange(JSON.stringify(obj));
  };

  const removeCreditCard = () => {
    setCreditCardInfo({});
    onChange(undefined);
    mainStore.updateCreditCardInfo({
      clientSecret: undefined,
      feeInformation: {},
    });
    mainStore.updateCreditCard({ last4: '', holder: '', brand: '' });
    loadSetupIntent();
  };

  const validateRequired = (obj: Record<string, any>) => {
    mainStore.updateCustomCardValidator(obj);
  };

  return (
    <div id="credit-card-group">
      {creditCardInfo && Object.keys(creditCardInfo).length > 0 ? (
        <div>
          <div className="flex flex-wrap">
            <Field className="flex w-1/4 sm:w-full mt-6">
              <label className="text-gray-500 font-medium">Card Holder Name</label>
              <div>{creditCardInfo.holder}</div>
            </Field>
            <Field className="flex w-1/4 sm:w-full mt-6">
              <label className="text-gray-500 font-medium">Card Number</label>
              <div>
                {creditCardInfo.brand === 'American Express' ? '***********' : '**** **** ****'} {creditCardInfo.last4}
              </div>
            </Field>
            <Field className="flex w-1/4 sm:w-full mt-6">
              <label className="text-gray-500 font-medium">Expiry date</label>
              <div>
                {creditCardInfo.exp_month?.toString().padStart(2, '0')} / {creditCardInfo.exp_year}
              </div>
            </Field>
            <Field className="flex w-1/4 sm:w-full mt-6">
              <button className="link" type="button" onClick={removeCreditCard}>
                Reset card information
              </button>
            </Field>
          </div>
          {feeInformation ? <CreditCardFee card={creditCardInfo} feeInformation={feeInformation} /> : ''}
        </div>
      ) : (
        <CreditCardForm
          stripe={stripe}
          elements={elements}
          options={options}
          feeInformation={feeInformation}
          onChange={updateCreditCard}
          validateRequired={validateRequired}
        />
      )}
    </div>
  );
};

export default CreditCard;
