import React, {useContext } from 'react';
import { observer} from 'mobx-react-lite';
import MainStore from '../../stores/MainStore';
import CompanyInfoForm from '../CompanyInfoForm';
import FormTitle from '../FormTitle';
import ApplicationTypeSelector from './ApplicationTypeSelector';
import CashApplicationSelector from './CashApplicationSelector';
import properties from '../../constants/properties';
import { ApplicationType } from '../../domains/TypeEnum';

const brooks_hire_options = [
    { value: ApplicationType.creditApplication, label: "Credit Application" },
    { value: ApplicationType.cashApplication, label: "Cash Application" },
];

const mayers_options = [
    { value: ApplicationType.prePaidAccount, label: "Pre-Paid Account" },
    { value: ApplicationType.creditAccount, label: "Credit Account" },
];

const omagroup_options = [
    { value: ApplicationType.cashCustomerApplication, label: "Cash Customer" },
    { value: ApplicationType.creditCustomerApplication, label: "Credit Customer" },
];

const reliable_hire_options = [
    { value: ApplicationType.creditApplication, label: "Credit Application" },
    { value: ApplicationType.cashApplication, label: "Cash Application" },
];


const CustomCompanyInfoForm = observer((props:Record<string, any>) => {

    const mainStore:any = useContext(MainStore);
    const { organizationId }:any = mainStore.creditApplicationDomain;

    const getApplicationTypeForm = () => {
        if(mainStore.customApplication.type === "") {
            return null;
        }
        
        if(mainStore.customApplication.type === ApplicationType.prePaidAccount 
            || mainStore.customApplication.type === ApplicationType.creditAccount) {
            return <CompanyInfoForm />;
        }
        
        if(mainStore.customApplication.type === ApplicationType.cashCustomerApplication 
            || mainStore.customApplication.type === ApplicationType.creditCustomerApplication) {
                return <>
                    <CashApplicationSelector errorLabel="Please select an account type" label="Account Type *" />
                    {
                        mainStore.customApplication.customerType !== "" && <CompanyInfoForm />
                    }
                </>
        } 
        
        if(mainStore.customApplication.type === ApplicationType.cashApplication) {
            if(mainStore.customApplication.customerType !== "") {
                return <>
                    <CashApplicationSelector />
                    <CompanyInfoForm />
                </>;
            }
            return <CashApplicationSelector />;
        }
        
        return <CompanyInfoForm />;
    }

    const getApplicationSelector = () => {
        if(properties.OMA_GROUP.includes(organizationId) ) {
            return <ApplicationTypeSelector errorLabel="Please select a customer type" label="Customer Type *" options={omagroup_options} />
        }

        if(properties.MAYERS_ORGS.includes(organizationId) ) {
            return <ApplicationTypeSelector options={mayers_options} />
        }

        if(properties.BROOKS_HIRE_ORGS.includes(organizationId)) {
            return <ApplicationTypeSelector options={brooks_hire_options} />
        }

        if(properties.RELIABLE_HIRE_ORGS.includes(organizationId)) {
            return <ApplicationTypeSelector options={reliable_hire_options} />
        }

        return <CompanyInfoForm />;
    } 

    return <>
        <div className="p-10">
            <FormTitle title={mainStore.companyInfoStep.label} />
            { getApplicationSelector() }
            { getApplicationTypeForm() }
        </div>
    </>
});

export default CustomCompanyInfoForm;