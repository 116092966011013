const env = process.env.REACT_APP_ENV || 'development';

const configs = {
  local: {
    api_base_url: 'http://localhost:5150/ca-api',
    form_api_base_url: 'http://localhost:5150/ca-api',
    abn_lookup_api_url: 'https://abn-api-uat.ezycollectuat.io/api/secured/business/v2/search/',
    publishable_key: 'pk_test_51KkemMIcQhkPN9GJIBn7X1HpBxVCIE6AsPeyFXjLqoJu2hOl1OKKEKe72C5V6MQ1pvQnK9ntla5iGSgyLN2Dvjkg00aVJzdwMK',
    recaptcha_key: '6LfdL0QjAAAAACs0lchsj7404ykhyr1togQYpJPM',
  },
  development: {
    api_base_url: 'http://localhost:8091/public/api/v1',
   form_api_base_url: 'http://localhost:8090/public/api/v1',
    abn_lookup_api_url: 'https://abn-api-uat.ezycollectuat.io/api/secured/business/v2/search/',
    publishable_key: 'pk_test_51KkemMIcQhkPN9GJIBn7X1HpBxVCIE6AsPeyFXjLqoJu2hOl1OKKEKe72C5V6MQ1pvQnK9ntla5iGSgyLN2Dvjkg00aVJzdwMK',
    recaptcha_key: '6LfdL0QjAAAAACs0lchsj7404ykhyr1togQYpJPM',
  },
  uat: {
    api_base_url: 'https://ca-api.ezycollect.info/public/api/v1',
    form_api_base_url: 'https://form-api.ezycollect.info/public/api/v1',
    abn_lookup_api_url: 'https://abn-api-uat.ezycollectuat.io/api/secured/business/v2/search/',
    publishable_key: 'pk_test_51KkemMIcQhkPN9GJIBn7X1HpBxVCIE6AsPeyFXjLqoJu2hOl1OKKEKe72C5V6MQ1pvQnK9ntla5iGSgyLN2Dvjkg00aVJzdwMK',
    recaptcha_key: '6LfdL0QjAAAAACs0lchsj7404ykhyr1togQYpJPM',
  },
  production: {
    api_base_url: 'https://ca-api.ezycreditapp.com.au/public/api/v1',
    form_api_base_url: 'https://form-api.ezycreditapp.com.au/public/api/v1',
    abn_lookup_api_url: 'https://abn-api-prod.ezycollect.io/api/secured/business/v2/search/',
    publishable_key: 'pk_live_51KkemMIcQhkPN9GJ4yN9fzPQw06xp2J59WQuevQpSV763efiYgQW7bTrZmVIVrtg0SBgRSmHmKKPN1Mp2nwyy45W00vVamA1kJ',
    recaptcha_key: '6LfL9fIpAAAAAGb3bVLBtbmqivpY1ZeSykv2fhJi',
  },
}[env];

export default configs;
