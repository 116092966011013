// eslint-disable-next-line
import React from 'react';
import styled from 'styled-components';

const colors = {
  black: '#02162C',
  textColor: '#36506B',
};

const FormTitleArea = styled.div`
  border-bottom: 2px solid ${colors.black};
  font-size: 14px;
  text-transform: uppercase;
  padding: 6px 12px;
  font-weight: bold;
  margin-top: 16px;
`;

const BasicFieldArea = styled.div`
  padding: 8px;
  border-bottom: 1px solid #02162c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > p {
    font-size: 14px;
    font-weight: 600;
    color: #02162c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > span {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    color: #36506b;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export { FormTitleArea, BasicFieldArea };
