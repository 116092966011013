class CustomApplicationDomain {

    private step: number;
    private stepName: string;
    private schemas: Record<string, any>
    private uiSchemas: Record<string, any>

    constructor(step:number, stepName:string, jsonSchemas:Record<string, any>, uiSchemas:Record<string, any>) {
        this.stepName = stepName.toLowerCase();
        this.step = step;
        this.schemas = jsonSchemas;
        this.uiSchemas = uiSchemas;
    }

    getJsonSchemas() {
        return this.schemas;
    }

    getUiSchemas() {
        return this.uiSchemas;
    }

    getJsonSchemaProperties(property: string = this.stepName) {
        return this.schemas[this.step].properties[property].items ?
          this.schemas[this.step].properties[property].items.properties :
          this.schemas[this.step].properties[property].properties;
    }

    getUiSchemaItems() {
        return this.uiSchemas[this.step][this.stepName].items;
    }

    hasProperty(prop:string):Boolean {
        if(this.schemas[this.step].properties[this.stepName]) {
            return this.schemas[this.step].properties[this.stepName].items.properties[prop] === true
        } else {
            return this.schemas[this.step].properties[prop] === true
        }
    }

    addPropertiesToJsonSchema(object: Record<string, any>, property: string = this.stepName) {
        if(this.schemas[this.step].properties[property]) {
            if(this.schemas[this.step].properties[property].items) {
                this.schemas[this.step].properties[property].items.properties = {
                    ...this.schemas[this.step].properties[property].items.properties,
                    ...object
                };
            } else {
                this.schemas[this.step].properties[property].properties = {
                    ...this.schemas[this.step].properties[property].properties,
                    ...object
                };
            }
        } else {
            this.schemas[this.step].properties = {
                ...this.schemas[this.step].properties,
                ...object
            };
        }

    }

    addPropertiesToUiSchema(object: Record<string, any>, property: string = this.stepName) {
        if(this.uiSchemas[this.step][this.stepName]) {
            if(this.schemas[this.step].properties[property].items) {
                this.uiSchemas[this.step][property].items = {
                    ...this.uiSchemas[this.step][property].items,
                    ...object
                }
            } else {
                this.uiSchemas[this.step][property] = {
                    ...this.uiSchemas[this.step][property],
                    ...object
                }
            }
        } else {
            this.uiSchemas[this.step] = {
                ...this.uiSchemas[this.step],
                ...object
            }
        }

    }

    addDependencies(dependencies: Record<string, any>, property: string = this.stepName) {
        if(this.schemas[this.step].properties[property].items) {
            this.schemas[this.step].properties[property].items.dependencies = {
                ...this.schemas[this.step].properties[property].items?.dependencies,
                ...dependencies
            }
        } else {
            this.schemas[this.step].properties[property].dependencies = {
                ...this.schemas[this.step].properties[property]?.dependencies,
                ...dependencies
            }
        }
    }

    getJsonSchemaSection(property: string = this.stepName) {
        return this.schemas[this.step].properties[property] ? this.schemas[this.step].properties[property] : null;
    }

    addSectionToJsonSchema(object: Record<string, any>, property: string = this.stepName) {
        this.schemas[this.step].properties[property] = {
            ...this.schemas[this.step].properties,
            ...object
        };
    }


    removeSectionFromJsonSchema(field:string) {
        delete this.schemas[this.step].properties[field];
    }

    removePropertiesFromJsonSchema(field:string) {
        if(this.schemas[this.step].properties[this.stepName]
          && this.schemas[this.step].properties[this.stepName].items) {
            delete this.schemas[this.step].properties[this.stepName].items.properties[field];
        } else {
            delete this.schemas[this.step].properties[field];
        }
    }

    addRequiredField(field:string, property: string = this.stepName) {
        let schema: { required: string[] } | null = null;
        if(this.schemas[this.step].properties[property]) {
            schema = this.schemas[this.step].properties[property].items;
        } else if(this.schemas[this.step].properties[field]) {
            schema = this.schemas[this.step];
        } else {
            schema = this.schemas[this.step];
        }

        if(schema && !schema.required?.includes(field)) {
            if(!schema.required) {
                schema.required = [];
            }
            schema.required.push(field);
        }
    }

    removeRequiredField(field:string) {
        if(this.schemas[this.step].properties[this.stepName] && this.schemas[this.step].properties[this.stepName].items.required) {
            this.schemas[this.step].properties[this.stepName].items.required = this.schemas[this.step].properties[this.stepName].items.required
              .filter((item: string) => item !== field);
        } else if(this.schemas[this.step].required) {
            this.schemas[this.step].required = this.schemas[this.step].required
              .filter((item: string) => item !== field);
        }
    }

    generateFileFieldUiSchema() {
        return {
            "ui:column" : "w-full",
            "ui:options": { accept: ['.pdf', '.jpg', '.jpeg', '.png', 'gif', '.PDF', '.JPG', 'JPEG', 'PNG', 'GIF'] }
        }
    }


    generateDateFieldUiSchema() {
        return {
            "ui:column" : "w-1/2 sm:w-1/2 xs:w-full form-group",
            "ui:widget" : "customMaskDate",
        };
    }

    generateGeneralFullWidthUiSchema() {
        return {
            "ui:column" : "w-full",
        };
    }

    generateGeneralUiSchema() {
        return {
            "ui:column" : "w-1/2 xs:w-full",
        };
    }

    generateDateField(title:string) {
        return {
            title,
            type: "string",
            format: "date",
        }
    }

    generateStringField(title:string) {
        return {
            title,
            type: "string",
        }
    }

    generateRadioButtonUI() {
        return {
            "ui:widget": {
                "component": "radio",
                "options": {
                    "inline": true
                }
            }
        };
    }

    generateFileField(title:string) {
        return {
            title: title,
            type: "string",
            format: "data-url",
        }
    }

    generateBooleanEnum(title:string) {
        return {
            title: title,
            type: "boolean",
            enum: [true, false],
            default: false,
        }
    }

    removeStep(property:string) {
        this.schemas.forEach( (e: Record<string, any>, i:number) => {
            if(e.properties && e.properties[property]) {
                this.schemas.splice(i, 1);
                this.uiSchemas.splice(i, 1);
            }
        });
    }

    generateConcatcFields() {
    }
}

export default CustomApplicationDomain;