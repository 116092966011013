import FormField from '../FormField';
import FormGroup from '../FormGroup';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import CreditCardInputStyle from '../../style/CreditCardInputStyle';
import { SetupIntentResult, StripeCardNumberElement, StripeElementStyle, TokenResult } from '@stripe/stripe-js';
import { TermsAndConditionsModal } from '../TermsAndConditionsModal';
import React, { useState } from 'react';
import styled from 'styled-components';
import { StripeElementChangeEvent } from '@stripe/stripe-js/types/stripe-js/elements/base';
import RenderLoading from '../RenderLoading';
import CreditCardFee from './CreditCardFee';

const Field = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0rem;
  padding: 0.5rem;
`;

interface FormFields {
  cardHolder: string;
  agreeToTerms: boolean;
}

const CreditCardForm = (props: any) => {
  const { stripe, elements, options, feeInformation, onChange } = props;
  const [formFields, setFormFields] = useState<FormFields>({
    cardHolder: '',
    agreeToTerms: false,
  });
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [cardInfo, setCardInfo] = useState({});

  const handleChangeStripe = (e: StripeElementChangeEvent) => {
    handleChange(e);
    props.validateRequired({ [e.elementType]: { complete: e.complete, empty: e.empty } });
  };

  const handleChangeInput = (e: Event & { target: HTMLButtonElement }) => {
    handleChange(e.target);
    props.validateRequired({ cardHolderName: { empty: e.target.value === '' } });
  };

  const agreementInput = (e: any) => {
    handleChange(e.target);
    props.validateRequired({ cardAgreement: { empty: !e.target.checked } });
  };

  const handleToggleTermsModal = () => {
    setShowTermsModal(!showTermsModal);
  };

  const handleChange = (field: any): void => {
    const name: string = field.id ? field.id : field.elementType;
    if (field.brand) {
      setCardInfo({
        brand: field.brand,
      });
    }

    const fields = {
      [name]: name === 'agreeToTerms' ? field.checked : field.value,
    };
    setFormFields({ ...formFields, ...fields });

    const items = Object.keys(formFields);
    if (
      items.includes('cardHolder') &&
      items.includes('cardNumber') &&
      items.includes('cardExpiry') &&
      items.includes('cardCvc') &&
      (formFields.agreeToTerms || fields.agreeToTerms)
    ) {
      setLoading(true);

      stripe
        ?.createToken(elements?.getElement(CardNumberElement) as StripeCardNumberElement)
        .then((tokenResult: TokenResult) => {
          if (!validateCreditCardBrand(tokenResult)) {
            setError('Card brand not supported');
            setLoading(false);
            return false;
          }

          if (tokenResult && tokenResult.token) {
            stripe
              ?.confirmCardSetup(options.clientSecret, {
                payment_method: {
                  card: {
                    token: tokenResult.token.id,
                  },
                  billing_details: {
                    name: formFields.cardHolder,
                  },
                },
              })
              .then((result: SetupIntentResult) => {
                if (result.error) {
                  setError(result.error.message ? result.error.message : 'Unexpected Error');
                  setLoading(false);

                  return false;
                }

                onChange({
                  token: tokenResult.token.id,
                  holder: formFields.cardHolder,
                  brand: tokenResult.token.card?.brand,
                  exp_month: tokenResult.token.card?.exp_month,
                  exp_year: tokenResult.token.card?.exp_year,
                  last4: tokenResult.token.card?.last4,
                  ip: tokenResult.token.client_ip,
                  payment_method: result.setupIntent?.payment_method,
                  setup: result.setupIntent?.id,
                });
                setLoading(false);
              })
              .catch((e: any) => {
                setError(e.error?.message);
                setLoading(false);
              });
          }
        })
        .catch((e: any) => {
          setError(e.error?.message);
          setLoading(false);
        });
    }
  };

  const validateCreditCardBrand = (tokenResult: TokenResult): string => {
    let brand: string | undefined = tokenResult.token?.card?.brand.toLowerCase();

    if (brand === 'american express') {
      // handle Stripe response correctly
      brand = 'amex'; // convert to our naming
    }

    return feeInformation.supportedCardsInfo.some((item: any) => {
      return item.type === brand;
    });
  };

  return (
    <div className="flex flex-wrap">
      <Field id="field-holder" className="w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 mt-6" style={{ paddingLeft: '0px' }}>
        <FormField
          id="cardHolder"
          label="Card Holder Name"
          placeholder="Card Holder Name"
          onChange={handleChangeInput}
          type="text"
          mxClass="false"
        />
      </Field>
      <Field id="field-card" className="w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 mt-6">
        <label className="text-gray-500 font-medium">Card Number</label>
        <FormGroup
          handleChange={handleChange}
          value=""
          placeholder=""
          title=""
          name="cardNumber"
          customInput={
            <>
              <CardNumberElement
                options={{
                  style: CreditCardInputStyle as StripeElementStyle,
                  showIcon: true,
                  placeholder: '',
                }}
                id="cardNumber"
                onChange={handleChangeStripe}
                onBlur={() => {}}
                onFocus={() => {}}
              />
            </>
          }
        />
      </Field>
      <Field id="field-card" className="w-full lg:w-1/6 xl:w-1/6 2xl:w-1/6 mt-6">
        <label className="text-gray-500 font-medium">Expiry date</label>
        <div className={`payment-input`}>
          <CardExpiryElement
            id="cardExpiry"
            options={{
              style: CreditCardInputStyle as StripeElementStyle,
              placeholder: 'MM/YY',
            }}
            onChange={handleChangeStripe}
            onBlur={() => {}}
            onFocus={() => {}}
          />
        </div>
        <span className="field-error"></span>
      </Field>
      <Field id="field-card" className="w-full lg:w-1/6 xl:w-1/6 2xl:w-1/6 mt-6" style={{ paddingRight: '0px' }}>
        <div>
          <label className="text-gray-500 font-medium">CVC</label>
          <div className={`payment-input cvc-container`}>
            <CardCvcElement
              id="cardCvc"
              options={{
                style: CreditCardInputStyle as StripeElementStyle,
                placeholder: '',
              }}
              onChange={handleChangeStripe}
              onBlur={() => {}}
              onFocus={() => {}}
            />
          </div>
        </div>
        <span className="field-error"></span>
      </Field>
      {error ? <div className="w-full p-5 text-red-600">{error}</div> : ''}
      <div className="content-center align-middle w-full -m-40 absolute">{loading ? <RenderLoading /> : ''}</div>
      <div className="w-full">
        {feeInformation ? <CreditCardFee card={cardInfo} feeInformation={feeInformation} /> : ''}
      </div>
      <div className="w-full border border-gray-400 border-solid rounded p-2 my-4">
        <h4 className="font-bold">Service Agreement</h4>
        <div className="pt-2 text-gray-600">
          By checking “I agree” below and clicking the “Confirm” button you acknowledge and agree that:
        </div>
        <ul className="list-disc m-2 ml-8 text-gray-600">
          <li>
            You have read and agree to the Direct Debit&nbsp;
            <button className="link" type="button" onClick={handleToggleTermsModal}>
              Terms and Conditions
            </button>
            .
          </li>
          <li>
            <span>The information you have provided is true and correct.</span>
          </li>
          <li>
            <span>You understand that you are agreeing to a contract with ongoing payments, where applicable.</span>
          </li>
          <li>
            <span>You agree to accept the payment processing fees, if applicable.</span>
          </li>
        </ul>
        <div>
          <input
            type="checkbox"
            id="agreeToTerms"
            name="agreeToTerms"
            className="rounded"
            checked={formFields.agreeToTerms}
            onChange={agreementInput}
          />
          <label className="p-2 select-none" htmlFor="agreeToTerms">
            I agree
          </label>
        </div>
      </div>
      <TermsAndConditionsModal
        agreeToTerms={formFields.agreeToTerms}
        showModal={showTermsModal}
        handleToggleModal={handleToggleTermsModal}
        handleChange={agreementInput}
        setShowModal={setShowTermsModal}
      />
    </div>
  );
};

export default CreditCardForm;
