const props = {
  REQUIRED: 'is a required field',
  AT_LEAST_ONE: 'At least one above must be selected',
  NO_NEGATIVE: "Can't be a negative value",
  NO_ZERO: "Can't be less than 1",
  MAX_CREDIT_PERIOD_INDIVIDUAL: "Max credit period is 4 weeks for Individual Account",
  MAX_CREDIT_PERIOD_COMPANY: "Max credit period is 3 months for Company Account",
  NO_LT_ZERO: "Can't be less than 0",
  NO_FUTURE: "Can't be date in the future",
  INVALID_DATE: 'Not a valid date',
  INVALID_BUSINESS_NUMBER: 'Invalid Business Number',
  INVALID_WEBSITE: 'Not a valid website',
  INVALID_EMAIL: 'Not a valid email',
  INVALID_PHONE: 'Not a valid phone number',
  BUSINESS_NUMBER_NOT_FOUND: 'Business Number was not found',
  FILE_MAX_SIZE: 'File exceeds the maximum size allowed (10MB)',
  FILE_INVALID_TYPE: 'File type is not valid',
  BUSINESS_NUMBER_DUPLICITY: 'The Business Number should be different of Trustee Business Number and vice versa',
  SUM_MUST_BE_EQUAL: 'The absorb and surcharge must be equal to the Fee',
  BSB_MAX_LENGTH: 'BSB must contain only numbers and be 6 characters long',
  SSN_LENGTH: 'Must contain only numbers and be 4 characters long',
  ROUTING_MAX_LENGTH: 'Routing Number must contain only numbers and be 9 characters long',
  ACCOUNT_MAX_LENGTH: 'Account Number must contain only numbers and maximum 9 characters long',
  US_ACCOUNT_MAX_LENGTH: 'Account Number must contain only numbers and maximum 17 characters long',
  NZ_ACCOUNT_NUMBER: 'Account Number must contain only numbers and be 16 characters long',
  FIRST_CONTACT_EMAIL: 'Email address recipient is required for the first contact',
  OLDER_THAN_13: 'Must be older than 13 years old',
  US_ZIPCODE_LENGTH: 'Must contain only numbers and be 5 characters long',
  ZIPCODE_LENGTH: 'Must contain only numbers and be 4 characters long',
  AT_LEAST_ONE_CONTACT: 'At least one contact must have both options selected',
  FULLNAME_TWO_WORDS: 'Full name must contain at least two words',
  SURCHARGE_GT4_PERC: 'Surcharge cannot exceed 4%',
};

export default props;
