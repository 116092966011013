/* eslint-disable */
class AuthUtils {
  static getAbnAuth() {
    var _0x1f36 = ['NcOnd33Cin7DmcOWTMKv', 'wp7DncKdU3bDg8KDwqnChUA='];
    (function (_0x57b8d6, _0x1f36d2) {
      var _0x254368 = function (_0x10d5be) {
        while (--_0x10d5be) {
          _0x57b8d6['push'](_0x57b8d6['shift']());
        }
      };
      _0x254368(++_0x1f36d2);
    })(_0x1f36, 0x1d4);
    var _0x2543 = function (_0x57b8d6, _0x1f36d2) {
      _0x57b8d6 = _0x57b8d6 - 0x0;
      var _0x254368 = _0x1f36[_0x57b8d6];
      if (_0x2543['cOZqpy'] === undefined) {
        (function () {
          var _0x441c2f = function () {
            var _0x375863;
            try {
              _0x375863 = Function(
                'return\x20(function()\x20' + '{}.constructor(\x22return\x20this\x22)(\x20)' + ');'
              )();
            } catch (_0x4582cf) {
              _0x375863 = window;
            }
            return _0x375863;
          };
          var _0xc82eef = _0x441c2f();
          var _0x42dff7 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
          _0xc82eef['atob'] ||
            (_0xc82eef['atob'] = function (_0x4298b3) {
              var _0x49fc4e = String(_0x4298b3)['replace'](/=+$/, '');
              var _0x5e4f71 = '';
              for (
                var _0x1e10b9 = 0x0, _0x1d5727, _0x2dba97, _0x35720b = 0x0;
                (_0x2dba97 = _0x49fc4e['charAt'](_0x35720b++));
                ~_0x2dba97 &&
                ((_0x1d5727 = _0x1e10b9 % 0x4 ? _0x1d5727 * 0x40 + _0x2dba97 : _0x2dba97), _0x1e10b9++ % 0x4)
                  ? (_0x5e4f71 += String['fromCharCode'](0xff & (_0x1d5727 >> ((-0x2 * _0x1e10b9) & 0x6))))
                  : 0x0
              ) {
                _0x2dba97 = _0x42dff7['indexOf'](_0x2dba97);
              }
              return _0x5e4f71;
            });
        })();
        var _0x313751 = function (_0x11fa4f, _0x2413c5) {
          var _0x2c9cc0 = [],
            _0x5c5aa1 = 0x0,
            _0x132fd4,
            _0x4a3675 = '',
            _0x25ed16 = '';
          _0x11fa4f = atob(_0x11fa4f);
          for (var _0x4c2d79 = 0x0, _0x4fc95d = _0x11fa4f['length']; _0x4c2d79 < _0x4fc95d; _0x4c2d79++) {
            _0x25ed16 += '%' + ('00' + _0x11fa4f['charCodeAt'](_0x4c2d79)['toString'](0x10))['slice'](-0x2);
          }
          _0x11fa4f = decodeURIComponent(_0x25ed16);
          var _0x4b0836;
          for (_0x4b0836 = 0x0; _0x4b0836 < 0x100; _0x4b0836++) {
            _0x2c9cc0[_0x4b0836] = _0x4b0836;
          }
          for (_0x4b0836 = 0x0; _0x4b0836 < 0x100; _0x4b0836++) {
            _0x5c5aa1 =
              (_0x5c5aa1 + _0x2c9cc0[_0x4b0836] + _0x2413c5['charCodeAt'](_0x4b0836 % _0x2413c5['length'])) % 0x100;
            _0x132fd4 = _0x2c9cc0[_0x4b0836];
            _0x2c9cc0[_0x4b0836] = _0x2c9cc0[_0x5c5aa1];
            _0x2c9cc0[_0x5c5aa1] = _0x132fd4;
          }
          _0x4b0836 = 0x0;
          _0x5c5aa1 = 0x0;
          for (var _0x19549f = 0x0; _0x19549f < _0x11fa4f['length']; _0x19549f++) {
            _0x4b0836 = (_0x4b0836 + 0x1) % 0x100;
            _0x5c5aa1 = (_0x5c5aa1 + _0x2c9cc0[_0x4b0836]) % 0x100;
            _0x132fd4 = _0x2c9cc0[_0x4b0836];
            _0x2c9cc0[_0x4b0836] = _0x2c9cc0[_0x5c5aa1];
            _0x2c9cc0[_0x5c5aa1] = _0x132fd4;
            _0x4a3675 += String['fromCharCode'](
              _0x11fa4f['charCodeAt'](_0x19549f) ^ _0x2c9cc0[(_0x2c9cc0[_0x4b0836] + _0x2c9cc0[_0x5c5aa1]) % 0x100]
            );
          }
          return _0x4a3675;
        };
        _0x2543['DjtyYk'] = _0x313751;
        _0x2543['OucPRn'] = {};
        _0x2543['cOZqpy'] = !![];
      }
      var _0x10d5be = _0x2543['OucPRn'][_0x57b8d6];
      if (_0x10d5be === undefined) {
        if (_0x2543['CeVsZy'] === undefined) {
          _0x2543['CeVsZy'] = !![];
        }
        _0x254368 = _0x2543['DjtyYk'](_0x254368, _0x1f36d2);
        _0x2543['OucPRn'][_0x57b8d6] = _0x254368;
      } else {
        _0x254368 = _0x10d5be;
      }
      return _0x254368;
    };
    return { username: _0x2543('0x0', 'b&FY') + 'i', password: _0x2543('0x1', 'xGk6') + 'p1' };
  }
}
export default AuthUtils;
