import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import MainStore from '../../stores/MainStore';
import { ApplicationType } from '../../domains/TypeEnum';

const ApplicationTypeSelector = observer((props:Record<string, any>) => {
    
    const mainStore = useContext(MainStore);
    const defaultLabel = props.label || "Application Type *"
    const defaultErrorLabel = props.errorLabel || "Please select an application type"

    const [error, setError] = useState(false);
    const [label, setLabel] = useState(defaultLabel);

    useEffect(() => {
        setError(false);
        setLabel(defaultLabel);
        if(mainStore.customApplication.error.type) {
            setLabel(defaultErrorLabel);
            setError(true);
        }
      },[mainStore.customApplication.error.type, mainStore.customApplication.error.customerType, defaultErrorLabel, defaultLabel]);

    const onChange = (e:any) => {
        setError(false);
        setLabel(defaultLabel);
        if(e.target.value === '') {
            setLabel(defaultErrorLabel);
            setError(true);
            mainStore.updateCustomApplication({customerType: ''});
        }
        mainStore.cleanCompanyInfoForResidential();
        mainStore.updateCustomApplication((e.target.value === ApplicationType.creditApplication) ? {type: e.target.value, customerType: ""} : {type: e.target.value});
    }

    return <div className="flex flex-wrap">
        <div className="w-1/4 sm:w-1/2 xs:w-full" style={{marginBottom: '20px'}}>
            <label className="block mx-4">
                <span className={`${error ? 'text-red-700' : 'text-gray-500'} block font-medium text-base`}>{label}</span>
                <select onChange={(e:any) => onChange(e)}
                    className="form-control rounded-md border-gray-300 width:auto shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mt-1"
                    id="select_application_type"
                    style={{ WebkitAppearance: 'none', appearance: 'none' }}
                    value={mainStore.customApplication.type}
                >
                    <option value=""></option>
                    { props.options.map( (e:Record<string, any>, i:number) => <option key={i} value={e.value}>{e.label}</option>) }
                </select>
            </label>
        </div>
    </div>
});

export default ApplicationTypeSelector;