import Form from '@rjsf/core';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import MainTemplate from './MainTemplate';
import validate from './Utils/JsonSchemaCustomValidations';
import customFormats from './Utils/JsonSchemeCustomFormats';
import transformErrors from './Utils/JsonSchemeTransformErrors';
import NewZealandValidation from './Utils/NewZealandValidation';
import PrintedFormUtils from './Utils/PrintedFormUtils';
import { customWidgets } from './components/CustomWidgets';
import DoneStep from './components/DoneStep';
import DraftModalBody from './components/DraftModal/DraftModalBody';
import DraftModalFooter from './components/DraftModal/DraftModalFooter';
import DraftModalTitle from './components/DraftModal/DraftModalTitle';
import ExitAlert from './components/ExitAlert';
import ExpiredLink from './components/ExpiredLink';
import FeeStructureForm from './components/FeesStructureForm';
import FormTitle from './components/FormTitle';
import IntroductionText from './components/IntroductionText';
import Modal from './components/Modal';
import { OTPForm } from './components/OTPForm';
import PrintedForm from './components/PrintForm/PrintedForm';
import ArrayFieldTemplate from './customRenderes/ArrayFieldsTemplate';
import columnsObjectFieldTemplate from './customRenderes/ColumnsObjectFieldTemplate';
import MainStore from './stores/MainStore';
import RenderLoading from './components/RenderLoading';
import CustomCompanyInfoForm from './components/CustomCompanyInfoForm/CustomCompanyInfoForm';
import AlertBox from './components/CreditCard/AlertBox';
import properties from './constants/properties';
import parse from 'html-react-parser';
import LaunchDarklyStore from './stores/LaunchDarklyStore';
import './sentry';

const App = observer(() => {
  const mainStore = useContext(MainStore);
  const launchDarklyStore = useContext(LaunchDarklyStore);
  const formRef = useRef();
  const [countryPhoneValidation, setCountryPhoneValidation] = useState();
  const creditApplicationID = getCreditApplicationID(window.location.pathname);
  const applicationType = getApplicationType(window.location.pathname);
  const { organizationId } = mainStore.creditApplicationDomain;

  useEffect(() => {
    launchDarklyStore.initializeLD(organizationId).then(() => {
      const caCustomization = launchDarklyStore.caCustomization.value;

      mainStore.init(creditApplicationID, applicationType, caCustomization);
    });

    if (mainStore.brand.instanceType !== 'EZYCOLLECT_STANDARD') {
      if (window.Intercom) {
        window.Intercom('hide');
        window.Intercom('update', {
          hide_default_launcher: true,
        });
      }
    }
  }, [mainStore, creditApplicationID, applicationType, launchDarklyStore, organizationId]);

  useEffect(() => {
    if (window.analytics) {
      window.analytics.track('Accessed Form Link');
    }

  }, []);

  useEffect(() => {
    setCountryPhoneValidation(mainStore.selectedCountry.value === 'AU' || mainStore.selectedCountry.value === 'US' ? customFormats : NewZealandValidation); //change to switch case when more countries added
  }, [mainStore.selectedCountry.value]);

  useEffect(() => {
    /**
     * Call the toggle on Store
     */
    function toggleBillingCopyAddress() {
      mainStore.setSameBillingAddressData();
    }

    function toggleDeliveryCopyAddress() {
      mainStore.setSameDeliveryAddressData();
    }

    /**
     * Check if has a previous checkbox rendered
     */
    const prevCheckbox = document.querySelector('#ca_same_address');
    if (prevCheckbox) {
      prevCheckbox.removeEventListener('click', toggleBillingCopyAddress);
      prevCheckbox.removeEventListener('click', toggleDeliveryCopyAddress);
      prevCheckbox.parentElement.remove();
    }

    /**
     * Function to create a checkbox and attach the element on the DOM
     */
    function showCopyAddress(type) {
      if (document) {
        const billingAddressContainer = document
          .querySelector(`#ca__${type}Address_streetAddressOne`)
          .closest('fieldset');

        const legendContainer = billingAddressContainer.querySelector('legend');
        const copyAddressElement = document.createElement('span');
        copyAddressElement.id = 'ca_same_address';
        copyAddressElement.className =
          'ml-2 text-xs cursor-pointer bg-blue-600 hover:bg-blue-800 text-white leading-snug font-medium tracking-wider p-2 rounded';

        if (type === 'billing') {
          copyAddressElement.addEventListener('click', toggleBillingCopyAddress);
        } else if ('delivery') {
          copyAddressElement.addEventListener('click', toggleDeliveryCopyAddress);
        }
        copyAddressElement.insertAdjacentText('beforeend', 'Copy from above address');

        legendContainer.appendChild(copyAddressElement);
      }
    }

    /**
     * Show copy option only if the step is the address
     */
    if (mainStore.isBillingAdrressStep) {
      showCopyAddress('billing');
    }

    if (mainStore.isDeliveryAddressStep) {
      showCopyAddress('delivery');
    }
  }, [mainStore, mainStore.isBillingAdrressStep, mainStore.isAddressessTheSame, mainStore.isDeliveryAddressStep]);

  const setCreditCardData = (data) => {
    if(formRef.current) {
      formRef.current.onChange({creditCardValidation: data});
    }
  };

  const next = (e) => {
    mainStore._trackEvent('Next Clicked', {
      currentStep: mainStore.currentStep,
    });

    mainStore.setCreditCardData = setCreditCardData;

    if (mainStore.isFeeStructureStep()) {
      mainStore.saveFeeStructure();
      return;
    }

    mainStore.updateLiveValidateState(false);

    if (mainStore.currentStep === 0) {
      mainStore.next();
    } else {
      const formData = mainStore.getFormData();
      const validation = formRef.current.validate(formData);

      mainStore.directorsUploadError(validation);
      formRef.current.onSubmit(e);

      if (validation.errors.length === 0) {
        mainStore.next(validation);
      }
    }
  };

  const prev = () => {
    mainStore._trackEvent('Back Clicked', {
      currentStep: mainStore.currentStep,
    });

    mainStore.updateLiveValidateState(false);

    if (mainStore.isConfirmationStep()) {
      mainStore.prev();
    } else {
      const formData = mainStore.getFormData();
      const validation = formRef.current.validate(formData);
      mainStore.prev(validation);
    }
  };

  const confirm = () => {
    mainStore.openConfirmModal();
  };

  const toggleModal = (origin) => {
    mainStore.toggleModal(origin);
  };

  const getTargetDate = (minutesExpiry) => {
    const targetDate = new Date();
    targetDate.setMinutes(targetDate.getMinutes() + minutesExpiry);

    return targetDate;
  };

  const renderApp = () =>
    mainStore.agreed ? (
      <MainTemplate
        wizards={mainStore.wizards}
        currentStep={mainStore.currentStep}
        brand={mainStore.brand}
        aggred={true} //agreed no longer relevent due to new implementation of terms and conditions
        isConfirmed={mainStore.isConfirmed}
      >
        <div className={`flex-1 overflow-auto ${mainStore.isFeeStructureStep() ? 'hidden' : ''}`}>
          {mainStore.currentStep === 0 && <CustomCompanyInfoForm mainStore={mainStore} />}
          {mainStore.currentStep > 0 && mainStore.currentStep < mainStore.wizards.length && (
            <React.Fragment>
              <div className="pt-10 pl-10 pr-10">
                {mainStore.showTitle() && <FormTitle title={mainStore.getCurrentStepTitle()} />}
              </div>
              {mainStore.isConfirmationStep() && !mainStore.isConfirmed ? (
                <PrintedForm
                  feeStructure={
                    mainStore.applicationType === 'paymentApplication' ? mainStore.feeStructureDomain : null
                  }
                  creditApplication={mainStore.creditApplicationDomain}
                  referenceOne={PrintedFormUtils.getFieldValueByKey(toJS(mainStore.formDataList), 'referenceOne')}
                  referenceTwo={PrintedFormUtils.getFieldValueByKey(toJS(mainStore.formDataList), 'referenceTwo')}
                  referenceThree={PrintedFormUtils.getFieldValueByKey(toJS(mainStore.formDataList), 'referenceThree')}
                  idVerification={PrintedFormUtils.getFieldValueByKey(
                    toJS(mainStore.formDataList),
                    'file_idVerification'
                  )}
                  directorsGuarantee={PrintedFormUtils.getFieldValueByKey(
                    toJS(mainStore.formDataList),
                    'file_directorsGuarantee'
                  )}
                  currencyCertificate={
                    PrintedFormUtils.getFieldValueByKey(
                      toJS(mainStore.formDataList),
                      'file_currencyVerification'
                    )
                  }
                  proofAddress={PrintedFormUtils.getFieldValueByKey(
                    toJS(mainStore.formDataList),
                    'file_proofAddress'
                  )}
                  taxId={PrintedFormUtils.getFieldValueByKey(
                    toJS(mainStore.formDataList),
                    'file_taxId'
                  )}
                  title="Please check, review your data and click the CONFIRM button once completed to submit."
                />
              ) : (
                <>
                  { mainStore.customApplication.isPaymentsStep && <AlertBox /> }
                  <Form
                    key={mainStore.getKey()}
                    schema={mainStore.getCurrentJsonSchema()}
                    uiSchema={mainStore.getCurrentUiSchema()}
                    onChange={(data) => mainStore.setData(data)}
                    showErrorList={false}
                    formData={mainStore.getFormData()}
                    formContext={mainStore.getContext()}
                    ObjectFieldTemplate={columnsObjectFieldTemplate}
                    ArrayFieldTemplate={ArrayFieldTemplate}
                    noHtml5Validate
                    customFormats={countryPhoneValidation}
                    transformErrors={(errors) => transformErrors(errors, mainStore.getContext())}
                    children={true}
                    className="px-10"
                    idPrefix="ca_"
                    ref={formRef}
                    validate={(formData, errors) => validate(formData, errors, mainStore.getContext())}
                    liveValidate={mainStore.liveValidateState}
                    widgets={customWidgets}
                  />
                </>
              )}
            </React.Fragment>
          )}
          {mainStore.isConfirmed && <DoneStep />}
        </div>

        {mainStore.isFeeStructureStep() && <FeeStructureForm applicationID={creditApplicationID} />}
        {!mainStore.isConfirmed && (
          <div className="bg-gray-70 py-4 px-6 text-right">
            {mainStore.currentStep !== 0 && !mainStore.isConfirmationStep() && (
              <button
                onClick={() => {
                  mainStore.setDraftSaved(false);
                  toggleModal('isSaveDraftModalVisible');
                }}
                className="float-left bg-white hover:bg-gray-200 text-gray-700  font-medium tracking-wider py-2 px-12 rounded mr-4 shadow disabled:cursor-not-allowed disabled:opacity-75"
                disabled={mainStore.isSaving}
              >
                SAVE DRAFT
              </button>
            )}

            {mainStore.currentStep !== 0 && (
              <button
                onClick={prev}
                className="bg-white hover:bg-gray-200 text-gray-700  font-medium tracking-wider py-2 px-12 rounded mr-4 shadow disabled:cursor-not-allowed disabled:opacity-75"
                disabled={mainStore.isSaving}
              >
                BACK
              </button>
            )}

            <button
              onClick={mainStore.isConfirmationStep() ? confirm : next}
              className="bg-blue-600 hover:bg-blue-800 text-white leading-snug font-medium tracking-wider py-2 px-12 rounded shadow disabled:cursor-not-allowed disabled:opacity-75"
              disabled={mainStore.isSaving}
            >
              {mainStore.isConfirmationStep() ? 'CONFIRM' : 'NEXT'}
            </button>

            <ExitAlert />
          </div>
        )}
        <Modal
          customStyle={{ minHeight: '320px' }}
          isVisible={mainStore.isSaveDraftModalVisible}
          title={<DraftModalTitle />}

          onClose={() => toggleModal('isSaveDraftModalVisible')}
          customFooter={<DraftModalFooter />}
        >
          <DraftModalBody></DraftModalBody>
        </Modal>
      </MainTemplate>
    ) : mainStore.applicationType === 'paymentApplication' && !mainStore.isOTPConfirmed ? (
      <MainTemplate brand={mainStore.brand}>
        <OTPForm
          email={mainStore.otpEmail}
          errorMessage={mainStore.otpErrorMessage}
          onSubmit={(code) => mainStore.verifyOtpCode(code)}
          targetDate={getTargetDate(1)}
          refreshOtp={() => {
            mainStore.otpErrorMessage = '';
            mainStore.getOtpToken();
          }}
        />
      </MainTemplate>
    ) : (
      <MainTemplate brand={mainStore.brand}>
        <IntroductionText
          brand={mainStore.brand}
          agree={() => mainStore.agree()}
          applicationType={mainStore.applicationType}
          organizationId={mainStore.creditApplicationDomain.organizationId}
        />
        <Modal
          isVisible={mainStore.isTermsModalVisible}
          title="Terms & Conditions"
          onClose={() => toggleModal('isTermsModalVisible')}
        >
          <div className="prose-sm px-8 text-gray-700">
            {
              launchDarklyStore.flags.caTextEditorFlag.value
              ? parse(mainStore.brand.terms)
              :
              (
                mainStore.brand.terms.split('\n').map((term, i) => (
                  <p key={i} className="px-8 text-gray-700">
                    {term}
                  </p>
                ))
              )
            }
          </div>
        </Modal>
        <Modal
          isVisible={mainStore.isPrivacyModalVisible}
          title="Privacy Policy"
          onClose={() => toggleModal('isPrivacyModalVisible')}
        >
          <div className="px-8 prose-sm text-gray-700">
          {
              properties.BROOKS_HIRE_ORGS.includes(organizationId) 
              ? parse(mainStore.brand.privacyPolicy)
              :
              (
                mainStore.brand.privacyPolicy.split('\n').map((privacy, i) => (
                  <p key={i}>{privacy}</p>
                ))
              )
          }
          </div>
        </Modal>
      </MainTemplate>
    );

  return mainStore.loading ? (
    <div className="h-screen w-screen absolute bg-gray-100 overflow-hidden">
      <RenderLoading />
    </div>
  ) : mainStore.isLinkExpired ? (
    <MainTemplate
      wizards={0}
      currentStep={mainStore.currentStep}
      brand={mainStore.brand}
      aggred={true} //agreed no longer relevant due to new implementation of terms and conditions
      isConfirmed={mainStore.isConfirmed}
    >
      <ExpiredLink />
    </MainTemplate>
  ) : (
    renderApp()
  );
});

const getCreditApplicationID = (pathname = '') => {
  const caID =
    pathname.length > 0 ? pathname.substring(pathname.lastIndexOf('/'), pathname.length).replace('/', '') : null;
  return caID !== 'credit-application' && caID !== 'payment-application' ? caID : null;
};

const getApplicationType = (pathname = '') => {
  return pathname.includes('payment-application') ? 'paymentApplication' : 'creditApplication';
};

export default App;
