import { format, isFuture, parse, parseISO, parseJSON } from 'date-fns';
import { action, makeObservable, observable } from 'mobx';
import DateUtils from '../Utils/DateUtils';
import customFormats from '../Utils/JsonSchemeCustomFormats';
import ValidationUtils from '../Utils/ValidationUtils';
import BUSINESS_IDENTIFICATION_TYPE_OPTIONS from '../constants/businessIdentificationTypeOptions';
import BUSINESS_TYPE_OPTIONS from '../constants/businessTypeOptions';
import ErrorMessages from '../constants/errors';
import Formatters from '../constants/formatters';
import INDUSTRY_GROUP_LIST from '../constants/industryOptions';
import properties from '../constants/properties';

class CompanyInfoDomain {
  currency = '';
  country = 'AU';
  businessNumber = '';
  companyNumber = '';
  tradingName = '';
  businessType = '';
  natureOfBusiness = '';
  registeredCompanyName = '';
  dateOfIncorporation = '';
  numberOfEmployees = '';
  creditLimitRequested = '';
  creditPeriod = '';
  trusteeFullName = '';
  trusteeDateOfBirth = '';
  trusteeDocument = '';
  trusteeBusinessNumber = '';
  trusteeCompanyNumber = '';
  trusteeBusinessType = '';
  trusteeTradingName = '';
  isTrusteeCompany = false;
  isTrusteeWithoutBusinessNumber = false;
  acceptedClientTerms = true;
  webFormId = null;
  organizationId = null;
  email = '';
  phoneNumber = '';
  fullName = '';
  isCustomerApplication = false;
  isCustomerCreditApplication = false;
  isIndividualCreditApplication = false;
  isBusinessApplication = false;

  //payment
  applicationType = 'creditApplication';
  industryOption = '';
  website = '';
  transactionLimitRequest = '';
  estimatedMonthlyValue = '';
  averageSingleTxAmount = '';
  highestSingleTxAmount = '';
  arrears = false;
  advance = false;
  daysInAdvance = '';
  trusteeDocumentBase64 = null;
  ein = '';
  businessIdentificationType = '';

  constructor() {
    makeObservable(this, {
      businessNumber: observable,
      companyNumber: observable,
      currency: observable,
      country: observable,
      tradingName: observable,
      businessType: observable,
      natureOfBusiness: observable,
      registeredCompanyName: observable,
      dateOfIncorporation: observable,
      numberOfEmployees: observable,
      creditLimitRequested: observable,
      creditPeriod: observable,
      acceptedClientTerms: observable,
      trusteeFullName: observable,
      trusteeDateOfBirth: observable,
      trusteeDocument: observable,
      trusteeBusinessNumber: observable,
      trusteeCompanyNumber: observable,
      trusteeBusinessType: observable,
      trusteeTradingName: observable,
      isTrusteeWithoutBusinessNumber: observable,
      isTrusteeCompany: observable,
      applicationType: observable,
      industryOption: observable,
      website: observable,
      transactionLimitRequest: observable,
      estimatedMonthlyValue: observable,
      averageSingleTxAmount: observable,
      highestSingleTxAmount: observable,
      arrears: observable,
      advance: observable,
      daysInAdvance: observable,
      trusteeDocumentBase64: observable,
      ein: observable,
      businessIdentificationType: observable,
      email: observable,
      phoneNumber: observable,
      fullName: observable,
      isCustomerCreditApplication: observable,
      isCustomerApplication: observable,
      isIndividualCreditApplication: observable,
      isBusinessApplication: observable,
      updateAttr: action,
      fillFromAbnLookup: action,
      fillCompanyInfoFromApi: action,
      updateBusinessTypeState: action,
    });
  }

  updateAttr(path, value) {
    this[path] = value;
  }

  clear() {
    this.currency = '';
    this.businessNumber = '';
    this.companyNumber = '';
    this.tradingName = '';
    this.businessType = '';
    this.natureOfBusiness = '';
    this.registeredCompanyName = '';
    this.dateOfIncorporation = '';
    this.numberOfEmployees = '';
    this.creditPeriod = '';
    this.creditLimitRequested = '';
    this.trusteeFullName = '';
    this.trusteeDateOfBirth = '';
    this.trusteeDocument = '';
    this.trusteeBusinessNumber = '';
    this.trusteeCompanyNumber = '';
    this.trusteeBusinessType = '';
    this.trusteeTradingName = '';
    this.email = '';
    this.phoneNumber = '';
    this.fullName = '';

    if (this.applicationType === 'paymentApplication') {
      this.industryOption = '';
      this.website = '';
      this.transactionLimitRequest = '';
      this.estimatedMonthlyValue = '';
      this.averageSingleTxAmount = '';
      this.highestSingleTxAmount = '';
      this.arrears = false;
      this.advance = false;
      this.daysInAdvance = '';
      this.trusteeDocumentBase64 = null;
      this.ein = null;
      this.businessIdentificationType = null;
    }
  }

  fillFromAbnLookup(businessInfo, config) {
    this.businessType = businessInfo.entityTypeDescription;
    this.registeredCompanyName = businessInfo.entityName;
    this.dateOfIncorporation = format(parseJSON(businessInfo.registrationDate), Formatters.DATE_FORMATTER);
    this.isTrusteeCompany = businessInfo.entityTypeDescription
      ? businessInfo.entityTypeDescription.toLowerCase().includes('trust')
      : false;
  }

  fillTrusteeInfoFromAbnLookup(abnInfo) {
    this.trusteeTradingName = abnInfo.entityName;
    this.trusteeBusinessType = abnInfo.entityTypeDescription;
  }

  validate(isCustomApplication = false, caCustomization = {}, memberType = '') {
    const validationResult = { hasError: false, errors: [] };

    let requiredFields = [
      this.getCreditLimitField(),
    ].concat(this.getTrusteeRequiredFields(caCustomization));

    if(caCustomization.businessType.required && caCustomization.businessType.visible) {
      requiredFields.push('businessType');
    }

    if(caCustomization.tradingName.required && caCustomization.tradingName.visible) {
      requiredFields.push('tradingName');
    }

    if(caCustomization.companyNumber.required) {
      requiredFields.push('companyNumber');
    }

    if(caCustomization.registeredCompanyName.required) {
      requiredFields.push('registeredCompanyName');
    }

    if(caCustomization.dateOfIncorporation.required) {
      requiredFields.push('dateOfIncorporation');
    }

    if (this.applicationType === 'creditApplication') {
      if(caCustomization.natureOfBusiness.required && caCustomization.natureOfBusiness.visible) {
        requiredFields.push('natureOfBusiness');
      }

      if (caCustomization.numberOfEmployees.required && caCustomization.numberOfEmployees.visible) {
        requiredFields.push('numberOfEmployees');
      }
    } else {
      requiredFields.push('transactionLimitRequest');
      requiredFields.push('estimatedMonthlyValue');
      requiredFields.push('averageSingleTxAmount');
      requiredFields.push('highestSingleTxAmount');
      requiredFields.push('industryOption');
      if (![...properties.US_ONBOARDING_ORGS, ...properties.NZ_ONBOARDING_ORGS].includes(this.organizationId)) {
        requiredFields.push('companyNumber');
      }

      if ((this.website && !this.website.match(customFormats.website)) || this.website === '') {
        validationResult.hasError = true;
        validationResult.errors.push({ fieldError: 'website', message: ErrorMessages.INVALID_WEBSITE });
      }

      if (!this.advance && !this.arrears) {
        validationResult.hasError = true;
        validationResult.errors.push({ fieldError: 'arrearsOrAdvance', message: ErrorMessages.AT_LEAST_ONE });
      }

      if (this.advance) {
        requiredFields.push('daysInAdvance');
      }
    }

    // Oma Group Customization
    if(this.creditPeriod && this.isIndividualCreditApplication && (parseInt(this.creditPeriod) <= 0 || parseInt(this.creditPeriod) > 4)) {
      if(parseInt(this.creditPeriod) <= 0 ) {
        validationResult.errors.push({ fieldError: 'creditPeriod', message: ErrorMessages.NO_ZERO });
      }
      validationResult.errors.push({ fieldError: 'creditPeriod', message: ErrorMessages.MAX_CREDIT_PERIOD_INDIVIDUAL });
      validationResult.hasError = true;
    } 

    if(this.creditPeriod && !this.isIndividualCreditApplication && (parseInt(this.creditPeriod) <= 0 || parseInt(this.creditPeriod) > 3)) {
      if(parseInt(this.creditPeriod) <= 0 ) {
        validationResult.errors.push({ fieldError: 'creditPeriod', message: ErrorMessages.NO_ZERO });
      }
      validationResult.errors.push({ fieldError: 'creditPeriod', message: ErrorMessages.MAX_CREDIT_PERIOD_COMPANY });
      validationResult.hasError = true;
    }

    // Validate phoneNumber
    if( this.phoneNumber && !this.phoneNumber.match(customFormats["onlyNumbers"])) {
      validationResult.hasError = true;
      validationResult.errors.push({ fieldError: 'phoneNumber', message: ErrorMessages.INVALID_PHONE });
    }

    // Validate Email
    if( this.email && !this.email.match(customFormats['ez-mail'])) {
      validationResult.hasError = true;
      validationResult.errors.push({ fieldError: 'email', message: ErrorMessages.INVALID_EMAIL });
    }

    if (properties.US_ONBOARDING_ORGS.includes(this.organizationId)) {
      requiredFields.push('ein');
      requiredFields.push('businessIdentificationType');
    } else if(!properties.SSAA_ORGS.includes(this.organizationId)
      || (properties.SSAA_ORGS.includes(this.organizationId) && memberType === 'existing_member')) {
      requiredFields.push('businessNumber');
    }

    if (properties.SAFESMART_ORG.includes(this.organizationId)) {
      requiredFields.push('companyNumber');
    }

    if (properties.HAA_HAA_ORGS.includes(this.organizationId)) {
      requiredFields = requiredFields.filter((item) => item !== "businessType"
        && item !== "natureOfBusiness"
        && item !== "numberOfEmployees"
        && item !== "registeredCompanyName")
    }

    if(properties.MAYERS_ORGS.includes(this.organizationId)) {
      requiredFields = requiredFields.filter((item) => item !== 'registeredCompanyName' 
        && item !== 'companyNumber'
        && item !== 'numberOfEmployees'
        && item !== 'natureOfBusiness'
      )
    }

    // Oma Group Customization
    if(this.isCustomerApplication) {

      if(this.isIndividualCreditApplication) {
        requiredFields = [];

        if(caCustomization.email?.required) {
          requiredFields.push('email');
        }
    
        if(caCustomization.phoneNumber?.required) {
          requiredFields.push('phoneNumber');
        }
    
        if(caCustomization.fullName?.required) {
          requiredFields.push('fullName');
        }
      }

      if(caCustomization.currency?.required) {
        requiredFields.push('currency');
      }

      if(this.isCustomerCreditApplication || caCustomization.creditPeriod.required) {
        requiredFields.push(this.getCreditLimitField());
        requiredFields.push('creditPeriod');
      }
    }

    if((this.isCustomerCreditApplication) || (caCustomization.creditLimitRequested.required && caCustomization.creditLimitRequested.visible)) {
      requiredFields.push('creditLimitRequested');
    }

    if(properties.RELIABLE_HIRE_ORGS.includes(this.organizationId) && this.isBusinessApplication) {
      requiredFields = requiredFields.filter(item => item !== 'creditLimitRequested');
    }

    if(isCustomApplication) {
      requiredFields = [];
      requiredFields.push(this.getCreditLimitField());
    }

    requiredFields.forEach((field) => {
      if (this[field] === '') {
        validationResult.hasError = true;
        validationResult.errors.push({ fieldError: field, message: ErrorMessages.REQUIRED });
      }
    });

    if (parseInt(this.numberOfEmployees, 10) < 0) {
      validationResult.hasError = true;
      validationResult.errors.push({ fieldError: 'numberOfEmployees', message: ErrorMessages.NO_LT_ZERO });
    }

    if (this.creditLimitRequested && parseFloat(this.creditLimitRequested) < 1) {
      validationResult.hasError = true;
      validationResult.errors.push({ fieldError: 'creditLimitRequested', message: ErrorMessages.NO_ZERO });
    }

    const dateOfIncorporation = this.dateOfIncorporation?.length > 0 ? parseISO(this.dateOfIncorporation) : null;
    const trusteeDateOfBirth = this.trusteeDateOfBirth?.length > 0 ? parseISO(this.trusteeDateOfBirth) : null;

    if (dateOfIncorporation === 'Invalid Date') {
      validationResult.hasError = true;
      validationResult.errors.push({ fieldError: 'dateOfIncorporation', message: ErrorMessages.INVALID_DATE });
    }

    if (trusteeDateOfBirth === 'Invalid Date') {
      validationResult.hasError = true;
      validationResult.errors.push({ fieldError: 'trusteeDateOfBirth', message: ErrorMessages.INVALID_DATE });
    }

    if (isFuture(dateOfIncorporation)) {
      validationResult.hasError = true;
      validationResult.errors.push({ fieldError: 'dateOfIncorporation', message: ErrorMessages.NO_FUTURE });
    }

    if (isFuture(trusteeDateOfBirth)) {
      validationResult.hasError = true;
      validationResult.errors.push({ fieldError: 'trusteeDateOfBirth', message: ErrorMessages.NO_FUTURE });
    }

    if (this.isTrusteeWithoutBusinessNumber && this.trusteeFullName?.length > 0) {
      const fullName = this.trusteeFullName.split(' ')
      if (fullName.some(item => item === '') || fullName.length < 2) {
        validationResult.hasError = true;
        validationResult.errors.push({ fieldError: 'trusteeFullName', message: ErrorMessages.FULLNAME_TWO_WORDS });
      }

    }

    if(!this.isCustomerApplication
      && !properties.RELIABLE_HIRE_ORGS.includes(this.organizationId)
      && caCustomization.businessNumber.validate) {
      if (this.businessNumber && !ValidationUtils.validateBusinessNumber(this.businessNumber, this.country)) {
        validationResult.hasError = true;
        validationResult.errors.push({ fieldError: 'businessNumber', message: ErrorMessages.INVALID_BUSINESS_NUMBER });
      }
    }

    if (this.businessNumber && this.trusteeBusinessNumber && !ValidationUtils.validateBusinessNumberIsUnique(this.businessNumber, this.trusteeBusinessNumber)) {
      validationResult.hasError = true;
      validationResult.errors.push({ fieldError: 'businessNumber', message: ErrorMessages.BUSINESS_NUMBER_DUPLICITY });
      validationResult.errors.push({
        fieldError: 'trusteeBusinessNumber',
        message: ErrorMessages.BUSINESS_NUMBER_DUPLICITY,
      });
    }

    return validationResult;
  }

  getCreditLimitField() {
    if (properties.POWELL_LYONS_ORGS.includes(this.organizationId)
        || properties.ORAFOL_ORGS.includes(this.organizationId)) {
      return 'creditLimitRequested';
    }
  }

  getTrusteeRequiredFields(caCustomization) {
    if (this.isTrusteeCompany) {
      if (this.isTrusteeWithoutBusinessNumber && properties.POPS_GROUP_ORGS.includes(this.organizationId)) {
        return ['trusteeFullName', 'trusteeDateOfBirth'];
      }

      if (!this.isTrusteeWithoutBusinessNumber
        && properties.POPS_GROUP_ORGS.includes(this.organizationId)
        && caCustomization.trusteeCompanyNumber.visible) {
        return ['trusteeCompanyNumber', 'trusteeTradingName'];
      }

      if (this.isTrusteeWithoutBusinessNumber) {
        return ['trusteeFullName', 'trusteeDateOfBirth', 'trusteeDocument'];
      }

      if (properties.HEALTH_CARE_OGRS.includes(this.organizationId)) {
        return ['trusteeCompanyNumber', 'trusteeTradingName'];
      }

      const requiredFields = [];

      if(caCustomization.trusteeCompanyNumber.required) {
        requiredFields.push('trusteeCompanyNumber');
      }

      if(caCustomization.trusteeBusinessType.required) {
        requiredFields.push('trusteeBusinessType');
      }

      if(caCustomization.trusteeTradingName.required) {
        requiredFields.push('trusteeTradingName');
      }

      if(this.isTrusteeWithoutBusinessNumber && caCustomization.trusteeFullName.required) {
        requiredFields.push('trusteeFullName');
      }

      if(this.isTrusteeWithoutBusinessNumber && caCustomization.trusteeDateOfBirth.required) {
        requiredFields.push('trusteeDateOfBirth');
      }

      if(this.isTrusteeWithoutBusinessNumber && caCustomization.trusteeDocument.required) {
        requiredFields.push('trusteeDocument');
      }

      return requiredFields;
    } else if (this.applicationType === 'paymentApplication' &&
      this.country === 'US' &&
      this.businessType?.value === BUSINESS_TYPE_OPTIONS[6]?.value) {
      return ['trusteeDocument64'];
    }

    return [];
  }

  fillCompanyInfoFromApi(data) {
    this.businessNumber = data?.businessNumber;
    this.companyNumber = data?.companyNumber;
    this.tradingName = data?.tradingName;
    this.businessType = data?.businessType;
    this.natureOfBusiness = data?.natureOfBusiness;
    this.registeredCompanyName = data?.registeredCompanyName;
    this.dateOfIncorporation = data?.dateOfIncorporation;
    this.numberOfEmployees = data?.numberOfEmployees;
    this.creditPeriod = data?.creditPeriod;
    this.creditLimitRequested = data?.creditLimitRequested;
    this.trusteeBusinessNumber = data?.trusteeBusinessNumber;
    this.trusteeCompanyNumber = data?.trusteeCompanyNumber;
    this.trusteeBusinessType = data?.trusteeBusinessType;
    this.trusteeTradingName = data?.trusteeTradingName;
    this.trusteeFullName = data?.trusteeFullName;
    this.trusteeDateOfBirth = data?.trusteeDateOfBirth;
    this.trusteeDocument = data?.trusteeDocument;
    this.isTrusteeCompany = data?.isTrusteeCompany;
    this.isTrusteeWithoutBusinessNumber = data?.trusteeFullName.length > 0;
    this.phoneNumber = data?.phoneNumber || "";
    this.creditPeriod = data?.creditPeriod || 0;
    this.fullName = data?.fullName || "";
    this.email = data?.email || "";
    this.currency = data?.currency || "";

    if (this.applicationType === 'paymentApplication') {
      this.industryOption = INDUSTRY_GROUP_LIST.find((industry) => industry.value === data?.industry);
      this.website = data?.website || '';
      this.transactionLimitRequest = data?.transactionLimitRequest || '';
      this.estimatedMonthlyValue = data?.estimatedMonthlyValue || '';
      this.averageSingleTxAmount = data?.averageSingleTxAmount || '';
      this.highestSingleTxAmount = data?.highestSingleTxAmount || '';
      this.arrears = data?.arrears || false;
      this.advance = data?.advance || false;
      this.daysInAdvance = data?.daysInAdvance || '';

      if (properties.US_ONBOARDING_ORGS.includes(this.organizationId)) {
        this.businessType = BUSINESS_TYPE_OPTIONS.find((businessType) => businessType.value === data?.businessType);
        this.ein = data?.ein || null;
        this.businessIdentificationType = BUSINESS_IDENTIFICATION_TYPE_OPTIONS.find(businessIdentificationType => businessIdentificationType.value === data?.businessIdentificationType) || null;
        this.dateOfIncorporation = parse(data?.dateOfIncorporation, 'yyyy-MM-dd', new Date()) || '';
      }
    }
  }

  updateBusinessTypeState(value) {
    this.businessType = value;
  }

  toParams() {
    const paymentFields =
      this.applicationType === 'paymentApplication'
        ? {
          industry: this.industryOption?.value,
          industryDescription: this.industryOption?.label,
          website: this.website,
          transactionLimitRequest: this.transactionLimitRequest,
          estimatedMonthlyValue: this.estimatedMonthlyValue,
          averageSingleTxAmount: this.averageSingleTxAmount,
          highestSingleTxAmount: this.highestSingleTxAmount,
          arrears: this.arrears,
          advance: this.advance,
          daysInAdvance: this.daysInAdvance,
          trusteeDocumentBase64: this.isTrusteeCompany || (this.country === 'US' &&
            this.businessType?.value === BUSINESS_TYPE_OPTIONS[6]?.value) ? this.trusteeDocumentBase64 : '',
          ein: properties.US_ONBOARDING_ORGS.includes(this.organizationId) ? this.ein : null,
          nzbn: properties.NZ_ONBOARDING_ORGS.includes(this.organizationId) ? this.businessNumber : null,
          businessIdentificationType: properties.US_ONBOARDING_ORGS.includes(this.organizationId) ? this.businessIdentificationType.value : null,
        }
        : {};
    return {
      country: this.country,
      abn: this.businessNumber,
      acn: this.companyNumber,
      tradingName: this.tradingName,
      businessType: this.businessType?.value ? this.businessType.value : this.businessType,
      natureOfBusiness: this.natureOfBusiness,
      registeredCompanyName: this.registeredCompanyName,
      dateOfIncorporation: properties.US_ONBOARDING_ORGS.includes(this.organizationId) ? DateUtils.toBackendFormat(this.dateOfIncorporation) : this.dateOfIncorporation,
      numberOfEmployees: this.numberOfEmployees,
      creditPeriod: this.creditPeriod,
      creditLimitRequested: this.creditLimitRequested,
      webFormId: this.webFormId,
      organizationId: this.organizationId,
      acceptedClientTerms: this.acceptedClientTerms,
      trusteeBusinessNumber: this.trusteeBusinessNumber,
      trusteeCompanyNumber: this.trusteeCompanyNumber,
      trusteeBusinessType: this.trusteeBusinessType,
      trusteeTradingName: this.trusteeTradingName,
      trusteeFullName: this.trusteeFullName,
      trusteeDateOfBirth: this.trusteeDateOfBirth,
      trusteeDocument: this.trusteeDocument,
      isTrusteeCompany: this.isTrusteeCompany,
      phoneNumber: this.phoneNumber,
      fullName: this.fullName,
      email: this.email,
      currency: this.currency,
      ...paymentFields,
    };
  }
}

export default CompanyInfoDomain;
