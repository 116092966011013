import React from 'react';
import ResponsiveModal from './Modal/ResponsiveModal';

type TermsAndConditionsModalProps = {
  agreeToTerms: boolean;
  showModal: boolean;
  handleToggleModal: () => void;
  handleChange: (e: any) => void;
  setShowModal: (showModal: boolean) => void;
};

export const TermsAndConditionsModal: React.FC<TermsAndConditionsModalProps> = ({
  showModal,
  handleToggleModal,
  agreeToTerms,
  handleChange,
  setShowModal,
}) => {
  return (
    <ResponsiveModal isOpen={showModal} onClose={handleToggleModal}>
      <section className="terms-modal-container">
        <h4 className="font-medium text-xl p-2 text-center">Direct Debit Terms & Conditions</h4>
        <div className="content">
          <p>
            This Agreement is designed to explain what your main obligations are when undertaking a
            Direct Debit arrangement with ezyCollect and the Business. It also details what our main
            obligations are to you as your Direct Debit Provider.
          </p>

          <p>
            We recommend you keep this agreement in a safe place for future reference. It forms part
            of the terms and conditions of your Direct Debit Request (DDR) and should be read in
            conjunction with your DDR form.
          </p>

          <p>
            I/We hereby authorize EzyCollect to make periodic debits on behalf of the "Business" as
            indicated on the front of this Direct Debit Request (herein referred to as the
            Business).
          </p>

          <p>
            I/We acknowledge that EzyCollect is acting as a Direct Debit Agent for the Business and
            that EzyCollect does not provide any goods or services and has no express or implied
            liability regarding the goods and services provided by the Business or the terms and
            conditions of any agreement with the Business.
          </p>

          <p>
            I/We acknowledge that EzyCollect and the Business will keep any information (including
            account details) contained in the Direct Debit Request confidential. EzyCollect and the
            Business will make reasonable efforts to keep any such information that we have about
            you secure and to ensure that any of our employees or agents who have access to
            information about you do not make any unauthorised use, modification, reproduction or
            disclosure of that information. We will only disclose information that we have about
            you:
          </p>

          <p>
            (a)to the extent specifically required by law; or(b)for the purposes of this agreement
            (including disclosing information in connection with any query or claim).
          </p>

          <p>
            I/We acknowledge that the debit amount will be debited from my/our account according to
            the Direct Debit Request, this Agreement and the terms and conditions of the agreement
            with the Business.
          </p>

          <p>
            I/We acknowledge that bank account details have been verified against a recent bank
            statement to ensure accuracy of the details provided. If uncertain you should contact
            your financial institution.
          </p>

          <p>
            I/We acknowledge that it is my/our responsibility to ensure that there are sufficient
            cleared funds in the nominated account by the due date to enable the direct debit to be
            honoured on the debit date. Direct debits normally occur overnight; however transactions
            can take up to three (3) business days depending on your financial institution.
          </p>

          <p>
            I/We acknowledge and agree that sufficient funds will remain in the nominated account
            until the direct debit amount has been debited from the account and that if there are
            insufficient funds available, I/We agree that EzyCollect will not be held responsible
            for any fees and charges that may be charged by your financial institution.
          </p>

          <p>I/We Acknowledge that there may be a delay in processing if:</p>

          <p>1) There is a public or bank holiday on the day, or any day after the debit date</p>

          <p>
            2) A payment request is received by EzyCollect on a day that is not a Banking Business
            Day
          </p>

          <p>
            3) A payment request is received after normal operational hours. Any payments that fall
            due on any of the above will be processed on the next business day.
          </p>

          <p>
            I/We authorise the Business to vary the amount of the payments from time to time as
            provided for within the Business agreement.
          </p>

          <p>
            I/We authorise EzyCollect to vary the amount of the payments upon instructions from the
            Business.
          </p>

          <p>
            I/We do not require EzyCollect to notify me/us of such variations to the debit amount.
          </p>

          <p>
            I/We acknowledge that the total amount billed will be for the specified period for this
            and/or subsequent agreements and/or amendments.
          </p>

          <p>
            I/We acknowledge that the Business is to provide 14 days' notice if proposing to vary
            the terms of the debit arrangements.
          </p>

          <p>
            I/We acknowledge that variations to the debit arrangement will be directed to the
            Business.
          </p>

          <p>
            I/We acknowledge that any request to stop or cancel the debit arrangement will be
            directed to the Business.
          </p>

          <p>
            I/We acknowledge that any disputed debit payments will be directed to the Business. If
            no resolution is forthcoming, you are advised to contact your financial institution.
          </p>

          <p>
            I/We acknowledge that if a debit is returned by my/our financial institution as unpaid,
            I/We will be responsible for any fees and charges for each unsuccessful debit in
            addition to any financial institution charges and collection fees, including and not
            limited to any solicitor fees and collection agent fees appointed by EzyCollect.
          </p>

          <p>
            I/We authorise EzyCollect to attempt to re-process any unsuccessful payments as advised
            by the Business.
          </p>

          <p>
            More details of the DDR Service Agreement can be found at:
            &nbsp;
            <a
              href="https://ezycollect.io/direct-debit-request-service-agreement/"
              target="_blank"
              rel="noreferrer"
              className="link font-sans"
            >
              https://ezycollect.io/direct-debit-request-service-agreement/
            </a>
            &nbsp;
            as amended from time to time.
          </p>

          <p>ezyCollect Payments Pty Ltd</p>

          <p>ABN 23 658 465 555</p>

          <p>320 Pitt Street</p>

          <p>Sydney NSW 2000 Australia</p>
        </div>
        <div className="footer">
          <div className="checkbox-container">
            <input
              id="agreeToTerms"
              name="agreeToTerms"
              type="checkbox"
              checked={agreeToTerms}
              onChange={handleChange}
            />
            <label htmlFor="agreeToTerms">I have read and I accept this terms & conditions</label>
          </div>
          <div className="eddr-action-container">
            <button
              type="button"
              onClick={() => setShowModal(false)}
              className="bg-white hover:bg-gray-200 text-gray-700  font-medium tracking-wider py-2 px-12 rounded mr-4 shadow disabled:cursor-not-allowed disabled:opacity-75">
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                setShowModal(false);
              }}
              className="bg-blue-600 hover:bg-blue-800 text-white leading-snug font-medium tracking-wider py-2 px-12 rounded shadow disabled:cursor-not-allowed disabled:opacity-75"
              disabled={!agreeToTerms}>
              Confirm
            </button>
          </div>
        </div>
      </section>
    </ResponsiveModal>
  );
};
