import React from 'react';
import styled from 'styled-components';

const colors = {
  black: '#02162C',
  textColor: '#36506B',
};

const PrintFormAreaTitle = styled.div`
  border-bottom: 2px solid ${colors.black};
  font-size: 14px;
  text-transform: uppercase;
  padding: 6px 12px;
  font-weight: bold;
  margin-top: 16px;
`;

const baseLabelValue = ` & div {
  padding: 8px;
  border-bottom: 1px solid ${colors.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  span {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    color: ${colors.textColor};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.black};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & span {
      font-size: 14px;
      font-weight: 600;
      color: ${colors.black};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}`;

const PDFRow = styled.div`
  display: flex;
  flex-direction: row;

  ${baseLabelValue}

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const PFBusinnesDetails = styled.div`
  .company-name {
    flex: 3;
  }
  .abn {
    flex: 1;
    border-left: 1px solid ${colors.black};
  }
  .acn {
    flex: 1;
    border-left: 1px solid ${colors.black};
  }
  .date {
    flex: 1;
    border-left: 1px solid ${colors.black};
  }
  .tranding-name {
    flex: 1;
  }
  .business-type {
    flex: 1;
  }
  .credit-limit {
    flex: 2;
  }
  .credit-period {
    flex: 2;
    border-left: 1px solid ${colors.black};
  }
  .employees {
    flex: 2;
    border-left: 1px solid ${colors.black};
  }
  .website {
    flex: 2;
    border-left: 1px solid ${colors.black};
  }
  .ein {
    flex: 2;
    border-left: 1px solid ${colors.black};
  }
  .business-identification-type {
    flex: 2;
    border-left: 1px solid ${colors.black};
  }
  .country {
    flex: 2;
    border-left: 1px solid ${colors.black};
  }
  .full-name {
    flex: 2;
  }
  .phone-number {
    flex: 2;
    border-left: 1px solid ${colors.black};
  }
  .email {
    flex: 2;
    border-left: 1px solid ${colors.black};
  }
  .currency {
    flex: 1;
    border-right: 1px solid ${colors.black};
  }
  .application-type {
    flex: 1;
  }
  .customer-type {
    flex: 1;
    border-left: 1px solid ${colors.black};
  }

  @media (max-width: 800px) {

    .company-name {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .abn {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .acn {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .date {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .tranding-name {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .business-type {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .credit-limit {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .credit-period {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .employees {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .website {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .ein {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .business-identification-type {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .country {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .full-name {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .phone-number {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .email {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .currency {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .application-type {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
    .customer-type {
      flex: 1;
      border-right: 0px;
      border-left: 0px;
    }
  }
`;

const PFTransactionInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 54px);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  ${baseLabelValue}

  .transaction-limit {
    grid-area: 1 / 1 / 2 / 2;
  }
  .estimated-value {
    grid-area: 1 / 2 / 2 / 3;
    border-left: 1px solid ${colors.black};
  }
  .average-amount {
    grid-area: 1 / 3 / 2 / 4;
    border-left: 1px solid ${colors.black};
  }
  .highest-amount {
    grid-area: 1 / 4 / 2 / 5;
    border-left: 1px solid ${colors.black};
  }
  .arrears-payment {
    grid-area: 2 / 1 / 3 / 2;
  }
  .advance-payment {
    grid-area: 2 / 2 / 3 / 3;
    border-left: 1px solid ${colors.black};
  }
  .days-advance {
    grid-area: 2 / 3 / 3 / 5;
    border-left: 1px solid ${colors.black};
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 54px);

    .transaction-limit {
      grid-area: 1 / 1 / 2 / 5;
    }
    .estimated-value {
      grid-area: 2 / 1 / 3 / 5;
      border-left: 0px;
    }
    .average-amount {
      grid-area: 3 / 1 / 4 / 5;
      border-left: 0px;
    }
    .highest-amount {
      grid-area: 4 / 1 / 5 / 5;
      border-left: 0px;
    }
    .arrears-payment {
      grid-area: 5 / 1 / 6 / 5;
      border-left: 0px;
    }
    .advance-payment {
      grid-area: 6 / 1 / 7 / 5;
      border-left: 0px;
    }
    .days-advance {
      grid-area: 7 / 1 / 8 / 5;
      border-left: 0px;
    }
  }
`;

const PFTrusteeDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 160px 140px 170px;
  grid-template-rows: repeat(1, 54px);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  ${baseLabelValue}

  .full-name {
    grid-area: 1 / 1 / 1 / 5;
  }
  .document {
    grid-area: 1 / 3 / 2 / 5;
    border-left: 1px solid ${colors.black};
  }
  .date {
    grid-area: 1 / 4 / 2 / 5;
    border-left: 1px solid ${colors.black};
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 54px);

    .full-name {
      grid-area: 1 / 1 / 1 / 5;
    }
    .document {
      grid-area: 2 / 1 / 2 / 5;
      border-left: 0px;
    }
    .date {
      grid-area: 3 / 1 / 3 / 5;
      border-left: 0px;
    }
  }
`;

const PFTrusteeCompanyDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 160px 140px 170px;
  grid-template-rows: repeat(2, 54px);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  ${baseLabelValue}

  .tranding-name {
    grid-area: 1 / 1 / 1 / 5;
  }
  .business-type {
    grid-area: 2 / 1 / 2 / 5;
  }
  .abn {
    grid-area: 1 / 3 / 1 / 5;
    border-left: 1px solid ${colors.black};
  }
  .acn {
    grid-area: 1 / 4 / 1 / 5;
    border-left: 1px solid ${colors.black};
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 54px);

    .tranding-name {
      grid-area: 1 / 1 / 1 / 5;
    }
    .abn {
      grid-area: 2 / 1 / 2 / 5;
      border-left: 0px;
    }
    .acn {
      grid-area: 3 / 1 / 3 / 5;
      border-left: 0px;
    }
    .business-type {
      grid-area: 4 / 1 / 4 / 5;
    }
  }
`;

const PFContact = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(2, 220px);
  grid-template-rows: repeat(2, 54px);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: ${(props) => (props.isOdd ? '#F7FAFC' : '#fffff')};

  ${baseLabelValue}

  .contact-name {
    grid-area: 1 / 1 / 2 / 2;
  }
  .position {
    grid-area: 1 / 2 / 2 / 4;
    border-left: 1px solid ${colors.black};
  }
  .email {
    grid-area: 2 / 1 / 3 / 2;
  }
  .phone {
    grid-area: 2 / 2 / 3 / 3;
    border-left: 1px solid ${colors.black};
  }
  .mobile {
    grid-area: 2 / 3 / 3 / 4;
    border-left: 1px solid ${colors.black};
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 54px);

    .contact-name {
      grid-area: 1 / 1 / 2 / 4;
    }
    .position {
      grid-area: 2 / 1 / 3 / 4;
      border-left: 0px;
    }
    .email {
      grid-area: 3 / 1 / 4 / 4;
    }
    .phone {
      grid-area: 4 / 1 / 5 / 4;
      border-left: 0px;
    }
    .mobile {
      grid-area: 5 / 1 / 6 / 4;
      border-left: 0px;
    }
  }
`;

const PFAddressOrDirectors = styled.div`
  display: grid;
  grid-template-columns: 1fr 310px 255px;
  grid-template-rows: repeat(2, 54px);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: ${(props) => (props.isOdd ? '#F7FAFC' : '#fffff')};

  ${baseLabelValue}

  .row1-col1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .row1-col2 {
    grid-area: 1 / 2 / 2 / 3;
    border-left: 1px solid ${colors.black};
  }
  .row1-col3 {
    grid-area: 1 / 3 / 2 / 4;
    border-left: 1px solid ${colors.black};
  }
  .row2-col1 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .row2-col2 {
    grid-area: 2 / 2 / 3 / 3;
    border-left: 1px solid ${colors.black};
  }
  .row2-col3 {
    grid-area: 2 / 3 / 3 / 4;
    border-left: 1px solid ${colors.black};
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 54px);

    .row1-col1 {
      grid-area: 1 / 1 / 2 / 4;
    }
    .row1-col2 {
      grid-area: 2 / 1 / 3 / 4;
      border-left: 0px;
    }
    .row1-col3 {
      grid-area: 3 / 1 / 4 / 4;
      border-left: 0px;
    }
    .row2-col1 {
      grid-area: 4 / 1 / 5 / 4;
    }
    .row2-col2 {
      grid-area: 5 / 1 / 6 / 4;
      border-left: 0px;
    }
    .row2-col3 {
      grid-area: 6 / 1 / 7 / 4;
      border-left: 0px;
    }
  }
`;

const PFReferences = styled.div`
  display: grid;
  grid-template-rows: 54px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: ${(props) => (props.isOdd ? '#F7FAFC' : '#fffff')};

  ${baseLabelValue}

  .col1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .col2 {
    grid-area: 1 / 2 / 2 / 3;
    border-left: 1px solid ${colors.black};
  }
  .col3 {
    grid-area: 1 / 3 / 2 / 4;
    border-left: 1px solid ${colors.black};
  }
  .col4 {
    grid-area: 1 / 4 / 2 / 5;
    border-left: 1px solid ${colors.black};
  }
  .col4-payto {
    grid-area: 1 / 4 / 2 / 5;
    border-left: 0px;
    & > svg {
      height: 30px;
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 54px);

    .col1 {
      grid-area: 1 / 1 / 2 / 5;
    }
    .col2 {
      grid-area: 2 / 1 / 3 / 5;
      border-left: 0px;
    }
    .col3 {
      grid-area: 3 / 1 / 4 / 5;
      border-left: 0px;
    }
    .col4 {
      grid-area: 4 / 1 / 5 / 5;
      border-left: 0px;
    }
  }
`;

const PFDocs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 54px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: ${(props) => (props.isOdd ? '#F7FAFC' : '#fffff')};

  & div {
    padding: 8px;
    border-bottom: 1px solid ${colors.black};

    p {
      font-size: 14px;
      font-weight: 600;
      color: ${colors.black};
      line-height: 37px;
    }
  }
`;

const PFFeeStructure = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 54px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  ${'' /* background-color: ${(props) => (props.isOdd ? '#F7FAFC' : '#fffff')}; */}

  ${baseLabelValue}

  .col1 {
    grid-area: 1 / 1 / 2;
  }
  .col2 {
    grid-area: 1 / 2 / 2;
    border-left: 1px solid ${colors.black};
  }
  .col3 {
    grid-area: 1 / 3 / 2;
    border-left: 1px solid ${colors.black};
  }
  .colfull {
    grid-area: 1 / 1 / 3 / 4;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 54px);

    .col1 {
      grid-area: 1 / 1 / 2;
    }
    .col2 {
      grid-area: 2 / 1 / 3;
      border-left: 0px;
    }
    .col3 {
      grid-area: 3 / 1 / 4;
      border-left: 0px;
    }
    .col4 {
      grid-area: 4 / 1 / 5;
      border-left: 0px;
    }
  }
`;

const DisplayLabelValue = ({ cssClass, label, value }) => {
  return (
    <div className={cssClass}>
      <span>{label}</span>
      <p>{value}</p>
    </div>
  );
};

export {
  PDFRow,
  PrintFormAreaTitle,
  PFBusinnesDetails,
  PFContact,
  PFAddressOrDirectors,
  PFReferences,
  PFDocs,
  DisplayLabelValue,
  PFTrusteeDetails,
  PFTrusteeCompanyDetails,
  PFFeeStructure,
  PFTransactionInfo,
};
