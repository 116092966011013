import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import DateUtils from '../Utils/DateUtils';
import BUSINESS_IDENTIFICATION_TYPE_OPTIONS from '../constants/businessIdentificationTypeOptions';
import BUSINESS_TYPE_OPTIONS from '../constants/businessTypeOptions';
import CountryCodes from '../constants/countryCodes';
import INDUSTRY_GROUP_LIST from '../constants/industryOptions';
import properties from '../constants/properties';
import MainStore from '../stores/MainStore';
import BusinessTypeCustom from "./BusinessTypeCustom";
import CustomDatePicker from './CustomDatePicker';
import FormField from './FormField';
import FormTitle from './FormTitle';
import SvgBase from './SvgBase';
import moment from 'moment';
import { ApplicationType, CustomerType } from '../domains/TypeEnum';
import LaunchDarklyStore from '../stores/LaunchDarklyStore';

const CompanyInfoForm = observer(() => {
  const mainStore = useContext(MainStore);
  const launchDarklyStore = useContext(LaunchDarklyStore);
  const caCustomization = launchDarklyStore.caCustomization.value;

  useEffect(() => {
    if(mainStore.countryOptions.length < 13) {
      mainStore.selectCountry(0);
    } else {
        mainStore.selectCountry(13);
    }

    if(mainStore.isCustomerApplication()) {
      if(mainStore.selectedCountry.value === "AU") {
        mainStore.selectCountry(0);
      }
    }
  }, [mainStore, mainStore.customApplication.type]);

  const fetchBusinessNumber = (_) => {
    mainStore.checkBusinessNumber();
  };

  const onSelectBusinessNumber = (e) => {
    mainStore.selectBusinessNumber(e.target.value);
  };

  const onSelectCountry = (e) => {
    mainStore.selectCountry(e.target.selectedIndex);
  };

  const onSelectIndustryOption = (e) => {
    mainStore.updateCompanyInfo('industryOption', INDUSTRY_GROUP_LIST[e.target.selectedIndex - 1]);
  };

  const onSelectBusinessType = (e) => {
    mainStore.updateCompanyInfo('businessType', BUSINESS_TYPE_OPTIONS[e.target.selectedIndex - 1]);
  };

  const onSelectbusinessIdentificationType = (e) => {
    mainStore.updateCompanyInfo('businessIdentificationType', BUSINESS_IDENTIFICATION_TYPE_OPTIONS[e.target.selectedIndex - 1]);
  };

  const onChange = (e) => {
    const field = e.target ? e.target : e;
    mainStore.updateCompanyInfo(field.name, field.value);
  };

  const onChangeCustomDateOfIncorporation = (date) => {
    mainStore.updateCompanyInfo('dateOfIncorporation', date);
  };

  const onChangeCheckbox = (fieldName) => {
    mainStore.updateCompanyInfo(fieldName, !mainStore.creditApplicationDomain[fieldName]);
  };

  const dateConfig = mainStore.dateConfig;

  const getDateOfIncorporation = () => {
    let dateOfIncorporationFormatted = "";
    if(mainStore.creditApplicationDomain.dateOfIncorporation) {
      dateOfIncorporationFormatted = DateUtils.formatDateTimezone(mainStore.creditApplicationDomain.dateOfIncorporation, dateConfig.dateFormat, null);
      return moment(dateOfIncorporationFormatted,  dateConfig.dateFormat).toDate();
    }
    return dateOfIncorporationFormatted;
  }

  const onChangeTrusteeDateOfBirth = (date, name) => {
    mainStore.updateCompanyInfo(name, moment(date).format("YYYY-MM-DDTHH:mm:ss") + ".00Z");
  };

  const getTrusteeDateOfBirth = () => {
    let dateOfBirthFormatted = "";
    if(mainStore.creditApplicationDomain.trusteeDateOfBirth) {
      dateOfBirthFormatted = DateUtils.formatDateTimezone(mainStore.creditApplicationDomain.trusteeDateOfBirth, dateConfig.dateFormat, null, false);
      return moment(dateOfBirthFormatted,  dateConfig.dateFormat).toDate();
    }
    return dateOfBirthFormatted;
  }

  let fields = {
    businessNumber: {
      name: 'businessNumber',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('businessNumber'),
    },
    companyNumber: {
      name: 'companyNumber',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('companyNumber'),
    },
    tradingName: {
      name: 'tradingName',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('tradingName'),
    },
    registeredCompanyName: {
      name: 'registeredCompanyName',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('registeredCompanyName'),
    },
    businessType: {
      name: 'businessType',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('businessType'),
    },
    natureOfBusiness: {
      name: 'natureOfBusiness',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('natureOfBusiness'),
    },
    dateOfIncorporation: {
      name: 'dateOfIncorporation',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('dateOfIncorporation'),
    },
    numberOfEmployees: {
      name: 'numberOfEmployees',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('numberOfEmployees'),
    },
    creditLimitRequested: {
      name: 'creditLimitRequested',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('creditLimitRequested'),
    },
    trusteeFullName: {
      name: 'trusteeFullName',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('trusteeFullName'),
    },
    trusteeDateOfBirth: {
      name: 'trusteeDateOfBirth',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('trusteeDateOfBirth'),
    },
    trusteeDocument: {
      name: 'trusteeDocument',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('trusteeDocument'),
    },
    trusteeBusinessNumber: {
      name: 'trusteeBusinessNumber',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('trusteeBusinessNumber'),
    },
    trusteeCompanyNumber: {
      name: 'trusteeCompanyNumber',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('trusteeCompanyNumber'),
    },
    trusteeBusinessType: {
      name: 'trusteeBusinessType',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('trusteeBusinessType'),
    },
    trusteeTradingName: {
      name: 'trusteeTradingName',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('trusteeTradingName'),
    },
    industryOption: {
      name: 'industryOption',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('industryOption'),
    },
    website: {
      name: 'website',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('website'),
    },
    transactionLimitRequest: {
      name: 'transactionLimitRequest',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('transactionLimitRequest'),
    },
    estimatedMonthlyValue: {
      name: 'estimatedMonthlyValue',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('estimatedMonthlyValue'),
    },
    averageSingleTxAmount: {
      name: 'averageSingleTxAmount',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('averageSingleTxAmount'),
    },
    highestSingleTxAmount: {
      name: 'highestSingleTxAmount',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('highestSingleTxAmount'),
    },
    daysInAdvance: {
      name: 'daysInAdvance',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('daysInAdvance'),
    },
    arrearsOrAdvance: {
      name: 'arrearsOrAdvance',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('arrearsOrAdvance'),
    },
    trusteeDocument64: {
      name: 'trusteeDocument64',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('trusteeDocument64'),
    },
    ein: {
      name: 'ein',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('ein'),
    },
    businessIdentificationType: {
      name: 'businessIdentificationType',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('businessIdentificationType'),
    },
    currency: {
      name: 'currency',
      validate: mainStore.hasErrorInCompanyInfoFormAttr('currency'),
    },
  };

  if(mainStore.isCreditCustomerIndividual() || mainStore.isCreditCustomerCompany()) {
    fields = {...fields, 
      creditPeriod: {
        name: 'creditPeriod',
        validate: mainStore.hasErrorInCompanyInfoFormAttr('creditPeriod'),
      },
    }
  }

  if(mainStore.isCashCustomerIndividual() || mainStore.isCreditCustomerIndividual()) {
    fields = {...fields, 
      fullName: {
        name: 'fullName',
        validate: mainStore.hasErrorInCompanyInfoFormAttr('fullName'),
      },
      email: {
        name: 'email',
        validate: mainStore.hasErrorInCompanyInfoFormAttr('email'),
      },
      phoneNumber: {
        name: 'phoneNumber',
        validate: mainStore.hasErrorInCompanyInfoFormAttr('phoneNumber'),
      }
    };
  }

  const { organizationId } = mainStore.creditApplicationDomain;

  const isOnboardingNZ = properties.NZ_ONBOARDING_ORGS.includes(organizationId);

  let creditLimitRequested = `Credit Limit Requested ${mainStore.selectedCountry.value === 'AU' ? '(AUD)' : mainStore.selectedCountry.value === 'NZ' ? '(NZD)' : ''}`;
  creditLimitRequested += `${
    (properties.POWELL_LYONS_ORGS.includes(organizationId)
    || properties.ORAFOL_ORGS.includes(organizationId)
    || (caCustomization?.creditLimitRequested?.required && caCustomization?.creditLimitRequested?.visible)) ? " *" : ""
  }`;

  const removeField = (field) => {
    if(caCustomization[field]?.remove) {
      return true;
    }
    return false;
  };

  const hideFields = ()=> {
    const {type} = mainStore.customApplication;
    const {customerType} = mainStore.customApplication;

    if(type === ApplicationType.cashApplication 
      && customerType === CustomerType.residential
    ) {
      return true;
    }

    if(properties.RELIABLE_HIRE_ORGS.includes(organizationId)
      && type === ApplicationType.cashApplication && customerType === CustomerType.individual) {
      return true;
    }

    if(mainStore.isCashCustomerIndividual() || mainStore.isCreditCustomerIndividual()) {
      return true;
    }

    return false;
  }

  const showCreditLimit = ()=> {
    const {type} = mainStore.customApplication;

    if(type === ApplicationType.cashApplication) {
      return false;
    }
    return true;
  }

  const shouldRenderSection = () => {
    let shouldRender = false;
    if(properties.OMA_GROUP.includes(organizationId)) {
      shouldRender = true;
    } else if ((mainStore.isValidBusinessNumber || mainStore.hadAValidBusinessNumber) && !hideFields()) {
      shouldRender = true;
    } else if (!CountryCodes.REGULAR_REGION_CODES.includes(mainStore.creditApplicationDomain.country)) {
      shouldRender = true;
    } else if (!caCustomization.businessNumber.validate) {
      shouldRender = true;
    }

    return shouldRender;
  };

  const handleFileUpload = (file) => {
    if (file) {
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }).then((data) => {
        let splitedData = data.split(';');
        const base64File = splitedData[0].concat(`;name=${file.name};`).concat(splitedData[1]);
        mainStore.updateCompanyInfo('trusteeDocumentBase64', base64File);
      });
    } else {
      mainStore.updateCompanyInfo('trusteeDocumentBase64', null);
    }
  };

  const labels = {
    businessNumber: caCustomization.businessNumber.title,
    companyNumber: caCustomization.companyNumber.title,
    businessType: caCustomization.businessType.title,
    tradingName: caCustomization.tradingName.title,
    natureOfBusiness: caCustomization.natureOfBusiness.title,
    numberOfEmployees: caCustomization.numberOfEmployees.title,
    trusteeTitle: caCustomization.trusteeTitle,
    trusteeBusinessNumber: caCustomization.trusteeBusinessNumber.title,
    trusteeFullName: caCustomization.trusteeFullName.title,
    trusteeDateOfBirth: caCustomization.trusteeDateOfBirth.title,
    trusteeCompanyNumber: caCustomization.trusteeCompanyNumber.title,
    trusteeBusinessType: caCustomization.trusteeBusinessType.title,
    trusteeTradingName: caCustomization.trusteeTradingName.title,
    trusteeDocument: caCustomization.trusteeDocument.title,
    registeredCompanyName: caCustomization.registeredCompanyName.title,
    dateOfIncorporation: caCustomization.dateOfIncorporation.title,
    currency: caCustomization.currency.title,
    creditPeriod: mainStore.isCreditCustomerIndividual() ?
      `${caCustomization.creditPeriod.title} (Weeks allowed to settle invoice from issued date)` :
      `${caCustomization.creditPeriod.title} (Months allowed to settle invoice from issued date)`,
    creditLimitRequested: caCustomization.creditLimitRequested.title,
    phoneNumber: caCustomization.phoneNumber.title,
    email: caCustomization.email.title,
    fullName: caCustomization.fullName.title,
    country: caCustomization.country.title
  };

  if (properties.HAA_HAA_ORGS.includes(organizationId)) {
    labels.businessNumber = "Type a valid ABN (Mandatory)";
    labels.businessType = "Business Type (not Mandatory)";
    labels.tradingName = "Hirer’s Name (Mandatory)";
    labels.natureOfBusiness = "Nature of Business (not Mandatory)";
    labels.numberOfEmployees = "Number of Employees (not Mandatory)";
    labels.companyNumber = "Company Number (not Mandatory)";
    labels.registeredCompanyName = "Registered Company Name (not Mandatory)";
  }

  if(properties.MAYERS_ORGS.includes(organizationId)) {
    labels.businessNumber = "Type a valid Business Number *";
    labels.companyNumber = "Company Number";
    labels.registeredCompanyName = "Registered Company Name";
  }

  if (properties.POPS_GROUP_ORGS.includes(organizationId)) {
    labels.trusteeBusinessType = "Business Type";
    labels.trusteeTradingName = "Company Name *";
    labels.trusteeDocument = "Driver's License or Passport Number";
    labels.numberOfEmployees = "Number of Employees";
  }

  // Todo remove test orgs
  if (properties.HEALTH_CARE_OGRS.includes(organizationId)) {
    labels.trusteeBusinessType = "Business Type";
  }

  let businessTypeClassName = "w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group";
  if (properties.SOUNG_YUEEN_ORGS.includes(organizationId)) {
    businessTypeClassName += " invisible";
  }

  //`Company Number ${mainStore.applicationType === 'paymentApplication' ? '*' : ''}`
  if (mainStore.applicationType === 'paymentApplication' || properties.SAFESMART_ORG.includes(organizationId)) {
    labels.companyNumber += " *";
  }

  const disabledField =
    mainStore.businessNumberOptions.length > 0 &&
    CountryCodes.REGULAR_REGION_CODES.includes(mainStore.creditApplicationDomain.country);

  const member_types = [{
    value: 'existing_member',
    label: 'Renewal Member'
  },
  {
    value: 'new_member',
    label: 'New Member'
  }];

  const changeMemberType = (e) => {
    mainStore.updateMemberType(e.target.value);
  }

  return (
    <>
      <div className="flex flex-wrap" data-has-error={mainStore.companyInfoErrors.hasError}>
        { properties.SSAA_ORGS.includes(organizationId) &&
          <div className="w-1/4 sm:w-1/2 xs:w-full mt-6">
            <label className="block mx-4">
              <span className="text-gray-500 block font-medium text-base">Membership Status</span>
              <select
                className="form-control rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 w-full mt-1"
                id="member-type"
                style={{ WebkitAppearance: 'none', appearance: 'none' }}
                onChange={(e) => changeMemberType(e)}
                value={mainStore.memberType}
              >
                {member_types.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </label>
          </div>
        }

        { (mainStore.isCustomerApplication() || !hideFields()) && caCustomization.country.visible &&
          <div className="w-1/4 sm:w-1/2 xs:w-full mt-6">
            <label className="block mx-4">
              <span className="text-gray-500 block font-medium text-base">{labels.country}</span>
              <select
                className="form-control rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 w-full mt-1"
                id="country"
                style={{ WebkitAppearance: 'none', appearance: 'none' }}
                onChange={(e) => onSelectCountry(e)}
                value={mainStore.selectedCountry.value}
                disabled={(mainStore.customApplication.type !== '') ? false : (mainStore.isSaved || mainStore.isSearchingBusinessNumber)}
              >
                {mainStore.countryOptions.map((country) => (
                  <option key={country.value} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </select>
            </label>
          </div>
        }

        { !hideFields()
          && !properties.US_ONBOARDING_ORGS.includes(organizationId)
          && (properties.SSAA_ORGS.includes(organizationId) && mainStore.memberType === 'existing_member' || !properties.SSAA_ORGS.includes(organizationId))
          && (
          <div className="w-1/4 sm:w-1/2 xs:w-full mt-6 form-group">
            <div className="relative">
              <FormField
                label={labels.businessNumber}
                value={mainStore.creditApplicationDomain.businessNumber}
                placeholder={labels.businessNumber}
                onChange={onChange}
                onBlur={fetchBusinessNumber}
                id="business_number"
                name={fields.businessNumber.name}
                hasError={fields.businessNumber.validate.hasError}
                errorMessage={fields.businessNumber.validate.message}
                type="text"
                disabled={(mainStore.customApplication.type !== '') ? false : (!properties.SSAA_ORGS.includes(organizationId) && (mainStore.isSaved || mainStore.isSearchingBusinessNumber))}
              />
              {mainStore.isSearchingBusinessNumber && (
                <span
                  className="z-10 text-indigo-900 absolute items-center justify-center bg-transparent"
                  style={{ left: '250px', top: '5px' }}
                >
                  <SvgBase cssClass="w-4 mr-2 animate-spin">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm10-5a1 1 0 0 1-1 1h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1zM7 12a1 1 0 0 1-1 1H3a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1zm12.071 7.071a1 1 0 0 1-1.414 0l-2.121-2.121a1 1 0 0 1 1.414-1.414l2.121 2.12a1 1 0 0 1 0 1.415zM8.464 8.464a1 1 0 0 1-1.414 0L4.93 6.344a1 1 0 0 1 1.414-1.415L8.464 7.05a1 1 0 0 1 0 1.414zM4.93 19.071a1 1 0 0 1 0-1.414l2.121-2.121a1 1 0 1 1 1.414 1.414l-2.12 2.121a1 1 0 0 1-1.415 0zM15.536 8.464a1 1 0 0 1 0-1.414l2.12-2.121a1 1 0 0 1 1.415 1.414L16.95 8.464a1 1 0 0 1-1.414 0z" />
                  </SvgBase>
                </span>
              )}
            </div>
          </div>
        )}

        {
            // Fields for Oma group customization - Cash and Credit Customer Application for Individual customer type
            (mainStore.isCashCustomerIndividual() || mainStore.isCreditCustomerIndividual()) &&
          <>
            <div className="w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group">
              <FormField
                label={labels.fullName}
                value={mainStore.creditApplicationDomain.fullName}
                placeholder={labels.fullName}
                onChange={onChange}
                name={fields.fullName.name}
                hasError={fields.fullName.validate.hasError}
                errorMessage={fields.fullName.validate.message}
                type="text"
                id="fullName"
              />
            </div>
            <div className="w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group">
              <FormField
                label={labels.email}
                value={mainStore.creditApplicationDomain.email}
                placeholder={labels.email}
                name={fields.email.name}
                onChange={onChange}
                hasError={fields.email.validate.hasError}
                errorMessage={fields.email.validate.message}
                type="text"
                id="email"
              />
            </div>
            <div className="w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group">
              <FormField
                label={labels.phoneNumber}
                value={mainStore.creditApplicationDomain.phoneNumber}
                placeholder={labels.phoneNumber}
                onChange={onChange}
                name={fields.phoneNumber.name}
                hasError={fields.phoneNumber.validate.hasError}
                errorMessage={fields.phoneNumber.validate.message}
                type="text"
                id="phoneNumber"
              />
            </div>
          </>
        }

        {!hideFields() && shouldRenderSection() && (
          <React.Fragment>
            {!properties.US_ONBOARDING_ORGS.includes(organizationId)
            && !removeField("companyNumber")
            && caCustomization.companyNumber.visible ?
            <div className="w-1/4 sm:w-1/2 xs:w-full mt-6 form-group">
              <FormField
                label={labels.companyNumber}
                value={mainStore.creditApplicationDomain.companyNumber}
                placeholder={labels.companyNumber}
                onChange={onChange}
                name={fields.companyNumber.name}
                hasError={fields.companyNumber.validate.hasError}
                errorMessage={fields.companyNumber.validate.message}
                type="text"
                id="acn"
              />
            </div> : ''}
            {caCustomization.businessType.visible && (
              <div className={businessTypeClassName}>
                {properties.US_ONBOARDING_ORGS.includes(organizationId) ?
                  <label className="block mx-4">
                    <span
                      className={`${fields.businessType.validate.hasError ? 'text-red-700' : 'text-gray-500'
                        } font-medium`}
                    >
                      Business Type *
                    </span>
                    <select
                      className="form-control rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 w-full mt-1"
                      id="businessType"
                      style={{
                        WebkitAppearance: 'none',
                        appearance: 'none',
                        borderColor: fields.businessType.validate.hasError ? '#b91c1c' : '#d1d5db',
                      }}
                      onChange={(e) => onSelectBusinessType(e)}
                      value={mainStore.creditApplicationDomain.businessType?.value}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {BUSINESS_TYPE_OPTIONS.map((businessType) => (
                        <option key={businessType.value} value={businessType.value}>
                          {businessType.label}
                        </option>
                      ))}
                    </select>
                    {fields.businessType.validate.hasError && (
                      <span className="text-red-700 text-sm font-medium">{fields.businessType.validate.message}</span>
                    )}
                  </label>
                  : !caCustomization?.businessType?.remove &&
                  <div className="w-full sm:w-full xs:w-full mt-6 form-group">
                    <FormField
                      label={labels.businessType}
                      value={mainStore.creditApplicationDomain.businessType}
                      placeholder={labels.businessType}
                      name={fields.businessType.name}
                      onChange={onChange}
                      hasError={fields.businessType.validate.hasError}
                      errorMessage={fields.businessType.validate.message}
                      type="text"
                      id="businessType"
                      disabled={disabledField}
                    />
                  </div>
                }
              </div>
            )}
            {properties.SOUNG_YUEEN_ORGS.includes(organizationId) ?
              <div className="w-1/4 sm:w-1/2 xs:w-full mt-6 form-group">
                <BusinessTypeCustom
                  value={mainStore.creditApplicationDomain.businessType}
                  hasError={fields.businessType.validate.hasError}
                  errorMessage={fields.businessType.validate.message} />
              </div> : ""}
            {properties.US_ONBOARDING_ORGS.includes(organizationId) ?
              <>
                <div className="w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group">
                  <label className="block mx-4">
                    <span
                      className={`${fields.businessIdentificationType.validate.hasError ? 'text-red-700' : 'text-gray-500'
                        } font-medium`}
                    >
                      Business Identification Type *
                    </span>
                    <select
                      className="form-control rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 w-full mt-1"
                      id="businessIdentificationType"
                      style={{
                        WebkitAppearance: 'none',
                        appearance: 'none',
                        borderColor: fields.businessIdentificationType.validate.hasError ? '#b91c1c' : '#d1d5db',
                      }}
                      onChange={(e) => onSelectbusinessIdentificationType(e)}
                      value={mainStore.creditApplicationDomain.businessIdentificationType?.value}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {BUSINESS_IDENTIFICATION_TYPE_OPTIONS.map((businessIdentificationType) => (
                        <option key={businessIdentificationType.value} value={businessIdentificationType.value}>
                          {businessIdentificationType.label}
                        </option>
                      ))}
                    </select>
                    {fields.businessIdentificationType.validate.hasError && (
                      <span className="text-red-700 text-sm font-medium">{fields.businessIdentificationType.validate.message}</span>
                    )}
                  </label>
                </div>
                <div className="w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group">
                  <FormField
                    label="Business Identification Number *"
                    value={mainStore.creditApplicationDomain.ein || ''}
                    placeholder="Business Identification Number"
                    onChange={onChange}
                    name={fields.ein.name}
                    hasError={fields.ein.validate.hasError}
                    errorMessage={fields.ein.validate.message}
                    type="text"
                    id="ein"
                  />
                </div>
              </> : ""}
            <div className={`w-1/4 sm:w-1/2 xs:w-full ${ caCustomization?.dateOfIncorporation?.marginTop || "mt-6"} form-group`}>
              {properties.US_ONBOARDING_ORGS.includes(organizationId) ?
                <CustomDatePicker
                  value={mainStore.creditApplicationDomain.dateOfIncorporation}
                  fields={fields}
                  onChange={onChangeCustomDateOfIncorporation}
                  label={labels.dateOfIncorporation}
                  name={fields.dateOfIncorporation.name}
                  placeholder={labels.dateOfIncorporation}
                  dateFormat="MM/dd/yyyy"
                  id="dateOfIncorporation"
                /> :
                <CustomDatePicker
                  value={getDateOfIncorporation()}
                  fields={fields}
                  onChange={onChangeCustomDateOfIncorporation}
                  label={labels.dateOfIncorporation}
                  name={fields.dateOfIncorporation.name}
                  placeholder={labels.dateOfIncorporation}
                  dateFormat={dateConfig.dateFormat.toLowerCase().replaceAll("m", "M") || "dd/MM/yyyy"}
                  id="dateOfIncorporation"
                />}
            </div>
            {caCustomization.registeredCompanyName.visible && (
              <div className="w-1/4 sm:w-1/2 xs:w-full mt-6 form-group">
                {disabledField ? (
                  <label className="block mx-4">
                    <span className="text-gray-500 font-medium text-base">Registered Company Name</span>
                    <select
                      className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 w-full mt-1"
                      name="registeredCompanyName"
                      onChange={onSelectBusinessNumber}
                      id="registeredCompanyName"
                      value={mainStore.businessNumberSelectedIndex}
                    >
                      {mainStore.businessNumberOptions.map((businessNumber, i) => (
                        <option key={businessNumber.entityName} value={i}>
                          {businessNumber.entityName}
                        </option>
                      ))}
                    </select>
                  </label>
                ) : (
                  <FormField
                    label={labels.registeredCompanyName}
                    value={mainStore.creditApplicationDomain.registeredCompanyName}
                    placeholder={labels.registeredCompanyName}
                    name={fields.registeredCompanyName.name}
                    onChange={onChange}
                    hasError={fields.registeredCompanyName.validate.hasError}
                    errorMessage={fields.registeredCompanyName.validate.message}
                    type="text"
                    id="registeredCompanyName"
                    disabled={disabledField}
                  />
                )}
              </div>
            )}
            <div className="w-1/4 sm:w-1/2 xs:w-full mt-6 form-group">
              {caCustomization.tradingName.visible && (
                <FormField
                  label={labels.tradingName}
                  value={mainStore.creditApplicationDomain.tradingName}
                  placeholder={labels.tradingName}
                  name={fields.tradingName.name}
                  onChange={onChange}
                  hasError={fields.tradingName.validate.hasError}
                  errorMessage={fields.tradingName.validate.message}
                  type="text"
                  id="tradingName"
                />
              )}
            </div>

            {mainStore.applicationType === 'paymentApplication' && (
              <>
                <div className="w-1/4 sm:w-1/2 xs:w-full mt-6">
                  <label className="block mx-4">
                    <span
                      className={`${fields.industryOption.validate.hasError ? 'text-red-700' : 'text-gray-500'
                        } font-medium`}
                    >
                      Industry *
                    </span>
                    <select
                      className="form-control rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 w-full mt-1"
                      id="industry"
                      style={{
                        WebkitAppearance: 'none',
                        appearance: 'none',
                        borderColor: fields.industryOption.validate.hasError ? '#b91c1c' : '#d1d5db',
                      }}
                      onChange={(e) => onSelectIndustryOption(e)}
                      value={mainStore.creditApplicationDomain.industryOption?.value}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {INDUSTRY_GROUP_LIST.map((industry) => (
                        <option key={industry.value} value={industry.value}>
                          {industry.label}
                        </option>
                      ))}
                    </select>
                    {fields.industryOption.validate.hasError && (
                      <span className="text-red-700 text-sm font-medium">{fields.industryOption.validate.message}</span>
                    )}
                  </label>
                </div>
                <div className="w-1/2 xs:w-full mt-6 form-group">
                  <FormField
                    label="Website *"
                    value={mainStore.creditApplicationDomain.website}
                    placeholder="e.g www.yourcompanydomain.com"
                    name={fields.website.name}
                    onChange={onChange}
                    hasError={fields.website.validate.hasError}
                    errorMessage={fields.website.validate.message}
                    type="text"
                    id="website"
                  />
                </div>
              </>
            )}

            {mainStore.applicationType === 'creditApplication' && (
              <>
                { (!removeField("natureOfBusiness")
                    && caCustomization.natureOfBusiness.visible)
                  && (<div className="w-1/4 sm:w-1/2 xs:w-full mt-6 form-group">
                  <FormField
                    label={labels.natureOfBusiness}
                    value={mainStore.creditApplicationDomain.natureOfBusiness}
                    placeholder={labels.natureOfBusiness}
                    name={fields.natureOfBusiness.name}
                    onChange={onChange}
                    hasError={fields.natureOfBusiness.validate.hasError}
                    errorMessage={fields.natureOfBusiness.validate.message}
                    type="text"
                    id="natureOfBusiness"
                  />
                </div>)}

                { (!removeField("numberOfEmployees")
                    && caCustomization.numberOfEmployees.visible)
                  && (<div className="w-1/4 sm:w-1/2 xs:w-full mt-6 form-group">
                  <FormField
                    label={labels.numberOfEmployees}
                    value={mainStore.creditApplicationDomain.numberOfEmployees}
                    placeholder={labels.numberOfEmployees}
                    name={fields.numberOfEmployees.name}
                    onChange={onChange}
                    hasError={fields.numberOfEmployees.validate.hasError}
                    errorMessage={fields.numberOfEmployees.validate.message}
                    type="number"
                    id="numberOfEmployees"
                  />
                </div>)}
              </>
            )}
          </React.Fragment>
        )}
      </div>

      { (mainStore.isCustomerApplication()) &&
            <div className="w-1/4 sm:w-1/2 xs:w-full mt-6 form-group">
              <label className="block mx-4">
                {
                  fields.currency?.validate?.hasError
                    ? <span className="text-red-700 font-medium">{labels.currency}</span>
                    : <span className="text-gray-500 block font-medium text-base">{labels.currency}</span>
                }
                <select
                  className="form-control rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 w-full mt-1"
                  id="currency"
                  name="currency"
                  style={{ WebkitAppearance: 'none', appearance: 'none' }}
                  onChange={(e) => mainStore.selectCurrency(e.target.value) }
                  value={mainStore.selectedCurrency}
                >
                  {mainStore.currencyOptions.map((currency) => (
                    <option key={currency.value} value={currency.value}>
                      {currency.label}
                    </option>
                  ))}
                </select>
                {fields.currency?.validate?.hasError && (
                  <span className="text-red-700 text-sm font-medium">{fields.currency?.validate?.message}</span>
                )}
              </label>
            </div>}

      {!hideFields() && shouldRenderSection() && mainStore.creditApplicationDomain.isTrusteeCompany && (
        <React.Fragment>
          <FormTitle title={labels.trusteeTitle} margin="mt-10 mb-8" />
          <div className="flex flex-wrap">
            <div className="w-1/2 xs:w-full">
              <label className="block mx-4 mb-6">
                <div
                  className={classNames('trustee-checkbox-container', {
                    active: mainStore.creditApplicationDomain.isTrusteeWithoutBusinessNumber,
                    disabled: mainStore.isSaved,
                  })}
                >
                  <input
                    type="checkbox"
                    className="mr-1"
                    name="checkbox"
                    value="1"
                    onChange={() => mainStore.toggleCompanyHasBusinessNumber()}
                    checked={mainStore.creditApplicationDomain.isTrusteeWithoutBusinessNumber}
                    disabled={mainStore.isSaved}
                  />
                  <span>Trustee does not have a Business Number or a Company Number</span>
                </div>
              </label>
            </div>
          </div>
          <div className="flex flex-wrap">
            {mainStore.creditApplicationDomain.isTrusteeWithoutBusinessNumber ? (
              <React.Fragment>
                <div className="w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group">
                  <FormField
                    label={labels.trusteeFullName}
                    value={mainStore.creditApplicationDomain.trusteeFullName}
                    placeholder={labels.trusteeFullName}
                    name={fields.trusteeFullName.name}
                    onChange={onChange}
                    hasError={fields.trusteeFullName.validate.hasError}
                    errorMessage={fields.trusteeFullName.validate.message}
                    type="text"
                    id="trusteeFullName"
                  />
                </div>
                <div className="w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group">
                  <CustomDatePicker
                    value={getTrusteeDateOfBirth()}
                    fields={fields}
                    onChange={(date) => onChangeTrusteeDateOfBirth(date, fields.trusteeDateOfBirth.name)}
                    label={labels.trusteeDateOfBirth}
                    name={fields.trusteeDateOfBirth.name}
                    placeholder={labels.trusteeDateOfBirth}
                    dateFormat={dateConfig.dateFormat.toLowerCase().replaceAll("m", "M") || "dd/MM/yyyy"}
                    id="trusteeDateOfBirth"
                  />
                </div>
                <div className="w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group">
                  <FormField
                    label={labels.trusteeDocument}
                    value={mainStore.creditApplicationDomain.trusteeDocument}
                    placeholder={labels.trusteeDocument}
                    name={fields.trusteeDocument.name}
                    onChange={onChange}
                    hasError={fields.trusteeDocument.validate.hasError}
                    errorMessage={fields.trusteeDocument.validate.message}
                    type="text"
                    id="trusteeDocument"
                  />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group">
                  <div className="relative">
                    {caCustomization.trusteeBusinessNumber.visible && (
                      <FormField
                        label={labels.trusteeBusinessNumber}
                        value={mainStore.creditApplicationDomain.trusteeBusinessNumber}
                        placeholder={labels.trusteeBusinessNumber}
                        name={fields.trusteeBusinessNumber.name}
                        onChange={onChange}
                        onBlur={fetchBusinessNumber}
                        hasError={fields.trusteeBusinessNumber.validate.hasError}
                        errorMessage={fields.trusteeBusinessNumber.validate.message}
                        type="text"
                        id="trusteeBusinessNumber"
                        disabled={mainStore.isSaved || mainStore.isSearchingTrusteeBusinessNumber}
                      />
                    )}
                    {mainStore.isSearchingTrusteeBusinessNumber && (
                      <span
                        className="z-10 text-indigo-900 absolute items-center justify-center bg-transparent"
                        style={{ left: '145px', top: '5px' }}
                      >
                        <SvgBase cssClass="w-4 mr-2 animate-spin">
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm10-5a1 1 0 0 1-1 1h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1zM7 12a1 1 0 0 1-1 1H3a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1zm12.071 7.071a1 1 0 0 1-1.414 0l-2.121-2.121a1 1 0 0 1 1.414-1.414l2.121 2.12a1 1 0 0 1 0 1.415zM8.464 8.464a1 1 0 0 1-1.414 0L4.93 6.344a1 1 0 0 1 1.414-1.415L8.464 7.05a1 1 0 0 1 0 1.414zM4.93 19.071a1 1 0 0 1 0-1.414l2.121-2.121a1 1 0 1 1 1.414 1.414l-2.12 2.121a1 1 0 0 1-1.415 0zM15.536 8.464a1 1 0 0 1 0-1.414l2.12-2.121a1 1 0 0 1 1.415 1.414L16.95 8.464a1 1 0 0 1-1.414 0z" />
                        </SvgBase>
                      </span>
                    )}
                  </div>
                </div>
                <div className="w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group">
                  {caCustomization.trusteeCompanyNumber.visible && (
                    <FormField
                      label={labels.trusteeCompanyNumber}
                      value={mainStore.creditApplicationDomain.trusteeCompanyNumber}
                      placeholder={labels.trusteeCompanyNumber}
                      onChange={onChange}
                      hasError={fields.trusteeCompanyNumber.validate.hasError}
                      errorMessage={fields.trusteeCompanyNumber.validate.message}
                      name={fields.trusteeCompanyNumber.name}
                      disabled={mainStore.isSaved}
                      type="text"
                      id="trusteeCompanyNumber"
                    />
                  )}
                </div>
                {caCustomization.trusteeBusinessType.visible ? (
                  <div className="w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group">
                    <FormField
                      label={labels.trusteeBusinessType}
                      value={mainStore.creditApplicationDomain.trusteeBusinessType}
                      placeholder={labels.trusteeBusinessType}
                      name={fields.trusteeBusinessType.name}
                      onChange={onChange}
                      hasError={fields.trusteeBusinessType.validate.hasError}
                      errorMessage={fields.trusteeBusinessType.validate.message}
                      type="text"
                      id="trusteeBusinessType"
                    />
                  </div>
                ) : ""}
                {caCustomization.trusteeTradingName.visible ? (
                  <div className="w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group">
                    <FormField
                      label={labels.trusteeTradingName}
                      value={mainStore.creditApplicationDomain.trusteeTradingName}
                      placeholder={labels.trusteeTradingName}
                      name={fields.trusteeTradingName.name}
                      onChange={onChange}
                      hasError={fields.trusteeTradingName.validate.hasError}
                      errorMessage={fields.trusteeTradingName.validate.message}
                      type="text"
                      id="trusteeTradingName"
                    />
                  </div>
                ) : ""}
              </React.Fragment>
            )}
          </div>

          {mainStore.applicationType === 'paymentApplication' && (
            <div className="flex flex-wrap">
              <div className="w-1/4 sm:w-1/2 xs:w-full mt-6">
                <label className="block mx-4">
                  <span
                    className={`${fields.trusteeDocument64.validate.hasError ? 'text-red-700' : 'text-gray-500'
                      } font-medium`}
                  >
                    Copy of the Executed Trust Deed
                  </span>
                  <input
                    id="trusteeDocument64"
                    name="trusteeDocument64"
                    type="file"
                    onChange={(e) => handleFileUpload(e.target.files[0])}
                  />
                  {fields.trusteeDocument64.validate.hasError && (
                    <span className="text-red-700 text-sm font-medium">
                      {fields.trusteeDocument64.validate.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}

          <div className="flex flex-wrap w-1/2 sm:w-1/2 xs:w-full mt-6 text-gray-500 text-sm">
            {caCustomization.trusteeNoteText}
          </div>
        </React.Fragment>
      )}

      {mainStore.applicationType === 'paymentApplication' &&
        mainStore.creditApplicationDomain.businessType?.value === BUSINESS_TYPE_OPTIONS[6]?.value &&
        (
          <div className="flex flex-wrap">
            <div className="w-1/4 sm:w-1/2 xs:w-full mt-6">
              <label className="block mx-4">
                <span
                  className={`${fields.trusteeDocument64.validate.hasError ? 'text-red-700' : 'text-gray-500'
                    } font-medium`}
                >
                  Copy of the Executed Trust Deed
                </span>
                <input
                  id="trusteeDocument64"
                  name="trusteeDocument64"
                  type="file"
                  onChange={(e) => handleFileUpload(e.target.files[0])}
                />
                {fields.trusteeDocument64.validate.hasError && (
                  <span className="text-red-700 text-sm font-medium">
                    {fields.trusteeDocument64.validate.message}
                  </span>
                )}
              </label>
            </div>
          </div>
        )}

      {
        ((mainStore.isCreditCustomerCompany() || mainStore.isCreditCustomerIndividual()) ||
        ( shouldRenderSection()
        && showCreditLimit()
        && mainStore.applicationType === 'creditApplication'
        && caCustomization.creditLimitRequested.visible ) )
        && (
        <React.Fragment>
          <FormTitle title="Credit Limit Info" margin="mt-10 mb-8" />
          <div className="flex flex-wrap">
            <div className="w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group">
              <FormField
                label={ labels.creditLimitRequested || creditLimitRequested }
                value={mainStore.creditApplicationDomain.creditLimitRequested}
                placeholder={labels.creditLimitRequested || creditLimitRequested }
                name={fields.creditLimitRequested.name}
                onChange={onChange}
                hasError={fields.creditLimitRequested.validate.hasError}
                errorMessage={fields.creditLimitRequested.validate.message}
                type="number"
                className="text-right"
                masked={true}
                mki-thousandSeparator={true}
                prefixed="$"
                mki-decimalScale={2}
                mki-fixedDecimalScale={true}
                mki-id="creditLimitRequested"
              />
            </div>

            { (mainStore.isCreditCustomerCompany() || mainStore.isCreditCustomerIndividual()) && !removeField("creditPeriod") &&
              <div className="w-1/4 sm:w-1/2 xs:w-full xs:mt-6 form-group">
                <FormField
                  type="number"
                  min="1"
                  max={mainStore.isCreditCustomerIndividual() ? "4" : "3"}
                  label={labels.creditPeriod}
                  value={mainStore.creditApplicationDomain.creditPeriod}
                  placeholder={labels.creditPeriod}
                  name={fields.creditPeriod.name}
                  onChange={onChange}
                  hasError={fields.creditPeriod.validate.hasError}
                  errorMessage={fields.creditPeriod.validate.message}
                  id="credit-period"
                />
            </div> }
          </div>
        </React.Fragment>
      )}

      {shouldRenderSection() && mainStore.applicationType === 'paymentApplication' && (
        <React.Fragment>
          <FormTitle title="Transaction Information" margin="mt-10 mb-8" />
          <div className="flex flex-wrap">
            <div className="w-1/3 xs:w-full xs:mt-6 form-group">
              <FormField
                label={`Transaction Limit Request ${isOnboardingNZ ? ' (NZD)' : ' ($)'} *`}
                value={mainStore.creditApplicationDomain.transactionLimitRequest}
                placeholder={`Transaction Limit Request ${isOnboardingNZ ? ' (NZD)' : ' ($)'}`}
                name={fields.transactionLimitRequest.name}
                onChange={onChange}
                hasError={fields.transactionLimitRequest.validate.hasError}
                errorMessage={fields.transactionLimitRequest.validate.message}
                type="number"
                className="text-right"
                masked={true}
                mki-thousandSeparator={true}
                prefixed="$"
                mki-decimalScale={2}
                mki-fixedDecimalScale={true}
                mki-id="transactionLimitRequest"
              />
            </div>
            <div className="w-1/3 xs:w-full xs:mt-6 form-group">
              <FormField
                label={`Estimated Monthly Value to be processed${isOnboardingNZ ? ' (NZD)' : ' ($)'} *`}
                value={mainStore.creditApplicationDomain.estimatedMonthlyValue}
                placeholder={`Estimated Monthly Value to be processed${isOnboardingNZ ? ' (NZD)' : ' ($)'}`}
                name={fields.estimatedMonthlyValue.name}
                onChange={onChange}
                hasError={fields.estimatedMonthlyValue.validate.hasError}
                errorMessage={fields.estimatedMonthlyValue.validate.message}
                type="number"
                className="text-right"
                masked={true}
                mki-thousandSeparator={true}
                prefixed="$"
                mki-decimalScale={2}
                mki-fixedDecimalScale={true}
                mki-id="estimatedMonthlyValue"
              />
            </div>
          </div>
          <div className="flex flex-wrap mt-6">
            <div className="w-1/3 xs:w-full xs:mt-6 form-group">
              <FormField
                label={`Average single transaction amount${isOnboardingNZ ? ' (NZD)' : ' ($)'} *`}
                value={mainStore.creditApplicationDomain.averageSingleTxAmount}
                placeholder={`Average single transaction amount${isOnboardingNZ ? ' (NZD)' : ' ($)'}`}
                name={fields.averageSingleTxAmount.name}
                onChange={onChange}
                hasError={fields.averageSingleTxAmount.validate.hasError}
                errorMessage={fields.averageSingleTxAmount.validate.message}
                type="number"
                className="text-right"
                masked={true}
                mki-thousandSeparator={true}
                prefixed="$"
                mki-decimalScale={2}
                mki-fixedDecimalScale={true}
                mki-id="averageSingleTxAmount"
              />
            </div>
            <div className="w-1/3 xs:w-full xs:mt-6 form-group">
              <FormField
                label={`Highest single transaction amount${isOnboardingNZ ? ' (NZD)' : ' ($)'} *`}
                value={mainStore.creditApplicationDomain.highestSingleTxAmount}
                placeholder={`Highest single transaction amount${isOnboardingNZ ? ' (NZD)' : ' ($)'}`}
                name={fields.highestSingleTxAmount.name}
                onChange={onChange}
                hasError={fields.highestSingleTxAmount.validate.hasError}
                errorMessage={fields.highestSingleTxAmount.validate.message}
                type="number"
                className="text-right"
                masked={true}
                mki-thousandSeparator={true}
                prefixed="$"
                mki-decimalScale={2}
                mki-fixedDecimalScale={true}
                mki-id="highestSingleTxAmount"
              />
            </div>
          </div>
          <div className="w-full xs:mt-6 form-group mt-6">
            <label className="block mx-4">
              <span
                className={`${fields.arrearsOrAdvance.validate.hasError ? 'text-red-700' : 'text-gray-500'
                  } font-medium`}
              >
                Do your customers pay in arrears or advance of services and/or goods provided?
              </span>
              <div className="flex mt-2">
                <div className="form-check form-check-inline mr-4">
                  <input
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    id="arrears"
                    onChange={() => onChangeCheckbox('arrears')}
                    checked={mainStore.creditApplicationDomain.arrears}
                  />
                  <label className="form-check-label inline-block text-gray-800" htmlFor="inlineCheckbox1">
                    Arrears
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    id="advance"
                    onChange={() => onChangeCheckbox('advance')}
                    checked={mainStore.creditApplicationDomain.advance}
                  />
                  <label className="form-check-label inline-block text-gray-800" htmlFor="inlineCheckbox2">
                    Advance
                  </label>
                </div>
              </div>
              {fields.arrearsOrAdvance.validate.hasError && (
                <span className="text-red-700 text-sm font-medium">{fields.arrearsOrAdvance.validate.message}</span>
              )}
            </label>
          </div>
          {mainStore.creditApplicationDomain.advance ?
            (<div className="flex flex-wrap mt-6">
              <div className="w-1/3 xs:w-full xs:mt-6 form-group">
                <FormField
                  label="Days in Advance *"
                  value={mainStore.creditApplicationDomain.daysInAdvance}
                  placeholder="Days in Advance"
                  name={fields.daysInAdvance.name}
                  onChange={onChange}
                  hasError={fields.daysInAdvance.validate.hasError}
                  errorMessage={fields.daysInAdvance.validate.message}
                  type="number"
                  className="text-right"
                  masked={true}
                  mki-id="daysInAdvance"
                />
              </div>
            </div>) : null}
        </React.Fragment>
      )}
    </>
  );
});

export default CompanyInfoForm;
