import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { action, makeObservable, observable, runInAction, toJS } from 'mobx';
import NProgress from 'nprogress';
import { createContext } from 'react';
import DateUtils from '../Utils/DateUtils';
import ValidationUtils from '../Utils/ValidationUtils';
import { prevent } from '../components/ExitAlert';
import CountryCodes from '../constants/countryCodes';
import CountryOptions from '../constants/countryOptions';
import ErrorMessages from '../constants/errors';
import properties from '../constants/properties';
import REGEX from '../constants/regex';
import CompanyInfoDomain from '../domains/CompanyInfoDomain';
import FeeStructureDomain from '../domains/FeeStructureDomain';
import DynamicFormBuilder from '../dynamicFormBuilder/dynamicFormBuilder';
import ABNService from '../services/abnService';
import ApiService from '../services/apiService';
import FormApiService from '../services/formApiService';
import otpService from '../services/otpService';
import { ApplicationType, CustomerType } from '../domains/TypeEnum';
import CustomApplicationDomain from '../domains/CustomApplicationDomain';
import CurrencyOptions from '../constants/currency';

class MainStore {
  wizards = [];
  jsonSchemas = [];
  uiSchemas = [];
  agreed = false;
  currentStep = 0;
  formDataList = [{}, {}, {}, {}, {}, {}];
  brand = {};
  loading = true;
  isSearchingBusinessNumber = false;
  isSearchingTrusteeBusinessNumber = false;
  errors = [];
  businessNumberOptions = [];
  creditApplicationDomain = new CompanyInfoDomain();
  feeStructureDomain = new FeeStructureDomain();
  paymentMethodType = ['CREDIT_CARD'];
  isTermsModalVisible = false;
  isPrivacyModalVisible = false;
  isSaveDraftModalVisible = false;
  isDraftSaved = false;
  companyInfoErrors = {};
  feeStructureErrors = {};
  trusteeCompanyInfoErrors = {};
  creditApplicationId = null;
  isSaved = null;
  isConfirmed = false;
  isLinkExpired = false;
  isValidBusinessNumber = false;
  hadAValidBusinessNumber = false;
  isSaving = false;
  businessNumberSelectedIndex = 0;
  acceptedClientTerms = true;
  key = 0;
  liveValidateState = false;
  isBillingAdrressStep = false;
  isDeliveryAddressStep = false;
  isAddressessTheSame = false;
  countryOptions = CountryOptions;
  currencyOptions = CurrencyOptions;
  selectedCountry = this.countryOptions.find( c => c.value === "AU" );
  selectedCurrency = this.currencyOptions[0];
  isConfirmModalOpen = false;
  formStructure = null;
  uploadedDirectorsGuarantee = true;
  directorGuaranteeRequired = false;
  prospectEmail = '';
  hasErrorInProspectEmail = false;
  applicationType = 'creditApplication';
  isOTPConfirmed = false;
  otpEmail = '';
  otpErrorMessage = '';
  authToken = '';
  dateConfig = {};
  orgid = null;
  creditCardInfo = {clientSecret: undefined, feeInformation: {}}
  creditCard = {last4: "", holder: "", brand: ""}
  // Brooks Hire Customization
  customApplication = { 
    type: "", 
    customerType: "", 
    wizards: null, 
    schemas: null, 
    stepRemoved: false, 
    directDebit: true,
    waiverFeeNoticeElection: "undefined",
    error: { type: false, customerType: false }, 
  };
  customCardValidator = null;
  caCustomization = null;
  companyContacts = null;
  accountContacts = null;
  contacts = null;
  primaryContacts = null;
  accountPayableContacts = null;
  siteContacts = null;
  bankTransferInfo = {};
  payToAgreement = {};
  memberType =  'existing_member';

  constructor() {
    this.abnField = 'businessNumber';
    this.trusteeBusinessNumberField = 'trusteeBusinessNumber';
    this.companyInfoStep = {
      label: 'Company Info',
      icon:
        '<path fill="none" d="M0 0h24v24H0z"/><path d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z"/>',
    };

    this.feeStructureStep = {
      label: 'Fees Structure',
      icon:
        '<path fill="none" d="M0 0h24v24H0z"/><path d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z"/>',
    };

    this.confirmStep = {
      label: 'Confirm',
      icon:
        '<path fill="none" d="M0 0h24v24H0z"/><path d="M11.602 13.76l1.412 1.412 8.466-8.466 1.414 1.414-9.88 9.88-6.364-6.364 1.414-1.414 2.125 2.125 1.413 1.412zm.002-2.828l4.952-4.953 1.41 1.41-4.952 4.953-1.41-1.41zm-2.827 5.655L7.364 18 1 11.636l1.414-1.414 1.413 1.413-.001.001 4.951 4.951z"/>',
    };

    makeObservable(this, {
      wizards: observable,
      jsonSchemas: observable,
      uiSchemas: observable,
      agreed: observable,
      currentStep: observable,
      formDataList: observable,
      brand: observable,
      loading: observable,
      errors: observable,
      businessNumberOptions: observable,
      isSearchingBusinessNumber: observable,
      isSearchingTrusteeBusinessNumber: observable,
      creditApplicationDomain: observable,
      isConfirmModalOpen: observable,
      isTermsModalVisible: observable,
      isPrivacyModalVisible: observable,
      isSaveDraftModalVisible: observable,
      isDraftSaved: observable,
      companyInfoErrors: observable,
      feeStructureErrors: observable,
      trusteeCompanyInfoErrors: observable,
      isConfirmed: observable,
      isLinkExpired: observable,
      isValidBusinessNumber: observable,
      hadAValidBusinessNumber: observable,
      isSaving: observable,
      isSaved: observable,
      creditApplicationId: observable,
      businessNumberSelectedIndex: observable,
      acceptedClientTerms: observable,
      isBillingAdrressStep: observable,
      isAddressessTheSame: observable,
      selectedCurrency: observable,
      selectedCountry: observable,
      countryOptions: observable,
      uploadedDirectorsGuarantee: observable,
      directorGuaranteeRequired: observable,
      prospectEmail: observable,
      hasErrorInProspectEmail: observable,
      applicationType: observable,
      isOTPConfirmed: observable,
      otpEmail: observable,
      otpErrorMessage: observable,
      authToken: observable,
      paymentMethodType: observable,
      payToAgreement: observable,
      agree: action,
      toggleModal: action,
      updateCompanyInfo: action,
      updateFeeStructure: action,
      hasErrorInCompanyInfoFormAttr: action,
      hasErrorInFeeStructureFormAttr: action,
      saveCreditApplication: action,
      fetchFeeStructureData: action,
      _shouldRestart: action,
      init: action,
      fetchBusinessNumber: action,
      fetchTrusteeBusinessNumber: action,
      next: action,
      prev: action,
      goToNext: action,
      setData: action,
      confirm: action,
      checkBusinessNumber: action,
      selectBusinessNumber: action,
      selectCountry: action,
      selectCurrency: action,
      updateAcceptedClientTerms: action,
      key: observable,
      liveValidateState: observable,
      updateLiveValidateState: action,
      toggleCompanyHasBusinessNumber: action,
      formStructure: observable,
      getFormWithData: action,
      fillDataFromCreditApplicationId: action,
      directorsUploadError: action,
      saveDraft: action,
      updateProspectEmail: action,
      saveFeeStructure: action,
      saveStep: action,
      openConfirmModal: action,
      closeConfirmModal: action,
      saveTermsModalDetails: action,
      validateLink: action,
      getOtpToken: action,
      verifyOtpCode: action,
      setDraftSaved: action,
      orgid: observable,
      creditCardInfo: observable,
      creditCard: observable,
      customApplication: observable,
      updateDirectDebit: action,
      customCardValidator: observable,
      updateCustomCardValidator: action,
      validateCustomApplication: action,
      clearCustomCardValidator: action,
      isCashCustomerCompany: action,
      isCashCustomerIndividual: action,
      isCreditCustomerIndividual: action,
      isCreditCustomerCompany: action,
      isCustomerApplication: action,
      companyContacts: observable,
      accountContacts: observable,
      contacts: observable,
      setCustomerTypeError: action,
      updateCustomApplication: action,
      cleanCompanyInfoForResidential: action,
      bankTransferInfo: observable,
      updateBankTransferInfo: action,
      updatePayToAgreement: action,
      memberType: observable,
      updateMemberType: action,
    });
  }

  init(creditApplicationId, applicationType, caCustomization) {
    this.countryOptions = caCustomization?.countryOptions || CountryOptions;
    this.caCustomization = caCustomization;
    this.companyInfoStep.label = caCustomization.companyInfoStep;

    ApiService.getBrand()
      .then((mainData) => {
        this.orgid = mainData.data.id;
        this.brand = mainData.data.brand;
        this.applicationType = applicationType;
        this.updateCompanyInfo('webFormId', mainData.data.webFormId);
        this.updateCompanyInfo('organizationId', mainData.data.id);

        if (this.isSmokeballInstance()) {
          this.updateCompanyInfo('arrears', true);
          this.updateCompanyInfo('advance', true);
          this.updateCompanyInfo('industryOption', {
            label: 'Legal Services, Attorneys',
            value: 8111,
          });
        }

        if (properties.SOUNG_YUEEN_ORGS.concat(properties.US_ONBOARDING_ORGS).concat(properties.NZ_ONBOARDING_ORGS).includes(mainData.data.id)) {
          const country = properties.SOUNG_YUEEN_ORGS.concat(properties.NZ_ONBOARDING_ORGS).includes(mainData.data.id) ? 'NZ' : 'US';

          this.countryOptions = this.countryOptions.filter((item) => item.value === country);

          this.selectedCountry = this.countryOptions[0];
          this.creditApplicationDomain.updateAttr('country', this.countryOptions[0].value);
        }

        if(properties.OMA_GROUP.includes(mainData.data.id)) {
          const omaGroupCountries = ['BJ', 'BF', 'CI', 'GH', 'MU', 'NA', 'NE', 'SN', 'TG', 'CM', 'GN', 'NG', 'GA', 'AO'];
          const subAgent = ['CM', 'GN', 'NG', 'GA', 'AO'];

          this.countryOptions = omaGroupCountries
              .map((item) => this.countryOptions.find((country) => item === country.value))
              .map((item) => {
                if(subAgent.includes(item.value)) {
                  item.label = `${item.label} (via sub-agent)`;
                }

                return item;
              });

          this.selectCountry(0);
        }

        this.directorGuaranteeRequired = this.brand.directorsGuaranteeRequired;

        FormApiService.getForm(mainData.data.webFormId).then((formData) => {
          runInAction(() => {
            this.loading = false;

            const steps = formData.data.formSteps.map((step) => {
              return { id: step.id, ...step.formStepData };
            });
            steps.unshift(this.companyInfoStep);
            if (this.applicationType === 'paymentApplication') {
              steps.push(this.feeStructureStep);
            }
            steps.push(this.confirmStep);
            this.wizards.replace(steps);
            this.customApplication.wizards = toJS(this.wizards);
            this.formStructure = formData.data.formSteps;

            const jsonSchemas = formData.data.formSteps.map((step) => {
              const stepItems = step.formJsonSchema.jsonSchemaData.data;

              if (stepItems.properties && stepItems.properties.file_directorsGuarantee) {
                const requiredFields = stepItems.required || undefined;

                if (requiredFields && !requiredFields.includes('file_directorsGuarantee')) {
                  if (this.directorGuaranteeRequired) {
                    requiredFields.push('file_directorsGuarantee');
                  }
                }
              }
              return step.formJsonSchema.jsonSchemaData.data;
            });
            jsonSchemas.unshift({});
            jsonSchemas.push({});

            this.jsonSchemas.replace(jsonSchemas);

            const uiSchemas = formData.data.formSteps.map((step) => step.formUiSchema.uiSchemaData.data);
            uiSchemas.unshift({});
            uiSchemas.push({});

            this.uiSchemas.replace(uiSchemas);

            this.customApplication.schemas = toJS(this.jsonSchemas);
            this.customApplication.uiSchema = toJS(this.uiSchemas);

            NProgress.configure({ parent: '#main-container' });

            if (applicationType === 'paymentApplication') {
              this.creditApplicationId = creditApplicationId;
              this.creditApplicationDomain.applicationType = 'paymentApplication';
              this.validateLink();
              this.getOtpToken();
            }

            this.getDateFormatTimezoneConfig();

            if (applicationType === 'creditApplication' && creditApplicationId) {
              this.fillDataFromCreditApplicationId(creditApplicationId);
            }
          });
        });
      })
      .catch((error) => console.log(error));
  }

  async getDateFormatTimezoneConfig() {
    try {
      const res = await ApiService.getDateFormatTimezone(this.creditApplicationDomain.organizationId);
      this.dateConfig = res.data;
    } catch (err) {
      console.log('Error dateformattimezone api');
    }
  }

  async validateLink() {
    try {
      await ApiService.getValidateLink(this.creditApplicationId);
    } catch {
      this.isLinkExpired = true;
    }
  }

  async fillDataFromCreditApplicationId(creditApplicationId) {
    try {
      this.loading = true;
      const { data } = await ApiService.getCreditApplicationData(
        creditApplicationId,
        this.applicationType,
        this.authToken
      );
      const { businessNumber, creditApplicationFormDataList, formStatus, status, country } = data;

      if (country !== 'US') {
        this.fetchBusinessNumber(businessNumber);
      }

      this.fillFormDataListFromApi(creditApplicationFormDataList);
      this.creditApplicationDomain.fillCompanyInfoFromApi(data);
      this.selectedCurrency = this.creditApplicationDomain.currency;
      this.creditApplicationId = creditApplicationId;
      this.currentStep =
        formStatus === 'COMPLETE'
          ? this.wizards.length
          : creditApplicationFormDataList
            ? creditApplicationFormDataList.length
            : 0;
      this.isSaved = true;
      this.isConfirmed = formStatus === 'COMPLETE';
      this.isLinkExpired = status === 'DELETED';
      this.agree();
      if (this.applicationType === 'paymentApplication') {
        this.fetchFeeStructureData();
      }
      this.updateCustomApplicationSteps(this.currentStep, true);
    } catch (error) {
      console.log(error);
      //this.isLinkExpired = true;
    } finally {
      this.loading = false;
    }
  }

  async fetchFeeStructureData() {
    try {
      const { data } = await ApiService.getFeeStructureData(this.creditApplicationId, this.authToken);

      const paymentMethods = [];

      if(data?.paymentMethodType === 'CREDIT_CARD_AND_EFT'){
        paymentMethods.push('EFT', 'CREDIT_CARD');
      } else if (data?.paymentMethodType) {
        paymentMethods.push(data?.paymentMethodType);
      } else {
        paymentMethods.push('CREDIT_CARD');
      }

      if(data?.realTimeFees && paymentMethods.includes('EFT')) {
        try {
          if(JSON.parse(data.realTimeFees).length !== 0) {
            paymentMethods.push('PAYTO');
          }
        } catch(err) {
          console.log(err);
        }
      }

      this.feeStructureDomain.fillFeeStructureFromApi({
        cardFees: data?.cardFees ? JSON.parse(data?.cardFees) : data.cardFees,
        internationalFees: data?.internationalFees ? JSON.parse(data?.internationalFees) : data.internationalFees,
        serviceFees: data?.serviceFees ? JSON.parse(data?.serviceFees) : data.serviceFees,
        eftFees: data?.eftFees ? JSON.parse(data?.eftFees) : data.eftFees,
        realTimeFees: data?.realTimeFees ? JSON.parse(data?.realTimeFees) : data.realTimeFees,
        paymentMethodType: paymentMethods,
        settlementDelay: data?.metadata?.length > 0 ? JSON.parse(data?.metadata).settlementDelay : null,
        clientType: data?.metadata?.length > 0 ? JSON.parse(data?.metadata).clientType : 'EZYCOLLECT_STANDARD',
        agreedToConfigTerms: data?.agreedToConfigTerms,
      });

      this.paymentMethodType = data?.paymentMethodType === 'CREDIT_CARD_AND_EFT'
        ? ['EFT', 'CREDIT_CARD']
        : data?.paymentMethodType ? [data?.paymentMethodType] : ['CREDIT_CARD'];

    } catch (exception) {
      console.log(exception);
      this._shouldRestart();
    }
  }

  async saveFeeStructure() {
    this.feeStructureErrors = this.feeStructureDomain.validate(this.creditApplicationDomain.organizationId, this.caCustomization);

    if (!this.feeStructureErrors.hasError) {
      NProgress.start();
      this.isSaving = true;

      try {
        const { data } = await ApiService.saveFeeStructureData(
          this.creditApplicationId,
          this.feeStructureDomain.toParams(this.creditApplicationDomain.organizationId),
          this.authToken
        );

        if (data) {
          this.goToNext();
          NProgress.done();
          this.isSaving = false;
        }
      } catch (exception) {
        console.log(exception);
        this._shouldRestart();
      }
    }
  }

  agree() {
    // this.acceptedClientTerms is set to true, used to be a checkbox value, can be removed and just set this.agreed to true
    this.agreed = this.acceptedClientTerms;
  }

  updateAcceptedClientTerms() {
    //not used after removing initial terms page can remove after confirmation
    this.acceptedClientTerms = !this.acceptedClientTerms;
    this.creditApplicationDomain.acceptedClientTerms = this.acceptedClientTerms;
  }

  getKey() {
    return this.key;
  }

  updateKey() {
    return (this.key = Date.now());
  }

  updateLiveValidateState(value) {
    this.liveValidateState = value;
  }

  toggleModal(origin) {
    this[origin] = !this[origin];
  }

  updateCompanyInfo(attr, value) {
    const errors = _get(this.companyInfoErrors, 'errors', []).filter((error) => error.fieldError !== attr);

    this.companyInfoErrors = {
      hasError: errors.length > 0,
      errors: errors,
    };

    let val = value;
    if (
      attr === this.abnField &&
      val !== '' &&
      CountryCodes.REGULAR_REGION_CODES.includes(this.creditApplicationDomain.country)
    ) {
      val = value.replace(/[^0-9\s]/g, '');
      if (!this.isCustomerApplication() && ValidationUtils.validateBusinessNumberIsUnique(val, this.creditApplicationDomain.trusteeBusinessNumber)) {
        this.fetchBusinessNumber(val);
      } else {
        this.companyInfoErrors['hasError'] = true;
        this.companyInfoErrors['errors'] = [
          { fieldError: 'businessNumber', message: ErrorMessages.BUSINESS_NUMBER_DUPLICITY },
        ];
      }
    } else if (attr === this.trusteeBusinessNumberField && val !== '') {
      if (ValidationUtils.validateBusinessNumberIsUnique(this.creditApplicationDomain.businessNumber, val)) {
        if (ValidationUtils.validateBusinessNumber(val, this.selectedCountry.value)) {
          this.fetchTrusteeBusinessNumber(val);
        }
      } else {
        this.companyInfoErrors['hasError'] = true;
        this.companyInfoErrors['errors'] = [
          { fieldError: 'trusteeBusinessNumber', message: ErrorMessages.BUSINESS_NUMBER_DUPLICITY },
        ];
      }
    }

    this.creditApplicationDomain.updateAttr(attr, val);
  }

  updateFeeStructure(key, subkey, value) {
    this.feeStructureDomain.updateAttr(key, subkey, value);
  }

  next() {
    if (this.currentStep === 0) {

      this.fillCompanyInfoForOmaGroup();

      const isCustomApplication = this.isCashApplicationResidential()
        || (properties.RELIABLE_HIRE_ORGS.includes(this.creditApplicationDomain.organizationId) && this.isCashIndividual());

      this.creditApplicationDomain.isBusinessApplication = (properties.RELIABLE_HIRE_ORGS.includes(this.creditApplicationDomain.organizationId) && this.isBusinessApplication());

      this.companyInfoErrors = this.creditApplicationDomain.validate(isCustomApplication, this.caCustomization, this.memberType);

      this.updateCustomApplicationSteps(this.currentStep + 1);

      if(this.isCreditAccountApplication() || this.isPrePaidAccountApplication()) {
        this.fillCompanyInfoForMayers();
      }

      if (!this.companyInfoErrors.hasError) {
        this.saveCreditApplication();
        NProgress.start();
      }

      if(this.customApplication.customerType === '') {
        this.customApplication.error.customerType = true;
      }

      if(!this.validateCustomApplication()) {
        if(this.companyInfoErrors.hasError && this.isCashApplicationResidential()) {
          this.saveCreditApplication();
          NProgress.start();
        }
      }      
    } else if(this.currentStep === 1 && (this.isCashApplicationResidential() || this.isCashApplicationBusiness() || this.isCashIndividual())) {
      
      NProgress.start();
      
      if(this.isCashApplicationResidential()) {
        this.fillCompanyInfoForResidential();
        this.saveCreditApplication(false);
      }

      if(this.isCashIndividual()) {
        this.fillCompanyInfoForCashApplication();
        this.saveCreditApplication(false);
      }
      // this.saveCustomApplication();
      this.saveStep();      
    } else {
      if(this.customCardValidator && !this.validateCreditCard()) {
        // add a custom validation to the form;
        this.setCreditCardData("notValid");
        return;
      }
      if(this.isLastStep()) {
        this.saveCustomApplication();
      }   
      NProgress.start();
      this.saveStep();
    }
  }

  isCustomCreditApplication() {
    return this.customApplication.type === ApplicationType.creditApplication
  }

  isPrePaidAccountApplication() {
    return (this.customApplication.type === ApplicationType.prePaidAccount);
  }

  isCreditAccountApplication() {
    return (this.customApplication.type === ApplicationType.creditAccount);
  }

  isCashApplication() {
    return this.customApplication.type === ApplicationType.cashApplication
  }

  isCashApplicationResidential() {
    return this.customApplication.type === ApplicationType.cashApplication
      && this.customApplication.customerType === CustomerType.residential;
  }

  isCashApplicationBusiness() {
    return this.customApplication.type === ApplicationType.cashApplication
      && this.customApplication.customerType === CustomerType.business;
  }

  isCustomerApplication() {
    return this.isCashCustomerCompany()
      || this.isCashCustomerIndividual()
      || this.isCreditCustomerCompany()
      || this.isCreditCustomerIndividual()
  }

  isCashCustomerIndividual() {
    return this.customApplication.type === ApplicationType.cashCustomerApplication
      && this.customApplication.customerType === CustomerType.individual
  }

  isCashCustomerCompany() {
    return this.customApplication.type === ApplicationType.cashCustomerApplication
      && this.customApplication.customerType === CustomerType.company
  }

  isCreditCustomerIndividual() {
    return this.customApplication.type === ApplicationType.creditCustomerApplication
      && this.customApplication.customerType === CustomerType.individual
  }

  isCashIndividual() {
    return this.customApplication.type === ApplicationType.cashApplication
      && this.customApplication.customerType === CustomerType.individual
  }

  isBusinessApplication() {
    return this.customApplication.type === ApplicationType.cashApplication
      && this.customApplication.customerType === CustomerType.business
  }

  isCreditCustomerCompany() {
    return this.customApplication.type === ApplicationType.creditCustomerApplication
      && this.customApplication.customerType === CustomerType.company
  }

  isCreditApplication() {
    return this.customApplication.type === ApplicationType.creditApplication;
  }

  directorsUploadError(validation) {
    if (validation.errors) {
      const hasUpload = validation.errors.some((element) => {
        return element.stack === 'file_directorsGuarantee: is a required property';
      });
      this.uploadedDirectorsGuarantee = !hasUpload;
    }
  }

  removeFromWizard(title, props, customApplicationDomain) {
    const index = this.wizards.findIndex( e => e.label === title);
    if(index !== -1) {
      this.wizards.splice(index, 1);
      customApplicationDomain.removeStep(props);
      this.customApplication.stepRemoved = true;
    }

    this.updateJsonSchemas(customApplicationDomain.getJsonSchemas());
    this.updateUiSchemas(customApplicationDomain.getUiSchemas());
  }

  addCustomApplication(form) {
    if (form && form.hasOwnProperty('customApplication')) {
      this.customApplication = form.customApplication;
    } else {
      if (form && form.hasOwnProperty('customApplicationDraft')) {
        this.customApplication = form.customApplicationDraft;
      }
    }

    const customApplicationDomain = new CustomApplicationDomain(0, "Contacts", toJS(this.jsonSchemas), toJS(this.uiSchemas));

    if(form.customApplication) {
      if(form.customApplication.type === ApplicationType.cashApplication) {
        if(form.customApplication.customerType === CustomerType.residential) {
          this.removeFromWizard("Director Details", "directors", customApplicationDomain);
          this.removeFromWizard("Trade References", "referenceOne", customApplicationDomain);
        }

        if(form.customApplication.customerType === CustomerType.business) {
          this.removeFromWizard("Trade References", "referenceOne", customApplicationDomain);
        }
      }
    }

    if(this.isCustomerApplication()) {
      if(this.isCashCustomerIndividual() || this.isCreditCustomerIndividual()) {
        this.removeFromWizard("Contacts", "contacts", customApplicationDomain);
        this.formDataList.splice(1,1);
      }

      if(this.isCashCustomerIndividual() || this.isCashCustomerCompany()) {
        this.formDataList.splice(3,1);
        this.removeFromWizard("Trade References", "referenceOne", customApplicationDomain);
      }
    }

  }

  addPaymentAuthorisationInfo(form) {
    if (form && form.hasOwnProperty('paymentAuthorisation')) {
      this.creditCard = JSON.parse(form.paymentAuthorisation);
    }
  }

  formatDateOfBirth(form) {
    if(this.customApplication.customerType === CustomerType.residential) {
      if (form && form.hasOwnProperty('contacts')) {
        form.contacts.forEach( (e, i) => {
          form.contacts[i].dateOfBirth = DateUtils.formatDateTimezone(e.dateOfBirth, this.dateConfig.dateFormat, '', true);
        });
      }
    }
    return form;
  }

  formatDirectorsBirthDate(form, load) {
    if (this.getContext().countryCode === 'US') return form;
    let key = '';

    const modifiedDirectors = (key) =>
      form[key].map((d) => {
        if (!load) {
          d.birthDate = DateUtils.formatDateTimezone(d.birthDate, this.dateConfig.dateFormat, this.dateConfig.timezone);
        } else {
          const birthDate = DateUtils.guessFormat(d.birthDate);
          d.birthDate = DateUtils.formatDateTimezone(birthDate, "YYYY-MM-DD");
        }
        return d;
      });

    if (form && form.hasOwnProperty('directors')) {
      key = 'directors';
      form[key] = modifiedDirectors(key);
    }

    if (form && form.hasOwnProperty('directorsOrOwners')) {
      key = 'directorsOrOwners';
      form[key] = modifiedDirectors(key);
    }

    return form;
  }

  saveStep() {
    this.isSaving = true;

    let form = this.getFormData();
    if (form && !form.hasOwnProperty("contacts") && form.hasOwnProperty("companyContacts")) {
      form["contacts"] = [{
        "fullName": ""
      }];
    }

    form = this.formatDirectorsBirthDate(toJS(form));
    form = this.formatDateOfBirth(toJS(form));

    if (form) {
      ApiService.updateCreditApplicationFormData(
        this.creditApplicationId,
        this.wizards[this.currentStep].id,
        form,
        this.applicationType,
        this.authToken
      )
        .then(() => {
          this.goToNext();
          NProgress.done();
          runInAction(() => {
            this.isSaving = false;
          });
        })
        .catch((error) => {
          console.log(error);
          runInAction(() => {
            this.isSaving = false;
            this._shouldRestart();
          });
        });
    } else {
      if( (this.isCashApplication() || this.isCustomCreditApplication())) {
        this.isSaving = false;
        NProgress.done();
        this.goToNext();
      }
    }
  }

  saveTermsModalDetails(data) {
    const modalTermsData = 'modalTermsData';
    this._trackEvent('Accepted Terms and submitted', { data: data.signerInfo });
    ApiService.updateCreditApplicationFormData(
      this.creditApplicationId,
      modalTermsData,
      data,
      this.applicationType,
      this.authToken
    )
      .then(() => {
        this.loading = true;
        this.confirm();
      })
      .catch((err) => {
        console.log(err);
        this._shouldRestart();
      });
  }

  saveCreditApplication(goNext = true) {
    this.isSaving = true;
    const requestMethod = this.isSaved ? 'updateCreditApplication' : 'createCreditApplication';

    ApiService[requestMethod](
      this.creditApplicationDomain.toParams(),
      this.creditApplicationId,
      this.applicationType,
      this.authToken
    )
      .then((response) => {
        if (!this.isSaved) {
          this.creditApplicationId =
            this.applicationType === 'paymentApplication' ? this.creditApplicationId : response.data;
          this.isSaved = true;
          this.fetchFeeStructureData();
        }
        NProgress.done();
        if(goNext) {
          this.goToNext();
        }
        runInAction(() => {
          this.isSaving = false;
        });
      })
      .catch((error) => {
        console.log(error);
        runInAction(() => {
          this._shouldRestart();
          this.isSaving = false;
        });
      });
  }

  isAddressesStep() {
    const schema = this.getCurrentJsonSchema();

    this.isBillingAdrressStep =
      Object.keys(schema).length > 0 ? Object.keys(schema.properties).includes('billingAddress') : false;
    this.isDeliveryAddressStep =
      Object.keys(schema).length > 0 ? Object.keys(schema.properties).includes('deliveryAddress') : false;
  }

  saveDraftCustomApplication() {
    if(this.customApplication.type && this.customApplication.type !== "") {
      this.formDataList[this.currentStep] = { ...this.formDataList[this.currentStep], customApplicationDraft: this.customApplication };
    }
  }

  saveCustomApplication(step = null) {
    let currentStep = (!step) ? this.currentStep : step;
    if(this.customApplication.type && this.customApplication.type !== "") {
      this.formDataList[currentStep] = { ...this.formDataList[currentStep], customApplication: this.customApplication };
    }
  }

  updateCustomApplicationSteps(step, isDraft = false) {

    if (this.applicationType === 'paymentApplication') {
      return;
    }

    let stepTitle = this.getCurrentStepTitle();
    const {stepRemoved, directDebit} = this.customApplication;

    if(step === 0) {
      if(stepRemoved) {
        this.wizards = toJS(this.customApplication.wizards);
        this.jsonSchemas = toJS(this.customApplication.schemas);
        this.uiSchemas = toJS(this.customApplication.uiSchema);
      }
    }

    let customApplicationDomain = new CustomApplicationDomain(step, stepTitle, toJS(this.jsonSchemas), toJS(this.uiSchemas));

    if(isDraft) {

      if(this.isCashCustomerIndividual() || this.isCashCustomerCompany()) {
        this.removeFromWizard("Trade References", "referenceOne", customApplicationDomain);
      }

      if(this.isCashCustomerIndividual() || this.isCreditCustomerIndividual() ) {
        this.removeFromWizard("Contacts", "contacts", customApplicationDomain);
        customApplicationDomain.removeSectionFromJsonSchema('contacts');
      }

      if(this.isCashApplicationResidential() || this.isPrePaidAccountApplication()) {
        this.removeFromWizard("Director/Principal Details", "directors", customApplicationDomain);
        this.removeFromWizard("Director Details", "directors", customApplicationDomain);
        this.removeFromWizard("Trade References", "referenceOne", customApplicationDomain);
      }

      if(this.isCashApplicationBusiness()) {
        this.removeFromWizard("Trade References", "referenceOne", customApplicationDomain);
      }
    }

    stepTitle = this.getCurrentStepTitle();
    customApplicationDomain = new CustomApplicationDomain(step, stepTitle, toJS(this.jsonSchemas), toJS(this.uiSchemas));

    // Oma group contacts
    if(this.isCustomerApplication()) {

      if(this.isCashCustomerIndividual() || this.isCashCustomerCompany()) {
        this.removeFromWizard("Trade References", "referenceOne", customApplicationDomain);
      }

      if(this.isCashCustomerIndividual() || this.isCreditCustomerIndividual() ) {
        this.removeFromWizard("Contacts", "contacts", customApplicationDomain);
      }
    }

    if(properties.RELIABLE_HIRE_ORGS.includes(this.creditApplicationDomain.organizationId)) {
      if(this.isCashApplication()) {
        this.removeFromWizard("Trade References", "referenceOne", customApplicationDomain);
        this.removeFromWizard("Director Details", "directors", customApplicationDomain);
      }
    }

    if(stepTitle  === "Contacts") {

      this.saveDraftCustomApplication();

      if(this.isCustomerApplication()) {
        this.saveCustomApplication();
      }

      if(this.isCreditAccountApplication()) {
        this.saveCustomApplication();
        
        customApplicationDomain.removePropertiesFromJsonSchema("specialDeliveryInstructions");

        this.updateJsonSchemas(customApplicationDomain.getJsonSchemas());        
      }

      if(this.isPrePaidAccountApplication()) {
        this.saveCustomApplication();
        this.removeFromWizard("Director/Principal Details", "directors", customApplicationDomain);
        this.removeFromWizard("Trade References", "referenceOne", customApplicationDomain);

        const jsonSchema = customApplicationDomain.getJsonSchemas();
        const properties = jsonSchema[step].properties;
        properties.contacts.items.properties.fullName.title = "Primary Contact Name - Re. Orders";

        jsonSchema[step].properties = properties;

        this.updateJsonSchemas(jsonSchema);
        this.updateUiSchemas(customApplicationDomain.getUiSchemas());

        // Form data will read this changes
        const contactUiSchemas = customApplicationDomain.getUiSchemas()[step];
        const contactJsonSchemas = customApplicationDomain.getJsonSchemas()[step];
        this.formStructure[step-1].formUiSchema.uiSchemaData.data = contactUiSchemas;
        this.formStructure[step-1].formJsonSchema.jsonSchemaData.data = contactJsonSchemas;
      }

      if(this.isCashApplication()) {

        if(this.isCashApplicationResidential()) {
          this.removeFromWizard("Director Details", "directors", customApplicationDomain);
          this.removeFromWizard("Trade References", "referenceOne", customApplicationDomain);
        }

        if(this.isCashApplicationBusiness()) {
          this.removeFromWizard("Trade References", "referenceOne", customApplicationDomain);
        }
      }

      if( (this.isCashApplication() || this.isCustomCreditApplication())) {

        if(properties.BROOKS_HIRE_ORGS.includes(this.creditApplicationDomain.organizationId)) {
          let contactsJsonSchemaItems = customApplicationDomain.getJsonSchemaProperties('contactPersonObject');

          const hire = "hire";
          if (!contactsJsonSchemaItems.hasOwnProperty(hire)) {
            const dependencies = {
              "hire": {
                "oneOf": [
                  {
                    "properties": {
                      "hire": {
                        "enum": [
                          false
                        ]
                      }
                    }
                  },
                  {
                    "properties": {
                      "hire": {
                        "enum": [
                          true
                        ]
                      },
                      "customerNumber": {
                        title: "Enter Customer No.",
                        "type": "string"
                      }
                    },
                    "required": [
                      "customerNumber"
                    ]
                  },
                ]
              }
            }

            const title = "Did you hire from us before?";
            const property = 'contactPersonObject';
            // customApplicationDomain.addRequiredField(hire);
            customApplicationDomain.addPropertiesToUiSchema({ hire: customApplicationDomain.generateRadioButtonUI() }, property);
            customApplicationDomain.addPropertiesToUiSchema({ customerNumber: customApplicationDomain.generateGeneralUiSchema() }, property);
            customApplicationDomain.addPropertiesToJsonSchema({ hire: customApplicationDomain.generateBooleanEnum(title) }, property);
            customApplicationDomain.addDependencies(dependencies, property);
            customApplicationDomain.addPropertiesToUiSchema({ "ui:order": ["contactPerson", "hire", "fullName", "dateOfBirth", "customerNumber", "position", "email", "mobile", "contactNumber", "driversLicense"] }, property);
          }

          if (customApplicationDomain.getJsonSchemaSection('companyContacts')) {
            this.companyContacts = customApplicationDomain.getJsonSchemaSection('companyContacts');
          }

          if (customApplicationDomain.getJsonSchemaSection('accountContacts')) {
            this.accountContacts = customApplicationDomain.getJsonSchemaSection('accountContacts');
          }

          if (customApplicationDomain.getJsonSchemaSection('contacts')) {
            this.contacts = customApplicationDomain.getJsonSchemaSection('contacts');
          }

          if (this.isCashApplicationResidential()) {
            customApplicationDomain.removeSectionFromJsonSchema('companyContacts');
            customApplicationDomain.removeSectionFromJsonSchema('accountContacts');
            customApplicationDomain.addSectionToJsonSchema(this.contacts, 'contacts');
          } else {
            customApplicationDomain.removeSectionFromJsonSchema('contacts');
            customApplicationDomain.addSectionToJsonSchema(this.companyContacts, 'companyContacts');
            customApplicationDomain.addSectionToJsonSchema(this.accountContacts, 'accountContacts');
          }
        }

        if (customApplicationDomain.getJsonSchemaSection('accountContacts')) {
          this.accountContacts = customApplicationDomain.getJsonSchemaSection('accountContacts');
        }

        if (customApplicationDomain.getJsonSchemaSection('primaryContacts')) {
          this.primaryContacts = customApplicationDomain.getJsonSchemaSection('primaryContacts');
        }

        if (customApplicationDomain.getJsonSchemaSection('accountPayableContacts')) {
          this.accountPayableContacts = customApplicationDomain.getJsonSchemaSection('accountPayableContacts');
        }

        if (customApplicationDomain.getJsonSchemaSection('siteContacts')) {
          this.siteContacts = customApplicationDomain.getJsonSchemaSection('siteContacts');
        }

        if(properties.RELIABLE_HIRE_ORGS.includes(this.creditApplicationDomain.organizationId)) {
          if(this.isCreditApplication()) {
            customApplicationDomain.removeSectionFromJsonSchema('accountContacts');
            customApplicationDomain.addSectionToJsonSchema(this.primaryContacts, 'primaryContacts');
            customApplicationDomain.addSectionToJsonSchema(this.accountPayableContacts, 'accountPayableContacts');
            customApplicationDomain.addSectionToJsonSchema(this.siteContacts, 'siteContacts');
          } else {
            customApplicationDomain.removeSectionFromJsonSchema('primaryContacts');
            customApplicationDomain.removeSectionFromJsonSchema('accountPayableContacts');
            customApplicationDomain.removeSectionFromJsonSchema('siteContacts');
            customApplicationDomain.addSectionToJsonSchema(this.accountContacts, 'accountContacts');
          }
        }

        this.updateJsonSchemas(customApplicationDomain.getJsonSchemas());
        this.updateUiSchemas(customApplicationDomain.getUiSchemas());
      }

      if(properties.SSAA_ORGS.includes(this.creditApplicationDomain.organizationId)
          && this.memberType === 'existing_member') {
        this.removeFromWizard("Contacts", "contacts", customApplicationDomain);
        this.removeFromWizard("Addresses", "registeredAddress", customApplicationDomain);
      }
    }

    if(stepTitle  === "Addresses") {
      if( (this.isCashApplication() && this.isCashApplicationResidential())) {
        const jsonSchema = customApplicationDomain.getJsonSchemas();
        const properties = jsonSchema[step].properties;

        properties.registeredAddress.title = "Home Address";
        properties.billingAddress.title = "Postal Address";

        jsonSchema[step].properties = properties;

        this.updateJsonSchemas(jsonSchema);
      }

      // Oma group contacts
      if(this.isCustomerApplication()) {

        this.saveCustomApplication();

        if(this.isCashCustomerIndividual() || this.isCashCustomerCompany()) {
          this.removeFromWizard("Trade References", "referenceOne", customApplicationDomain);
          const jsonSchema = customApplicationDomain.getJsonSchemas();
          jsonSchema[step].definitions.address.required = [];
          this.updateJsonSchemas(jsonSchema);
        }
      }
    }

    if(stepTitle  === "Documents" ) {

      const filePropertie = "file_currencyVerification";
      const fileLabel = "Please upload a copy of Certificate of Currency [pdf, jpg, png, gif]";
      const idVerificationLabel = "ID Verification (e.g: Passport/National ID Card) [pdf, jpg, png, gif]";

      // Oma group customization
      if(this.isCustomerApplication()) {
        if(this.isCashCustomerCompany()) {
          customApplicationDomain.removeRequiredField("file_idVerification");
          customApplicationDomain.removeRequiredField("file_proofAddress");
          customApplicationDomain.removeRequiredField("file_taxId");
        }
        if(this.isCashCustomerIndividual() || this.isCreditCustomerIndividual() ) {
          customApplicationDomain.removePropertiesFromJsonSchema('file_taxId');
          customApplicationDomain.removePropertiesFromJsonSchema('file_proofAddress');
          customApplicationDomain.removeRequiredField("file_proofAddress");
          customApplicationDomain.removeRequiredField("file_taxId");
          if(this.isCreditCustomerIndividual()) {
            customApplicationDomain.addRequiredField("file_idVerification");
          }
          customApplicationDomain.addPropertiesToJsonSchema({"file_idVerification": customApplicationDomain.generateFileField(idVerificationLabel)});
        }
      }

      if(this.isPrePaidAccountApplication()) {
        customApplicationDomain.removePropertiesFromJsonSchema('download_directorsGuarantee');
        customApplicationDomain.removeRequiredField("file_directorsGuarantee");
        customApplicationDomain.addRequiredField("file_idVerification");
      }

      if(this.isCreditAccountApplication()) {
        customApplicationDomain.addRequiredField("file_idVerification");
        customApplicationDomain.addRequiredField("file_directorsGuarantee");
      }

      if(this.isCustomCreditApplication()) {
        customApplicationDomain.removeRequiredField("file_idVerification");
      }

      if( (this.isCashApplication() || this.isCustomCreditApplication())) {
        if(this.customApplication.waiverFeeNoticeElection) {
          customApplicationDomain.removePropertiesFromJsonSchema(filePropertie);
          customApplicationDomain.removeRequiredField(filePropertie);
        } else {
          const uiProperty = customApplicationDomain.generateFileFieldUiSchema(fileLabel);
          customApplicationDomain.addPropertiesToUiSchema({file_currencyVerification: uiProperty});
          customApplicationDomain.addRequiredField(filePropertie);
          customApplicationDomain.addPropertiesToJsonSchema({"file_currencyVerification": customApplicationDomain.generateFileField(fileLabel)});
        }
        // customApplicationDomain.addPropertiesToUiSchema({"ui:order": ["file_idVerification", "file_currencyVerification", "file_directorsGuarantee", "download_directorsGuarantee"]});
      }

      if(this.isCashApplication()) {
        customApplicationDomain.addRequiredField("file_idVerification");
      }

      if(properties.RELIABLE_HIRE_ORGS.includes(this.creditApplicationDomain.organizationId)) {
        if(this.isCreditApplication()) {
          customApplicationDomain.addRequiredField("file_directorsGuarantee");
          customApplicationDomain.removeRequiredField('file_idVerification');
        } else {
          customApplicationDomain.addRequiredField("file_idVerification");
          customApplicationDomain.removeRequiredField('file_directorsGuarantee');
          customApplicationDomain.removePropertiesFromJsonSchema('file_directorsGuarantee');
          customApplicationDomain.removePropertiesFromJsonSchema('download_directorsGuarantee');
        }
      }

      this.updateUiSchemas(customApplicationDomain.getUiSchemas());
      this.updateJsonSchemas(customApplicationDomain.getJsonSchemas());
    }

    if(stepTitle  === "Payment Authorisation" || stepTitle  === "Payments")  {

      this.checkIfCreditCardHasValue();

      if( (this.isCashApplication() || this.isCustomCreditApplication())) {

        if(!properties.RELIABLE_HIRE_ORGS.includes(this.creditApplicationDomain.organizationId)) {
          const schema = {
            "type": "string",
            title: " ",
          }

          const ui = {
            "ui:widget": "alertBox",
          }
          customApplicationDomain.addPropertiesToJsonSchema({ alertBox: schema });
          customApplicationDomain.addPropertiesToUiSchema({ alertBox: ui });
          customApplicationDomain.addPropertiesToUiSchema({ "ui:order": ["alertBox", "creditCard"] });

          if(this.isCreditApplication()) {
            customApplicationDomain.removeRequiredField("creditCard");
          }

        }

        if(properties.RELIABLE_HIRE_ORGS.includes(this.creditApplicationDomain.organizationId)) {
          if(this.isCreditApplication()) {
            customApplicationDomain.removeRequiredField('creditCard');
          } else {
            customApplicationDomain.addRequiredField('creditCard');
            customApplicationDomain.removePropertiesFromJsonSchema('isDDAEnabled');
          }
        }

        this.updateJsonSchemas(customApplicationDomain.getJsonSchemas());
        this.updateUiSchemas(customApplicationDomain.getUiSchemas());

        // this.saveCustomApplication();
      }

      if(this.isCustomCreditApplication()) {
        if(directDebit) {
          this.updateDirectDebit(true);
        } else {
          this.updateDirectDebit(false);
          this.updateCreditCardInfo({
            clientSecret: undefined,
            feeInformation: {}
          });
          this.updateCreditCard({last4: "", holder: "", brand: ""});
        }
      }
    }

    if(this.isCashApplication()) {
      const jsonSchema = customApplicationDomain.getJsonSchemas();
      jsonSchema.forEach( (e, i) => {
        if(e.properties && e.properties['download_directorsGuarantee']) {
          delete jsonSchema[i].properties['download_directorsGuarantee'];
        }
      });

      this.updateJsonSchemas(jsonSchema);

      if(this.isCashApplicationResidential()) {
        this.fillCompanyInfoForResidential();
      }

      if(properties.RELIABLE_HIRE_ORGS.includes(this.creditApplicationDomain.organizationId)
        && this.isCashIndividual()) {
        this.fillCompanyInfoForCashApplication();
      }
    }

  }

  checkIfCreditCardHasValue() {
    if(this.creditCard.last4 === '' && this.creditCard.brand === '' && this.creditCard.holder === '') {
      this.formDataList.forEach((form) => {
        if(form?.creditCard) {
          this.creditCard = JSON.parse(form.creditCard);
        }
      });
    }
  }

  isLastStep() {
    return (this.jsonSchemas.length - 2) === (this.currentStep);
  }

  goToNext() {
    if (this.jsonSchemas.length > this.currentStep + 1) {
      this.currentStep = this.currentStep + 1;
    } else if (this.isFeeStructureStep()) {
      this.currentStep = this.currentStep + 1;
      return;
    }
    this.updateCustomApplicationSteps(this.currentStep);
    this.isAddressesStep();
  }

  prev() {
    if (this.currentStep > 0) {
      this.currentStep = this.currentStep - 1;
    }
    this.customCardValidator = null;
    this.updateKey();
    this.isAddressesStep();
    this.updateCustomApplicationSteps(this.currentStep);
  }

  setSameBillingAddressData() {
    const data = this.getFormData();
    data.billingAddress = data.registeredAddress;
    const formData = { formData: data };
    this.setData(formData);
    this.updateLiveValidateState(true);
  }

  setSameDeliveryAddressData() {
    const data = this.getFormData();
    data.deliveryAddress = data.billingAddress;
    const formData = { formData: data };
    this.setData(formData);
    this.updateLiveValidateState(true);
  }

  getFormData() {
    return toJS(this.formDataList[this.currentStep]) || {};
  }

  getContext() {
    return {
      countryCode: this.selectedCountry.value,
      paymentMethod: this.paymentMethodType,
      applicationType: this.applicationType,
      dateConfig: this.dateConfig,
      cardValidation: !this.validateCreditCard(),
      customApplication: this.customApplication,
      orgId: this.orgid
    };
  }

  setData(data) {
    this.updateLiveValidateState(false);

    const formDataList = this.formDataList[this.currentStep];

    if (
      !_isEmpty(data.errors) &&
      typeof formDataList !== 'undefined' &&
      !_isEmpty(formDataList) &&
      JSON.stringify(toJS(formDataList)) !== JSON.stringify(data.formData)
    ) {
      this.updateLiveValidateState(true);
    }

    this.directorsUploadError(data);
    this.formDataList[this.currentStep] = data.formData;
  }

  getCurrentJsonSchema() {
    return toJS(this.jsonSchemas[this.currentStep]);
  }

  getCurrentUiSchema() {
    return toJS(this.uiSchemas[this.currentStep]);
  }

  getCurrentStepTitle() {
    return this.wizards[this.currentStep].label;
  }

  fetchBusinessNumber(value) {
    if (ValidationUtils.validateBusinessNumber(value, this.selectedCountry.value)) {
      this.isSearchingBusinessNumber = true;
      this.isValidBusinessNumber = true;
      this.hadAValidBusinessNumber = true;

      ABNService.getABNInfo(value, this.selectedCountry.value)
        .then((response) => {
          runInAction(() => {
            this.isSearchingBusinessNumber = false;
            this.companyInfoErrors = {};
            this.businessNumberOptions.replace(response.data);

            if (response.data && response.data.length > 0) {
              this.creditApplicationDomain.fillFromAbnLookup(this.businessNumberOptions[0], this.dateConfig);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.businessNumberOptions.clear();

      if (!this.isSmokeballInstance()) {
        this.creditApplicationDomain.clear();
      }
    }
  }

  isSmokeballInstance() {
    return this.brand.instanceType === 'SMOKEBALL';
  }

  fetchTrusteeBusinessNumber(value) {
    if (ValidationUtils.validateBusinessNumber(value, this.selectedCountry.value)) {
      this.isSearchingTrusteeBusinessNumber = true;
      ABNService.getABNInfo(value, this.selectedCountry.value)
        .then((response) => {
          runInAction(() => {
            this.isSearchingTrusteeBusinessNumber = false;
            if (response.data && response.data.length > 0) {
              this.creditApplicationDomain.fillTrusteeInfoFromAbnLookup(this.businessNumberOptions[0]);
              this.companyInfoErrors = {};
            } else {
              this.companyInfoErrors['hasError'] = true;
              this.companyInfoErrors['errors'] = [
                { fieldError: 'trusteeBusinessNumber', message: ErrorMessages.BUSINESS_NUMBER_NOT_FOUND },
              ];
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  checkBusinessNumber() {
    if (
      this.caCustomization.businessNumber.validate
      && !ValidationUtils.validateBusinessNumber(this.creditApplicationDomain.businessNumber, this.selectedCountry.value)
    ) {
      const tempAbn = this.creditApplicationDomain.businessNumber;
      this.businessNumberOptions.clear();
      this.isValidBusinessNumber = false;
      this.creditApplicationDomain.updateAttr(this.abnField, tempAbn);
      this.companyInfoErrors['hasError'] = true;
      this.companyInfoErrors['errors'] = [
        { fieldError: 'businessNumber', message: ErrorMessages.INVALID_BUSINESS_NUMBER },
      ];

      if (!this.isSmokeballInstance()) {
        this.creditApplicationDomain.clear();
      }
    } else {
      this.clearAbnError();
    }
  }

  clearAbnError() {
    if (this.companyInfoErrors.hasError) {
      const index = this.companyInfoErrors.errors.findIndex((e) => e.fieldError === this.abnField);
      if (index >= 0) {
        this.companyInfoErrors.errors.splice(index, 1);
        this.companyInfoErrors.hasError = this.companyInfoErrors.errors.length > 0;
      }
    }
  }

  selectBusinessNumber(index) {
    const abn = this.businessNumberOptions[index];
    this.businessNumberSelectedIndex = index;
    this.creditApplicationDomain.fillFromAbnLookup(abn);
  }

  selectCurrency(selectedCurrency) {
    this.creditApplicationDomain.updateAttr('currency', selectedCurrency);
    this.selectedCurrency = selectedCurrency;
  }

  selectCountry(selectedIndex) {
    if(this.isCustomerApplication()) {
      this.selectedCountry = this.countryOptions[selectedIndex];
      this.creditApplicationDomain.updateAttr('country', this.countryOptions[selectedIndex].value);
      return;
    }

    if (CountryCodes.REGULAR_REGION_CODES.includes(this.countryOptions[selectedIndex]?.value)) {
      this.businessNumberOptions.clear();
      this.companyInfoErrors = {};
      this.isValidBusinessNumber = false;
      this.hadAValidBusinessNumber = false;

      if (!this.isSmokeballInstance() && this.caCustomization.country.visible) {
        this.creditApplicationDomain.clear();
      }
    } else {
      this.isValidBusinessNumber = true;
      this.hadAValidBusinessNumber = true;
    }
  
    this.selectedCountry = this.countryOptions[selectedIndex];
    this.creditApplicationDomain.updateAttr('country', this.countryOptions[selectedIndex].value);
  }

  hasErrorInCompanyInfoFormAttr(attr) {
    let error = undefined;

    if (this.companyInfoErrors.hasError) {
      error = this.companyInfoErrors.errors.find((error) => error.fieldError === attr);
    }

    return { hasError: error !== undefined, message: error ? error.message : '' };
  }

  hasErrorInFeeStructureFormAttr(parent, fieldKey) {
    let error = undefined;
    if (this.feeStructureErrors.hasError) {
      error = this.feeStructureErrors.errors.find((error) => error[parent]?.fieldError === fieldKey);
    }

    return {
      hasError: error !== undefined,
      message: error ? error[parent].message : '',
    };
  }

  isConfirmationStep() {
    return this.currentStep === this.wizards.length - 1;
  }

  isFeeStructureStep() {
    // return true;
    return this.applicationType === 'paymentApplication' && this.currentStep === this.wizards.length - 2;
  }

  openConfirmModal() {
    this.isConfirmModalOpen = true;
    this._trackEvent('Clicked on confirm');
  }

  closeConfirmModal() {
    this.isConfirmModalOpen = false;
    this._trackEvent('Close accepted terms modal');
  }

  confirm() {

    ApiService.complete(this.creditApplicationId, this.applicationType, this.authToken)
      .then(() => {
        runInAction(() => {
          this.loading = false;
          this.isConfirmed = true;
          this.currentStep = this.wizards.length;
        });
      })
      .catch((error) => {
        console.log(error);
        this._shouldRestart();
      });
  }

  showTitle() {
    return !(this.isConfirmationStep() || this.isConfirmed || this.isFeeStructureStep());
  }

  toggleCompanyHasBusinessNumber() {
    this.creditApplicationDomain.isTrusteeWithoutBusinessNumber = !this.creditApplicationDomain
      .isTrusteeWithoutBusinessNumber;
    this.clearTrusteeInfo();
  }

  clearTrusteeInfo() {
    this.creditApplicationDomain.trusteeFullName = '';
    this.creditApplicationDomain.trusteeDateOfBirth = '';
    this.creditApplicationDomain.trusteeDocument = '';
    this.creditApplicationDomain.trusteeBusinessNumber = '';
    this.creditApplicationDomain.trusteeCompanyNumber = '';
    this.creditApplicationDomain.trusteeBusinessType = '';
    this.creditApplicationDomain.trusteeTradingName = '';
  }

  getFormWithData() {
    const form = this.formStructure;

    if(this.isCashApplication() && this.isCustomCreditApplication()) {
      // todo refact to a function
      const index = form.findIndex( (step) => step.formJsonSchema.jsonSchemaData.data.properties.hasOwnProperty("contacts") );
      if(index !== -1) {
        const constactProperties = form[index].formJsonSchema.jsonSchemaData.data.properties.contacts.items.properties;
        const contactUiItems = form[index].formUiSchema.uiSchemaData.data.contacts.items
        const customerNumber = {"title": "Customer No.", "type": "string"};
        const customerNumberUI = {'ui:column': ''};
        form[index].formJsonSchema.jsonSchemaData.data.properties.contacts.items.properties = {...constactProperties, customerNumber};
        form[index].formUiSchema.uiSchemaData.data.contacts.items = {...contactUiItems, customerNumber: customerNumberUI};
        form[index].formJsonSchema.jsonSchemaData.data.properties.contacts.items.required.push("customerNumber");
  
        if( (this.customApplication.type ===  ApplicationType.cashApplication && this.customApplication.customerType === CustomerType.residential)) {
          const driversLicense = {"title": "Driver's License", "type": "string"};
          const driversLicenseUI = {'ui:column': ''};
          const dateOfBirth = {"title": "Date Of Birth", "type": "string"};
          const dateOfBirthUI = {'ui:column': ''};
          form[index].formJsonSchema.jsonSchemaData.data.properties.contacts.items.properties = {...constactProperties, driversLicense, dateOfBirth};
          form[index].formUiSchema.uiSchemaData.data.contacts.items = {
            ...contactUiItems,
            customerNumber: customerNumberUI,
            driversLicense: driversLicenseUI,
            dateOfBirth: dateOfBirthUI
          };
          form[index].formJsonSchema.jsonSchemaData.data.properties.contacts.items.required.push("driversLicense", dateOfBirth);
        }
      }
  
  
      if( (this.customApplication.type ===  ApplicationType.cashApplication && this.customApplication.customerType === CustomerType.residential)) {
        const addressIndex = form.findIndex( (step) => step.formJsonSchema.jsonSchemaData.data.properties.hasOwnProperty("registeredAddress") );
        if(addressIndex !== -1) {
          form[addressIndex].formJsonSchema.jsonSchemaData.data.properties.registeredAddress.title = "Home Address";
          form[addressIndex].formJsonSchema.jsonSchemaData.data.properties.billingAddress.title = "Postal Address";
        }
      }
    }

    if(this.isCashCustomerIndividual() || this.isCreditCustomerIndividual()) {
      const index = form.findIndex( (step) => step.formJsonSchema.jsonSchemaData.data.properties.hasOwnProperty("contacts") );
      if(index !== -1) {
        form.splice(index, 1);
        this.formDataList.splice(index,1);
      }
    }

    const dynamicFormBuilder = new DynamicFormBuilder(form);
    return dynamicFormBuilder.generateFormWithData(toJS(this.formDataList));
  }

  fillFormDataListFromApi(creditApplicationFormDataList = []) {

    if (creditApplicationFormDataList.length > 0) {
      creditApplicationFormDataList.forEach((formStepData) => {
        if (formStepData) {
          this.formatDirectorsBirthDate(formStepData.formData, true);
          this.addPaymentAuthorisationInfo(formStepData.formData);
          this.addCustomApplication(formStepData.formData);
          this.formDataList[formStepData.stepOrder] = formStepData.formData;
        }
      });

      const formDataList = [];
      this.formDataList.forEach( (e, i) => {
        if(i === 0) {
          formDataList.push(e);
        }else {
          if(e && Object.keys(e).length !== 0) {
            formDataList.push(e);
          } else {
            formDataList.push({});
          }
        }
      });
      this.formDataList = formDataList;
    }
  }

  updateProspectEmail(value) {
    this.hasErrorInProspectEmail = false;
    this.prospectEmail = value;
  }

  async saveDraft() {
    if (this.prospectEmail.match(REGEX.EMAIL)) {
      this.isSaving = true;
      NProgress.start();

      this.saveDraftCustomApplication();

      if (this.isFeeStructureStep()) {
        try {
          const { data } = await ApiService.saveFeeStructureData(
            this.creditApplicationId,
            this.feeStructureDomain.toParams(this.creditApplicationDomain.organizationId),
            this.authToken
          );

          if (data) {
            NProgress.done();
            this.isSaving = false;
          }
        } catch (exception) {
          console.log(exception);
          this._shouldRestart();
          this.isSaving = false;
        }

        this.isSaveDraftModalVisible = false;
      } else {
        ApiService.updateCreditApplicationFormData(
          this.creditApplicationId,
          this.wizards[this.currentStep].id,
          this.formDataList[this.currentStep],
          this.applicationType,
          this.authToken
        )
          .then(() => {
            NProgress.done();
            runInAction(() => {
              this.isSaving = false;
            });
          })
          .catch((error) => {
            console.log("add data error:", error);
            this._shouldRestart();
            runInAction(() => {
              this.isSaving = false;
            });
          });
      }

      ApiService.saveDraft(this.creditApplicationId, this.prospectEmail, this.applicationType, this.authToken)
        .then(() => {
          this.isDraftSaved = true;
        })
        .catch((error) => console.log(error));

      this._trackEvent('Save Draft', { email: this.prospectEmail });
    } else {
      this.hasErrorInProspectEmail = true;
    }
  }

  getOtpToken() {
    this.loading = true;

    otpService.isOtpEnabled(this.creditApplicationId).then((response) => {
      const { enabled } = response?.data;

      if (enabled) {
        otpService
          .getTokenId(this.creditApplicationId)
          .then((response) => {
            this.otpEmail = response?.data?.email;
            this.uuid = response?.data?.id;
          })
          .catch((error) => {
            console.log(error);
            this.isLinkExpired = true;
          })
          .finally(() => (this.loading = false));
      } else {
        this.isOTPConfirmed = true;
        this.fillDataFromCreditApplicationId(this.creditApplicationId);
      }
    });
  }

  verifyOtpCode(otpCode) {
    this.loading = true;
    otpService
      .verifyOtpCode(this.creditApplicationId, { uuid: this.uuid, otp: otpCode })
      .then((response) => {
        this.authToken = response?.data?.id;
        this.isOTPConfirmed = true;
        this.fillDataFromCreditApplicationId(this.creditApplicationId);
      })
      .catch(({ response }) => {
        this.otpErrorMessage = response?.data?.message;
      })
      .finally(() => (this.loading = false));
  }

  setDraftSaved(isDraftSaved) {
    this.isDraftSaved = isDraftSaved;
  }

  _trackEvent(event, data) {
    if (window.analytics) {
      window.analytics.track(event, data);
    }
  }

  _shouldRestart() {
    if (this.applicationType === 'paymentApplication') {
      window.removeEventListener('beforeunload', prevent);
      window.location.reload();
    }
  }

  fillCompanyInfoForOmaGroup() {
    if(this.isCustomerApplication()) {
      this.creditApplicationDomain.updateAttr('isCustomerApplication', this.isCustomerApplication());
      this.creditApplicationDomain.updateAttr('isIndividualCreditApplication', ( this.isCreditCustomerIndividual() || this.isCashCustomerIndividual() ));
      this.creditApplicationDomain.updateAttr('isCustomerCreditApplication', ( this.isCreditCustomerIndividual() || this.isCreditCustomerCompany() ));
      this.creditApplicationDomain.numberOfEmployees = "0";
      this.creditApplicationDomain.natureOfBusiness = "---";
    }

    if(this.isCashCustomerIndividual()
      || this.isCreditCustomerIndividual()
      || (properties.RELIABLE_HIRE_ORGS.includes(this.creditApplicationDomain.organizationId) && this.isCashIndividual())) {
      this.creditApplicationDomain.businessNumber = "---";
      this.creditApplicationDomain.businessType = "---";
      this.creditApplicationDomain.dateOfIncorporation = new Date();
      this.creditApplicationDomain.registeredCompanyName = this.creditApplicationDomain.fullName;
      this.creditApplicationDomain.tradingName = this.creditApplicationDomain.fullName;
      this.creditApplicationDomain.natureOfBusiness = "---";
      this.creditApplicationDomain.numberOfEmployees = "0";
    }

    if(this.isCashCustomerCompany() || this.isCashCustomerIndividual() ) {
      this.creditApplicationDomain.creditPeriod = 0;
    }
  }

  fillCompanyInfoForMayers() {
    this.creditApplicationDomain.numberOfEmployees = "0";
    this.creditApplicationDomain.natureOfBusiness = "---";
  }

  fillCompanyInfoForResidential() {
    this.creditApplicationDomain.country = "AU";
    this.creditApplicationDomain.businessNumber = "0000000";
    this.creditApplicationDomain.businessType = "---";
    this.creditApplicationDomain.dateOfIncorporation = new Date();
    this.creditApplicationDomain.registeredCompanyName = "---";
    this.creditApplicationDomain.tradingName = "---";
    this.creditApplicationDomain.natureOfBusiness = "---";
    this.creditApplicationDomain.numberOfEmployees = "0";

    this.formDataList.forEach(e => {
      if(e?.contacts && e?.contacts[0]?.fullName) {
        this.creditApplicationDomain.tradingName = e.contacts[0].fullName;
        this.creditApplicationDomain.registeredCompanyName = e.contacts[0].fullName;
        return;
      }
    });
  }

  fillCompanyInfoForCashApplication() {
    this.creditApplicationDomain.country = "AU";
    this.creditApplicationDomain.businessNumber = "0000000";
    this.creditApplicationDomain.businessType = "---";
    this.creditApplicationDomain.dateOfIncorporation = new Date();
    this.creditApplicationDomain.registeredCompanyName = "---";
    this.creditApplicationDomain.tradingName = "---";
    this.creditApplicationDomain.natureOfBusiness = "---";
    this.creditApplicationDomain.numberOfEmployees = "0";

    this.formDataList.forEach(e => {
      if(e?.accountContacts && e?.accountContacts?.fullName) {
        this.creditApplicationDomain.tradingName = e.accountContacts.fullName;
        this.creditApplicationDomain.registeredCompanyName = e.accountContacts.fullName;
        return;
      }
    });
  }

  cleanCompanyInfoForResidential() {
    this.creditApplicationDomain.country = "AU";
    this.creditApplicationDomain.businessNumber = "";
    this.creditApplicationDomain.businessType = "";
    this.creditApplicationDomain.dateOfIncorporation = "";
    this.creditApplicationDomain.registeredCompanyName = "";
    this.creditApplicationDomain.tradingName = "";
    this.creditApplicationDomain.natureOfBusiness = "";
    this.creditApplicationDomain.numberOfEmployees = "";
    this.creditApplicationDomain.fullName = "";
    this.creditApplicationDomain.email = "";
    this.creditApplicationDomain.phoneNumber = "";
    this.creditApplicationDomain.currency = "";
    this.selectedCurrency = "";
  }

  updateJsonSchemas(schema) {
    this.jsonSchemas = schema;
  }

  updateUiSchemas(uiSchema) {
    this.uiSchemas = uiSchema;
  }

  updateDirectDebit(value) {
    const step = this.currentStep;
    const stepTitle = this.getCurrentStepTitle();

    const customApplicationDomain = new CustomApplicationDomain(
      step,
      stepTitle,
      toJS(this.jsonSchemas),
      toJS(this.uiSchemas)
    );

    const creditCard = "creditCard";

    if(this.isCustomCreditApplication() && !properties.RELIABLE_HIRE_ORGS.includes(this.creditApplicationDomain.organizationId)) {
      if(value && !customApplicationDomain.hasProperty(creditCard)) {
        customApplicationDomain.addPropertiesToJsonSchema({"creditCard": {title: "Please enter your Card details below:", type: "string"}});
        if(!properties.BROOKS_HIRE_ORGS.includes(this.creditApplicationDomain.organizationId)) {
           customApplicationDomain.addRequiredField(creditCard);
        }
      } else {
        customApplicationDomain.removePropertiesFromJsonSchema(creditCard);
        customApplicationDomain.removeRequiredField(creditCard);
        this.updateCreditCardInfo({
          clientSecret: undefined,
          feeInformation: {}
        });
        const creditCardIdx = this.formDataList.findIndex( x => x?.creditCard);
        if(creditCardIdx !== -1) {
          delete this.formDataList[creditCardIdx].creditCard;
        } 
        this.updateCreditCard({last4: "", holder: "", brand: ""});
      }
    }

    this.updateJsonSchemas(customApplicationDomain.getJsonSchemas());
    this.updateUiSchemas(customApplicationDomain.getUiSchemas());
    // this.saveCustomApplication();
  }

  clearCustomCardValidator() {
    this.customCardValidator = null;
  }

  updateCreditCardInfo(cardInfo) {
    this.creditCardInfo = cardInfo;
  }

  updateCreditCard(creditCard) {
    if(typeof creditCard === "string") {
      this.creditCard = JSON.parse(creditCard);
    }
    this.creditCard = creditCard;
    if(this.setCreditCardData) {
      this.setCreditCardData("valid");
    }
  
    this.customCardValidator = null;
  }

  updateBankTransferInfo(bankTransferInfo) {
    if(typeof bankTransferInfo === "string") {
      bankTransferInfo = JSON.parse(bankTransferInfo);
    }
    
    if (this.payToAgreement?.status === 'ACTIVE') {
      this.bankTransferInfo = {...bankTransferInfo, authorityType: 'PayTo'}
    } else {
      this.bankTransferInfo = bankTransferInfo;
    }
  }

  validateCreditCard() {

    if(!this.customCardValidator) {
      return true;
    }

    const {cardAgreement, cardHolderName, cardNumber, cardExpiry, cardCvc} = this.customCardValidator;

    let isValid = false;
   
    if(cardAgreement && cardAgreement.empty) {
      isValid = true;
    }

    if(cardHolderName && cardHolderName.empty) {
      isValid = true;
    }

    if(cardNumber && cardNumber.empty) {
      isValid = true;
    }

    if(cardExpiry && cardExpiry.empty) {
      isValid = true;
    }

    if(cardCvc && cardCvc.empty) {
      isValid = true;
    }

    if(
      cardAgreement &&
      cardHolderName &&
      cardNumber &&
      cardExpiry &&
      cardCvc
    ) {
      isValid = true;
    }

    if(
      cardAgreement?.empty &&
      cardHolderName?.empty &&
      cardNumber?.empty &&
      cardExpiry?.empty &&
      cardCvc?.empty
    ) {
      isValid = true;
    }

    if(
      (cardNumber?.complete &&
      cardExpiry?.complete &&
      cardCvc?.complete) &&
      ( !cardNumber?.error &&
        !cardExpiry?.error&&
        !cardCvc?.error )
    ) {
      isValid = true;
    }

    if((!cardAgreement || cardAgreement?.empty) && (!cardCvc?.empty && !cardExpiry?.empty && !cardNumber?.empty && !cardHolderName?.empty)) {
      return false;
    }

    return isValid;
  }

  updateCustomCardValidator(obj) {
    // field: "cardAgreement"
    // field: "cardHolderName"
    // field: "cardNumber"
    // field: "cardExpiry"
    // field: "cardCvc"
    this.customCardValidator = {...this.customCardValidator, ...obj};
  }

  setCustomerTypeError(hasError) {
    this.customApplication.error.customerType = hasError;
  }

  updateCustomApplication(data) {
    const custom = { ...this.customApplication, ...data};
    this.customApplication = custom;
  }

  validateCustomApplication() {
    this.customApplication.error.customerType = false;
    this.customApplication.error.type = false;

    if(this.customApplication.type === "") {
      this.customApplication.error.type = true;
    }

    if(this.customApplication.type === ApplicationType.cashApplication && this.customApplication.customerType === "") {
      this.customApplication.error.customerType = true;
    }

    return (this.customApplication.error.type || this.customApplication.error.customerType);
  }

  updatePayToAgreement(agreement) {
    this.payToAgreement = agreement;
  }

  updateMemberType(value) {
    this.memberType = value;
  }
}

export default createContext(new MainStore());
