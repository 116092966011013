import React from 'react';
import classNames from 'classnames';

function columnsObjectFieldTemplate({ properties, description, title, type, required }) {
  return (
    <fieldset className="bg-gray-70 p-4">
      <legend className="float-left mt-0 mb-4 w-full uppercase text-indigo-700 font-semibold">{title}</legend>
      <div className="w-full py-1">{description}</div>
      <div className="w-full flex flex-wrap">
        {properties.map((prop) => {
          const uiSchema = prop.content.props.uiSchema;
          const className = classNames('px-2 mb-4', uiSchema['ui:column'] || 'w-full');
          // const opts = uiSchema['ui:options'];

          return (
            <div key={prop.content.key} className={className}>
              {prop.content}
            </div>
          );
        })}
      </div>
    </fieldset>
  );
}

export default columnsObjectFieldTemplate;
