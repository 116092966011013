import React, { ReactNode } from 'react';
import './EzyPayFacFormStyle.scss';

type FormGroupProps = {
  handleChange: (evt: any) => void;
  handleBlur?: (evt: any) => void;
  name: string;
  value: string;
  placeholder: string;
  title: string;
  error?: string | null;
  customInput?: ReactNode;
  customMessage?: ReactNode;
  disabled?: boolean;
  type?: 'text' | 'number' | 'radio';
  otherProps?: any;
};

const FormGroup: React.FC<FormGroupProps> = ({
  handleChange,
  handleBlur = () => {},
  name,
  value,
  placeholder,
  title,
  error,
  customInput = null,
  customMessage = null,
  disabled = false,
  type = 'text',
  otherProps,
}) => {
  return type === 'radio' ? (
    <>
      <input
        placeholder={placeholder}
        name={name}
        className={`payment-input ${error ? 'error' : ''}`}
        onChange={handleChange}
        onBlur={handleBlur}
        type="radio"
        value={value}
        disabled={disabled}
        id={name}
        {...otherProps}
      />
      <label htmlFor={name}>{title}</label>

      {customMessage && !error && <>{customMessage}</>}
      {error && <span className="field-error">{error}</span>}
    </>
  ) : (
    <div className="stripe-form-group">
      { title ?
        <label className="payment-label" id={name}>
          {title}
          <span id="required">*</span>
        </label>
      : '' }
      {customInput ? (
        <div className={`payment-input ${error ? 'error' : ''}`}>{customInput} </div>
      ) : (
        <input
          placeholder={placeholder}
          name={name}
          className={`payment-input ${error ? 'error' : ''}`}
          onChange={handleChange}
          onBlur={handleBlur}
          type={type}
          value={value}
          disabled={disabled}
          {...otherProps}
        />
      )}
      {customMessage && !error && <>{customMessage}</>}
      {error && <span className="field-error">{error}</span>}
    </div>
  );
};

export default FormGroup;
