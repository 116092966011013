import React, { Fragment } from 'react';

function CreditCardFee(props: any) {
  const { feeInformation, card } = props
  const feeCard = feeInformation.supportedCardsInfo.filter((item: any) => card.brand && card.brand.toLowerCase() === item.type.toLowerCase())[0];

  let hasSurcharge = feeCard && feeCard.surchargeValue && feeCard.surchargeValue !== '0';
  let surcharge = hasSurcharge ? feeCard.surchargeText + '%' : undefined;

  let hasServiceFee = feeInformation.serviceFeeInfo && feeInformation.serviceFeeInfo.enabled && feeInformation.serviceFeeInfo.displayValue !== '0';
  let serviceFee = hasServiceFee && (feeInformation.serviceFeeInfo.valueType === 'PERCENTAGE' ? feeInformation.serviceFeeInfo.displayValue + '%' : '$' + feeInformation.serviceFeeInfo.displayValue);

  let hasInternationalFee = feeInformation.i18FeeInfo && feeInformation.i18FeeInfo.enabled && feeInformation.i18FeeInfo.displayValue !== '0';
  let internationalFee = hasInternationalFee && (feeInformation.i18FeeInfo.valueType === 'PERCENTAGE' ? feeInformation.i18FeeInfo.displayValue + '%' : '$' + feeInformation.i18FeeInfo.displayValue);
  
  const basicFeeText = (feeCardType?: string, surcharge?: string, serviceFee?: string) => {
    if(!feeCardType) return null;

    if(surcharge) {
      if(serviceFee) {
        return `A transaction fee of ${serviceFee} and ${surcharge} for ${feeCardType.toUpperCase()} will be added to the payment amount`;
      } else {
        return `A transaction fee of ${surcharge} for ${feeCardType.toUpperCase()} will be added to the payment amount`;
      }
    } else {
      if(serviceFee) {
        return `A transaction fee of ${serviceFee} for ${feeCardType.toUpperCase()} will be added to the payment amount`
      } else {
        return null;
      }
    }
  };

  const TopFeeText = (props: {text?: string;}) => {
    return (
      <div className="text-center mt-5 link font-sans">
        { props.text }
      </div>
    );
  };

  const BottomFeeText = (props: {text?: string;}) => {
    return (
      <div className="text-center mb-5 link font-sans">
        { props.text }
      </div>
    );
  };

  const FullFeeText = (props: {cardBrand?: string, surcharge?: string, serviceFee?: string, internationalFee?: string, feeCardBrand?: string}) => {
    const {cardBrand, surcharge, serviceFee, internationalFee, feeCardBrand } = props;
    const text = basicFeeText(feeCardBrand, surcharge, serviceFee);
    const cardBrandSet = cardBrand && cardBrand !== 'unknown';
    
    if(internationalFee && text) {
      return (
        <Fragment>
          <TopFeeText text={text} />
          <BottomFeeText text={`(if it is an international card a fee of ${internationalFee} will be added instead)`} />
        </Fragment>
      );
    } else if(text) {
      return (
        <TopFeeText text={text} />
      );
    } else if(internationalFee && cardBrandSet) {
      return (
        <TopFeeText text={`If it is an international card a transaction fee of ${internationalFee} will be added`} />
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <FullFeeText surcharge={surcharge} serviceFee={serviceFee} cardBrand={card && card.brand}
        feeCardBrand={feeCard && feeCard.type} internationalFee={internationalFee} />
    </div>
  );
}

export default CreditCardFee;