import React, { useContext, useEffect } from 'react';
import MainStore from '../../stores/MainStore';
import properties from '../../constants/properties';
import { ApplicationType } from '../../domains/TypeEnum';
import PaymentAgreementDetails from '../PrintForm/components/PaymentAgreementDetails';

function RadioButton(props: Record<string, any>) {
  const mainStore: any = useContext(MainStore);
  const { organizationId }: any = mainStore.creditApplicationDomain;

  useEffect(() => {
    mainStore.updateDirectDebit(props.checked);
  }, [mainStore, props]);

  return (
    <div className="mt-5">
      <label className="select-none" htmlFor="directDebit">
        {properties.BROOKS_HIRE_ORGS.includes(organizationId) ? (
          <>
            Sign up to Direct Debit?
          </>
        ) : (
          <>
            Do you want to enable direct debit payment in your account?*
          </>
        )}
      </label>
      <div className="mt-2">
        <input
          id="radio-yes"
          name="directDebit"
          type="radio"
          checked={props.checked}
          value="true"
          onChange={(e) => props.onChange(e)}
        />
        <label className="p-2 select-none" htmlFor="radio-yes">
          Yes
        </label>
        <input
          id="radio-no"
          name="directDebit"
          type="radio"
          value="false"
          checked={!props.checked}
          onChange={(e) => props.onChange(e)}
        />
        <label className="p-2 select-none" htmlFor="radio-no">
          No
        </label>
      </div>
    </div>
  );
}

function AlertBox() {
  const paymentDiv = {
    padding: '15px 10px',
  };

  const paymentParagraph = {
    fontSize: '14px',
    fontWeight: '600',
    color: 'rgb(2, 22, 44)',
  };

  const mainStore: any = useContext(MainStore);
  const { organizationId }: any = mainStore.creditApplicationDomain;
  const { directDebit }: any = mainStore.customApplication;

  const onChange = (e: Record<string, any>) => {
    mainStore.updateCustomApplication({ directDebit: e.target.value === 'true' });
    mainStore.updateDirectDebit(e.target.value === 'true');
  };

  return (
    <>
      {properties.BROOKS_HIRE_ORGS.includes(organizationId) ||
        (properties.RELIABLE_HIRE_ORGS.includes(organizationId) && (
          <div className="w-full border border-gray-400 border-solid rounded p-2 my-4 pt-2 text-gray-600">
            {properties.BROOKS_HIRE_ORGS.includes(organizationId) && (
              <PaymentAgreementDetails
                customApplication={mainStore.customApplication}
                css={{ paymentDiv, paymentParagraph }}
                organizationId={organizationId}
              />
            )}
            {mainStore.customApplication.type === ApplicationType.creditApplication && (
              <RadioButton onChange={onChange} checked={directDebit} />
            )}
          </div>
        ))}
    </>
  );
}

export default AlertBox;
