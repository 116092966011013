const properties = {
  LOCAL_STORAGE_CA_ID: 'ci_id',
  US_ONBOARDING_ORGS: [4023, 6961, 6998],
  NZ_ONBOARDING_ORGS: [7796,7797, 7836, 4463],
  // organization customization 1828=regte local/UAT, 3433=rayor UAT, 4021=smoke1 PROD
  TEST_ORGS: [1828, 3433, 4021],
  SAFESMART_ORG: [3532],
  HAA_HAA_ORGS: [6719, 6958, 6957],
  POWELL_LYONS_ORGS: [6520, 6666],
  POPS_GROUP_ORGS: [6487],
  SOUNG_YUEEN_ORGS: [6568],
  HG_GROUP_ORGS: [7088, 7086, 7087, 4045, 4046, 4047],
  HEALTH_CARE_OGRS: [15471],
  BROOKS_HIRE_ORGS: [7255, 6754, 4064],
  MAYERS_ORGS: [4372, 7504],
  ORAFOL_ORGS: [5880],
  OMA_GROUP: [4417, 7673, 7674, 7675, 7676, 7677, 7678],
  RELIABLE_HIRE_ORGS: [4712, 3835],
  SSAA_ORGS: [8439, 4273],
};

export default properties;
