import { useContext } from 'react';
import FormField from '../FormField';
import MainStore from '../../stores/MainStore';
import { observer } from 'mobx-react-lite';
import LaunchDarklyStore from '../../stores/LaunchDarklyStore';

const DraftModalBody = observer(() => {
  const mainStore = useContext(MainStore);
  const launchDarklyStore = useContext(LaunchDarklyStore);
  const caCustomization = launchDarklyStore.caCustomization.value;

  return (
    <div style={{ width: '600px' }} className="text-gray-700 max-h-80 h-36 flex items-center">
      {mainStore.isDraftSaved ? (
        <div className="flex">
          <p className="text-gray-700 mx-4">
            {caCustomization.draftText}
          </p>
        </div>
      ) : (
        <div>
          <p className="text-gray-700 mx-4">
            {caCustomization.draftTextForm}
          </p>
          <div className="w-full mt-6 form-group">
            <FormField
              label="Email"
              value={mainStore.prospectEmail}
              placeholder="Please type an valid email"
              name="prospectEmail"
              onChange={(e) => mainStore.updateProspectEmail(e.target.value)}
              hasError={mainStore.hasErrorInProspectEmail}
              errorMessage={'The email must be valid'}
              type="text"
              id="prospectEmail"
            />
          </div>
        </div>
      )}
    </div>
  );
});
export default DraftModalBody;
