export interface FormFields {
  accountName: string;
  bsb: string;
  accountNumber: string;
  confirmAccountNumber: string;
  email: string;
  agreeToTerms: boolean;
}

export interface FormErrors {
  accountName?: string;
  bsb?: string;
  accountNumber?: string;
  confirmAccountNumber?: string;
  agreeToTerms?: string;
}

export const validateField = (formValues: FormFields, field: keyof FormFields): string | undefined => {
  switch (field) {
    case 'accountName':
      if (!formValues.accountName) {
        return 'Account Name is required';
      }
      break;
    case 'bsb':
      if (!formValues.bsb) {
        return 'BSB is required';
      } else if (!formValues.bsb.match(/^\d{6}$/)) {
        return 'BSB must contain only numbers and be 6 characters long';
      }
      break;
    case 'accountNumber':
      if (!formValues.accountNumber) {
        return 'Bank Account Number is required';
      } else if (!formValues.accountNumber.match(/^\d{1,9}$/)) {
        return 'Account Number must contain only numbers and maximum 9 characters long';
      }
      break;
    case 'confirmAccountNumber':
      if (!formValues.confirmAccountNumber) {
        return 'Confirm Account Number is required';
      } else if (formValues.accountNumber !== formValues.confirmAccountNumber) {
        return "Account Number didn't match";
      }
      break;
    case 'agreeToTerms':
      if (!formValues.agreeToTerms) {
        return 'Please agree to the terms';
      }
      break;
  }
};

export const validateForm = (formValues: FormFields): FormErrors => {
  const errors: FormErrors = {};
  Object.keys(formValues).forEach((field) => {
    const error = validateField(formValues, field as keyof FormFields);
    if (error) {
      errors[field as keyof FormErrors] = error;
    }
  });
  return errors;
};
