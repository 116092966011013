import { useContext } from 'react';
import MainStore from '../../stores/MainStore';
import { observer } from 'mobx-react-lite';

const DraftModalFooter = observer(() => {
  const mainStore = useContext(MainStore);

  return mainStore.isDraftSaved ? (
    <div>
      <button
        className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 disabled:cursor-not-allowed disabled:opacity-75"
        type="button"
        style={{ transition: 'all .15s ease' }}
        onClick={() => {
          mainStore.toggleModal('isSaveDraftModalVisible');
        }}
      >
        Ok
      </button>
    </div>
  ) : (
    <div>
      <button
        className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 disabled:cursor-not-allowed disabled:opacity-75"
        type="button"
        style={{ transition: 'all .15s ease' }}
        onClick={() => {
          mainStore.saveDraft();
        }}
        disabled={mainStore.prospectEmail.length === 0 || mainStore.hasErrorInProspectEmail}
      >
        Save
      </button>
    </div>
  );
});

export default DraftModalFooter;
