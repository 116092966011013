const PrintedFormUtils = {
  getFieldValueByKey: (formDataList, key) => {
    let fieldValue;
    if (JSON.stringify(formDataList).includes(key)) {
      formDataList.forEach((formData) => {
        if (formData instanceof Object && formData[key]) {
          fieldValue = formData[key];
        }
      });
    }
    return fieldValue;
  },
};

export default PrintedFormUtils;
