import { differenceInYears, isFuture, parse, parseISO } from 'date-fns';
import ErrorMessages from '../constants/errors';
import FileConstants from '../constants/file';
import ValidationUtils from './ValidationUtils';
import properties from '../constants/properties';

function validate(formData, errors, formContext) {

  let ALLOWED_FILE_TYPES = FileConstants.ALLOWED_FILE_TYPES;

  if(properties.MAYERS_ORGS.includes(formContext.orgId)) {
    ALLOWED_FILE_TYPES = FileConstants.MAYERS_ALLOWED_EXTEND_FILES;
  }

  if(properties.OMA_GROUP.includes(formContext.orgId)) {
    ALLOWED_FILE_TYPES = FileConstants.OMA_ALLOWED_FILE_TYPES;
  }

  if (formData.directors && formData.directors.length) {
    formData.directors.forEach((director, i) => {
      if (isFuture(parseISO(director.birthDate))) {
        errors.directors[i].birthDate.addError(ErrorMessages.NO_FUTURE);
      }
    });
  }
  if (formData.file_idVerification) {
    const fileInfo = ValidationUtils.getBase64Info(formData.file_idVerification);
    
    if (!ALLOWED_FILE_TYPES.includes(fileInfo.extension.toLowerCase())) {
      errors.file_idVerification.addError(ErrorMessages.FILE_INVALID_TYPE);
    } else if (fileInfo.size > FileConstants.MAX_FILE_SIZE) {
      errors.file_idVerification.addError(ErrorMessages.FILE_MAX_SIZE);
    }
  }

  if (formData.file_directorsGuarantee) {
    const fileInfo = ValidationUtils.getBase64Info(formData.file_directorsGuarantee);

    if (!ALLOWED_FILE_TYPES.includes(fileInfo.extension.toLowerCase())) {
      errors.file_directorsGuarantee.addError(ErrorMessages.FILE_INVALID_TYPE);
    } else if (fileInfo.size > FileConstants.MAX_FILE_SIZE) {
      errors.file_directorsGuarantee.addError(ErrorMessages.FILE_MAX_SIZE);
    }
  }

  if (formData.file_currencyVerification) {
    const fileInfo = ValidationUtils.getBase64Info(formData.file_currencyVerification);

    if (!ALLOWED_FILE_TYPES.includes(fileInfo.extension.toLowerCase())) {
      errors.file_currencyVerification.addError(ErrorMessages.FILE_INVALID_TYPE);
    } else if (fileInfo.size > FileConstants.MAX_FILE_SIZE) {
      errors.file_currencyVerification.addError(ErrorMessages.FILE_MAX_SIZE);
    }
  }

  // Oma Group file verification
  if (formData.file_taxId) {
    const fileInfo = ValidationUtils.getBase64Info(formData.file_taxId);
    
    if (!ALLOWED_FILE_TYPES.includes(fileInfo.extension.toLowerCase())) {
      errors.file_taxId.addError(ErrorMessages.FILE_INVALID_TYPE);
    } else if (fileInfo.size > FileConstants.MAX_FILE_SIZE) {
      errors.file_taxId.addError(ErrorMessages.FILE_MAX_SIZE);
    }
  }

  if (formData.file_proofAddress) {
    const fileInfo = ValidationUtils.getBase64Info(formData.file_proofAddress);
    
    if (!ALLOWED_FILE_TYPES.includes(fileInfo.extension.toLowerCase())) {
      errors.file_proofAddress.addError(ErrorMessages.FILE_INVALID_TYPE);
    } else if (fileInfo.size > FileConstants.MAX_FILE_SIZE) {
      errors.file_proofAddress.addError(ErrorMessages.FILE_MAX_SIZE);
    }
  }

  if (formData.bilingAccountDetails) {
    if (!formData.bilingAccountDetails.directDebitTerms) {
      errors.bilingAccountDetails.directDebitTerms.addError(ErrorMessages.REQUIRED);
    }
  }
  if (formData.directorsOrOwners && formData.directorsOrOwners.length) {
    formData.directorsOrOwners.forEach((director, i) => {
      if (!director.own25Checkbox && !director.boardMemberCheckbox && !director.significantControlCheckbox) {
        errors.directorsOrOwners[i].significantControlCheckbox.addError(ErrorMessages.AT_LEAST_ONE);
      }
      if (formContext?.countryCode === 'US') {
        if (director.last4ssn && !director.last4ssn.trim().match(/^\d{4}$/)) {
          errors.directorsOrOwners[i].last4ssn.addError(ErrorMessages.SSN_LENGTH);
        }
        if (director.birthDate && differenceInYears(new Date(), parse(director.birthDate, "MM/dd/yyyy", new Date())) < 13) {
          errors.directorsOrOwners[i].birthDate.addError(ErrorMessages.OLDER_THAN_13);
        }
        if (director.postCode && !director.postCode.trim().match(/^\d{5}$/)) {
          errors.directorsOrOwners[i].postCode.addError(ErrorMessages.US_ZIPCODE_LENGTH);
        }
      } else if (formContext?.countryCode === 'AU' || formContext?.countryCode === 'NZ') {
        const parsedDate = parse(director.birthDate, formContext.dateConfig.dateFormat.toLowerCase().replace("mm", "MM"), new Date())
        if (director.birthDate && differenceInYears(new Date(), parsedDate) < 13) {
          errors.directorsOrOwners[i].birthDate.addError(ErrorMessages.OLDER_THAN_13);
        }

        if (director.postCode && !director.postCode.trim().match(/^\d{4}$/)) {
          errors.directorsOrOwners[i].postCode.addError(ErrorMessages.ZIPCODE_LENGTH);
        }
      }

      
    });
  }

  if (formData.mainContacts && formData.mainContacts.length) {
    if (!formData.mainContacts[0].emailRecipient) {
      errors.mainContacts[0].addError(ErrorMessages.FIRST_CONTACT_EMAIL);
    } else if (formData.mainContacts[0].emailRecipient) {
      if (formData.mainContacts[0].emailRecipient === '') {
        errors.mainContacts[0].emailRecipient.addError(ErrorMessages.REQUIRED);
      }
    }
    if (formData.mainContacts?.length > 0) {
      if (!formData.mainContacts.some((contact) => contact.emailRefundAccess && contact.receivePaymentNotification)) {
        errors.mainContacts[0].receivePaymentNotification.addError(ErrorMessages.AT_LEAST_ONE_CONTACT);
      }

      formData.mainContacts.forEach((contact, i) => {
        if(contact?.fullName && contact?.fullName !== "") {
          const fullName = contact?.fullName.split(' ');
          if (fullName.some(item => item === '') || fullName.length < 2) {
            errors.mainContacts[i].fullName.addError(ErrorMessages.FULLNAME_TWO_WORDS);
          }
        }
      });

    }
  }

  if (formData.bankDetails) {
    if (formContext?.countryCode === 'US') {
      if (formData.bankDetails.bsb?.trim().length > 9 || !formData.bankDetails.bsb?.trim().match(/^\d{9}$/)) {
        errors.bankDetails?.bsb.addError(ErrorMessages.ROUTING_MAX_LENGTH);
      }
      if (formData.bankDetails.accountNumber?.trim().length > 17 || !formData.bankDetails.accountNumber?.trim().match(/^\d{1,17}$/)) {
        errors.bankDetails?.accountNumber.addError(ErrorMessages.US_ACCOUNT_MAX_LENGTH);
      }
    }
    if (formContext?.countryCode === 'AU') {
      if (formData.bankDetails.bsb?.length > 6 || !formData.bankDetails.bsb?.match(/^\d{6}$/)) {
        errors.bankDetails?.bsb.addError(ErrorMessages.BSB_MAX_LENGTH);
      }
      if (formData.bankDetails.accountNumber?.length > 9 || !formData.bankDetails.accountNumber?.match(/^\d{1,9}$/)) {
        errors.bankDetails?.accountNumber.addError(ErrorMessages.ACCOUNT_MAX_LENGTH);
      }
    }
    if (formContext?.countryCode === 'NZ') {
      if (formData.bankDetails.accountNumber?.trim().length !== 16 || !formData.bankDetails.accountNumber?.trim().match(/^\d{16}$/)) {
        errors.bankDetails?.accountNumber.addError(ErrorMessages.NZ_ACCOUNT_NUMBER);
      }
    }
  }
  if (formData.bilingAccountDetails) {
    if (formContext?.countryCode === 'US') {
      if (formData.bilingAccountDetails?.bsb?.trim().length > 9 || !formData.bilingAccountDetails?.bsb?.trim().match(/^\d{9}$/)) {
        errors.bilingAccountDetails?.bsb.addError(ErrorMessages.ROUTING_MAX_LENGTH);
      }
      if (formData.bilingAccountDetails?.accountNumber?.trim().length > 17 || !formData.bilingAccountDetails?.accountNumber?.trim().match(/^\d{1,17}$/)) {
        errors.bilingAccountDetails?.accountNumber.addError(ErrorMessages.US_ACCOUNT_MAX_LENGTH);
      }
    }
    if (formContext?.countryCode === 'AU') {
      if (formData.bilingAccountDetails?.bsb?.length > 6 || !formData.bilingAccountDetails?.bsb?.match(/^\d{6}$/)) {
        errors.bilingAccountDetails?.bsb.addError(ErrorMessages.BSB_MAX_LENGTH);
      }
      if (formData.bilingAccountDetails?.accountNumber?.length > 9 || !formData.bilingAccountDetails?.accountNumber?.match(/^\d{1,9}$/)) {
        errors.bilingAccountDetails?.accountNumber.addError(ErrorMessages.ACCOUNT_MAX_LENGTH);
      }
    }
    if (formContext?.countryCode === 'NZ') {
      if (formData.bilingAccountDetails?.accountNumber?.trim().length !== 16 || !formData.bilingAccountDetails?.accountNumber?.trim().match(/^\d{16}$/)) {
        errors.bilingAccountDetails?.accountNumber.addError(ErrorMessages.NZ_ACCOUNT_NUMBER);
      }
    }
  }
  if (formData?.registeredAddress && formContext?.countryCode === 'US') {
    if (formData?.registeredAddress?.postCode && !formData?.registeredAddress?.postCode.trim().match(/^\d{5}$/)) {
      errors.registeredAddress.postCode.addError(ErrorMessages.US_ZIPCODE_LENGTH);
    }
  } else if (formData?.registeredAddress && (formContext?.countryCode === 'NZ' || formContext?.countryCode === 'AU')) {
    if (formData?.registeredAddress?.postCode && !formData?.registeredAddress?.postCode.trim().match(/^\d{4}$/)) {
      errors.registeredAddress.postCode.addError(ErrorMessages.ZIPCODE_LENGTH);
    }
  }

  if(formContext?.cardValidation) {
    errors.creditCard.addError(ErrorMessages.REQUIRED);
  }

  // Those bellow are custom validation for brooks hire custom application.
  if(formContext?.customApplication?.type !== "") {
    if(formData.directors && formData.directors.length > 0) {
      formData.directors.forEach((director, i) => {
        if (director.phone === '') {
          errors.directors[i].phone.addError(ErrorMessages.REQUIRED);
        }
      });
    }

    if(formData?.contacts && formData?.contacts.length > 0) {
      formData.contacts.forEach((contact, i) => {
        if (contact.contactNumber === '') {
          errors.contacts[i].contactNumber.addError(ErrorMessages.REQUIRED);
        }
      });
    }

    if(formData?.referenceOne?.phone === "") {
      errors.referenceOne.phone.addError(ErrorMessages.REQUIRED);
    }

    if(formData?.referenceTwo?.phone === "") {
      errors.referenceTwo.phone.addError(ErrorMessages.REQUIRED);
    }

    if(formData.hasOwnProperty("waiverFee") && formContext?.customApplication?.waiverFeeNoticeElection === "undefined") {
      errors.waiverFee.addError(ErrorMessages.REQUIRED);
    } 
  }
  

  
  return errors;
}

export default validate;
