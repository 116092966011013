import React, { useState } from 'react';
import FormField from './FormField';
import { useCountdown } from '../hooks/countdownHook';

export const OTPForm = ({ email, errorMessage = '', onSubmit, targetDate, refreshOtp }) => {
  const [code, setCode] = useState('');  
  const { minutes, seconds } = useCountdown(targetDate);

  return (
    <form
      className="flex flex-col h-full px-6 py-6 items-center text-center justify-between"
      onSubmit={(evt) => evt.preventDefault()}
    >
      <article className="prose-lg">
        <h4 className="text-lg pt-8 pb-5 text-gray-750 uppercase text-center">Email Verification</h4>

        <p className="px-8 text-center text-base text-gray-700">
          We sent you an <strong>One-Time Password.</strong>
          <br />
          Please check your email <strong>{email}</strong> and inform the code below:
        </p>
        <div className="w-full flex justify-center items-center">
          <div className="w-full content-center form-group">
            <FormField
              label={" "}
              value={code}
              placeholder="OTP CODE"
              onChange={(values) => setCode(values.value)}
              hasError={errorMessage.length > 0}
              errorMessage={errorMessage}
              name="otp"
              id="otp"
              masked
              mki-format="######"
              type="number"
              mki-style={{
                fontSize: '25px',
                lineHeight: '35px',
                letterSpacing: '20px',
                display: 'inline-block',
                textAlign: 'center',
                minWidth: '300px',
                maxWidth: '400px',
              }}
            />
            <br />
            <span className="px-8 text-center text-base text-gray-400">Didn't receive the verification OTP? {
              ((minutes + seconds <= 0) || errorMessage.length > 0) ?
              (<b style={{cursor: 'pointer'}} onClick={() => refreshOtp()}>Resend verification</b>)
              :
              (minutes + ':' + seconds)
            }</span>
          </div>
        </div>
        <button
          type="button"
          className="mt-10 bg-blue-600 hover:bg-blue-800 text-white font-semibold tracking-wider py-2 px-12 rounded shadow self-center"
          onClick={() => {
            onSubmit(code);
          }}
        >
          SUBMIT
        </button>
      </article>
    </form>
  );
};
