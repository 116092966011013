import configs from '../constants/config';
import axios from 'axios';

class OtpService {
  baseURL = configs.api_base_url;

  isOtpEnabled(applicationID) {
    return axios.get(`${this.baseURL}/otp/${applicationID}`);
  }

  getTokenId(applicationID) {
    return axios.post(`${this.baseURL}/otp/${applicationID}`);
  }

  verifyOtpCode(applicationID, data) {
    return axios.post(`${this.baseURL}/otp/${applicationID}/verify`, data);
  }
}

const otp = new OtpService();

export default otp;
