import React from 'react';
import DateUtils from '../../Utils/DateUtils';
import { BasicFieldArea, FormTitleArea } from './dynamicFormComponents';

export default function DynamicForm({ form, dateConfig, country }) {

  return (
    <React.Fragment>
      {form.filter(item => item.title !== 'Payment Authorisation')
        .map((formStep, index) => {
          return (
            <section key={formStep.title + index}>
              <FormTitleArea>{formStep.title}</FormTitleArea>
              <div>
                {Object.keys(formStep.fields).map((key) => (
                  <div key={key + index}>
                    {formStep.fields[key].map((fieldSection, fieldSectionIndex) => {
                      return (
                        <React.Fragment key={fieldSectionIndex}>
                          {fieldSection.hasOwnProperty('title') && (
                            <FormTitleArea key={fieldSection.title + index}>{fieldSection.title}</FormTitleArea>
                          )}
                          {fieldSection && fieldSection.label && fieldSection.value ? (
                            <BasicFieldArea key={fieldSection.label + index}>
                              <span>{fieldSection.label}</span>
                              <p>{(typeof fieldSection.value === 'string' && fieldSection.value.split(';')[1]) ? decodeURI(fieldSection.value.split(';')[1]?.replace('name=', '')) || '---' : '' }</p>
                              <p>{(typeof fieldSection.value === 'string' && !fieldSection.value.split(';')[1]) ? fieldSection.value || '---' : '' }</p>
                              <p>{(typeof fieldSection.value === 'boolean') ? fieldSection.value === true ? 'checked' : 'not checked' : '' }</p>
                            </BasicFieldArea>
                          ) : (
                            Object.keys(fieldSection).map(
                              (fieldKey) =>
                                fieldKey !== 'title' &&
                                fieldSection[fieldKey].label && (
                                  // <div key={fieldKey + index} className={fieldSection[fieldKey].cssClass}>
                                  <BasicFieldArea key={fieldKey + index}>
                                    <span>{fieldSection[fieldKey].label}</span>
                                    {/* <p>{getFieldValue(fieldSection, fieldKey)}</p> */}

                                    <p>{(( (fieldKey === "birthDate" || fieldKey === "dateOfBirth") && country !== 'US')
                                      ? DateUtils.formatDateTimezone(getFieldValue(fieldSection, fieldKey), dateConfig.dateFormat, null)
                                      : getFieldValue(fieldSection, fieldKey))}</p>

                                  </BasicFieldArea>
                                )
                            )
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                ))}
              </div>
            </section>
          );
      })}
    </React.Fragment>
  );
}

function getFieldValue(fieldSection, fieldKey) {
  const booleanFields = ['directDebitTerms', 'own25Checkbox', 'boardMemberCheckbox', 'significantControlCheckbox', 'refundTerms'];
  if (fieldSection[fieldKey].value?.toString().includes('data:')) {
    return decodeURI(fieldSection[fieldKey].value.split(';')[1].replace('name=', ''));
  } else if (fieldSection[fieldKey].value?.toString() === 'true') {
    return 'Yes';
  } else if ((booleanFields.includes(fieldKey) && fieldSection[fieldKey]?.value?.toString() === '') || (booleanFields.includes(fieldKey) && fieldSection[fieldKey]?.value?.toString() === 'false')) {
    return 'No';
  }
  return fieldSection[fieldKey].value || '---';
}
