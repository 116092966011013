import React from 'react';

const StepLinkBar = ({ done }) => {
  const cssClass = done ? 'w-0 bg-green-300 py-1 rounded' : 'w-0 py-1 rounded';
  return (
    <div
      className="absolute flex align-center items-center align-middle content-center"
      style={{ width: 'calc(100% - 2.5rem - 1rem)', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 0 }}
    >
      <div className="w-full bg-gray-300 rounded items-center align-middle align-center flex-1">
        <div className={cssClass} style={{ width: '100%' }} />
      </div>
    </div>
  );
};

const Step = ({ wizard, index, currentStep, isConfirmed }) => {
  const isDone = isConfirmed || currentStep > index;
  const isCurrent = currentStep === index;

  let stepCssClasses;
  let iconCssClasses;

  if (isCurrent && !isConfirmed) {
    stepCssClasses = 'border-2 border-yellow-400 bg-yellow-100 animate-bounce';
    iconCssClasses = 'text-yellow-400';
  } else {
    stepCssClasses = isDone ? 'bg-green-500' : 'border-2 border-gray-400 bg-white';
    iconCssClasses = isDone ? 'text-white' : 'text-gray-500';
  }

  return (
    <div className="flex-1">
      <div className="relative mb-2">
        {index !== 0 && <StepLinkBar done={isDone} />}
        <div className={`w-10 h-10 mx-auto rounded-full text-lg  flex items-center z-10 ${stepCssClasses}`}>
          <span className={`text-center w-full`} title={wizard.label}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              className={`m-auto fill-current ${iconCssClasses}`}
              dangerouslySetInnerHTML={{ __html: wizard.icon }}
            />
          </span>
        </div>
      </div>
      <div
        className={`text-sm text-center xs:hidden md:text-sm text-gray-600 ${
          isCurrent && !isConfirmed ? 'font-bold' : 'font-medium'
        }`}
      >
        {wizard.label}
      </div>
    </div>
  );
};

export default Step;
