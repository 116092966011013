// sentry.js
import * as Sentry from '@sentry/react';
import React from 'react';

Sentry.init({
  dsn: "https://36ead103c3d45be55181732ca02c93fb@o953791.ingest.us.sentry.io/4507149173915648",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      // useLocation,
      // useNavigationType,
      // createRoutesFromChildren,
      // matchRoutes
    }),
    Sentry.replayIntegration()
  ],

  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/ezycollect\.info/, /^https:\/\/ezycreditapp\.com\.au/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
