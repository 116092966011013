import { action, makeObservable, observable } from 'mobx';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { createContext } from 'react';

class LaunchDarklyStore {

    clientSideID = { prod: "5c75bb518af6a31e73168762", uat: "5c75bb518af6a31e73168761"};

    context = {
        kind: "user",
        key: "",
    };
    client = null;
    flags = {
        "caTextEditorFlag": {
            label: "credit-application-management-editor-for-text-areas",
            value: false,
            default: false
        },
        isPayToLive: {
            label: "zepto-pay-to",
            value: false,
            default: false
        }

    };

    caCustomization = {
        label: "credit-application-ui-customization",
        value: {},
        default: {
            frontPageTitle: "CREDIT APPLICATION FORM",
            draftText: "We have sent you an email with the link to retrieve the credit application form. Please," +
              " ensure to check the spam folder in case you do not find the email in your inbox.",
            draftTextForm: "We will send you a link on your email address that you can use to access this form later" +
              " and complete your application",
            businessNumber: {
                title: "Business Number*",
                validate: true,
            },
            companyNumber: {
                title: "Company Number",
                required: false,
                visible: true
            },
            businessType: {
                title: "Business Type *",
                required: true,
                visible: true
            },
            tradingName: {
                title: "Trading Name *",
                required: true,
                visible: true
            },
            natureOfBusiness: {
                title: "Nature of Business *",
                required: true,
                visible: true
            },
            numberOfEmployees: {
                title: "Number of Employees *",
                required: true,
                visible: true
            },
            trusteeTitle: "Trustee Info",
            trusteeBusinessNumber: {
                title: "Business Number",
                required: false,
                visible: true
            },
            trusteeCompanyNumber: {
                title: "Company Number *",
                required: true,
                visible: true
            },
            trusteeBusinessType: {
                title: "Business Type *",
                required: true,
                visible: true
            },
            trusteeTradingName: {
                title: "Trading Name",
                required: false,
                visible: true
            },
            trusteeFullName: {
                title: "Full Name *",
                required: true
            },
            trusteeDateOfBirth: {
                title: "Date of Birth *",
                required: true
            },
            trusteeDocument: {
                title: "Driver's License or Passport Number *",
                required: true
            },
            trusteeNoteText: "",
            registeredCompanyName: {
                title: "Registered Company Name *",
                required: true,
                visible: true
            },
            dateOfIncorporation: {
                title: "Date of Incorporation *",
                required: true
            },
            creditLimitRequested: {
                required: false,
                visible: true
            },
            termsAndConditions: "Terms and Conditions",
            privacyPolicy: "Privacy Policy",
            currency: "Currency",
            creditPeriod: "Credit Period",
            phoneNumber: "Phone Number",
            email: "Email",
            fullName: "Full Name",
            country: {
                title: "Country",
                visible: true
            },
            directorsGuarantee: 'Director’s Guarantee',
            companyInfoStep: 'Company Information',
            businessDetailConfirmPage: 'Business Detail',
        }
    }

    constructor() {
        makeObservable(this, {
            clientSideID: observable,
            context: observable,
            client: observable,
            flags: observable,
            caCustomization: observable,
            initializeLD: action,
        });
    }

    getEnvironment() {
        if(window.location.href.includes("ezycreditapp.com.au")) {
            return "prod";
        }
        return "uat";
    }

    async initializeLD(organizationId) {
        this.context.key = `Organisation-${organizationId}`;
        if(this.client === null && organizationId !== null) {
            this.client = LDClient.initialize(this.clientSideID[this.getEnvironment()], this.context);
            return this.client.waitUntilReady().then(() => {
                Object.keys(this.flags).forEach(key => {
                    this.flags[key].value = this.client.variation(this.flags[key].label, this.flags[key].default);
                });
                
                const custom = this.client.variation(this.caCustomization.label, this.caCustomization.default);
                this.caCustomization.value = Object.keys(custom).length > 0 ? {...this.caCustomization.default, ...custom} : this.caCustomization.default;
            }).catch(err => {
                this.client = null;
                console.log("launch darkly: " + err);
            });
        }
    }

}

export default createContext(new LaunchDarklyStore());