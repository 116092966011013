import React, { useContext } from 'react';
import MainStore from '../../stores/MainStore';

const acceptLabel = "The Applicant accepts the Waiver Fee and understands that the prescribed amount be either included in amounts invoiced or invoiced as a separate item.";
const declineLabel = "The Applicant declines the offer of the Waiver Fee and hereby accept full and total responsibility for the costs associated with the replacement or repair of items in my/our control whilst subject to an agreement between the Applicant and the Owner. Applicants who decline the offer of the Waiver Fee will be required to provide a copy of Certificate of Currency of an appropriate level of insurance and to register the Owner as an interested party on such insurance policy.";

function WaiverFeeNoticeElection(props:Record<string, any>) {

    const mainStore: any = useContext(MainStore);

    const onChange = (e: Record<string, any>) => {
        mainStore.updateCustomApplication({ waiverFeeNoticeElection: (e.target.value === "true") });
    }

    return <div className="flex-col mt-5">
        <p style={{ color: "rgba(107,114,128" }}>The Applicant makes the following election in relation to the payment of the Waiver Fee as that term is defined in the Brooks Hire Terms and Conditions:</p>
        <div className="w-full mt-10 p-5" style={{border: "1px solid rgb(192, 192, 192)"}}>
            <input
                id="radio-yes"
                name="waiverFee"
                type="radio"
                value="true"
                checked={ mainStore.customApplication.waiverFeeNoticeElection === "undefined" ? false : mainStore.customApplication.waiverFeeNoticeElection}
                onChange={(e) => {
                    onChange(e);
                }}
            />
            <label className="p-2 select-none" htmlFor="radio-yes">{acceptLabel}</label>
        </div>
        <div className="w-full p-5 mt-5 mb-10"  style={{border: "1px solid rgb(192, 192, 192)"}}>
            <input
                id="radio-no"
                name="waiverFee"
                type="radio"
                value="false"
                checked={ mainStore.customApplication.waiverFeeNoticeElection === "undefined" ? false : !mainStore.customApplication.waiverFeeNoticeElection }
                onChange={(e) => {
                    onChange(e);
                }}
            />
            <label className="p-2 select-none" htmlFor="radio-no">{declineLabel}</label>
        </div>
        <p style={{ color: "rgba(107,114,128" }}>Unless otherwise indicated, the Owner will automatically include the Waiver Fee in all invoices issued to the Applicant.</p>
    </div>
};

export default WaiverFeeNoticeElection;