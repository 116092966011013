import ReactDatePicker from "react-datepicker";

const CustomDatePicker = ({ value, fields, onChange, label, name, placeholder, widget, dateFormat = 'DD/MM/YYYY', id, maxDate = new Date() }) => {
  return (
    <div className={`block ${!widget ? 'mx-4' : ''}`}>
      <span
        className={`${fields[name].validate.hasError ? 'text-red-700' : 'text-gray-500'
          } font-medium`}
      >
        {label}
      </span>
      <ReactDatePicker
        id={id ? id : fields[name].id}
        selected={value}
        onChange={onChange}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        showYearDropdown
        yearDropdownItemNumber={100}
        maxDate={maxDate}
        scrollableYearDropdown
        className={`mt-1 block w-full rounded-md placeholder-gray-400 placeholder-opacity-50 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-100 ${fields[name]?.validate?.hasError ? 'has-error' : ''}`}
      />
      <div>
        {fields[name].validate.hasError && (
          <span className="text-red-700 text-sm font-medium">{fields[name].validate.message}</span>
        )}
      </div>
    </div>
  )

};

export default CustomDatePicker;