import SvgBase from './SvgBase';
import React from 'react';

const RenderLoading = () => {
  return (
    <div>
      <div className="z-20 mt-20 block text-center text-black uppercase">
          <span className="block">
            <SvgBase cssClass="animate-spin w-8 m-auto">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" />
            </SvgBase>
          </span>
        <p>loading</p>
      </div>
    </div>
  );
};

export default RenderLoading;