import React, { useEffect } from 'react';
const ExitAlert = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', prevent);

    return () => window.removeEventListener('beforeunload', prevent);
  }, []);
  return <div></div>;
};

export const prevent = (event) => {
  event.preventDefault();
  event.returnValue = '';
};

export default ExitAlert;
