class ValidationUtils {
  static validateBusinessNumber(value, country) {
    let result = false;
    const businessNumber = value.replaceAll(/[^0-9]/gi, '');

    if (country === 'AU') {
      const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

      if (businessNumber.length === 11) {
        let sum = 0;
        let weight;
        let digit;

        for (let index = 0; index <= weights.length - 1; index++) {
          weight = weights[index];
          digit = businessNumber[index] - (index ? 0 : 1);
          sum += weight * digit;
        }

        result = sum % 89 === 0;
      }
    } else if (country === 'NZ') {
      result = businessNumber.length === 13 && businessNumber[0] === '9';
    } else {
      result = true;
    }

    return result;
  }

  /**
   * Calculate the size of a Base64 file, following the formula bellow:
   *
   *  fileSize = (base64.length * (3/4)) - padding
   *
   * 1. fileSize is the size of a file in bytes
   * 2. base64.length is the length of the Base64 String
   * 3. padding will be 2 if Base64 ends with '==' and 1 if Base64 ends with '='.
   *
   * source: https://softwareengineering.stackexchange.com/questions/288670/know-file-size-with-a-base64-string
   */
  static getBase64Info(base64) {
    const endsWith = (str, end) => {
      let charsFromEnd = end.length;
      let extractedEnd = str.slice(-charsFromEnd);
      return extractedEnd === end;
    };
    const base64Padding = endsWith(base64, '==') ? 2 : 1;
    const padding = base64.length ? base64Padding : 0;

    const name = base64.match(/name=(.*);/)[1];
    const size = (Math.ceil(base64.length * (3 / 4)) - padding) / 1000;
    const extension = name.match(/\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/)[0];

    return { name, size, extension };
  }

  static validateBusinessNumberIsUnique(businessNumber = '', trusteeBusinessNumber = '') {
    return businessNumber.replace(/ /g, '') !== trusteeBusinessNumber.replace(/ /g, '');
  }
}

export default ValidationUtils;
