import CreditCard from './CreditCard/CreditCard';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import configs from '../constants/config';
import ApiService from '../services/apiService';
import MainStore from '../stores/MainStore';
import { Loader2 } from 'tabler-icons-react';

function StripeElements(props: { value: any; onChange: any }) {
  const [options, setOptions] = useState({ clientSecret: undefined });
  const stripePromise = loadStripe(configs!.publishable_key);
  const mainStore = useContext(MainStore);

  const loadSetupIntent = useCallback(() => {
    const data: FormData = new FormData();
    // @ts-ignore
    data.append('paymentMethodsType', ['card']);
    data.append('uniqueReference', mainStore.creditApplicationId !== null ? mainStore.creditApplicationId : '');
    data.append(
      'description',
      mainStore.creditApplicationDomain.registeredCompanyName !== ''
        ? mainStore.creditApplicationDomain.registeredCompanyName
        : '---'
    );

    ApiService.createOrphanSetupIntent(data, mainStore.orgid, mainStore.authToken)
      .then((response) => {
        mainStore.updateCreditCardInfo({
          clientSecret: response.data.clientSecret,
          feeInformation: response.data.feeInformation,
        });
        setOptions({
          // passing the client secret obtained from the server
          clientSecret: response.data.clientSecret,
        });
      })
      .catch((error) => {
        console.error('Error on load setup intent', error.message);
      });
  }, [mainStore]);

  useEffect(() => {
    if (!mainStore.creditCardInfo.clientSecret) {
      loadSetupIntent();
    }
  }, [mainStore.creditCardInfo.clientSecret, loadSetupIntent]);

  return mainStore.creditCardInfo && mainStore.creditCardInfo.clientSecret !== undefined && stripePromise ? (
    <Elements stripe={stripePromise} options={options}>
      <CreditCard
        value={props.value}
        onChange={props.onChange}
        options={mainStore.creditCardInfo}
        feeInformation={mainStore.creditCardInfo.feeInformation}
        loadSetupIntent={loadSetupIntent}
      />
    </Elements>
  ) : (
    <div className="flex justify-center">
      <Loader2 size={48} strokeWidth={2} color={'#3d82f1'} className="animate-spin" />
    </div>
  );
}

export default StripeElements;
