import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import URLS from '../../constants/caUrls';
import MainStore from '../../stores/MainStore';
import { useObserver } from 'mobx-react-lite';
import properties from "../../constants/properties";
import LaunchDarklyStore from '../../stores/LaunchDarklyStore';
import { ApplicationType } from '../../domains/TypeEnum';

const LinkText = styled.b`
  color: blue;
`;

const Wrapper = styled.div`
  margin-bottom: -1rem;
`;

const CustomStyledLabel = styled.div`
  color: ${(props) => (!props.error ? '#b91c1c' : '')};
`;

const DirectorsGuaranteeTitle = styled.div`
  color: ${(props) => (!props.error ? '#b91c1c' : '#6B7280')};
  font-weight: 500;
`;

const DirectorsGuarantee = (props) => {
  const mainStore = useContext(MainStore);
  const [guaranteeExist, setGuaranteeExist] = useState(false);
  const subdomainName = window.location.href.split('://')[1].split('.')[0];
  const { organizationId } = mainStore.creditApplicationDomain;

  const launchDarklyStore = useContext(LaunchDarklyStore);
  const caCustomization = launchDarklyStore.caCustomization.value;
  let directorGuaranteeLinkLabel = mainStore.directorGuaranteeRequired ? caCustomization.directorsGuarantee + '*' : caCustomization.directorsGuarantee;

  useEffect(() => {
    const checkGuaranteeExist = setInterval(async () => {
      const guaranteeStatus = await axios.get(`${URLS.brand}${subdomainName}/directorsGuaranteeStatus`);

      if (guaranteeStatus.data.exists) {
        setGuaranteeExist(true);
        clearInterval(checkGuaranteeExist);
      }
    }, 1000);

    setTimeout(() => {
      clearInterval(checkGuaranteeExist);
    }, 10000);
  }, [subdomainName]);

  if (properties.HG_GROUP_ORGS.includes(organizationId)) {
    directorGuaranteeLinkLabel = 'Terms & Conditions of Trade';
  }

  if (properties.POPS_GROUP_ORGS.includes(organizationId)) {
    directorGuaranteeLinkLabel = 'Guarantee & Indemnity + Terms & Conditions';
  }

  if(properties.MAYERS_ORGS.includes(organizationId)
    || properties.RELIABLE_HIRE_ORGS.includes(organizationId) && mainStore.customApplication.type === ApplicationType.creditApplication) {
    directorGuaranteeLinkLabel = 'Director’s Guarantee *';
  }

  let directorGuarantee = (
    <DirectorsGuaranteeTitle error={mainStore.uploadedDirectorsGuarantee}>
      {directorGuaranteeLinkLabel}
    </DirectorsGuaranteeTitle>
  );

  return useObserver(() => (
    <Wrapper>
      {guaranteeExist ? (
        <>
          {directorGuarantee}
          <CustomStyledLabel error={mainStore.uploadedDirectorsGuarantee}>
            Please download the{' '}
            <LinkText>
              <u>
                {' '}
                <a href={`${URLS.brand}${subdomainName}/directorsGuarantee`} target="_blank" download>
                  {directorGuaranteeLinkLabel}
                </a>
              </u>
            </LinkText>{' '}
            document, sign, and upload it above
          </CustomStyledLabel>
        </>
      ) : (
        <label>Others documents [pdf, jpg, png, gif]</label>
      )}
    </Wrapper>
  ));
};

export default DirectorsGuarantee;
