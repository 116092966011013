import { action, makeObservable, observable } from 'mobx';
import { getDynamicPrecision } from '../Utils/NumberFormatter';
import ErrorMessages from '../constants/errors';
import properties from '../constants/properties';

class FeeStructureDomain {
  transactionFee = null;
  fee3ds = null;
  failedFee = null;
  disputeFee = null;
  mastercardFees = null;
  visaFees = null;
  amexFees = null;
  internationalFees = null;
  eftTransactionFee = null;
  eftVariableFee = null;
  realTimeTransactionFee = null;
  realTimeVariableFee = null;
  paymentMethodType = ['CREDIT_CARD', 'EFT'];
  realTimePaymentType = false;
  isLoaded = false;
  eftRefundFee = null;
  eftFailedFee = null;
  eftDisputeFee = null;
  settlementDelay = null;
  agreedToConfigTerms = false;
  clientType = null;

  constructor() {
    makeObservable(this, {
      transactionFee: observable,
      fee3ds: observable,
      failedFee: observable,
      disputeFee: observable,
      mastercardFees: observable,
      visaFees: observable,
      amexFees: observable,
      internationalFees: observable,
      isLoaded: observable,
      eftTransactionFee: observable,
      realTimeTransactionFee: observable,
      eftVariableFee: observable,
      realTimeVariableFee: observable,
      paymentMethodType: observable,
      realTimePaymentType: observable,
      eftRefundFee: observable,
      eftFailedFee: observable,
      eftDisputeFee: observable,
      settlementDelay: observable,
      agreedToConfigTerms: observable,
      clientType: observable,
      updateAttr: action,
      fillFeeStructureFromApi: action,
    });
  }

  updateAttr(path, subpath, value) {
    const decimalPrecision = getDynamicPrecision(value);

    if (path && subpath) {
      this[path][subpath] = parseFloat(value).toFixed(decimalPrecision);

      if (subpath === 'netValue') {
        if (this[path][subpath] <= this[path].feeValue) {
          const rootPrecision = getDynamicPrecision(this[path].feeValue);
          const testPrecision = getDynamicPrecision(this[path].surchargeValue);
          this[path].surchargeValue = parseFloat(this[path].feeValue - value).toFixed(
            Math.max(decimalPrecision, testPrecision, rootPrecision)
          );
        }
      } else if (subpath === 'surchargeValue') {
        if (this[path][subpath] <= this[path].feeValue) {
          const rootPrecision = getDynamicPrecision(this[path].feeValue);
          const testPrecision = getDynamicPrecision(this[path].netValue);
          this[path].netValue = parseFloat(this[path].feeValue - value).toFixed(
            Math.max(decimalPrecision, testPrecision, rootPrecision)
          );
        }
      } else if (subpath === 'surchargeRate') {
        if (this[path][subpath] <= this[path].feeRate) {
          const rootPrecision = getDynamicPrecision(this[path].feeRate);
          const testPrecision = getDynamicPrecision(this[path].netRate);
          this[path].netRate = parseFloat(this[path].feeRate - value).toFixed(
            Math.max(decimalPrecision, testPrecision, rootPrecision)
          );
        }
      } else if (subpath === 'netRate') {
        if (this[path][subpath] <= this[path].feeRate) {
          const rootPrecision = getDynamicPrecision(this[path].feeRate);
          const testPrecision = getDynamicPrecision(this[path].surchargeRate);
          this[path].surchargeRate = parseFloat(this[path].feeRate - value).toFixed(
            Math.max(decimalPrecision, testPrecision, rootPrecision)
          );
        }
      }
    } else {
      if (path === 'agreedToConfigTerms') {
        this[path] = value;
      }
    }
  }

  clear() {
    this.transactionFee = null;
    this.fee3ds = null;
    this.failedFee = null;
    this.disputeFee = null;
    this.mastercardFees = null;
    this.visaFees = null;
    this.amexFees = null;
    this.internationalFees = null;
    this.eftTransactionFee = null;
    this.realTimeTransactionFee = null;
    this.eftVariableFee = null;
    this.realTimeVariableFee = null;
    this.paymentMethodType = null;
    this.realTimePaymentType = null;
    this.eftRefundFee = null;
    this.eftFailedFee = null;
    this.eftDisputeFee = null;
  }

  validate(organizationId) {
    const isOnboardingUS = properties.US_ONBOARDING_ORGS.includes(organizationId);

    const validationResult = { hasError: false, errors: [] };
    const configurableFees = [];
    if (this.paymentMethodType.includes('CREDIT_CARD')) {
      configurableFees.push('transactionFee');

      if (this.visaFees) {
        configurableFees.push('visaFees');
      }
      if (this.mastercardFees) {
        configurableFees.push('mastercardFees');
      }
      if (this.amexFees) {
        configurableFees.push('amexFees');
      }
      if (this.internationalFees?.feeRate) {
        configurableFees.push('internationalFees');
      }
    }

    if (this.paymentMethodType.includes('EFT')) {
      if (!properties.US_ONBOARDING_ORGS.includes(organizationId)) {
        configurableFees.push('eftTransactionFee');
      }
      configurableFees.push('eftVariableFee');
    }

    if(this.paymentMethodType.includes('PAYTO')) {
      configurableFees.push('realTimeTransactionFee');
      configurableFees.push('realTimeVariableFee');
    }

    configurableFees.forEach((configFee) => {
      if (this[configFee]?.lock) return;

      Object.keys(this[configFee]).forEach((fieldKey) => {
        if (
          fieldKey === 'netValue' ||
          fieldKey === 'surchargeValue' ||
          fieldKey === 'netRate' ||
          fieldKey === 'surchargeRate'
        ) {
          if (this[configFee][fieldKey] === '' || this[configFee][fieldKey] === null) {
            validationResult.hasError = true;
            validationResult.errors.push({ [configFee]: { fieldError: fieldKey, message: ErrorMessages.REQUIRED } });
          }
        }

        if (fieldKey === 'feeValue') {
          if (
            this[configFee][fieldKey] !==
            parseFloat(
              (parseFloat(this[configFee].netValue) + parseFloat(this[configFee].surchargeValue)).toFixed(
                getDynamicPrecision(this[configFee][fieldKey])
              )
            )
          ) {
            validationResult.hasError = true;
            validationResult.errors.push({
              [configFee]: { fieldError: fieldKey, message: ErrorMessages.SUM_MUST_BE_EQUAL },
            });
          }
        }

        if (fieldKey === 'feeRate') {
          if (
            this[configFee][fieldKey] !==
            parseFloat(
              (parseFloat(this[configFee].netRate) + parseFloat(this[configFee].surchargeRate)).toFixed(
                getDynamicPrecision(this[configFee][fieldKey])
              )
            )
          ) {
            validationResult.hasError = true;
            validationResult.errors.push({
              [configFee]: { fieldError: fieldKey, message: ErrorMessages.SUM_MUST_BE_EQUAL },
            });
          }
        }
      });
    });

    if (!this.agreedToConfigTerms && this.clientType === 'EZYCOLLECT_STANDARD') {
      validationResult.hasError = true;
      validationResult.errors.push({
        agreedToConfigTerms: { fieldError: 'agreedToConfigTerms', message: ErrorMessages.REQUIRED },
      });
    }
    return validationResult;
  }

  fillFeeStructureFromApi(data) {
    this.internationalFees = data?.internationalFees;
    this.transactionFee = data?.serviceFees?.find((serviceFee) => serviceFee.feeType === 'SERVICE_FEE');
    this.fee3ds = data?.serviceFees?.find((serviceFee) => serviceFee.feeType === '3DS_FEE');
    this.failedFee = data?.serviceFees?.find((serviceFee) => serviceFee.feeType === 'FAILURE_FEE');
    this.disputeFee = data?.serviceFees?.find((serviceFee) => serviceFee.feeType === 'DISPUTE_FEE');
    this.mastercardFees = data?.cardFees?.find((cardFee) => cardFee.cardType.toUpperCase() === 'MASTERCARD');
    this.visaFees = data?.cardFees?.find((cardFee) => cardFee.cardType.toUpperCase() === 'VISA');
    this.amexFees = data?.cardFees?.find((cardFee) => cardFee.cardType.toUpperCase() === 'AMEX');
    this.eftTransactionFee = data?.eftFees?.find((bankTransferFee) => bankTransferFee.feeType === 'TRANSACTION_FEE');
    this.realTimeTransactionFee = data?.realTimeFees?.find((bankTransferFee) => bankTransferFee.feeType === 'TRANSACTION_FEE');
    this.eftVariableFee = data?.eftFees?.find((bankTransferFee) => bankTransferFee.feeType === 'EFT_VARIABLE_FEE');
    this.realTimeVariableFee = data?.realTimeFees?.find((bankTransferFee) => bankTransferFee.feeType === 'EFT_VARIABLE_FEE');
    this.eftRefundFee = data?.eftFees?.find((bankTransferFee) => bankTransferFee.feeType === 'EFT_REFUND_FEE');
    this.eftFailedFee = data?.eftFees?.find((bankTransferFee) => bankTransferFee.feeType === 'EFT_FAILURE_FEE');
    this.eftDisputeFee = data?.eftFees?.find((bankTransferFee) => bankTransferFee.feeType === 'EFT_DISPUTE_FEE');
    this.paymentMethodType = data?.paymentMethodType;
    this.realTimePaymentType = data?.realTimeFees;
    this.settlementDelay = data?.settlementDelay || 'T+4';
    this.clientType = data?.clientType || 'EZYCOLLECT_STANDARD';
    this.agreedToConfigTerms = data?.agreedToConfigTerms;
    this.isLoaded = true;
  }

  isPaymentMethodTypeEnabled(paymentMethodType) {
    return this.paymentMethodType?.includes(paymentMethodType);
  }

  toParams(organizationId) {
    const acceptedCardBrands = [];
    if (this.amexFees) {
      acceptedCardBrands.push(this.amexFees);
    }
    if (this.visaFees) {
      acceptedCardBrands.push(this.visaFees);
    }
    if (this.mastercardFees) {
      acceptedCardBrands.push(this.mastercardFees);
    }

    const data = {
      ...this,
      serviceFees: this.isPaymentMethodTypeEnabled('CREDIT_CARD')
        ? [this.disputeFee, this.fee3ds, this.transactionFee, this.failedFee]
        : [],
      cardFees: this.isPaymentMethodTypeEnabled('CREDIT_CARD') ? acceptedCardBrands : [],
      internationalFees: this.isPaymentMethodTypeEnabled('CREDIT_CARD') ? this.internationalFees : {},
      eftFees: this.isPaymentMethodTypeEnabled('EFT')
        ? [this.eftTransactionFee, this.eftVariableFee, this.eftRefundFee, this.eftFailedFee, this.eftDisputeFee]
        : [],
      realTimeFees: this.isPaymentMethodTypeEnabled('PAYTO') ?
        [this.realTimeTransactionFee, this.realTimeVariableFee, this.eftFailedFee, this.eftDisputeFee]: [],
    };

    return {
      serviceFees: JSON.stringify(data?.serviceFees),
      internationalFees: JSON.stringify(data?.internationalFees),
      cardFees: JSON.stringify(data?.cardFees),
      eftFees: JSON.stringify(data?.eftFees),
      agreedToConfigTerms: this.agreedToConfigTerms,
      realTimeFees: JSON.stringify(data?.realTimeFees),
    };
  }
}

export default FeeStructureDomain;
