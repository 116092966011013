import URLS from '../constants/caUrls';
import axios from 'axios';
import CommonUtils from '../Utils/CommonsUtils';

class ApiService {
  getBrand() {
    return axios.get(`${URLS.brand}${CommonUtils.getSubDomain()}`);
  }

  createCreditApplication(data, applicationId, applicationType = 'creditApplication', authToken) {
    const preparedData =
      applicationId && applicationType === 'paymentApplication' ? { ...data, onboardFormKey: applicationId } : data;
    return axios.post(`${URLS[applicationType]}`, preparedData, this._getAuthObject(applicationType, authToken));
  }

  updateCreditApplication(data, caId, applicationType = 'creditApplication', authToken) {
    return axios.put(`${URLS[applicationType]}/${caId}`, data, this._getAuthObject(applicationType, authToken));
  }

  updateCreditApplicationFormData(caId, formStepId, data, applicationType = 'creditApplication', authToken) {
    return axios.put(`${URLS[applicationType]}/addData/${caId}`, { formStepId: formStepId, formData: data }, this._getAuthObject(applicationType, authToken));
  }

  complete(caId, applicationType = 'creditApplication', authToken) {
    return axios.put(`${URLS[applicationType]}/complete/${caId}`, {}, this._getAuthObject(applicationType, authToken));
  }

  getDocumentStatus(caID, applicationType = 'creditApplication', authToken) {
    return axios.get(`${URLS[applicationType]}/documentStatus/${caID}`, this._getAuthObject(applicationType, authToken));
  }

  getCreditApplicationData(caId, applicationType = 'creditApplication', authToken) {
    return axios.get(`${URLS[applicationType]}/${caId}`, this._getAuthObject(applicationType, authToken));
  }

  getFeeStructureData(applicationId, authToken) {
    return axios.get(`${URLS.paymentApplication}/${applicationId}/fee-structure`, this._getAuthObject('paymentApplication', authToken));
  }

  createOrphanSetupIntent(data, orgId, authToken) {
    return axios.post(`${URLS.createOrphanSetupIntent}/${orgId}`, data, this._getAuthObject('creditApplication', authToken));
  }

  saveFeeStructureData(applicationId, data, authToken) {
    return axios.put(`${URLS.paymentApplication}/${applicationId}/fee-structure`, data, this._getAuthObject('paymentApplication', authToken));
  }

  saveDraft(caId, email, applicationType = 'creditApplication', authToken) {
    return axios.put(`${URLS[applicationType]}/continue-later/${caId}`, { email }, this._getAuthObject(applicationType, authToken));
  }

  getValidateLink(applicationId) {
    return axios.get(`${URLS.paymentApplication}/${applicationId}/valid`);
  }

  getApplicationFile(applicationId, applicationType, authToken) {
    const { headers } = this._getAuthObject(applicationType, authToken);

    return axios.get(`${URLS[applicationType]}/download/${applicationId}`, {
      responseType: 'blob',
      headers
    });
  }

  createOrphanBankTransferAgreement(data, orgId, authToken) {
    return axios.post(`${URLS.createOrphanBankTransferAgreement}/${orgId}`, data, this._getAuthObject('creditApplication', authToken));
  }

  getFeesByOrgId(orgId, authToken) {
    return axios.get(`${URLS.getFeesByOrgId}/${orgId}`, this._getAuthObject('creditApplication', authToken));
  }

  _getAuthObject(applicationType, authToken) {
    return applicationType === 'paymentApplication'
      ? {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      : {};
  }

  getDateFormatTimezone(orgId) {
    return axios.get(`${URLS.techSettings}${orgId}`);
  }

  getPayToAgreementStatus(agreementId) {
    return axios.get(`${URLS.getAgreementStatus}/${agreementId}`);
  }

}

export default new ApiService();
