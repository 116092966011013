import React from 'react';
import { ApplicationType } from '../../../domains/TypeEnum';

const PaymentAgreementDetails = (props: Record<string, any>) => {
  const { css, customApplication } = props;

  return (
    <>
      {customApplication && customApplication.type !== '' && (
        <div style={css.paymentDiv}>
          <p style={css.paymentParagraph}>
            By signing up to this Direct Debit Authority form, you are authorising{' '}
            <b>Brooks Hire Service Pty Limited</b> and the following entities:
          </p>
          <p style={css.paymentParagraph}>
            <b>Brooks Hire Service | Brooks Transport Service | Brooks Access | Brooks Equipment Sales</b>&nbsp; to
            debit payments from your authorised account in respect of the hire, but not limited to extended hire,
            missing items, damage caused by the applicant.
          </p>
        </div>
      )}

      {customApplication && customApplication.type !== ApplicationType.cashApplication && (
        <div style={css.paymentDiv}>
          <p style={css.paymentParagraph}>
            Disclaimer: No machine release until payment is received. All Card details are encrypted.
          </p>
        </div>
      )}

      {customApplication && customApplication.type === ApplicationType.cashApplication && (
        <div style={css.paymentDiv}>
          <p style={css.paymentParagraph}>
            Disclaimer: No new machine hire release permitted until payment received. (Minimum 24-hour notice period
            required before online Direct Debit payment can be processed).
          </p>
          <p style={css.paymentParagraph}>
            Should this timeframe not be provided, alternate payment method will be required as all card details are
            encrypted.
          </p>
        </div>
      )}
    </>
  );
};

export default PaymentAgreementDetails;
