import React from 'react';

function Modal({ isVisible, children, title, onClose, customFooter, customStyle = {} }) {
  return isVisible ? (
    <>
      <div
        className="w-screen h-screen justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        style={{ top: 0, left: 0 }}
      >
        <div
          className="w-screen h-screen justify-center bg-gray-700 opacity-50 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-60 outline-none focus:outline-none"
          style={{ top: 0, left: 0 }}
        ></div>

        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white  outline-none focus:outline-none"
            style={customStyle}
          >
            {/*header*/}
            <div className="flex items-start justify-between px-5 py-3 border-b border-solid border-gray-300 rounded-t">
              <h3 className="text-xl font-semibold">{title}</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-50 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => onClose()}
              >
                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto max-h-80 overflow-auto">{children}</div>
            {/*footer*/}
            <div className="flex items-center justify-end p-3 border-t border-solid border-gray-300 rounded-b">
              {customFooter ? (
                <>{customFooter}</>
              ) : (
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  style={{ transition: 'all .15s ease' }}
                  onClick={() => onClose()}
                >
                  Close
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : null;
}

export default Modal;
