import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import DateUtils from '../../Utils/DateUtils';
import properties from '../../constants/properties';
import MainStore from '../../stores/MainStore';
import DynamicForm from '../DynamicForm/dynamicForm';
import FormField from '../FormField';
import {
  PDFRow,
  DisplayLabelValue,
  PFBusinnesDetails,
  PFDocs,
  PFFeeStructure,
  PFReferences,
  PFTransactionInfo,
  PFTrusteeCompanyDetails,
  PFTrusteeDetails,
  PrintFormAreaTitle,
} from './PrintFormComponents';
import PaymentAgreementDetails from './components/PaymentAgreementDetails';
import { ApplicationType, CustomerType } from '../../domains/TypeEnum';
import parse from 'html-react-parser';
import LaunchDarklyStore from '../../stores/LaunchDarklyStore';
import PayToLogo from '../PaymentAuthorisation/BankTransfer/eddr/payto-modal/PayTo/PayToLogo';

Modal.setAppElement('#root');

const ModalAcceptButton = styled.button`
  margin-left: 0.5rem;
`;

const ModalCancelButton = styled.button``;

const ModalHeader = styled.div`
  border-bottom: 1px solid grey;
  font-weight: bold;
  padding: 1rem;
  padding-left: 1.5rem;
`;

const ModalFooter = styled.div`
  border-top: 1px solid grey;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  padding: 1rem 1.5rem;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ModalBody = styled.div`
  //line-height: 3;
  padding: 1.5rem;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SignerInfoWrapper = styled.div`
  margin-top: 1.5rem;
`;

const PolicySeperator = styled.div`
  border-bottom: 1px solid grey;
  left: 0;
  position: absolute;
  right: 0;
  margin-top: 1.5rem;
`;

const ModalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
  },
  content: {
    position: 'absolute',
    top: '10%',
    left: '0',
    right: '0',
    bottom: '12%',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px',
    maxHeight: '720px',
    maxWidth: '900px',
    width: '50%',
    margin: '0 auto',
  },
};

const paymentDiv = {
  padding: '15px 10px',
};

const paymentParagraph = {
  fontSize: '14px',
  fontWeight: '600',
  color: 'rgb(2, 22, 44)',
};

const PrintedForm = observer(
  ({
    creditApplication,
    referenceOne,
    referenceTwo,
    referenceThree,
    idVerification = undefined,
    directorsGuarantee = undefined,
    currencyCertificate = undefined,
    proofAddress = undefined,
    taxId = undefined,
    title = undefined,
    feeStructure = null,
  }) => {
    const mainStore = useContext(MainStore);
    const launchDarklyStore = useContext(LaunchDarklyStore);
    const caCustomization = launchDarklyStore.caCustomization.value;

    const [modalInputs, setModalInputs] = useState({ name: '', email: '', jobTitle: '' });
    const [modalNameError, setModalNameError] = useState(false);
    const [modalEmailError, setModalEmailError] = useState(false);
    const [modalJobTitleError, setModalJobTitleError] = useState(false);
    const [dateConfig, setDateConfig] = useState({ format: 'DD-MMM-YYYY', timezone: 'Australia/Sydney' });
    const isPaymentApplication = mainStore.applicationType === 'paymentApplication';
    const { organizationId } = mainStore.creditApplicationDomain;
    const { creditCard, customApplication, bankTransferInfo } = mainStore;
    const creditCardMask = creditCard.brand === 'American Express' ? '***********' : '**** **** ****';
    let privacyPolicyLabel = caCustomization.privacyPolicy;
    let tradingNameLabel = caCustomization.tradingName && caCustomization.tradingName.title ? caCustomization.tradingName.title.replace('*', '') : '';
    const dynamicFormDefinition = mainStore.getFormWithData();

    const companyNumberTitle = caCustomization.companyNumber && caCustomization.companyNumber.title ? caCustomization.companyNumber.title : '';
    const businessNumberTitle = caCustomization.businessNumber && caCustomization.businessNumber.title ? caCustomization.businessNumber.title : '';
    const dateOfIncorporationTitle = caCustomization.dateOfIncorporation && caCustomization.dateOfIncorporation.title ? caCustomization.dateOfIncorporation.title : '';
    const businessTypeTitle = caCustomization.businessType && caCustomization.businessType.title ? caCustomization.businessType.title : '';
    const numberOfEmployeesTitle = caCustomization.numberOfEmployees.title ? caCustomization.numberOfEmployees.title : 'NUMBER OF EMPLOYEES';

    dynamicFormDefinition.forEach((form, i) => {
      if (Object.getOwnPropertyNames(form.fields).length === 0) {
        delete dynamicFormDefinition[i].title;
      }
      if (form.fields.hasOwnProperty('paymentAuthorisation')) {
        dynamicFormDefinition[i].fields = {};
        delete dynamicFormDefinition[i].title;
      }
    });

    if (properties.POPS_GROUP_ORGS.includes(organizationId)) {
      privacyPolicyLabel = 'Guarantee & Indemnity';
    }

    if (properties.HAA_HAA_ORGS.includes(organizationId)) {
      tradingNameLabel = "Hirer's Name";
    }

    const handleModalAccept = (evt) => {
      evt.preventDefault();
      const isNotValid = validateModalFields();
      if (isNotValid) return;
      mainStore.saveTermsModalDetails({ signerInfo: modalInputs });
      mainStore.closeConfirmModal();
    };

    const validateModalFields = () => {
      const isValidEmail = validateEmail(modalInputs.email);
      setModalEmailError(!isValidEmail);

      const isValidName = validateEmptyField(modalInputs.name);
      setModalNameError(!isValidName);

      const isValidJob = validateEmptyField(modalInputs.jobTitle);
      setModalJobTitleError(!isValidJob);

      return !isValidName || !isValidEmail || !isValidJob;
    };

    const validateEmail = (email) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    };

    const validateEmptyField = (fieldValue) => {
      const validEntry = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
      return validEntry.test(String(fieldValue).toLowerCase());
    };

    const inputChange = (evt) => {
      const field = evt.target ? evt.target : evt;
      setModalInputs({ ...modalInputs, [field.name]: field.value });
    };

    const maskBankTransfer = (bankTransfer) => {
      return String(bankTransfer).substring(0, 2) + '****' + String(bankTransfer).substring(bankTransfer.length - 2);
    };

    const resetModalInputs = () => {
      setModalInputs({ name: '', email: '', jobTitle: '' });
      setModalNameError(false);
      setModalEmailError(false);
      setModalJobTitleError(false);
    };

    useEffect(() => {
      if (mainStore.dateConfig) {
        setDateConfig(mainStore.dateConfig);
      }
    }, [mainStore]);

    useEffect(() => {
      const fieldValue = modalInputs.name;

      //check for non empty fieldValue so error doesnt show initially
      if (fieldValue) {
        setModalNameError(!validateEmptyField(fieldValue));
      }
    }, [modalInputs.name]);

    useEffect(() => {
      const fieldValue = modalInputs.email;
      //so that email error only starts displaying/disappearing after accept button has been hit
      if (fieldValue && modalEmailError) {
        setModalEmailError(!validateEmail(fieldValue));
      }
    }, [modalInputs.email, modalEmailError]);

    useEffect(() => {
      const fieldValue = modalInputs.jobTitle;
      if (fieldValue) {
        setModalJobTitleError(!validateEmptyField(fieldValue));
      }
    }, [modalInputs.jobTitle]);

    const isOnboardingUS = properties.US_ONBOARDING_ORGS.includes(organizationId);
    const isOnboardingNZ = properties.NZ_ONBOARDING_ORGS.includes(organizationId);
    const feeStructureBankTransferTitle = isOnboardingUS
      ? `ACH - Fee Structure ${feeStructure.settlementDelay === 'T+4' ? '(%)' : '($)'}`
      : `Bank Transfer - Fee Structure ${isOnboardingNZ ? '(NZD)' : ''}`;

    const isUSAndHasSurcharge = () => {
      if (!isOnboardingUS) return false;

      const hasSomeSurcharge = [
        feeStructure.transactionFee?.surchargeValue,
        feeStructure.amexFees?.surchargeRate,
        feeStructure.mastercardFees?.surchargeRate,
        feeStructure.visaFees?.surchargeRate,
        feeStructure.internationalFees?.surchargeRate,
      ].some((fee) => fee > 0.0);

      return hasSomeSurcharge;
    };

    const getDateOfIncorporationValue = () => {
      if (
        mainStore.isCashCustomerIndividual() ||
        mainStore.isCreditCustomerIndividual() ||
        customApplication.customerType === CustomerType.residential
      ) {
        return '---';
      }

      return DateUtils.formatDateTimezone(
        creditApplication.dateOfIncorporation,
        dateConfig.dateFormat,
        dateConfig.timezone,
        creditApplication?.country === 'US' && properties.US_ONBOARDING_ORGS.includes(organizationId)
      );
    };

    const getFeeValue = (feeParentName, feeName) =>  {
      if (feeStructure[feeParentName]?.[feeName]?.toString().length > 0) {
        return parseFloat(feeStructure[feeParentName][feeName]);
      } else {
        return '';
      }
    }

    return (
      <>
        <Modal isOpen={mainStore.isConfirmModalOpen} style={ModalStyles}>
          <ModalForm
            onSubmit={(evt) => {
              handleModalAccept(evt);
            }}
          >
            <ModalHeader>
              <h2>{caCustomization.termsAndConditions}</h2>
            </ModalHeader>

            <ModalBody>
              <div className="prose-sm px-8 text-gray-700">
                {launchDarklyStore.flags.caTextEditorFlag.value
                  ? parse(mainStore.brand.terms)
                  : mainStore.brand.terms.split('\n').map((term, i) => (
                      <p key={i} className="px-8 text-gray-700">
                        {term}
                      </p>
                    ))}
              </div>
              <b>{privacyPolicyLabel}</b>
              <PolicySeperator />
              <div className="px-8 prose-sm text-gray-700" style={{ marginTop: '1.5rem' }}>
                {launchDarklyStore.flags.caTextEditorFlag.value
                  ? parse(mainStore.brand.privacyPolicy)
                  : mainStore.brand.privacyPolicy.split('\n').map((privacy, i) => (
                      <p key={i} className="px-8 text-gray-700">
                        {privacy}
                      </p>
                    ))}
              </div>
              <br />
              <input
                required
                id="terms-conditions"
                name="terms-conditions"
                type="checkbox"
                className="focus:outline-none focus:ring-transparent"
              />
              <label htmlFor="terms-conditions" className="ml-2 text-sm">
                I acknowledge that I have read and accept the Terms and Conditions and {privacyPolicyLabel}
              </label>
              <SignerInfoWrapper className="flex flex-wrap">
                <div className="w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 mt-6 form-group">
                  <FormField
                    label="Name *"
                    value={modalInputs.name}
                    placeholder="Name"
                    onChange={inputChange}
                    id="name"
                    name={'name'}
                    hasError={modalNameError}
                    errorMessage={'Please enter a name'}
                    type="text"
                  />
                </div>
                <div className="w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 mt-6 form-group">
                  <FormField
                    label="Job Title *"
                    value={modalInputs.jobTitle}
                    placeholder="Job Title"
                    onChange={inputChange}
                    id="jobTitle"
                    name={'jobTitle'}
                    hasError={modalJobTitleError}
                    errorMessage={'Please enter a job title'}
                    type="text"
                  />
                </div>
                <div className="w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 mt-6 form-group">
                  <FormField
                    label="Email *"
                    value={modalInputs.email}
                    placeholder="Email"
                    onChange={inputChange}
                    id="email"
                    name={'email'}
                    hasError={modalEmailError}
                    errorMessage={'Please enter a valid email'}
                    type="text"
                  />
                </div>
              </SignerInfoWrapper>
            </ModalBody>
            <ModalFooter>
              <ModalCancelButton
                className="bg-white hover:bg-gray-200 text-gray-700  font-medium tracking-wider py-2 px-5 rounded  shadow disabled:cursor-not-allowed disabled:opacity-75"
                onClick={() => {
                  resetModalInputs();
                  mainStore.closeConfirmModal();
                }}
              >
                CANCEL
              </ModalCancelButton>
              <ModalAcceptButton className="bg-blue-600 hover:bg-blue-800 text-white leading-snug font-medium tracking-wider py-2 px-5 rounded shadow disabled:cursor-not-allowed disabled:opacity-75">
                ACCEPT
              </ModalAcceptButton>
            </ModalFooter>
          </ModalForm>
        </Modal>
        <div className="m-2">
          {title && <h3 className="text-center text-gray-750 text-lg">{title}</h3>}
          <PrintFormAreaTitle>{caCustomization.businessDetailConfirmPage}</PrintFormAreaTitle>
          <></>
          <PFBusinnesDetails>
            {(mainStore.isCashCustomerCompany() ||
              mainStore.isCreditCustomerCompany() ||
              !mainStore.isCustomerApplication()) && (
              <>
                <PDFRow>
                  {caCustomization.registeredCompanyName.visible && (
                    <DisplayLabelValue
                      cssClass="company-name"
                      label={`${caCustomization?.companyName?.title || 'Legal Name'}`}
                      value={
                        customApplication.customerType === CustomerType.residential
                          ? '---'
                          : creditApplication.registeredCompanyName
                      }
                    />
                  )}
                  {creditApplication?.country === 'US' &&
                  creditApplication?.applicationType === 'paymentApplication' ? (
                    <></>
                  ) : (
                    <DisplayLabelValue
                      cssClass="abn"
                      label={businessNumberTitle.replace('*', '')}
                      value={creditApplication.businessNumber}
                    />
                  )}
                  {creditApplication?.country === 'US' &&
                  creditApplication?.applicationType === 'paymentApplication' ? (
                    <></>
                  ) : (
                    <DisplayLabelValue cssClass="acn" label={companyNumberTitle.replace('*', '')} value={creditApplication.companyNumber} />
                  )}
                  <DisplayLabelValue
                    cssClass="date"
                    label={dateOfIncorporationTitle.replace('*', '')}
                    value={getDateOfIncorporationValue()}
                  />
                </PDFRow>

                <PDFRow>
                  <DisplayLabelValue
                    cssClass="tranding-name"
                    label={tradingNameLabel}
                    value={
                      customApplication.customerType === CustomerType.residential
                        ? '---'
                        : creditApplication.tradingName
                    }
                  />
                </PDFRow>

                <PDFRow>
                  {creditApplication?.businessIdentificationType ? (
                    <DisplayLabelValue
                      cssClass="business-identification-type"
                      label="Business Identification Type"
                      value={creditApplication.businessIdentificationType?.value}
                    />
                  ) : null}
                  {creditApplication.ein ? (
                    <DisplayLabelValue
                      cssClass="ein"
                      label="BUSINESS IDENTIFICATION NUMBER"
                      value={creditApplication.ein}
                    />
                  ) : null}
                  {!mainStore.isCustomerApplication() && (
                    <DisplayLabelValue
                      cssClass="business-type"
                      label={businessTypeTitle.replace('*', '')}
                      value={
                        creditApplication.businessType?.value
                          ? creditApplication.businessType?.value
                          : creditApplication.businessType
                      }
                    />
                  )}
                </PDFRow>
              </>
            )}

            <PDFRow>
              {(mainStore.isCashCustomerIndividual() || mainStore.isCreditCustomerIndividual()) && (
                <DisplayLabelValue cssClass="full-name" label="Full Name" value={creditApplication.fullName} />
              )}

              {(mainStore.isCashCustomerIndividual() || mainStore.isCreditCustomerIndividual()) && (
                <DisplayLabelValue cssClass="email" label="Email" value={creditApplication.email} />
              )}

              {(mainStore.isCashCustomerIndividual() || mainStore.isCreditCustomerIndividual()) && (
                <DisplayLabelValue cssClass="phone-number" label="Phone Number" value={creditApplication.phoneNumber} />
              )}
            </PDFRow>

            <PDFRow>
              {isPaymentApplication ? (
                <>
                  <DisplayLabelValue
                    cssClass="credit-limit"
                    label="INDUSTRY"
                    value={creditApplication.industryOption.label}
                  />
                  <DisplayLabelValue cssClass="website" label="WEBSITE" value={creditApplication.website} />
                </>
              ) : (
                <>
                  {(mainStore.isCreditCustomerIndividual() || mainStore.isCreditCustomerCompany()) && (
                    <div className="credit-limit">
                      <span>Credit Limit requested</span>
                      <p>
                        <NumberFormat
                          value={creditApplication.creditLimitRequested}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </p>
                    </div>
                  )}

                  {(mainStore.isCreditCustomerIndividual() || mainStore.isCreditCustomerCompany()) && (
                    <DisplayLabelValue
                      cssClass="credit-period"
                      label="CREDIT PERIOD"
                      value={creditApplication.creditPeriod}
                    />
                  )}

                  {!(!caCustomization.numberOfEmployees?.remove || !caCustomization.numberOfEmployees.visible) && (
                    <DisplayLabelValue
                      cssClass="employees"
                      label={numberOfEmployeesTitle.toUpperCase().replace('*', '') }
                      value={creditApplication.numberOfEmployees}
                    />
                  )}
                </>
              )}

              {caCustomization.country.visible && (
                <DisplayLabelValue cssClass="country" label="Country" value={creditApplication.country} />
              )}
            </PDFRow>

            <PDFRow>
              {mainStore.isCustomerApplication() && (
                <DisplayLabelValue cssClass="currency" label="Currency" value={creditApplication.currency} />
              )}
              {customApplication.type && (
                <DisplayLabelValue
                  cssClass="application-type"
                  label={`${caCustomization?.applicationType?.title || 'Application Type'}`}
                  value={customApplication.type}
                />
              )}
              {customApplication.type && customApplication.customerType && (
                <DisplayLabelValue
                  cssClass="customer-type"
                  label={`${caCustomization?.customerType?.title || 'Customer Type'}`}
                  value={customApplication.customerType}
                />
              )}
            </PDFRow>
          </PFBusinnesDetails>

          {creditApplication.trusteeDocument &&
          creditApplication?.country === 'US' &&
          properties.US_ONBOARDING_ORGS.includes(organizationId) ? (
            <React.Fragment>
              <PrintFormAreaTitle>Trustee Info</PrintFormAreaTitle>
              <PFTrusteeDetails>
                <DisplayLabelValue
                  cssClass="document"
                  label="Document Number"
                  value={creditApplication.trusteeDocument}
                />
              </PFTrusteeDetails>
            </React.Fragment>
          ) : null}

          {creditApplication.isTrusteeCompany && (
            <React.Fragment>
              <PrintFormAreaTitle>Trustee Info</PrintFormAreaTitle>
              {creditApplication.isTrusteeCompany && creditApplication.trusteeCompanyNumber !== '' ? (
                <PFTrusteeCompanyDetails>
                  <DisplayLabelValue
                    cssClass="tranding-name"
                    label="TRADING NAME"
                    value={creditApplication.trusteeTradingName}
                  />

                  <DisplayLabelValue
                    cssClass="business-type"
                    label="Business Type"
                    value={creditApplication.trusteeBusinessType}
                  />
                  <DisplayLabelValue
                    cssClass="acn"
                    label="Company Number"
                    value={creditApplication.trusteeCompanyNumber}
                  />
                  <DisplayLabelValue
                    cssClass="abn"
                    label="Business Number"
                    value={creditApplication.trusteeBusinessNumber}
                  />
                </PFTrusteeCompanyDetails>
              ) : (
                <PFTrusteeDetails>
                  <DisplayLabelValue cssClass="full-name" label="Full name" value={creditApplication.trusteeFullName} />
                  <DisplayLabelValue
                    cssClass="date"
                    label="Date of birth"
                    value={DateUtils.formatDateTimezone(
                      creditApplication.trusteeDateOfBirth,
                      dateConfig.dateFormat,
                      '',
                      false
                    )}
                  />
                  <DisplayLabelValue
                    cssClass="document"
                    label="Document Number"
                    value={creditApplication.trusteeDocument}
                  />
                </PFTrusteeDetails>
              )}
            </React.Fragment>
          )}
          {isPaymentApplication && (
            <>
              <PrintFormAreaTitle>Transaction Info</PrintFormAreaTitle>
              <PFTransactionInfo>
                <DisplayLabelValue
                  cssClass="transaction-limit"
                  label={`Transaction Limit Request ${isOnboardingNZ ? ' (NZD)' : ' ($)'}`}
                  value={creditApplication.transactionLimitRequest}
                />
                <DisplayLabelValue
                  cssClass="estimated-value"
                  label={`Estimated Monthly Value to be processed${isOnboardingNZ ? ' (NZD)' : ' ($)'}`}
                  value={creditApplication.estimatedMonthlyValue}
                />
                <DisplayLabelValue
                  cssClass="average-amount"
                  label={`Average single transaction amount${isOnboardingNZ ? ' (NZD)' : ' ($)'}`}
                  value={creditApplication.averageSingleTxAmount}
                />
                <DisplayLabelValue
                  cssClass="highest-amount"
                  label={`Highest single transaction amount${isOnboardingNZ ? ' (NZD)' : ' ($)'}`}
                  value={creditApplication.highestSingleTxAmount}
                />
                <DisplayLabelValue
                  cssClass="arrears-payment"
                  label="Arrears Payments?"
                  value={creditApplication.arrears ? 'Yes' : 'No'}
                />
                <DisplayLabelValue
                  cssClass="advance-payment"
                  label="Advance Payments?"
                  value={creditApplication.advance ? 'Yes' : 'No'}
                />
                <DisplayLabelValue
                  cssClass="days-advance"
                  label="Days in Advance "
                  value={creditApplication.daysInAdvance}
                />
              </PFTransactionInfo>
            </>
          )}

          <DynamicForm form={dynamicFormDefinition} dateConfig={dateConfig} country={creditApplication?.country} />

          {feeStructure && feeStructure.paymentMethodType?.includes('CREDIT_CARD') && (
            <>
              <PrintFormAreaTitle>
                Fee Structure{' '}
                {isUSAndHasSurcharge() ? ` (Surcharge fees are not applicable for debit card transactions)` : ''}
              </PrintFormAreaTitle>
              <PrintFormAreaTitle>Transaction Fees {isOnboardingNZ ? '(NZD)' : ''}</PrintFormAreaTitle>
              <PFFeeStructure>
                <DisplayLabelValue
                  cssClass="col1"
                  label="Fee"
                  value={feeStructure.transactionFee?.feeValue ? parseFloat(feeStructure.transactionFee?.feeValue) : ''}
                />
                <DisplayLabelValue
                  cssClass="col2"
                  label="Absorb"
                  value={feeStructure.transactionFee?.netValue ? parseFloat(feeStructure.transactionFee.netValue) : ''}
                />
                <DisplayLabelValue
                  cssClass="col3"
                  label="Surcharge"
                  value={
                    feeStructure.transactionFee?.surchargeValue
                      ? parseFloat(feeStructure.transactionFee.surchargeValue)
                      : ''
                  }
                />
              </PFFeeStructure>
              {feeStructure.fee3ds?.feeValue && (
                <PFFeeStructure>
                  <DisplayLabelValue
                    cssClass="colfull"
                    label="3DS"
                    value={feeStructure.fee3ds?.feeValue ? parseFloat(feeStructure.fee3ds?.feeValue) : ''}
                  />
                </PFFeeStructure>
              )}
              {feeStructure.visaFees && (
                <>
                  <PrintFormAreaTitle>VISA</PrintFormAreaTitle>
                  <PFFeeStructure>
                    <DisplayLabelValue
                      cssClass="col1"
                      label="Fee"
                      value={feeStructure.visaFees.feeRate ? parseFloat(feeStructure.visaFees.feeRate) : ''}
                    />
                    <DisplayLabelValue
                      cssClass="col2"
                      label="Absorb"
                      value={feeStructure.visaFees.netRate ? parseFloat(feeStructure.visaFees.netRate) : ''}
                    />
                    <DisplayLabelValue
                      cssClass="col3"
                      label="Surcharge"
                      value={feeStructure.visaFees.surchargeRate ? parseFloat(feeStructure.visaFees.surchargeRate) : ''}
                    />
                  </PFFeeStructure>
                </>
              )}
              {feeStructure.mastercardFees && (
                <>
                  <PrintFormAreaTitle>MASTERCARD</PrintFormAreaTitle>
                  <PFFeeStructure>
                    <DisplayLabelValue
                      cssClass="col1"
                      label="Fee"
                      value={feeStructure.mastercardFees.feeRate ? parseFloat(feeStructure.mastercardFees.feeRate) : ''}
                    />
                    <DisplayLabelValue
                      cssClass="col2"
                      label="Absorb"
                      value={feeStructure.mastercardFees.netRate ? parseFloat(feeStructure.mastercardFees.netRate) : ''}
                    />
                    <DisplayLabelValue
                      cssClass="col3"
                      label="Surcharge"
                      value={
                        feeStructure.mastercardFees.surchargeRate
                          ? parseFloat(feeStructure.mastercardFees.surchargeRate)
                          : ''
                      }
                    />
                  </PFFeeStructure>
                </>
              )}
              {feeStructure.amexFees && (
                <>
                  <PrintFormAreaTitle>AMERICAN EXPRESS</PrintFormAreaTitle>
                  <PFFeeStructure>
                    <DisplayLabelValue
                      cssClass="col1"
                      label="Fee"
                      value={feeStructure.amexFees.feeRate ? parseFloat(feeStructure.amexFees.feeRate) : ''}
                    />
                    <DisplayLabelValue
                      cssClass="col2"
                      label="Absorb"
                      value={feeStructure.amexFees.netRate ? parseFloat(feeStructure.amexFees.netRate) : ''}
                    />
                    <DisplayLabelValue
                      cssClass="col3"
                      label="Surcharge"
                      value={feeStructure.amexFees.surchargeRate ? parseFloat(feeStructure.amexFees.surchargeRate) : ''}
                    />
                  </PFFeeStructure>
                </>
              )}
              {parseFloat(feeStructure.internationalFees?.feeRate?.toString()) > 0 && (
                <>
                  <PrintFormAreaTitle>INTERNATIONAL CARD</PrintFormAreaTitle>
                  <PFFeeStructure>
                    <DisplayLabelValue
                      cssClass="col1"
                      label="Fee"
                      value={
                        feeStructure.internationalFees.feeRate ? parseFloat(feeStructure.internationalFees.feeRate) : ''
                      }
                    />
                    <DisplayLabelValue
                      cssClass="col2"
                      label="Absorb"
                      value={
                        feeStructure.internationalFees.netRate ? parseFloat(feeStructure.internationalFees.netRate) : ''
                      }
                    />
                    <DisplayLabelValue
                      cssClass="col3"
                      label="Surcharge"
                      value={
                        feeStructure.internationalFees.surchargeRate
                          ? parseFloat(feeStructure.internationalFees.surchargeRate)
                          : ''
                      }
                    />
                  </PFFeeStructure>
                </>
              )}
              <PrintFormAreaTitle>FIXED BUSINESS FEES {isOnboardingNZ ? '(NZD)' : ''}</PrintFormAreaTitle>
              <PFFeeStructure>
                <DisplayLabelValue
                  cssClass="colfull"
                  label="Failed Transaction Fee"
                  value={feeStructure.failedFee?.feeValue ? parseFloat(feeStructure.failedFee?.feeValue) : ''}
                />
              </PFFeeStructure>
              <PFFeeStructure>
                <DisplayLabelValue
                  cssClass="colfull"
                  label="Dispute / Chargeback Fee"
                  value={feeStructure.disputeFee?.feeValue ? parseFloat(feeStructure.disputeFee?.feeValue) : ''}
                />
              </PFFeeStructure>
            </>
          )}

          {feeStructure && feeStructure.paymentMethodType?.includes('EFT') && (
            <>
              <PrintFormAreaTitle>{feeStructureBankTransferTitle}</PrintFormAreaTitle>
              {properties.US_ONBOARDING_ORGS.includes(organizationId) ? (
                <></>
              ) : (
                <>
                  <PrintFormAreaTitle>Transaction Fees ($)</PrintFormAreaTitle>
                  <PFFeeStructure>
                    <DisplayLabelValue
                      cssClass="col1"
                      label="Fee"
                      value={
                        feeStructure.eftTransactionFee?.feeValue
                          ? parseFloat(feeStructure.eftTransactionFee?.feeValue)
                          : ''
                      }
                    />
                    <DisplayLabelValue
                      cssClass="col2"
                      label="Absorb"
                      value={
                        feeStructure.eftTransactionFee.netValue
                          ? parseFloat(feeStructure.eftTransactionFee.netValue)
                          : ''
                      }
                    />
                    <DisplayLabelValue
                      cssClass="col3"
                      label="Surcharge"
                      value={
                        feeStructure.eftTransactionFee.surchargeValue
                          ? parseFloat(feeStructure.eftTransactionFee.surchargeValue)
                          : ''
                      }
                    />
                  </PFFeeStructure>
                  <PrintFormAreaTitle>
                    {`Additional Charge over $${feeStructure.eftVariableFee.thresholdValue}`} (%)
                  </PrintFormAreaTitle>
                </>
              )}
              <PFFeeStructure>
                <DisplayLabelValue
                  cssClass="col1"
                  label="Fee"
                  value={feeStructure.eftVariableFee?.feeValue ? parseFloat(feeStructure.eftVariableFee?.feeValue) : ''}
                />
                <DisplayLabelValue
                  cssClass="col2"
                  label="Absorb"
                  value={feeStructure.eftVariableFee.netValue ? parseFloat(feeStructure.eftVariableFee.netValue) : ''}
                />
                <DisplayLabelValue
                  cssClass="col3"
                  label="Surcharge"
                  value={
                    feeStructure.eftVariableFee.surchargeValue
                      ? parseFloat(feeStructure.eftVariableFee.surchargeValue)
                      : ''
                  }
                />
              </PFFeeStructure>

              {properties.US_ONBOARDING_ORGS.includes(organizationId) && feeStructure?.settlementDelay === 'T+4' ? (
                <PFFeeStructure>
                  <DisplayLabelValue
                    cssClass="colfull"
                    label="Capped at ($)"
                    value={
                      feeStructure.eftVariableFee.thresholdValue
                        ? parseFloat(feeStructure.eftVariableFee.thresholdValue)
                        : ''
                    }
                  />
                </PFFeeStructure>
              ) : (
                <></>
              )}

              <PrintFormAreaTitle>FIXED BUSINESS FEES ($)</PrintFormAreaTitle>
              <PFFeeStructure>
                <DisplayLabelValue
                  cssClass="col1"
                  label={
                    properties.US_ONBOARDING_ORGS.includes(organizationId)
                      ? 'Failed ACH Transaction Fee'
                      : 'Failed Transaction Fee'
                  }
                  value={feeStructure.eftFailedFee?.feeValue ? parseFloat(feeStructure.eftFailedFee?.feeValue) : ''}
                />
                <DisplayLabelValue
                  cssClass="col2"
                  label="Dispute / Chargeback Fee"
                  value={feeStructure.eftDisputeFee?.feeValue ? parseFloat(feeStructure.eftDisputeFee?.feeValue) : ''}
                />
                {!properties.US_ONBOARDING_ORGS.includes(organizationId) ? (
                  <DisplayLabelValue
                    cssClass="col3"
                    label="Refund Fee"
                    value={feeStructure.eftRefundFee?.feeValue ? parseFloat(feeStructure.eftRefundFee?.feeValue) : ''}
                  />
                ) : (
                  <DisplayLabelValue cssClass="col3"></DisplayLabelValue>
                )}
              </PFFeeStructure>
              {feeStructure.realTimePaymentType?.length > 0 ? (
                <>
                  <PrintFormAreaTitle>PayTo - Fee Structure</PrintFormAreaTitle>
                  <>
                    <PrintFormAreaTitle>Transaction Fees ($)</PrintFormAreaTitle>
                    <PFFeeStructure>
                      <DisplayLabelValue
                        cssClass="col1"
                        label="Fee"
                        value={getFeeValue('realTimeTransactionFee', 'feeValue')}
                      />
                      <DisplayLabelValue
                        cssClass="col2"
                        label="Absorb"
                        value={getFeeValue('realTimeTransactionFee', 'netValue')}
                      />
                      <DisplayLabelValue
                        cssClass="col3"
                        label="Surcharge"
                        value={getFeeValue('realTimeTransactionFee', 'surchargeValue')}
                      />
                    </PFFeeStructure>
                    <PrintFormAreaTitle>
                      {`Additional Charge over $${feeStructure.realTimeVariableFee.thresholdValue}`} (%)
                    </PrintFormAreaTitle>
                  </>
                  <PFFeeStructure>
                    <DisplayLabelValue
                      cssClass="col1"
                      label="Fee"
                      value={getFeeValue('realTimeVariableFee', 'feeValue')}
                    />
                    <DisplayLabelValue
                      cssClass="col2"
                      label="Absorb"
                      value={getFeeValue('realTimeVariableFee', 'netValue')}
                    />
                    <DisplayLabelValue
                      cssClass="col3"
                      label="Surcharge"
                      value={getFeeValue('realTimeVariableFee', 'surchargeValue')}
                    />
                  </PFFeeStructure>

                  <PrintFormAreaTitle>FIXED BUSINESS FEES ($)</PrintFormAreaTitle>
                  <PFFeeStructure>
                    <DisplayLabelValue
                      cssClass="col1"
                      label="Failed Transaction Fee"
                      value={feeStructure.eftFailedFee?.feeValue ? parseFloat(feeStructure.eftFailedFee?.feeValue) : ''}
                    />
                    <DisplayLabelValue
                      cssClass="col2"
                      label="Dispute / Chargeback Fee"
                      value={
                        feeStructure.eftDisputeFee?.feeValue ? parseFloat(feeStructure.eftDisputeFee?.feeValue) : ''
                      }
                    />
                    <DisplayLabelValue cssClass="col3" label="" value="" />
                  </PFFeeStructure>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          {feeStructure && feeStructure.clientType === 'EZYCOLLECT_STANDARD' ? (
            <div style={paymentDiv}>
              <p style={paymentParagraph}>To apply a surcharge, you're required to comply with the following:</p>
              <ul className="list-disc pl-5 pt-2">
                {isOnboardingUS ? (
                  <>
                    <li>You're responsible for determining if surcharge is allowed in your state.</li>
                    <li>You must follow state and local laws.</li>
                    <li>Only credit card transactions are permitted.</li>
                    <li>The surcharge amount must not exceed your cost of acceptance for the credit card.</li>
                  </>
                ) : (
                  <></>
                )}
                <li>
                  This is the default fee structure that applies to all your customers. However, you have the
                  flexibility to customize the fee structure for individual customers as needed
                </li>
              </ul>
              <div>
                <input style={{ marginRight: '10px' }} id="terms" type="checkbox" checked={true} readOnly />
                <label htmlFor="terms"> I agree</label>
              </div>
            </div>
          ) : null}

          {(referenceOne || referenceTwo || referenceThree) && (
            <>
              <PrintFormAreaTitle>Trade References</PrintFormAreaTitle>
              <PFReferences>
                <DisplayLabelValue cssClass="col1" label="Company Name" value={referenceOne?.companyName} />
                <DisplayLabelValue cssClass="col2" label="Contact Name" value={referenceOne?.contactName} />
                <DisplayLabelValue cssClass="col3" label="Email" value={referenceOne?.email} />
                <DisplayLabelValue cssClass="col4" label="Phone" value={referenceOne?.phone} />
              </PFReferences>
              <PFReferences odd={true}>
                <DisplayLabelValue cssClass="col1" label="Company Name" value={referenceTwo?.companyName} />
                <DisplayLabelValue cssClass="col2" label="Contact Name" value={referenceTwo?.contactName} />
                <DisplayLabelValue cssClass="col3" label="Email" value={referenceTwo?.email} />
                <DisplayLabelValue cssClass="col4" label="Phone" value={referenceTwo?.phone} />
              </PFReferences>
              <PFReferences>
                <DisplayLabelValue cssClass="col1" label="Company Name" value={referenceThree?.companyName} />
                <DisplayLabelValue cssClass="col2" label="Contact Name" value={referenceThree?.contactName} />
                <DisplayLabelValue cssClass="col3" label="Email" value={referenceThree?.email} />
                <DisplayLabelValue cssClass="col4" label="Phone" value={referenceThree?.phone} />
              </PFReferences>
            </>
          )}

          {mainStore.customApplication &&
            mainStore.customApplication?.type !== '' &&
            mainStore.customApplication?.type !== ApplicationType.prePaidAccount &&
            mainStore.customApplication?.type !== ApplicationType.creditAccount &&
            properties.BROOKS_HIRE_ORGS.includes(organizationId) &&
            !mainStore.isCustomerApplication() && (
              <>
                <PrintFormAreaTitle>Waiver Fee Notice and Election</PrintFormAreaTitle>
                <div style={paymentDiv}>
                  {mainStore.customApplication.waiverFeeNoticeElection ? (
                    <p style={paymentParagraph}>
                      The Applicant accepts the Waiver Fee and understands that the prescribed amount be either included
                      in amounts invoiced or invoiced as a separate item.
                    </p>
                  ) : (
                    <p style={paymentParagraph}>
                      The Applicant declines the offer of the Waiver Fee and hereby accept full and total responsibility
                      for the costs associated with the replacement or repair of items in my/our control whilst subject
                      to an agreement between the Applicant and the Owner. Applicants who decline the offer of the
                      Waiver Fee will be required to provide a copy of Certificate of Currency of an appropriate level
                      of insurance and to register the Owner as an interested party on such insurance policy.
                    </p>
                  )}
                </div>
              </>
            )}

          {(idVerification || directorsGuarantee || currencyCertificate) && (
            <>
              <PrintFormAreaTitle>Documents</PrintFormAreaTitle>
              <PFDocs>
                <div>
                  <p>{idVerification && decodeURI(idVerification.split(';')[1].replace('name=', ''))}</p>
                </div>
              </PFDocs>
              <PFDocs>
                <div>
                  <p>{currencyCertificate && decodeURI(currencyCertificate.split(';')[1].replace('name=', ''))}</p>
                </div>
              </PFDocs>
              <PFDocs>
                <div>
                  <p>{directorsGuarantee && decodeURI(directorsGuarantee.split(';')[1].replace('name=', ''))}</p>
                </div>
              </PFDocs>
              <PFDocs>
                <div>
                  <p>{proofAddress && decodeURI(proofAddress.split(';')[1].replace('name=', ''))}</p>
                </div>
              </PFDocs>
              <PFDocs>
                <div>
                  <p>{taxId && decodeURI(taxId.split(';')[1].replace('name=', ''))}</p>
                </div>
              </PFDocs>
            </>
          )}

          {(creditCard.last4 || bankTransferInfo.bsb) && !isPaymentApplication && (
            <>
              <PrintFormAreaTitle>Payment Authorisation Details</PrintFormAreaTitle>

              {properties.BROOKS_HIRE_ORGS.includes(organizationId) && (
                <PaymentAgreementDetails
                  customApplication={bankTransferInfo.bsb ? null : customApplication}
                  css={{ paymentDiv, paymentParagraph }}
                />
              )}

              <div style={paymentDiv}>
                <p style={paymentParagraph}>You acknowledge your agreement of the following:</p>
                <br></br>
                <p style={paymentParagraph}>1. You have read and agreed to the Direct Debit Terms and Conditions.</p>
                <p style={paymentParagraph}>2. The information you have provided is true and correct.</p>
                <p style={paymentParagraph}>
                  3. You understand that you are agreeing to a contract with ongoing payments, where applicable.
                </p>
                <p style={paymentParagraph}>4. You agree to accept the payment processing fees, if applicable.</p>

                <div>
                  <input style={{ marginRight: '10px' }} id="terms" type="checkbox" checked={true} readOnly />
                  <label htmlFor="terms"> I agree</label>
                </div>
              </div>

              <PrintFormAreaTitle>Payments</PrintFormAreaTitle>
              <div style={paymentDiv}>
                <p style={paymentParagraph}>
                  In accordance with your instructions we have updated our records with the following information (as
                  may be updated by you or your Financial Institution and notified to us):
                </p>
              </div>
              <PFReferences>
                {bankTransferInfo.bsb ? (
                  <>
                    <DisplayLabelValue cssClass="col1" label="Account Name" value={bankTransferInfo.accountName} />
                    <DisplayLabelValue cssClass="col2" label="BSB" value={bankTransferInfo.bsb} />
                    <DisplayLabelValue
                      cssClass="col3"
                      label="Account Number"
                      value={maskBankTransfer(bankTransferInfo.accountNumber)}
                    />
                    {bankTransferInfo.authorityType === 'PayTo' ? (
                      <div className="col4-payto">
                        <PayToLogo />
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <DisplayLabelValue
                      cssClass="col1"
                      label="Card Number"
                      value={`${creditCardMask} ${creditCard.last4}`}
                    />
                    <DisplayLabelValue
                      cssClass="col2"
                      label="Card Holder Name"
                      value={`${mainStore.creditCard.holder}`}
                    />
                  </>
                )}
              </PFReferences>
            </>
          )}
        </div>
      </>
    );
  }
);

export default PrintedForm;
