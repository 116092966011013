import React, { useCallback, useEffect, useState } from 'react';
import ResponsiveModal from '../../../../Modal/ResponsiveModal';
import apiService from '../../../../../services/apiService';
import ExpiredAgreementSVG from './PayTo/ExpiredAgreementSVG';
import AgreementSVG from './PayTo/AgreementSVG';
import { useTimer } from 'react-timer-hook';
import moment from 'moment';
import './PayToProcessing.scss';

type PayToErrorReason = {
  code: string;
  detail: string;
};

export type PayToAgreement = {
  uid: string;
  status: string;
  reason?: PayToErrorReason;
};

type PayToProcessingModalProps = {
  showModal: boolean;
  payToAgreementId: string;
  companyName: string;
  handleToggleModal: (agreementDetails: PayToAgreement) => void;
  onClose: () => void;
};

const PayToProcessingModal: React.FC<PayToProcessingModalProps> = ({
  showModal,
  payToAgreementId,
  companyName,
  handleToggleModal,
  onClose,
}) => {
  const [agreementDetails, setAgreementDetails] = useState<PayToAgreement>({ uid: '', status: '' });
  const [isAgreementExpired, setIsAgreementExpired] = useState(false);
  const { minutes, seconds, pause, restart } = useTimer({
    expiryTimestamp: moment().add(30, 'minutes').toDate(),
  });

  const getAgreementStatus = useCallback(() => {
    apiService
      .getPayToAgreementStatus(payToAgreementId)
      .then((response) => {
        setAgreementDetails(response.data);
      })
      .catch((error) => {
        handleToggleModal({
          uid: payToAgreementId,
          status: 'CANCELED',
          reason: { code: 'UN', detail: `Error on PayTo agreement status: ${error.message} ` },
        });
        console.error('Error on PayTo agreement status: ', error.message);
      });
  }, [payToAgreementId, handleToggleModal]);

  useEffect(() => {
    const agreementStatusInterval = setInterval(() => {
      switch (agreementDetails?.status) {
        case 'ACTIVE':
          clearInterval(agreementStatusInterval);
          onClose();
          break;
        case 'EXPIRED':
          clearInterval(agreementStatusInterval);
          setIsAgreementExpired(true);
          break;
        case 'CANCELED':
          clearInterval(agreementStatusInterval);
          handleToggleModal(agreementDetails);
          onClose();
          break;
      }

      if (!['ACTIVE', 'EXPIRED', 'CANCELED'].includes(agreementDetails?.status) && payToAgreementId?.length > 0) {
        getAgreementStatus();
      }
    }, 5000);

    return () => {
      clearInterval(agreementStatusInterval);
    };
  }, [isAgreementExpired, agreementDetails, payToAgreementId, getAgreementStatus, onClose, handleToggleModal]);

  useEffect(() => {
    return () => {
      setIsAgreementExpired(false);
      setAgreementDetails({ uid: '', status: '' });
      pause();
    };
  }, [pause]);

  //start timer only when the modal is visible
  useEffect(() => {
    if (showModal) {
      restart(moment().add(30, 'minutes').toDate());
    }
  }, [showModal, restart]);

  const isAgreementAccepted = agreementDetails?.status === 'ACTIVE';

  //close modal if agreement is accepted
  if (isAgreementAccepted) handleToggleModal(agreementDetails);

  return (
    <ResponsiveModal
      isOpen={showModal}
      onClose={() => handleToggleModal({ uid: payToAgreementId, status: 'CANCELED' })}
    >
      <div className="payto-processing-container">
        <div className="content-container">
          {isAgreementExpired ? (
            <>
              <h3 className="title">Agreement Expired!</h3>
              <div className="agreement-expired-container">
                <ExpiredAgreementSVG />
                <p className="mb-8">
                  We regret to inform you that the agreement required for this payment has expired. However, you can
                  easily generate a new agreement by clicking on the button below:
                </p>
                <div className="agreement-actions-container">
                  <button
                    type="button"
                    onClick={() => {
                      setIsAgreementExpired(false);
                      handleToggleModal({ uid: payToAgreementId, status: 'CANCELED' });
                      onClose();
                    }}
                    className="bg-white hover:bg-gray-200 text-gray-700  font-medium tracking-wider py-2 px-4 rounded mr-4 shadow disabled:cursor-not-allowed disabled:opacity-75"
                  >
                    Close
                  </button>
                  <button
                    className="bg-blue-600 hover:bg-blue-800 text-white leading-snug font-medium tracking-wider py-2 px-4 rounded shadow disabled:cursor-not-allowed disabled:opacity-75"
                    onClick={() => {
                      setAgreementDetails({ uid: '', status: '' });
                      setIsAgreementExpired(false);
                      restart(moment().add(30, 'minutes').toDate());
                      handleToggleModal({ uid: '', status: 'EXPIRED' });
                    }}
                  >
                    Generate New Agreement
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <h3 className="title">New PayTo agreement sent to your Bank App!</h3>
              <h4 className="subtitle">
                Please accept it in your bank app within{' '}
                <strong>
                  {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
                </strong>
              </h4>
              <div className="instructions-container">
                <div className="img-container">
                  <AgreementSVG />
                </div>
                <div className="instructions">
                  <p>1. Log in to your Internet Banking account or Banking app</p>
                  <p>2. Go to the PayTo agreements list and select the PayTo agreement you would like to authorise</p>
                  <p>
                    3. Review the details to make sure they match your agreement with <strong>{companyName}</strong> and
                    authorise agreement
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </ResponsiveModal>
  );
};

export default PayToProcessingModal;
