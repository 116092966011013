class CommonUtils {
  static getSubDomain() {
    return window.location.href.split('://')[1].split('.')[0];
  }

  static formatMoney = (n, c, d, t) => {
    let n1 = Number(n);
    let c1 = c;
    c1 = isNaN((c1 = Math.abs(parseFloat(c1)))) ? 2 : c1;
    let d1 = d || '.';
    let t1 = t || ',';
    let s1 = n1 < 0 ? '-' : '';
    let i1 = String(parseInt((n1 = Math.abs(Number(n1) || 0).toFixed(c1))));
    let j1 = i1.length > 3 ? i1.length % 3 : 0;
    return (
      s1 +
      (j1 ? i1.substr(0, j1) + t1 : '') +
      i1.substr(j1).replace(/(\d{3})(?=\d)/g, '$1' + t1) +
      (c1
        ? d1 +
          Math.abs(Number(n1) - Number(i1))
            .toFixed(c1)
            .slice(2)
        : '')
    );
  };
}

export default CommonUtils;
