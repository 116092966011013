import React, { useContext } from 'react';
import properties from '../constants/properties';
import parse from 'html-react-parser';
import LaunchDarklyStore from '../stores/LaunchDarklyStore';
import { observer } from 'mobx-react-lite';

const IntroductionText = observer(({ agree, applicationType, brand, organizationId }) => {
  const launchDarklyStore = useContext(LaunchDarklyStore);
  const caCustomization = launchDarklyStore.caCustomization.value;

  const getTermsAndConditionsLink = (applicationType, organizationId) => {
    if (applicationType === 'paymentApplication') {
      if (properties.US_ONBOARDING_ORGS.includes(organizationId)) {
        return 'https://ezycollect.io/terms-conditions-us/';
      } else if (properties.NZ_ONBOARDING_ORGS.includes(organizationId)) {
        return 'https://ezycollect.io/payments-terms-and-conditions-nz/';
      } else {
        return 'https://www.ezycollect.com.au/payments-terms-and-conditions/';
      }
    } else {
        return 'https://www.ezycollect.com.au/credit-reporting-policy'
    }
  };

  const getPrivacyPolicyLink = (organizationId) => {
    if (properties.US_ONBOARDING_ORGS.includes(organizationId)) {
      return 'https://ezycollect.io/privacy-policy-us/';
    } else if (properties.NZ_ONBOARDING_ORGS.includes(organizationId)) {
      return 'https://ezycollect.io/privacy-policy/';
    } else {
      return 'https://www.ezycollect.com.au/privacy-policy/';
    }
  }

  return brand.instanceType === 'SMOKEBALL' ? (
    <form
      className="flex flex-col h-full px-6 py-6 text-center justify-between"
      onSubmit={() => {
        agree();
        window.analytics.track('Form Start Clicked');
      }}
    >
      <article className="flex flex-col h-full items-center justify-center">
        <img
          className="w-96"
          src="https://ezy-marketing-images.s3.us-west-2.amazonaws.com/smokeball/smokeball-logo.png"
          alt="Smokeball Logo"
        />
        <h4 className="text-lg pt-4 pb-5 text-gray-750 uppercase text-center">Welcome to Smokeball payments</h4>
        <p className="px-8 sm:w-1/2 text-base text-gray-700 text-left w-1/2 xs:w-full">
          Smokeball payment is our embedded payment solution that connects your firms work to your invoices, and
          payments all in one platform.
        </p>
        <p className="px-8 py-10 sm:w-1/2 text-base text-gray-700 text-left w-1/2 xs:w-full">
          Smokeball payments are facilitated by EzyCollect Payments Pty Ltd. EzyCollect Payments Pty Ltd will provide
          the payment service to you on the terms set out in the{' '}
          <a
            href="https://www.smokeball.com.au/info/payments-terms"
            target="_blank"
            rel="noreferrer"
            style={{ color: '#0077C5' }}
          >
            Terms and Conditions
          </a>
          .
        </p>
      </article>
      <div>
        <button
          className="bg-blue-600 hover:bg-blue-800 text-white font-semibold tracking-wider py-2 px-12 rounded shadow self-center"
          type="submit"
        >
          START
        </button>
        <div className={`privacy-policy a:link py-4`}>
          <span>
            By clicking Start, you agree to Smokeball's{' '}
            <a
              style={{ color: '#0077C5' }}
              href="https://www.smokeball.com.au/info/payments-terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>{' '}
            and{' '}
            <a
              style={{ color: '#0077C5' }}
              href="https://www.smokeball.com.au/info/payments-privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
          </span>
        </div>
      </div>
    </form>
  ) : (
    <form
      className="flex flex-col h-full px-6 py-6 text-center justify-between"
      onSubmit={() => {
        agree();
        window.analytics.track('Form Start Clicked');
      }}
    >
      <article className="prose-lg">
        <h4 className="text-lg pt-8 pb-5 text-gray-750 uppercase text-center">
          {applicationType === 'paymentApplication' ? 'Payment Application Form' : caCustomization.frontPageTitle}
        </h4>
        {launchDarklyStore.flags.caTextEditorFlag.value
          ? parse(brand.introductionText)
          : brand.introductionText.split('\n').map((item, i) => (
              <p key={i} className="px-8 text-left text-base text-gray-700">
                {item}
              </p>
            ))}
      </article>
      <div>
        <button
          className="bg-blue-600 hover:bg-blue-800 text-white font-semibold tracking-wider py-2 px-12 rounded shadow self-center"
          type="submit"
        >
          START
        </button>
        <div className={`privacy-policy a:link py-4`}>
          <span>
            By clicking Start, you agree to ezyCollect's{' '}
            <a
              style={{ color: '#0077C5' }}
              href={getTermsAndConditionsLink(applicationType, organizationId)}
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>{' '}
            and{' '}
            <a
              style={{ color: '#0077C5' }}
              href={getPrivacyPolicyLink(organizationId)}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
          </span>
        </div>
      </div>
    </form>
  );
});

export default IntroductionText;
