import FormUrls from '../constants/formUrls';
import axios from 'axios';

class FormApiService {
  getForm(id) {
    return axios.get(`${FormUrls.form}${id}`);
  }
}

export default new FormApiService();
