import React from 'react';
import SvgBase from '../components/SvgBase';

function ArrayFieldTemplate(props) {
  return (
    <div className={props.className}>
      {props.items &&
        props.items.map((element) => (
          <div key={element.key} className={element.className}>
            <div>{element.children}</div>
            <div className="inline-flex">
              {element.hasMoveDown && (
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white text-xs font-medium tracking-wider py-2 px-4 rounded ml-4 shadow inline-flex items-center"
                  onClick={element.onReorderClick(element.index, element.index + 1)}>Move Down</button>
              )}
              {element.hasMoveUp && (
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white text-xs font-medium tracking-wider py-2 px-4 rounded ml-4 shadow inline-flex items-center"
                  onClick={element.onReorderClick(element.index, element.index - 1)}>Move Up</button>
              )}
              {element.index + 1 > props.schema.minItems && (
                <button
                  onClick={element.onDropIndexClick(element.index)}
                  className="bg-red-500 hover:bg-red-600 text-white text-xs font-medium tracking-wider py-2 px-4 rounded ml-4 shadow inline-flex items-center"
                >
                  <SvgBase>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z" />
                  </SvgBase>
                  <span>Delete</span>
                </button>
              )}
            </div>
            <hr className="my-4" />
          </div>
        ))}

      {props.canAdd && (
        <div className="row">
          <p className="col-xs-3 col-xs-offset-9 array-item-add text-right">
            <button
              onClick={props.onAddClick}
              type="button"
              className="bg-white hover:bg-gray-200 text-gray-700 text-sm font-medium tracking-wider py-2 px-4 rounded shadow inline-flex items-center"
            >
              <svg className="fill-current w-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
              </svg>
              <span>Add more</span>
            </button>
          </p>
        </div>
      )}
    </div>
  );
}

export default ArrayFieldTemplate;
