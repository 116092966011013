import React from 'react';

const PayToLogo: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 543.87 256.73">
      <path
        d="M148.82,239.12h40.54a88.81,88.81,0,0,1,19.3,1.93,40.39,40.39,0,0,1,14.74,6.32,29.58,29.58,0,0,1,9.48,11.23,37.46,37.46,0,0,1,3.33,16.5q0,10.35-3.86,17.37a31.22,31.22,0,0,1-10.44,11.32A45.62,45.62,0,0,1,206.55,310,86.21,86.21,0,0,1,188,312h-17v51.42H148.82Zm37.55,54.23a64.9,64.9,0,0,0,10.36-.79,28.58,28.58,0,0,0,8.77-2.81,16,16,0,0,0,6.14-5.52,16.49,16.49,0,0,0,2.28-9.13,15.68,15.68,0,0,0-2.28-8.86,15.84,15.84,0,0,0-6-5.36,26.12,26.12,0,0,0-8.6-2.54,78.41,78.41,0,0,0-9.92-.61H170.93v35.62Z"
        transform="translate(-148.82 -168.9)"
      />
      <path
        d="M297.28,352.67h-.53a23.53,23.53,0,0,1-10.17,9.21,34.55,34.55,0,0,1-16,3.6A45.64,45.64,0,0,1,260,364.16a31.57,31.57,0,0,1-10.18-4.39,25.28,25.28,0,0,1-7.72-8.07A23.21,23.21,0,0,1,239,339.33q0-9.48,5.35-15.09a34.55,34.55,0,0,1,13.78-8.6,77.32,77.32,0,0,1,18.69-3.95q10.26-1,20.09-1v-2.1c0-5.27-1.9-9.15-5.7-11.67a24.08,24.08,0,0,0-13.6-3.78A30.55,30.55,0,0,0,264.81,296a34.68,34.68,0,0,0-10.17,6.85L243.76,290a47.5,47.5,0,0,1,16.58-10,56.57,56.57,0,0,1,19-3.33q11.24,0,18.51,3.16a30,30,0,0,1,11.5,8.25,29.56,29.56,0,0,1,5.88,11.4,49.44,49.44,0,0,1,1.67,12.64v51.24H297.28Zm-.35-27.73h-4.74q-5.08,0-10.7.44a46,46,0,0,0-10.36,1.93,22.12,22.12,0,0,0-7.9,4.21,9.35,9.35,0,0,0-3.15,7.46,9,9,0,0,0,1.31,5,10.13,10.13,0,0,0,3.42,3.25,15.33,15.33,0,0,0,4.74,1.75,26.92,26.92,0,0,0,5.27.53q10.88,0,16.49-5.79t5.62-15.8Z"
        transform="translate(-148.82 -168.9)"
      />
      <path
        d="M323.2,279.14h23.34l23.51,61.24h.36l20.88-61.24h21.94L373.39,381.45a73.38,73.38,0,0,1-4.91,10.26,28.73,28.73,0,0,1-6.32,7.55,25.37,25.37,0,0,1-8.69,4.65,40.27,40.27,0,0,1-12,1.58c-1.76,0-3.54-.09-5.35-.26a38,38,0,0,1-5.53-1L332.32,386a22.37,22.37,0,0,0,4.13,1,30.14,30.14,0,0,0,3.77.26,17.3,17.3,0,0,0,5.62-.79A9.62,9.62,0,0,0,349.7,384a16.91,16.91,0,0,0,2.8-4.12q1.23-2.46,2.64-5.79l4.21-10.71Z"
        transform="translate(-148.82 -168.9)"
      />
      <path
        d="M634.65,168.9H494A58.05,58.05,0,0,0,436,227V367.59a58,58,0,0,0,58.05,58H634.65a58,58,0,0,0,58-58V227A58,58,0,0,0,634.65,168.9ZM585.2,360.48a49,49,0,0,1,0-97.9h55.22v-15.2L668.83,271l-28.44,23.67V279.14H584.49c-17.11,0-31.26,15.29-31.26,32.39a32,32,0,1,0,63.94,0,38.2,38.2,0,0,0-6.28-20.5h18.76a49.14,49.14,0,0,1,4.5,20.5A48.53,48.53,0,0,1,585.2,360.48Zm-68-17a41.31,41.31,0,0,0,11.49-2.22v16.83a33.4,33.4,0,0,1-11.49,2.41,30.6,30.6,0,0,1-30.35-30.77V279.14H467.71V262.58h19.16v-21h16.61v21h45.33a62.55,62.55,0,0,0-16.44,16.56H503.48v50.57A13.83,13.83,0,0,0,517.22,343.46Z"
        transform="translate(-148.82 -168.9)"
      />
    </svg>
  );
};

export default PayToLogo;
