import React from 'react';
import ReactDatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';

interface FormFieldType {
  label: string;
  placeholder: string;
  name?: string;
  dateFormat?: any;
  value?: string;
  hasError?: boolean;
  errorMessage?: string;
  onChange?: Function;
  masked?: boolean;
  date?: boolean;
  type: string;
  id?: string;
  mxClass?: string;
  onBlur?: Function;
}

const FormField = ({
  label,
  placeholder,
  name,
  dateFormat,
  value,
  hasError,
  errorMessage,
  onChange = (e?: any) => {},
  masked = false,
  date = false,
  mxClass = 'true',
  ...rest
}: FormFieldType) => {
  const defaultOpts: any = {
    name: name,
    className: `mt-1 block w-full rounded-md placeholder-gray-400 placeholder-opacity-50 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-100 ${
      hasError ? 'has-error' : ''
    }`,
    placeholder: placeholder,
    value: value,
  };
  let clearedRest;

  if (masked) {
    /**
     * https://github.com/s-yadav/react-number-format#v400
     *
     * Component don't fire event, then, we need to send the input name back to the change function
     * @param {*} e
     */
    defaultOpts.onValueChange = (e: any) => {
      e.name = name;
      onChange(e);
    };
    clearedRest = Object.keys(rest)
      .filter((k) => k.includes('mki-'))
      .map((k) => {
        const key = k.replace('mki-', '');
        const obj: any = {};
        // @ts-ignore
        obj[key] = rest[k];
        return obj;
      })
      .reduce((result, item) => {
        const key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
      }, {});
  } else {
    defaultOpts.onChange = onChange;
    clearedRest = Object.keys(rest)
      .filter((k) => !k.includes('mki-'))
      .map((k) => {
        const key = k;
        const obj: any = {};
        // @ts-ignore
        obj[key] = rest[k];
        return obj;
      })
      .reduce((result, item) => {
        const key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
      }, {});
  }

  const hasPrefix = Object.keys(clearedRest).includes('prefixed') && clearedRest?.prefixed?.length > 0;
  const hasSuffix = Object.keys(clearedRest).includes('sufixed') && clearedRest?.sufixed?.length > 0;

  if (hasPrefix) {
    defaultOpts.className = `${defaultOpts.className} rounded-l-none`;
  }

  if (hasSuffix) {
    defaultOpts.className = `${defaultOpts.className} rounded-r-none`;
  }

  return date ? (
    <div className="block mx-4">
      <span className={`${hasError ? 'text-red-700' : 'text-gray-500'} font-medium`}>{label}</span>
      <ReactDatePicker {...defaultOpts} {...clearedRest} />
      {hasError && <span className="text-red-700 text-sm font-medium">{errorMessage}</span>}
    </div>
  ) : (
    <label className={`block ${mxClass === 'true' ? 'mx-4' : mxClass}`}>
      <span className={`${hasError ? 'text-red-700' : 'text-gray-500'} font-medium`}>{label}</span>
      {masked ? (
        <React.Fragment>
          {hasPrefix ? (
            <div className="flex">
              <span
                style={{
                  height: '42px',
                  // borderColor: hasError ? 'rgba(185, 28, 28, var(--tw-border-opacity))' : 'border-gray-300',
                }}
                className="inline-flex items-center mt-1 px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"
              >
                {clearedRest.prefixed}
              </span>
              <NumberFormat {...defaultOpts} {...clearedRest} />
            </div>
          ) : hasSuffix ? (
            <div className="flex">
              <NumberFormat {...defaultOpts} {...clearedRest} />
              <span
                style={{
                  height: '42px',
                  // borderColor: hasError ? 'rgba(185, 28, 28, var(--tw-border-opacity))' : 'border-gray-300',
                }}
                className="inline-flex items-center mt-1 px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"
              >
                {clearedRest.sufixed}
              </span>
            </div>
          ) : (
            <NumberFormat {...defaultOpts} {...clearedRest} />
          )}
        </React.Fragment>
      ) : (
        <input {...defaultOpts} {...clearedRest} />
      )}
      {hasError && rest?.id === 'otp' && <br />}
      {hasError && <span className="text-red-700 text-sm">{errorMessage}</span>}
    </label>
  );
};

export default FormField;
