import Cleave from 'cleave.js/react';
// eslint-disable-next-line no-unused-vars
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.i18n'; //needed for Cleave to work with country

import React, { useContext, useEffect, useState } from 'react';
import DateUtils from '../Utils/DateUtils';
import MainStore from '../stores/MainStore';
import DirectorsGuarantee from './DirectorsGuarantee';
import StripeElements from './StripeElements';
import CustomDatePicker from './CustomDatePicker';
import AlertBox from './CreditCard/AlertBox';
import WaiverFeeNoticeElection from './CustomCompanyInfoForm/WaiverFeeNoticeElection';
import moment from "moment";
import PaymentAuthorisationWrapper from './PaymentAuthorisation/PaymentAuthorisationWrapper';

/**
 * IMPORTANT: See the documentation: https://react-jsonschema-form.readthedocs.io/en/latest/advanced-customization/custom-widgets-fields/#custom-component-registration
 * We are using FormContext to get CountryCode, the documentation is available: https://react-jsonschema-form.readthedocs.io/en/latest/api-reference/themes/semantic-ui/uiSchema/#formcontext
 */
function MaskedInput(props) {
  const cleaveOptions =
    ['AU', 'US', 'NZ'].includes(props.formContext.countryCode) ? { phone: true, phoneRegionCode: props.formContext.countryCode } : {numericOnly: true};
  return (
    <Cleave
      className="form-control"
      id={props.id}
      options={cleaveOptions}
      onChange={(event) => props.onChange(event.target.value?.trim())}
      required={props.required}
      value={props.value}

    />
  );
}

function LinkWidget(props) {
  return (
    <a
      target={props.schema.target}
      className="font-medium block text-blue-700 hover:text-blue-900"
      href={props.schema.url}
      title={props.schema.text}
    >
      {props.schema.text}
    </a>
  );
}

function TermsAndConditionsCheckbox(props) {
  const { replaceList, text } = props.schema

  return (
    <div className='flex flex-row items-center'>
      <input id={props.id}
        className="mr-1 rounded"
        type={'checkbox'}
        onChange={(event) => props.onChange(event.target.checked)}
        required={props.required}
        value={props.value}
        checked={props.value}
      />
      <span dangerouslySetInnerHTML={{ __html: generateTermsComponent(replaceList, text) }} />
    </div>
  )
}

function AllowRefundCheckbox(props) {
  const paymentMethod = props.formContext.paymentMethod;
  const isApplicationOnlyCreditCard = paymentMethod?.length === 1 && paymentMethod[0] === 'CREDIT_CARD'

  useEffect(() => {
    if (isApplicationOnlyCreditCard) {
      document.querySelectorAll('[for="ca__bankDetails_refundTerms"]')?.item(0)?.remove();
    }
  }, [isApplicationOnlyCreditCard]);

  return isApplicationOnlyCreditCard ?
    <></> :
    <TermsAndConditionsCheckbox {...props} />
}

function generateTermsComponent(replaceList, text) {
  let stringfiedComponent = text
  replaceList?.forEach(item => {
    const regex = `{{${item.key}}}`;
    stringfiedComponent = stringfiedComponent
      .replace(new RegExp(regex, "g"),
        `<a target='_blank' class='font-medium text-blue-700 hover:text-blue-900' href=${item.url}>${item.label}</a>`)
  })

  return stringfiedComponent;
}

function GeneralDateField(props) {
  const mainStore = useContext(MainStore);
  const format = mainStore.dateConfig.dateFormat;
  const oldDate = props.value ? DateUtils.formatDateTimezone(props.value, "yyyy-MM-dd") : "";
  const [value, setValue] = useState(oldDate);

  return (
    <input id={props.id}
      className="input form-control"
      type="date"
      onChange={(e) => {
        props.onChange(DateUtils.formatDateTimezone(e.target.value, format));
        setValue(e.target.value);
      }}
      required={props.required}
      value={value}
    />
  );
}

function CustomMaskDatePicker(props) {
  const {dateConfig} = props.formContext;
  const format = dateConfig.dateFormat?.toLowerCase().replaceAll("m", "M") || "dd/MM/yyyy";

  const fields = {};
  fields[props.id] = {validate: {hasError: false}};

  let value = props.value;
  if(props.value === "Invalid Date") {
    value = "";
  }

  return (<CustomDatePicker
    name={props.id}
    value={value ? moment(value, "YYYY-MM-DD").toDate() : ""}
    fields={fields}
    onChange={(date) => props.onChange(DateUtils.formatDateTimezone(date, "YYYY-MM-DD"))}
    placeholderText={`${format}`}
    dateFormat={format}
    id={props.id}
    widget={true}
    maxDate={props.schema.maxDate ? props.schema.maxDate : null}
  /> )
}

function WaiverFeeNoticeElectionComponent(props) {
  return <WaiverFeeNoticeElection />
}

export const customWidgets = {
  maskedInput: MaskedInput,
  link: LinkWidget,
  directorsGuarantee: DirectorsGuarantee,
  termsAndConditionsCheckbox: TermsAndConditionsCheckbox,
  allowRefundCheckbox: AllowRefundCheckbox,
  customDate: GeneralDateField,
  customMaskDate: CustomMaskDatePicker,
  creditCard: StripeElements,
  paymentAuthorisation: PaymentAuthorisationWrapper,
  alertBox: AlertBox,
  waiverFeeNoticeElection: WaiverFeeNoticeElectionComponent,
};
