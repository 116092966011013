import React from 'react';

const ExpiredAgreementSVG: React.FC = () => {
  return (
    <svg
      width="250"
      height="230"
      viewBox="0 0 250 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className='mb-8 mt-14'
    >
      <g clipPath="url(#clip0_929_47)">
        <path
          d="M231.101 175.956L242.316 177.68C246.112 173.159 248.729 167.795 249.939 162.053C241.739 168.656 229.268 165.147 219.324 168.752C216.231 169.9 213.44 171.717 211.159 174.07C208.878 176.423 207.164 179.25 206.144 182.343L200.663 188.144C205.624 190.076 210.953 190.927 216.281 190.638C221.609 190.35 226.81 188.929 231.526 186.472C234.157 185.069 236.599 183.345 238.792 181.341C234.868 178.91 231.101 175.956 231.101 175.956Z"
          fill="#F2F2F2"
        />
        <path
          d="M227.12 158.313L237.939 154.936C239.287 149.224 239.197 143.275 237.677 137.604C233.331 147.112 220.578 149.442 213.312 157.031C211.064 159.415 209.391 162.266 208.416 165.373C207.44 168.48 207.188 171.762 207.678 174.978L205.402 182.574C210.719 182.127 215.874 180.551 220.512 177.956C225.15 175.36 229.161 171.806 232.268 167.539C233.987 165.128 235.39 162.514 236.445 159.758C231.831 159.304 227.12 158.313 227.12 158.313Z"
          fill="#F2F2F2"
        />
        <path
          d="M250 191.658C250 191.715 249.989 191.771 249.967 191.824C249.945 191.876 249.912 191.924 249.871 191.964C249.83 192.005 249.782 192.036 249.728 192.058C249.675 192.08 249.618 192.091 249.56 192.091H0.440305C0.323529 192.091 0.211534 192.045 0.128961 191.964C0.0463884 191.883 0 191.773 0 191.658C0 191.543 0.0463884 191.433 0.128961 191.351C0.211534 191.27 0.323529 191.225 0.440305 191.225H249.56C249.618 191.224 249.675 191.236 249.728 191.257C249.782 191.279 249.83 191.311 249.871 191.351C249.912 191.391 249.945 191.439 249.967 191.492C249.989 191.544 250 191.601 250 191.658Z"
          fill="#CCCCCC"
        />
        <path
          d="M103.23 173.194C147.165 173.194 182.781 138.16 182.781 94.9436C182.781 51.7272 147.165 16.6934 103.23 16.6934C59.2957 16.6934 23.6797 51.7272 23.6797 94.9436C23.6797 138.16 59.2957 173.194 103.23 173.194Z"
          fill="#E6E6E6"
        />
        <path
          d="M171.68 94.9429C171.688 105.449 169.193 115.811 164.396 125.198C159.598 134.584 152.63 142.734 144.052 148.994C143.479 149.416 142.894 149.827 142.306 150.228C130.835 158.071 117.2 162.275 103.23 162.275C89.2601 162.275 75.6246 158.071 64.1538 150.228C63.5655 149.827 62.9809 149.416 62.4074 148.994C53.8354 142.729 46.8724 134.577 42.0751 125.192C37.2778 115.807 34.7793 105.448 34.7793 94.9429C34.7793 84.4381 37.2778 74.079 42.0751 64.6937C46.8724 55.3084 53.8354 47.1573 62.4074 40.892C62.9809 40.4698 63.5655 40.0586 64.1538 39.6582C75.6246 31.8151 89.2601 27.6113 103.23 27.6113C117.2 27.6113 130.835 31.8151 142.306 39.6582C142.894 40.0586 143.479 40.4698 144.052 40.892C152.63 47.1518 159.598 55.3018 164.396 64.6883C169.193 74.0748 171.688 84.4367 171.68 94.9429Z"
          fill="white"
        />
        <path
          d="M103.23 156.451C137.764 156.451 165.76 128.913 165.76 94.9429C165.76 60.9728 137.764 33.4346 103.23 33.4346C68.6951 33.4346 40.6992 60.9728 40.6992 94.9429C40.6992 128.913 68.6951 156.451 103.23 156.451Z"
          fill="#FD9A3C"
        />
        <path
          opacity="0.2"
          d="M103.394 33.6074C102.446 33.6074 101.504 33.6318 100.566 33.6729C116.37 35.0389 131.056 42.2633 141.647 53.8823C152.238 65.5013 157.944 80.6461 157.607 96.2484C157.27 111.851 150.917 126.744 139.834 137.91C128.751 149.077 113.767 155.682 97.918 156.387C99.7228 156.541 101.548 156.624 103.394 156.624C119.978 156.624 135.883 150.144 147.609 138.609C159.336 127.074 165.924 111.429 165.924 95.1158C165.924 78.8027 159.336 63.1579 147.609 51.6228C135.883 40.0878 119.978 33.6074 103.394 33.6074Z"
          fill="black"
        />
        <path
          d="M103.415 29.0674C102.875 29.0683 102.358 29.2795 101.977 29.6547C101.596 30.0299 101.381 30.5385 101.38 31.0691V39.804C101.38 40.3349 101.594 40.8441 101.976 41.2195C102.358 41.5949 102.875 41.8058 103.415 41.8058C103.955 41.8058 104.472 41.5949 104.854 41.2195C105.236 40.8441 105.45 40.3349 105.45 39.804V31.0691C105.449 30.5385 105.234 30.0299 104.853 29.6547C104.471 29.2795 103.954 29.0683 103.415 29.0674Z"
          fill="white"
        />
        <path
          d="M144.053 40.8923C143.479 40.4702 142.895 40.0589 142.306 39.6585C141.953 39.6359 141.6 39.7042 141.282 39.8568C140.964 40.0093 140.692 40.2408 140.493 40.5284L135.494 47.7456C135.191 48.1843 135.077 48.7237 135.177 49.2452C135.278 49.7667 135.585 50.2275 136.031 50.5262C136.477 50.825 137.025 50.9372 137.556 50.8382C138.086 50.7393 138.554 50.4372 138.858 49.9985L143.857 42.7813C144.047 42.5075 144.164 42.1912 144.199 41.8613C144.233 41.5314 144.183 41.1983 144.053 40.8923Z"
          fill="white"
        />
        <path
          d="M166.763 73.8014C166.663 73.558 166.515 73.3366 166.327 73.1496C166.14 72.9627 165.916 72.8139 165.67 72.7118C165.424 72.6098 165.16 72.5564 164.893 72.5547C164.626 72.5531 164.362 72.6032 164.114 72.7022L155.885 75.9887C155.386 76.1889 154.988 76.5759 154.778 77.0648C154.568 77.5536 154.565 78.1043 154.768 78.5958C154.971 79.0873 155.364 79.4794 155.861 79.686C156.357 79.8926 156.917 79.8967 157.417 79.6974L165.646 76.4109C166.145 76.2098 166.543 75.8222 166.753 75.3331C166.962 74.8441 166.966 74.2933 166.763 73.8014Z"
          fill="white"
        />
        <path
          d="M70.9655 47.7456L65.9668 40.5284C65.7676 40.2408 65.4956 40.0093 65.1776 39.8568C64.8597 39.7042 64.5068 39.6359 64.1538 39.6585C63.5655 40.0589 62.9809 40.4702 62.4074 40.8923C62.2774 41.1983 62.2272 41.5314 62.2614 41.8613C62.2957 42.1912 62.4133 42.5075 62.6035 42.7813L67.6022 49.9985C67.9059 50.4372 68.3744 50.7393 68.9045 50.8382C69.4346 50.9372 69.983 50.825 70.429 50.5262C70.875 50.2275 71.1821 49.7667 71.2827 49.2452C71.3833 48.7237 71.2692 48.1843 70.9655 47.7456Z"
          fill="white"
        />
        <path
          d="M50.5751 75.9883L42.3463 72.7018C41.8463 72.5021 41.2862 72.5059 40.7891 72.7124C40.292 72.9188 39.8987 73.3111 39.6955 73.8028C39.4924 74.2946 39.4962 74.8455 39.706 75.3345C39.9159 75.8235 40.3146 76.2106 40.8145 76.4104L49.0433 79.6969C49.5432 79.8962 50.103 79.8921 50.5998 79.6855C51.0966 79.4789 51.4897 79.0868 51.6927 78.5953C51.8957 78.1038 51.892 77.5531 51.6825 77.0642C51.4729 76.5754 51.0746 76.1884 50.5751 75.9883Z"
          fill="white"
        />
        <path
          d="M103.415 148.081C102.875 148.082 102.358 148.293 101.977 148.668C101.596 149.044 101.381 149.552 101.38 150.083V158.818C101.38 159.349 101.594 159.858 101.976 160.233C102.358 160.609 102.875 160.819 103.415 160.819C103.955 160.819 104.472 160.609 104.854 160.233C105.236 159.858 105.45 159.349 105.45 158.818V150.083C105.449 149.552 105.234 149.044 104.853 148.668C104.471 148.293 103.954 148.082 103.415 148.081Z"
          fill="white"
        />
        <path
          d="M143.857 147.106L138.858 139.889C138.707 139.671 138.515 139.485 138.292 139.341C138.068 139.197 137.818 139.098 137.556 139.049C137.293 139 137.023 139.002 136.762 139.056C136.5 139.109 136.252 139.213 136.031 139.361C135.81 139.509 135.621 139.698 135.475 139.918C135.328 140.138 135.227 140.384 135.177 140.642C135.127 140.9 135.13 141.165 135.184 141.423C135.239 141.68 135.344 141.924 135.494 142.141L140.493 149.359C140.692 149.646 140.964 149.878 141.282 150.03C141.6 150.183 141.953 150.251 142.306 150.228C142.895 149.828 143.479 149.417 144.053 148.995C144.183 148.689 144.233 148.356 144.199 148.026C144.164 147.696 144.047 147.38 143.857 147.106Z"
          fill="white"
        />
        <path
          d="M165.645 113.476L157.417 110.189C156.917 109.989 156.357 109.993 155.859 110.2C155.362 110.406 154.969 110.798 154.766 111.29C154.563 111.782 154.567 112.333 154.776 112.822C154.986 113.311 155.385 113.698 155.885 113.898L164.114 117.184C164.613 117.383 165.173 117.379 165.67 117.173C166.167 116.966 166.56 116.574 166.763 116.083C166.966 115.591 166.962 115.04 166.753 114.552C166.543 114.063 166.145 113.676 165.645 113.476Z"
          fill="white"
        />
        <path
          d="M70.429 139.361C69.983 139.062 69.4346 138.95 68.9045 139.049C68.3744 139.148 67.906 139.45 67.6022 139.888L62.6035 147.106C62.4133 147.379 62.2957 147.696 62.2614 148.026C62.2272 148.355 62.2774 148.689 62.4074 148.994C62.9809 149.417 63.5655 149.828 64.1538 150.228C64.5068 150.251 64.8597 150.183 65.1776 150.03C65.4956 149.877 65.7676 149.646 65.9668 149.358L70.9655 142.141C71.1159 141.924 71.2214 141.68 71.2758 141.422C71.3302 141.165 71.3326 140.9 71.2828 140.642C71.233 140.383 71.1319 140.137 70.9854 139.918C70.8389 139.698 70.6499 139.509 70.429 139.361Z"
          fill="white"
        />
        <path
          d="M51.6922 111.289C51.5916 111.045 51.4434 110.824 51.2558 110.637C51.0683 110.45 50.8451 110.301 50.5991 110.199C50.353 110.097 50.089 110.044 49.822 110.042C49.555 110.04 49.2902 110.091 49.0429 110.19L40.8141 113.476C40.3146 113.676 39.9163 114.063 39.7067 114.552C39.4972 115.041 39.4935 115.592 39.6965 116.083C39.8995 116.575 40.2926 116.967 40.7894 117.173C41.2862 117.38 41.846 117.384 42.3459 117.185L50.5747 113.898C51.074 113.697 51.4719 113.31 51.6813 112.82C51.8907 112.331 51.8946 111.781 51.6922 111.289Z"
          fill="white"
        />
        <path
          d="M106.056 93.9497C106.243 95.0439 105.982 96.1667 105.33 97.0718C104.677 97.9769 103.686 98.5905 102.574 98.7778L66.4627 104.82C65.9118 104.912 65.3479 104.897 64.8031 104.774C64.2583 104.652 63.7433 104.426 63.2876 104.108C62.8318 103.79 62.4441 103.386 62.1468 102.921C61.8494 102.456 61.6481 101.937 61.5544 101.396C61.4607 100.854 61.4764 100.299 61.6007 99.7631C61.7249 99.2272 61.9552 98.7206 62.2785 98.2723C62.6017 97.824 63.0116 97.4427 63.4847 97.1501C63.9578 96.8576 64.4848 96.6596 65.0357 96.5675L101.147 90.5254C102.26 90.3405 103.401 90.5972 104.321 91.2392C105.241 91.8812 105.865 92.856 106.056 93.9497Z"
          fill="#3F3D56"
        />
        <path
          d="M144.062 133.305C143.543 133.818 142.839 134.108 142.103 134.11C141.367 134.113 140.661 133.829 140.138 133.32L101.264 95.3815C100.742 94.8714 100.448 94.1785 100.445 93.455C100.443 92.7315 100.732 92.0366 101.25 91.5229C101.768 91.0093 102.473 90.719 103.208 90.7158C103.944 90.7127 104.65 90.9969 105.173 91.506L144.047 129.444C144.568 129.954 144.863 130.648 144.866 131.371C144.869 132.095 144.58 132.79 144.062 133.305Z"
          fill="#3F3D56"
        />
        <path
          d="M103.23 99.6747C105.886 99.6747 108.04 97.5564 108.04 94.9433C108.04 92.3302 105.886 90.2119 103.23 90.2119C100.573 90.2119 98.4199 92.3302 98.4199 94.9433C98.4199 97.5564 100.573 99.6747 103.23 99.6747Z"
          fill="#CCCCCC"
        />
        <path
          d="M70.0989 12.9005C62.7156 24.3186 51.4502 31.8963 37.5572 36.8743C37.2827 37.0766 36.9704 37.2236 36.638 37.3072C36.3057 37.3907 35.9599 37.409 35.6204 37.361C35.2809 37.3131 34.9543 37.1999 34.6593 37.0278C34.3643 36.8557 34.1066 36.6281 33.901 36.3581C26.3545 26.4467 28.0585 12.2814 38.0183 4.70791C40.4263 2.88009 43.1794 1.54097 46.1177 0.768304C49.0561 -0.00436295 52.1213 -0.195194 55.1352 0.206895C58.1492 0.608984 61.0519 1.59598 63.6749 3.11058C66.298 4.62519 68.589 6.63723 70.415 9.0299L70.6237 9.30404C70.8294 9.57408 70.9789 9.88131 71.0638 10.2082C71.1487 10.5351 71.1673 10.8752 71.1186 11.2092C71.0698 11.5431 70.9547 11.8644 70.7797 12.1546C70.6048 12.4448 70.3734 12.6982 70.0989 12.9005Z"
          fill="#E6E6E6"
        />
        <path
          d="M136.045 9.0299C137.872 6.63723 140.163 4.62519 142.786 3.11058C145.409 1.59598 148.311 0.608986 151.325 0.206896C154.339 -0.195193 157.404 -0.00436694 160.343 0.768301C163.281 1.54097 166.034 2.88008 168.442 4.7079C178.402 12.2814 180.106 26.4467 172.559 36.3581C172.354 36.6281 172.096 36.8557 171.801 37.0278C171.506 37.1999 171.18 37.3131 170.84 37.3611C170.501 37.409 170.155 37.3907 169.822 37.3072C169.49 37.2236 169.178 37.0766 168.903 36.8743C156.006 32.0172 145.429 23.612 136.362 12.9005C135.807 12.492 135.44 11.8836 135.342 11.2092C135.244 10.5347 135.421 9.8494 135.837 9.30402L136.045 9.0299Z"
          fill="#E6E6E6"
        />
        <path
          d="M132.809 162.736C132.339 162.996 131.993 163.429 131.846 163.939C131.7 164.45 131.765 164.997 132.027 165.46L143.772 186.105C144.036 186.568 144.475 186.909 144.995 187.053C145.514 187.198 146.07 187.133 146.541 186.874C147.012 186.615 147.359 186.182 147.506 185.671C147.653 185.16 147.587 184.613 147.324 184.15L135.579 163.505C135.315 163.043 134.875 162.702 134.356 162.558C133.837 162.414 133.281 162.478 132.809 162.736Z"
          fill="#E6E6E6"
        />
        <path
          d="M70.8814 163.506L59.1369 184.151C58.8734 184.614 58.8079 185.161 58.9546 185.672C59.1013 186.183 59.4483 186.615 59.9192 186.874C60.3901 187.134 60.9464 187.198 61.4658 187.054C61.9851 186.91 62.425 186.569 62.6886 186.106L74.4332 165.461C74.5638 165.232 74.6471 164.979 74.6785 164.718C74.7099 164.457 74.6886 164.192 74.616 163.939C74.5434 163.686 74.4209 163.45 74.2553 163.244C74.0898 163.037 73.8846 162.865 73.6513 162.736C73.4181 162.608 73.1614 162.526 72.896 162.495C72.6305 162.464 72.3615 162.485 72.1043 162.557C71.8471 162.628 71.6067 162.749 71.3969 162.912C71.1871 163.075 71.0119 163.277 70.8814 163.506Z"
          fill="#E6E6E6"
        />
        <path
          d="M146.15 191.391C149.624 191.391 152.44 188.621 152.44 185.204C152.44 181.787 149.624 179.017 146.15 179.017C142.677 179.017 139.86 181.787 139.86 185.204C139.86 188.621 142.677 191.391 146.15 191.391Z"
          fill="#E6E6E6"
        />
        <path
          d="M150.591 33.0707C154.065 33.0707 156.881 30.3006 156.881 26.8835C156.881 23.4664 154.065 20.6963 150.591 20.6963C147.117 20.6963 144.301 23.4664 144.301 26.8835C144.301 30.3006 147.117 33.0707 150.591 33.0707Z"
          fill="#E6E6E6"
        />
        <path
          d="M102.86 18.5131C106.334 18.5131 109.15 15.743 109.15 12.3259C109.15 8.90879 106.334 6.13867 102.86 6.13867C99.3865 6.13867 96.5703 8.90879 96.5703 12.3259C96.5703 15.743 99.3865 18.5131 102.86 18.5131Z"
          fill="#E6E6E6"
        />
        <path
          d="M57.3496 32.3432C60.8235 32.3432 63.6397 29.5731 63.6397 26.156C63.6397 22.7389 60.8235 19.9688 57.3496 19.9688C53.8757 19.9688 51.0596 22.7389 51.0596 26.156C51.0596 29.5731 53.8757 32.3432 57.3496 32.3432Z"
          fill="#E6E6E6"
        />
        <path
          d="M61.4199 191.391C64.8938 191.391 67.71 188.621 67.71 185.204C67.71 181.787 64.8938 179.017 61.4199 179.017C57.946 179.017 55.1299 181.787 55.1299 185.204C55.1299 188.621 57.946 191.391 61.4199 191.391Z"
          fill="#E6E6E6"
        />
        <path
          d="M141.216 129.825C141.464 130.207 141.794 130.531 142.183 130.774C142.572 131.017 143.01 131.173 143.467 131.231C143.924 131.289 144.388 131.248 144.827 131.111C145.266 130.973 145.669 130.743 146.008 130.436L155.878 135.638L155.349 129.938L146.057 125.872C145.495 125.346 144.756 125.042 143.981 125.016C143.206 124.991 142.448 125.246 141.851 125.733C141.255 126.221 140.86 126.906 140.743 127.661C140.626 128.415 140.795 129.185 141.216 129.825Z"
          fill="#FFB7B7"
        />
        <path
          d="M148.408 131.814L152.276 126.562L170.909 135.227L194.096 130.948C195.242 130.736 196.422 130.775 197.551 131.063C198.68 131.35 199.731 131.878 200.629 132.61C201.527 133.341 202.25 134.259 202.747 135.296C203.244 136.334 203.503 137.466 203.506 138.613C203.507 140.685 202.673 142.673 201.187 144.141C199.7 145.608 197.682 146.436 195.576 146.442L169.004 146.491L148.408 131.814Z"
          fill="#3F3D56"
        />
        <path
          d="M142.451 222.521L138.537 220.681L143.913 204.591L149.688 207.308L142.451 222.521Z"
          fill="#FFB7B7"
        />
        <path
          d="M168.686 219.928C168.402 220.103 168.117 220.275 167.83 220.448C167.258 220.793 166.68 221.132 166.102 221.466C165.812 221.632 165.519 221.799 165.228 221.963V221.972L165.211 221.975L165.205 221.977L164.763 222.085L164.128 220.434L163.834 219.673L163.42 218.596L162.662 216.625L158.666 206.248L164.878 204.741L165.111 205.677H165.113L165.228 206.141L167.399 214.799L167.841 216.569L167.856 216.622L168.123 217.68L168.353 218.596L168.609 219.619L168.686 219.928Z"
          fill="#FFB7B7"
        />
        <path
          d="M205.741 183.486C205.347 184.108 204.945 184.727 204.54 185.341C203.786 186.483 203.013 187.609 202.22 188.717C202.22 188.72 202.217 188.72 202.214 188.723C201.751 189.144 201.24 189.512 200.691 189.817C200.668 189.834 200.642 189.845 200.619 189.859C200.214 190.079 199.784 190.249 199.337 190.365C199.176 190.405 199.012 190.445 198.851 190.484C198.27 190.62 197.695 190.736 197.123 190.838C196.106 191.016 195.1 191.143 194.099 191.222C194.019 191.231 193.939 191.236 193.858 191.242C189.745 191.539 185.741 191.061 181.561 190.278C179.497 189.893 177.39 189.432 175.209 188.955C174.496 188.796 173.78 188.641 173.056 188.485C173.001 188.474 172.943 188.46 172.886 188.448C171.851 188.225 170.799 188.005 169.721 187.787C168.364 187.513 166.965 187.247 165.51 186.995L165.614 189.011C165.7 190.562 166.027 192.091 166.585 193.544C167.206 195.331 167.698 196.744 166.66 199.688L166.16 201.113C166.419 201.82 166.508 202.577 166.42 203.325C166.331 204.072 166.068 204.789 165.651 205.419L165.55 205.569L169.721 215.948L169.991 216.621H167.856L165.228 216.624H160.776L154.352 203.946L154.044 203.341V203.31L154.708 178.945C154.717 178.73 154.734 178.513 154.763 178.298C154.88 177.321 155.193 176.377 155.685 175.52C156.177 174.664 156.838 173.913 157.628 173.31C158.386 172.728 159.253 172.3 160.181 172.05C161.108 171.801 162.076 171.735 163.03 171.857L165.228 172.137L169.721 172.708L172.886 173.112L172.949 173.121L182.283 174.308L182.952 171.622L183.076 171.577L187.005 170.16L200.596 165.26L200.63 165.249L201.524 164.927L201.631 165.096C201.662 165.147 201.694 165.198 201.723 165.249C202.606 166.807 203.326 168.45 203.87 170.152C204.785 172.932 205.538 175.761 206.126 178.626C206.152 178.75 206.175 178.863 206.195 178.965L206.204 178.996C206.212 179.044 206.218 179.075 206.221 179.084C206.534 180.368 206.316 181.934 205.741 183.486Z"
          fill="#2F2E41"
        />
        <path
          d="M206.315 174.508C206.272 174.327 206.217 174.143 206.16 173.965C205.703 172.616 205.172 171.292 204.567 170.001C203.889 168.531 203.075 166.956 202.107 165.251C201.638 164.425 201.134 163.566 200.595 162.675C200.324 162.228 200.044 161.773 199.752 161.309C199.741 161.288 199.729 161.267 199.715 161.247L199.632 161.111L186.185 162.881L181.178 163.54L180.879 165.994L180.807 166.602C180.804 166.602 180.804 166.602 180.807 166.605L180.606 168.262V168.265L180.338 170.453L180.315 170.45L172.885 169.503L165.227 168.528L161.114 168.005C160.161 167.883 159.192 167.948 158.265 168.198C157.338 168.447 156.47 168.876 155.713 169.458C154.923 170.061 154.262 170.812 153.77 171.668C153.278 172.525 152.964 173.469 152.847 174.446C152.821 174.644 152.804 174.847 152.795 175.048L148.915 184.093C146.593 189.502 145.114 195.226 144.528 201.069L141.683 210.018L142.088 210.055L148.119 210.606L149.711 210.753L153.218 205.717C153.617 205.143 153.997 204.553 154.35 203.947C154.932 202.959 155.45 201.935 155.9 200.882L163.485 183.126C164.072 183.225 164.652 183.319 165.227 183.409C167.886 183.833 170.439 184.179 172.885 184.446C180.838 185.32 187.726 185.388 193.857 184.647C195.579 184.441 197.243 184.169 198.85 183.833C199.439 183.711 200.021 183.58 200.595 183.44C200.73 183.409 200.862 183.378 200.994 183.344C202.182 183.042 203.274 182.453 204.171 181.629C205.067 180.805 205.739 179.773 206.125 178.627C206.586 177.3 206.652 175.871 206.315 174.508Z"
          fill="#2F2E41"
        />
        <path
          d="M143.701 219.894L139.467 217.898L138.803 217.584L137.742 220.146L130.338 221.749C129.972 221.828 129.641 222.02 129.394 222.297C129.146 222.574 128.996 222.922 128.963 223.289C128.931 223.656 129.02 224.024 129.215 224.339C129.411 224.653 129.703 224.898 130.05 225.037L137.285 227.941L139.582 225.908L139.019 228.637L141.744 229.731L144.73 220.38L143.701 219.894Z"
          fill="#2F2E41"
        />
        <path d="M169.722 215.949L169.992 216.623H169.722V215.949Z" fill="#2F2E41" />
        <path
          d="M168.247 216.765L163.81 218.277L163.114 218.514L164.193 221.068L160.078 227.332C159.874 227.642 159.777 228.007 159.799 228.375C159.822 228.743 159.963 229.094 160.203 229.378C160.443 229.661 160.769 229.861 161.133 229.949C161.497 230.038 161.88 230.009 162.226 229.868L169.444 226.924L169.622 223.889L171.173 226.218L173.892 225.11L169.326 216.398L168.247 216.765Z"
          fill="#2F2E41"
        />
        <path
          d="M205.209 178.605C204.945 178.604 204.686 178.541 204.453 178.42L181.041 166.387L180.771 165.298L180.261 161.85L180.268 161.818L182.494 151.721C182.453 151.634 182.368 151.492 182.272 151.331C181.278 149.675 178.253 144.633 184.026 137.69L194.163 126.065L202.862 125.697L202.961 125.82C205.288 128.692 206.856 132.085 207.528 135.697C208.2 139.309 207.955 143.029 206.813 146.525L205.907 162.618L206.879 165.868C207.447 167.778 207.353 169.819 206.612 171.671L206.835 176.933C206.843 177.149 206.808 177.364 206.73 177.566C206.652 177.768 206.534 177.953 206.382 178.109C206.231 178.265 206.048 178.389 205.847 178.474C205.645 178.559 205.428 178.604 205.209 178.605Z"
          fill="#3F3D56"
        />
        <path
          d="M184.901 191.876C185.297 191.646 185.638 191.333 185.898 190.959C186.158 190.585 186.332 190.159 186.407 189.712C186.482 189.265 186.456 188.807 186.332 188.371C186.208 187.935 185.988 187.531 185.687 187.187L191.316 177.665L185.506 177.987L181.052 186.98C180.499 187.514 180.164 188.23 180.111 188.991C180.058 189.752 180.291 190.506 180.765 191.11C181.24 191.713 181.923 192.124 182.685 192.266C183.447 192.407 184.235 192.268 184.901 191.876Z"
          fill="#FFB7B7"
        />
        <path
          d="M187.172 184.876L181.971 180.891L191.422 162.875L187.881 139.932C187.706 138.798 187.786 137.64 188.118 136.54C188.449 135.44 189.022 134.425 189.797 133.568C190.571 132.71 191.528 132.031 192.6 131.579C193.671 131.126 194.831 130.911 195.996 130.949C198.101 131.019 200.092 131.908 201.532 133.421C202.971 134.933 203.742 136.945 203.675 139.016L202.8 165.14L187.172 184.876Z"
          fill="#3F3D56"
        />
        <path
          d="M188.582 121.234V112.766C188.58 109.757 189.794 106.87 191.955 104.741C194.117 102.611 197.051 101.414 200.11 101.412C203.17 101.41 206.105 102.604 208.269 104.73C210.434 106.857 211.651 109.742 211.653 112.752V112.766C213.686 116.934 212.414 118.915 211.653 121.234C211.653 121.638 211.489 122.025 211.199 122.311C210.908 122.597 210.515 122.757 210.104 122.758H190.132C189.721 122.758 189.327 122.597 189.037 122.311C188.746 122.026 188.583 121.638 188.582 121.234Z"
          fill="#2F2E41"
        />
        <path
          d="M196.336 122.208C201.105 122.208 204.971 118.405 204.971 113.714C204.971 109.023 201.105 105.221 196.336 105.221C191.567 105.221 187.701 109.023 187.701 113.714C187.701 118.405 191.567 122.208 196.336 122.208Z"
          fill="#FFB7B7"
        />
        <path
          d="M205.373 115.941C205.294 115.929 205.216 115.913 205.138 115.9C203.791 115.665 202.444 115.435 201.101 115.195L200.978 112.201L199.351 114.883C195.64 116.095 192.167 115.41 188.901 113.899C187.824 113.396 186.778 112.829 185.771 112.201C186.045 110.545 186.692 108.971 187.665 107.593C188.638 106.214 189.911 105.067 191.393 104.236C191.516 104.167 191.636 104.106 191.76 104.045L191.761 104.045L191.762 104.044L191.763 104.043L191.764 104.041C191.974 103.944 192.188 103.851 192.402 103.766C194.079 103.121 195.907 102.958 197.675 103.295C199.442 103.632 201.076 104.456 202.386 105.67C205.023 108.146 206.209 112.234 205.373 115.941Z"
          fill="#2F2E41"
        />
      </g>
      <defs>
        <clipPath id="clip0_929_47">
          <rect width="250" height="230" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExpiredAgreementSVG;
