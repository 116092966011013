import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat)

const DateUtils = {};

DateUtils.guessFormat = (dateString: string): Date => {
  if (dayjs(dateString, "DD/MM/YYYY").isValid()) {
    return dayjs(dateString, "DD/MM/YYYY");
  }

  if (dayjs(dateString, "MM/DD/YYYY").isValid()) {
    return dayjs(dateString, "MM/DD/YYYY");
  }

  if (dayjs(dateString, "YYYY/MM/DD").isValid()) {
    return dayjs(dateString, "YYYY/MM/DD");
  }

  if (dayjs(dateString, "DD-MM-YYYY").isValid()) {
    return dayjs(dateString, "DD-MM-YYYY");
  }

  if (dayjs(dateString, "MM-DD-YYYY").isValid()) {
    return dayjs(dateString, "YYYY-MM-DD");
  }

  if (dayjs(dateString, "YYYY-MM-DD").isValid()) {
    return dayjs(dateString, "YYYY-MM-DD");
  }
}

DateUtils.customFormat = (dateString: string, format: string): Date => {
  return dayjs(dateString, format);
}

DateUtils.setDate = (dateString: string): Date => {
  return dayjs(dateString).tz("Australia/Sydney");
}

DateUtils.formatDateTimezone = (date: string, format: string, timezone: string, isDateSet: boolean = true): string => {
  if (isDateSet) {
    const formattedDate: string = dayjs(date).format(format || "DD-MM-YYYY");
    return formattedDate;
  } else {
    date = DateUtils.setDate(date);
    const dateTimezone: Date = date.tz(timezone || "Australia/Sydney");
    const formattedDate: string = dayjs(dateTimezone).format(format || "DD-MM-YYYY");
    return formattedDate;

  }
}

DateUtils.toBackendFormat = (date: Date, format: string = "YYYY-MM-DD"): string => {
  return dayjs(date).format(format);
}

export default DateUtils;