import React, { useContext, useState, useEffect } from 'react';

import MainStore from '../stores/MainStore';
import apiService from '../services/apiService';

const DoneStep = () => {
  const mainStore = useContext(MainStore);
  const caID = mainStore.creditApplicationId;
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [isDocumentReady, setIsDocumentReady] = useState(false);
  const [isDownloadFailed, setIsDownloadFailed] = useState(false);

  useEffect(() => {
    let isReady = false;
    setIsDocumentLoading(true);
    const myInterval = setInterval(async () => {
      try {
        const response = await apiService.getDocumentStatus(caID, mainStore.applicationType, mainStore.authToken);
        isReady = response?.data?.ready;
        if (isReady) {
          setIsDocumentLoading(false);
          setIsDocumentReady(true);
          clearInterval(myInterval);
        }
  
      } catch(exception) {
        console.log(exception);
        mainStore._shouldRestart();
      }
    }, 5000);

    setTimeout(() => {
      clearInterval(myInterval);
      setIsDocumentLoading(false);
      if (!isReady) {
        console.error('CA ID: ', caID);
        setIsDocumentReady(false);
        return false;
      }
    }, 60000);
  }, [caID, mainStore]);

  const downloadApplication = async (id, applicationType) => {
  
    try {
      const response = await apiService.getApplicationFile(id, applicationType, mainStore.authToken);
      
      const contentDisposition = response.headers['content-disposition'];
      
      let fileName = applicationType === 'paymentApplication' ? 'onboardingApplication.pdf' : 'creditApplication.pdf'

      if (contentDisposition) {
        const match = contentDisposition.match(/filename="?([^"]+)"?/);
        if (match) {
          fileName = match[1];
        }
      }
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const applicationLink = document.createElement('a');
      applicationLink.href = url;
      applicationLink.setAttribute('download', fileName);
      document.body.appendChild(applicationLink);
      applicationLink.click();
    } catch(error) {
      setIsDownloadFailed(true);
    }
  }

  return (
    <div className="text-center py-10">
      <svg
        id="undraw_certification_aif8"
        xmlns="http://www.w3.org/2000/svg"
        width="238.783"
        height="193.68"
        viewBox="0 0 238.783 193.68"
        className="m-auto"
      >
        <path
          id="Path_309"
          data-name="Path 309"
          d="M352.7,670.379a244.718,244.718,0,0,1-33.232-2.177c-.36-.046-.724-.1-1.086-.151-2.7-.385-5.409-.824-8.039-1.306a173.64,173.64,0,0,1-33.171-9.4c-33.862-13.545-55.153-10.662-67.051-5.859-12.876,5.2-17.825,13.7-17.874,13.789l-.424-.24c.049-.087,5.063-8.718,18.079-13.985,7.632-3.088,16.485-4.438,26.317-4.013,12.272.531,26.111,3.848,41.133,9.856a173.161,173.161,0,0,0,33.077,9.369c2.623.481,5.322.92,8.021,1.3.361.054.722.1,1.08.151,29.859,4.107,58.663,1.63,77.57-1.17a248.768,248.768,0,0,0,25.713-5.035l.127.47a249.12,249.12,0,0,1-25.769,5.047A307.058,307.058,0,0,1,352.7,670.38Z"
          transform="translate(-191.819 -492.513)"
          fill="#3f3d56"
        />
        <path
          id="Path_310"
          data-name="Path 310"
          d="M352.728,192.527c-8.585,0-16.942-.4-25.005-1.2-18.529-1.845-35.538-5.827-50.556-11.834-33.862-13.545-55.153-10.663-67.051-5.859-12.876,5.2-17.825,13.7-17.874,13.789l-.424-.24c.049-.087,5.063-8.718,18.079-13.985,7.632-3.088,16.485-4.439,26.317-4.013,12.272.531,26.111,3.847,41.133,9.856C311.264,192.6,349,193.135,374.692,191.2a287.778,287.778,0,0,0,48.119-7.542l.127.47a288.223,288.223,0,0,1-48.2,7.557C367.272,192.244,359.92,192.527,352.728,192.527Z"
          transform="translate(-191.819 -154.424)"
          fill="#3f3d56"
        />
        <path
          id="Path_311"
          data-name="Path 311"
          d="M661.543,121.274s-9.574-3.419-15.728,10.258a269.707,269.707,0,0,1-15.728,28.721l5.471,1.368s1.368-9.574,4.787-10.941l-1.368,12.309s41.03,14.361,59.494-1.368l-.684-4.787s2.735.684,2.735,4.787l2.052-2.052s-2.052-4.1-8.206-9.574c-4.04-3.591-5.428-10.422-5.9-14.693a17.78,17.78,0,0,0-4.094-9.683C680.279,120.879,672.827,115.841,661.543,121.274Z"
          transform="translate(-501.895 -118.919)"
          fill="#2f2e41"
        />
        <path
          id="Path_312"
          data-name="Path 312"
          d="M876.644,391.56s18.016,15.366,20.665,24.374S887.242,426,887.242,426l-27.554,2.649s-.53,12.717-5.3,14.307-.968-21.029-.968-21.029l26.932-7.584-12.187-12.717Z"
          transform="translate(-658.89 -311.814)"
          fill="#fbbebe"
        />
        <path
          id="Path_313"
          data-name="Path 313"
          d="M602.3,445.907l-2.12,28.083L588.525,521.68s-10.6,21.725-1.59,22.255,8.478-20.665,8.478-20.665l16.956-45.04,1.59-30.733Z"
          transform="translate(-468.797 -350.265)"
          fill="#fbbebe"
        />
        <circle
          id="Ellipse_34"
          data-name="Ellipse 34"
          cx="17.486"
          cy="17.486"
          r="17.486"
          transform="translate(145.691 11.392)"
          fill="#fbbebe"
        />
        <path
          id="Path_314"
          data-name="Path 314"
          d="M709.246,246.635s6.359,22.785-3.709,28.613,25.434,10.068,34.972-7.418c0,0-16.956-5.829-12.187-21.2Z"
          transform="translate(-554.017 -209.279)"
          fill="#fbbebe"
        />
        <path
          id="Path_315"
          data-name="Path 315"
          d="M655.224,350.954l-3.179-1.06s-6.359,2.649-6.359,7.418-1.06,23.844-1.06,23.844l14.307,1.59Z"
          transform="translate(-512.182 -282.335)"
          fill="#6c63ff"
        />
        <path
          id="Path_316"
          data-name="Path 316"
          d="M705.959,596.228c-19.561,2.9-48.07,5.238-77.637,1.171,0-.292,0-.577,0-.855q0-2.48.19-4.9c1.515-19.9,11.766-36.489,15.733-42.239.2-.292.385-.555.55-.789.64-.9,1.013-1.373,1.013-1.373s30.733-.5,46.629,5.856a6.836,6.836,0,0,1,2.07,1.354C700.431,559.919,703.913,577.675,705.959,596.228Z"
          transform="translate(-500.645 -421.951)"
          fill="#2f2e41"
        />
        <path
          id="Path_317"
          data-name="Path 317"
          d="M847.078,326.064s6.888-3.709,13.777,4.239l13.777,15.9-10.6,11.657L843.9,345.67Z"
          transform="translate(-653.168 -264.864)"
          fill="#6c63ff"
        />
        <path
          id="Path_318"
          data-name="Path 318"
          d="M683.571,167.652c6.19-4.48,11.2-9.64,14.516-15.778,0,0,11.36,12.622,18.3,13.253s.631-15.147.631-15.147L704.4,146.825l-11.991,1.262L682.94,154.4Z"
          transform="translate(-539.289 -138.663)"
          fill="#2f2e41"
        />
        <path
          id="Path_319"
          data-name="Path 319"
          d="M713.968,315.474S706.553,327.2,684.3,321.6l-12.63,3.478-1.679.462s1.59,31.793,5.829,37.621,3.179,9.008,2.12,9.538-3.709-.53-2.12,2.119,3.709,1.06,1.59,2.649-3.179,7.418-3.179,7.418l49.808,6.359s-1.59-20.665,2.649-31.263,5.3-14.837,5.3-14.837L728.8,318.654Z"
          transform="translate(-530.125 -257.983)"
          fill="#6c63ff"
        />
        <rect
          id="Rectangle_547"
          data-name="Rectangle 547"
          width="68.182"
          height="85.227"
          transform="translate(12.144 47.118)"
          fill="#e6e6e6"
        />
        <rect
          id="Rectangle_548"
          data-name="Rectangle 548"
          width="14.61"
          height="9.74"
          transform="translate(16.892 54.302)"
          fill="#fff"
        />
        <rect
          id="Rectangle_549"
          data-name="Rectangle 549"
          width="56.493"
          height="3.409"
          transform="translate(17.014 73.295)"
          fill="#fff"
        />
        <rect
          id="Rectangle_550"
          data-name="Rectangle 550"
          width="56.493"
          height="3.409"
          transform="translate(17.014 82.305)"
          fill="#fff"
        />
        <rect
          id="Rectangle_551"
          data-name="Rectangle 551"
          width="56.493"
          height="3.409"
          transform="translate(17.014 91.315)"
          fill="#fff"
        />
        <rect
          id="Rectangle_552"
          data-name="Rectangle 552"
          width="56.493"
          height="3.409"
          transform="translate(17.014 100.324)"
          fill="#fff"
        />
        <rect
          id="Rectangle_553"
          data-name="Rectangle 553"
          width="56.493"
          height="3.409"
          transform="translate(17.014 109.334)"
          fill="#fff"
        />
        <path
          id="Path_320"
          data-name="Path 320"
          d="M447.613,538.633a13.355,13.355,0,1,1-2.07-7.154A13.355,13.355,0,0,1,447.613,538.633Z"
          transform="translate(-353.895 -406.409)"
          fill="#6c63ff"
        />
        <path
          id="Path_321"
          data-name="Path 321"
          d="M460.091,541.689,446.908,554.87c-.829-1.283-5.445-7.244-5.445-7.244a18.68,18.68,0,0,1,1.89-1.668l3.828,5.1,11.367-11.367A13.337,13.337,0,0,1,460.091,541.689Z"
          transform="translate(-368.443 -416.619)"
          fill="#fff"
        />
      </svg>
      <h3 className="text-xl text-gray-700 font-semibold py-8 pb-2">CONGRATULATIONS</h3>
      <p className="text-gray-700">Your application has been sent successfully.</p>
      {isDownloadFailed && (
      <>
        <p className="text-gray-700">
          {mainStore.applicationType === 'paymentApplication' ? 'Payment application' : 'Credit application'} file '{caID}' already downloaded. Please, contact the CS Team.
        </p>
      </>
      )
      }
      {!isDocumentReady && !isDocumentLoading ? (
        <>
          <p className="text-gray-700">
            Unfortunately, we cannot generate the PDF version of your{' '}
            {mainStore.applicationType === 'paymentApplication' ? 'payment application' : 'credit application'}, please
            contact the CS Team.
          </p>
          <p className="text-gray-700">Application ID: {caID}</p>
        </>
      ) : (
        <span
          className="flex mt-2 justify-center items-center"
          disabled={!isDocumentReady}
        >
          <button
            className="flex justify-center items-center bg-blue-600 hover:bg-blue-800 text-white font-semibold tracking-wider py-2 px-12 rounded shadow self-center disabled:opacity-50"
            onClick={() => downloadApplication(caID, mainStore.applicationType)}
            disabled={!isDocumentReady || isDownloadFailed}
          >
            <span>Download PDF Version</span>
            {isDocumentLoading && (
              <div
                className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full border-dotted ml-1.5"
                role="status"
              />
            )}
          </button>
        </span>
      )}
    </div>
  );
};

export default DoneStep;
