import React, { useContext, useEffect, useState } from 'react';
import FormField from '../../../FormField';
import { TermsAndConditionsModal } from '../../../TermsAndConditionsModal';
import { FormFields, validateField, validateForm } from './FormValidation';
import { Loader2 } from 'tabler-icons-react';
import { BankTransferFee } from './BankTransferFee';
import { Field } from '../../../CreditCard/CreditCard';
import MainStore from '../../../../stores/MainStore';
import PayToProcessingModal, { PayToAgreement } from './payto-modal/PayToProcessingModal';
import PayToLogo from './payto-modal/PayTo/PayToLogo';
import Recaptcha from 'react-google-invisible-recaptcha';
import configs from '../../../../constants/config';
import LaunchDarklyStore from '../../../../stores/LaunchDarklyStore';

type BankTransferFormProps = {
  value: any;
  onChange: any;
  schema: any;
  feeInformation: any;
  validateRequired?: any;
  onSubmit: any;
  bankTransferAuthorityCreated: boolean;
  setBankTransferAuthorityCreated: any;
  isLoading: boolean;
};

type CompanyBrand = {
  companyName: string;
};

type Callbacks = {
  execute: () => void;
  reset: () => void;
  getResponse: () => string;
};

const BankTransferForm: React.FC<BankTransferFormProps> = (props) => {
  const refRecaptcha = React.useRef<Callbacks>(null);
  const mainStore = useContext(MainStore);
  const launchDarklyStore = useContext(LaunchDarklyStore);
  const isPayToLive = launchDarklyStore.flags.isPayToLive.value;

  const payToAgreement = mainStore.payToAgreement as PayToAgreement;
  const getPayToError = () => payToAgreement.reason?.detail || '';
  const [errors, setErrors]: any = useState({});
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPayToModal, setShowPayToModal] = useState(false);
  const [formFields, setFormFields] = React.useState<FormFields>(
    props.value
      ? JSON.parse(props.value)
      : {
          accountName: '',
          bsb: '',
          accountNumber: '',
          confirmAccountNumber: '',
          agreeToTerms: false,
          email: '',
        }
  );
  const {
    isLoading,
    onSubmit,
    feeInformation,
    bankTransferAuthorityCreated,
    setBankTransferAuthorityCreated,
    onChange,
  } = props;

  useEffect(() => {
    const formErrors = validateForm(formFields);
    if (Object.keys(formErrors).length === 0) {
      if (isPayToLive) {
        refRecaptcha?.current?.execute();
      }
      onChange(JSON.stringify(formFields));
      onSubmit(formFields, () => setShowPayToModal(true));
    } else {
      if (formFields.agreeToTerms) {
        setErrors(formErrors);
      }
      onChange(undefined);
      // if (isPayToLive && refRecaptcha && refRecaptcha.current && refRecaptcha.current.reset) {
      //   refRecaptcha?.current?.reset();
      // }
    }
  }, [formFields, onSubmit, isLoading, onChange, isPayToLive]);

  const handleChange = (element: any): void => {
    if (getPayToError().length > 0) {
      mainStore.updatePayToAgreement({});
    }
    const { name, value } = element.target ? element.target : element;
    const formToBeUpdated = { ...formFields, [name]: name === 'agreeToTerms' ? element.checked : value };
    setFormFields(formToBeUpdated);
    mainStore.updateBankTransferInfo(formToBeUpdated);
    if (Object.keys(errors)?.length > 0) {
      setErrors({ ...errors, [name]: validateField(formToBeUpdated, name) });
    }
  };

  const agreementInput = (e: any) => {
    handleChange(e.target);
  };

  const handleToggleTermsModal = () => {
    setShowTermsModal(!showTermsModal);
  };

  const resetForm = () => {
    setFormFields({
      accountName: '',
      bsb: '',
      accountNumber: '',
      confirmAccountNumber: '',
      agreeToTerms: false,
      email: '',
    });
    setErrors({});
    setBankTransferAuthorityCreated(false);
    props.onChange(undefined);
    mainStore.updateBankTransferInfo({});
    mainStore.updatePayToAgreement({});
  };

  const handleTogglePayToModal = (payToAgreement: PayToAgreement) => {
    if (payToAgreement?.status === 'ACTIVE') {
      mainStore.updatePayToAgreement(payToAgreement);
      mainStore.updateBankTransferInfo(formFields);
      onChange(JSON.stringify({ ...formFields, authorityType: 'PayTo' }));
    } else if (payToAgreement?.status === 'CANCELED') {
      resetForm();
      setBankTransferAuthorityCreated(false);
      mainStore.updatePayToAgreement(payToAgreement);
    } else if (payToAgreement?.status === 'EXPIRED') {
      setBankTransferAuthorityCreated(false);
      onSubmit(formFields, () => setShowPayToModal(true));
    }
  };

  const getAuthorityType = () => {
    if (Object.hasOwn(mainStore.bankTransferInfo, 'authorityType')) {
      return (mainStore.bankTransferInfo as { authorityType?: string })?.authorityType || 'Bank Transfer';
    }
    return 'Bank Transfer';
  };

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center">
          <Loader2 size={48} strokeWidth={2} color={'#3d82f1'} className="animate-spin" />
        </div>
      ) : bankTransferAuthorityCreated ? (
        <div>
          <div className="flex flex-wrap">
            <Field className="flex w-1/5 sm:w-full mt-6">
              <label className="text-gray-500 font-medium">Account Name</label>
              <div>{formFields.accountName}</div>
            </Field>
            <Field className="flex w-1/5 sm:w-full mt-6">
              <label className="text-gray-500 font-medium">Account Number</label>
              <div>
                {`${formFields.accountNumber.slice(0, 2)}xxxx${formFields.accountNumber.slice(
                  formFields.accountNumber.length - 2,
                  formFields.accountNumber.length
                )}`}
              </div>
            </Field>
            <Field className="flex w-1/5 sm:w-full mt-6">
              <label className="text-gray-500 font-medium">BSB</label>
              <div>{formFields.bsb}</div>
            </Field>
            {getAuthorityType() === 'PayTo' ? (
              <Field className="flex w-1/5 sm:w-full mt-6" style={{ height: '40px' }}>
                <PayToLogo />
              </Field>
            ) : null}
            <Field className="flex w-1/5 sm:w-full mt-6">
              <button className="link" type="button" onClick={resetForm}>
                {getAuthorityType() === 'PayTo'
                  ? 'Discard PayTo Agreement information'
                  : 'Reset Bank Transfer information'}
              </button>
            </Field>
          </div>
          {feeInformation ? <BankTransferFee feeInformation={feeInformation} /> : ''}
        </div>
      ) : (
        <>
          {getPayToError().length > 0 ? (
            <span className="font-medium text-red-600 text-red-700 text-sm">{getPayToError()}</span>
          ) : (
            ''
          )}
          <div className="flex flex-wrap">
            <div className="w-1/3 xs:w-full xs:mt-6 form-group mt-2">
              <FormField
                onChange={handleChange}
                label="Account Name"
                value={formFields.accountName}
                placeholder="Account name"
                name="accountName"
                type="text"
                hasError={errors?.accountName?.length > 0}
                errorMessage={errors?.accountName || ''}
                onBlur={() => setErrors({ ...errors, accountName: validateField(formFields, 'accountName') })}
                mxClass="false"
              />
            </div>
            <div className="w-1/6 xs:w-full xs:mt-6 form-group mt-2">
              <FormField
                onChange={handleChange}
                label={'BSB'}
                value={formFields.bsb}
                placeholder="BSB"
                name="bsb"
                type="text"
                mki-mask="######"
                hasError={errors?.bsb?.length > 0}
                errorMessage={errors?.bsb || ''}
                mki-onBlur={() => setErrors({ ...errors, bsb: validateField(formFields, 'bsb') })}
              />
            </div>
            <div className="w-1/4 xs:w-full xs:mt-6 form-group mt-2">
              <FormField
                onChange={handleChange}
                label={'Account Number'}
                value={formFields.accountNumber}
                placeholder="Account number"
                name="accountNumber"
                type="text"
                mki-mask="#########"
                hasError={errors?.accountNumber?.length > 0}
                errorMessage={errors?.accountNumber || ''}
                mki-onBlur={() => setErrors({ ...errors, accountNumber: validateField(formFields, 'accountNumber') })}
                mxClass="false"
              />
            </div>
            <div className="w-1/4 xs:w-full xs:mt-6 form-group mt-2">
              <FormField
                onChange={handleChange}
                label={'Confirm Account Number'}
                value={formFields.confirmAccountNumber}
                placeholder="Confirm account number"
                name="confirmAccountNumber"
                type="text"
                mki-mask="#########"
                hasError={errors?.confirmAccountNumber?.length > 0}
                errorMessage={errors?.confirmAccountNumber || ''}
                mki-onBlur={() =>
                  setErrors({ ...errors, confirmAccountNumber: validateField(formFields, 'confirmAccountNumber') })
                }
                mxClass="ml-4"
              />
            </div>
            {feeInformation ? (
              <div className="w-full">
                <BankTransferFee feeInformation={feeInformation} />
              </div>
            ) : (
              <></>
            )}
            <div className="w-full border border-gray-400 border-solid rounded p-2">
              <h4 className="font-bold">Service Agreement</h4>
              <div className="pt-2 text-gray-600">
                By checking “I agree” below and clicking the “Confirm” button you acknowledge and agree that:
              </div>
              <ul className="list-disc m-2 ml-8 text-gray-600">
                <li>
                  You have read and agree to the Direct Debit&nbsp;
                  <button className="link" type="button" onClick={handleToggleTermsModal}>
                    Terms and Conditions
                  </button>
                  .
                </li>
                <li>
                  <span>The information you have provided is true and correct.</span>
                </li>
                <li>
                  <span>
                    You understand that you are agreeing to a contract with ongoing payments, where applicable.
                  </span>
                </li>
                <li>
                  <span>You agree to accept the payment processing fees, if applicable.</span>
                </li>
              </ul>
              <div>
                <input
                  type="checkbox"
                  id="agreeToTerms"
                  name="agreeToTerms"
                  className="rounded"
                  checked={formFields.agreeToTerms}
                  onChange={agreementInput}
                />
                <label className="p-2 select-none" htmlFor="agreeToTerms">
                  I agree
                </label>
              </div>
            </div>
            <TermsAndConditionsModal
              agreeToTerms={formFields.agreeToTerms}
              showModal={showTermsModal}
              handleToggleModal={handleToggleTermsModal}
              handleChange={agreementInput}
              setShowModal={setShowTermsModal}
            />
          </div>
          {isPayToLive ? <Recaptcha ref={refRecaptcha} sitekey={configs?.recaptcha_key} badge='bottomleft' /> : null}
        </>
      )}
      {!isLoading && showPayToModal ? (
        <PayToProcessingModal
          payToAgreementId={payToAgreement.uid}
          showModal={true}
          handleToggleModal={(payToAgreement: PayToAgreement) => handleTogglePayToModal(payToAgreement)}
          onClose={() => setShowPayToModal(false)}
          companyName={(mainStore.brand as CompanyBrand)?.companyName || '---'}
        />
      ) : null}
    </>
  );
};

export default BankTransferForm;
