class DynamicFormBuilder {
  constructor(JSON = undefined) {
    if (!JSON) {
      throw new Error('Invalid JSON!');
    }

    this.JSON = JSON;
  }

  getFormDefinition() {
    const formDefinition = [];

    this.JSON.forEach((formStep) => {
      const section = {};
      section.title = this.getSectionTitle(formStep);
      section.fields = {};
      Object.keys(formStep.formUiSchema.uiSchemaData.data).forEach((uiDataKey) => {
        const field = {};
        if (Object.keys(formStep.formUiSchema.uiSchemaData.data).length >= 1 && Object.keys(formStep.formUiSchema.uiSchemaData.data[uiDataKey]).includes('items')) {
          Object.keys(formStep.formUiSchema.uiSchemaData.data[uiDataKey].items).forEach((itemKey) => {
            field[itemKey] = {
              label: this.getLabel(formStep, uiDataKey, itemKey),
              cssClass: this.getCSSClass(formStep, uiDataKey),
              value: '',
            };
          });
          section.fields[uiDataKey] = field;
        } else if (
          !formStep.formUiSchema.uiSchemaData.data[uiDataKey].hasOwnProperty('items') &&
          !formStep.formUiSchema.uiSchemaData.data[uiDataKey].hasOwnProperty('ui:column')
        ) {
          const hasTitle = formStep.formJsonSchema.jsonSchemaData.data.properties[uiDataKey]
            ? formStep.formJsonSchema.jsonSchemaData.data.properties[uiDataKey].hasOwnProperty('title')
            : false;
          const item = {};
          Object.keys(formStep.formUiSchema.uiSchemaData.data[uiDataKey]).forEach((itemKey) => {
            if (itemKey !== 'ui:options') {
              item[itemKey] = {
                label: this.getLabel(formStep, uiDataKey, itemKey),
                cssClass: this.getCSSClass(formStep, uiDataKey, itemKey),
                value: '',
              };
            }
          });

          if (hasTitle) {
            item.title = formStep.formJsonSchema.jsonSchemaData.data.properties[uiDataKey].title;
          }

          section.fields[uiDataKey] = item;
        } else {
          section.fields[uiDataKey] = {
            label: this.getLabel(formStep, uiDataKey),
            cssClass: this.getCSSClass(formStep, uiDataKey),
            value: '',
          };
        }
      });

      formDefinition.push(section);
    });

    return formDefinition;
  }

  getSectionTitle(formStep) {
    if (formStep && formStep.formStepData && formStep.formStepData.label) {
      return formStep.formStepData.label;
    } else {
      return '';
    }
  }

  getLabel(formStep, uiDataKey, itemKey) {
    let label = '';
    const properties = formStep.formJsonSchema.jsonSchemaData.data.properties[uiDataKey]
    const definition = this.generateDefinitionsKey(formStep, uiDataKey);

    if (formStep.formJsonSchema.jsonSchemaData.data.hasOwnProperty('definitions')
      && formStep.formJsonSchema.jsonSchemaData.data.definitions[definition]
      && formStep.formJsonSchema.jsonSchemaData.data.definitions[definition].properties.hasOwnProperty(itemKey)) {
      const definitionsKey = this.generateDefinitionsKey(formStep, uiDataKey);
      label = formStep.formJsonSchema.jsonSchemaData.data.definitions[definitionsKey].properties[itemKey]?.title || label;
    } else if (properties && properties.type === "object") {
      label = properties.properties[itemKey]?.title || label;
    } else if (properties && properties.hasOwnProperty('title') && !properties.hasOwnProperty('items')) {
      label = properties?.title || label;
    } else if (properties && properties.hasOwnProperty('items')) {
      label = properties.items.properties[itemKey]?.title || label;
    } else if(!properties && formStep.formJsonSchema.jsonSchemaData.data.allOf) {
      label = formStep.formJsonSchema.jsonSchemaData.data.allOf
        .filter(item => item.then.properties.hasOwnProperty(uiDataKey))
        .map(item => item.then.properties[uiDataKey]?.title)[0];
    }

    return label;
  }

  generateDefinitionsKey(formStep, uiDataKey) {
    const definitionsKey = formStep.formJsonSchema.jsonSchemaData.data.properties[uiDataKey]
      ? formStep.formJsonSchema.jsonSchemaData.data.properties[uiDataKey]['$ref'] : null;
    return definitionsKey && definitionsKey.length > 0
      ? definitionsKey.substr(definitionsKey.lastIndexOf('/') + 1, definitionsKey.length - 1)
      : '';
  }

  getCSSClass(formStep, uiDataKey, itemKey) {
    let cssClass = '';
    if (formStep && uiDataKey && itemKey) {
      if (
        formStep.formUiSchema.uiSchemaData[uiDataKey] &&
        formStep.formUiSchema.uiSchemaData[uiDataKey].hasOwnProperty('ui:column')
      ) {
        cssClass = formStep.formUiSchema.uiSchemaData.data[uiDataKey]['ui:column'];
      } else if (
        formStep.formUiSchema.uiSchemaData.data[uiDataKey].items &&
        formStep.formUiSchema.uiSchemaData.data[uiDataKey].items[itemKey]
      ) {
        cssClass = formStep.formUiSchema.uiSchemaData.data[uiDataKey].items[itemKey]['ui:column'];
      } else {
        cssClass = formStep.formUiSchema.uiSchemaData.data[uiDataKey][itemKey]['ui:column'];
      }
    }

    return cssClass;
  }

  generateFormWithData(formDataList) {
    const formDefinition = this.getFormDefinition();
    const formDataListFixed = formDataList.filter((formData, index) => index > 0 || Object.keys(formData).length > 0);
    const formWithData = [];

    formDefinition.forEach((formStep, formStepIndex) => {
      formDataListFixed.forEach((formData, formDataIndex) => {
        if (formStepIndex === formDataIndex && !['Trade References', 'Documents', ''].includes(formStep.title)) {
          const fields = {};
          Object.keys(formStep.fields).forEach((formStepKey) => {
            if(formData) {
              Object.keys(formData).forEach((formDataKey) => {

                if (formStepKey === formDataKey) {
                  fields[formStepKey] = [];
                  if (formData[formStepKey] instanceof Array) {
                    formData[formStepKey].forEach((formDataItem) => {
                      let item = {};
                      Object.keys(formStep.fields[formStepKey]).forEach((key) => {
                        item[key] = {};
                        if (key === 'title') {
                          formStep.fields[formStepKey].title = formData[formStepKey][key];
                        } else {
                          item[key].value = formDataItem[key] || '';
                        }
                        item[key].label = formStep.fields[formStepKey][key].label;
                        item[key].cssClass = formStep.fields[formStepKey][key].cssClass;
                      });
                      fields[formStepKey].push(item);
                    });
                  } else {
                    Object.keys(formStep.fields[formStepKey]).forEach((key) => {
                      if (key !== 'title') {
                        if (
                          formStep.fields[formStepKey][key] instanceof Object &&
                          formStep.fields[formStepKey][key].hasOwnProperty('value')
                        ) {
                          formStep.fields[formStepKey][key].value = formData[formStepKey][key];
                        } else {
                          if(formDataKey.toLowerCase().indexOf('credit_card') >= 0
                            || formDataKey.toLowerCase().indexOf('creditcard') >= 0) {
                            const creditCardData = JSON.parse(formData[formStepKey]);
                            formStep.fields[formStepKey].value = `**** **** **** ${creditCardData.last4}`;
                          } else {
                            formStep.fields[formStepKey].value = formData[formStepKey];
                          }
                        }
                      }
                    });
                    fields[formStepKey].push(formStep.fields[formStepKey]);
                  }
                }
              });
            }
          });

          formWithData.push({
            title: formStep.title,
            fields: fields,
          });
        }
      });
    });

    return formWithData;
  }
}

export default DynamicFormBuilder;
