import React from 'react';
import './ResponsiveModal.scss';

interface ResponsiveModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
}

const ResponsiveModal: React.FC<ResponsiveModalProps> = ({
  isOpen = false,
  onClose,
  children,
  style,
  contentStyle,
}) => {
  return (
    <div className={`responsive-modal-overlay ${isOpen ? 'active' : ''}`}>
      <div className={`responsive-modal ${isOpen ? 'active' : ''}`} style={style}>
        <button className="close-responsive-modal" onClick={() => onClose()}>
          <i className="fa fa-times" />
        </button>

        <div className="responsive-modal-content" style={contentStyle}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveModal;
