const CurrencyOptions = [
    { value: '', label: '' },
    { value: 'EUR', label: 'EUR' },
    { value: 'GHS', label: 'GHS' },
    { value: 'MUR', label: 'MUR' },
    { value: 'NAD', label: 'NAD' },
    { value: 'USD', label: 'USD' },
    { value: 'XOF', label: 'XOF' }
]

export default CurrencyOptions;